import {useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import parse from "html-react-parser"
import ActionsModal from './ActionsModal'

import artifacts from "./Artifacts.json"

const AllArtifcats = () => {
    const [action, setAction] = useState(0)
    const [showModal, setShowModal] = useState(false)

    const setActionNumber = (number) => {
        setAction(number)
        setShowModal(true)
    }

    const columns = [
        {
            title: "Artifact",
            field: "artifact",
        },
        {
            title: "Artifact Description",
            field: "description",
            render: (rowData) => parse(rowData.description),
            sorting: false
        },
        {
            title: "Related Actions, Artifacts, Decisions",
            field: "actions",
            render: (rowData) => rowData?.actions?.map(number => <Button className='p-0' style={{minWidth: 25}} variant="link" onClick={() => setActionNumber(number)}>{number},</Button>),
            sorting: false
        }
    ]

    const options = {
        showTitle: true,
        headerStyle: {
            fontWeight: "bold",
            borderBottom: "none",
            paddingBottom: "0",
        },
        filtering: false,
        pageSize: 20
    };

    return (
        <Container fluid>
            <ActionsModal show={showModal} onHide={() => setShowModal(false)} actionNumber={action} />
            <Row className="mt-5 mb-3">
                <Col sm={12}>
                    <h3 className="text-bright-navy">Training Center</h3>
                </Col>
            </Row>
            <Row className="mt-5 mb-3">
                <Col sm={12}>
                    <ResponsiveMaterialTable
                        title={"Artifacts"}
                        columns={columns}
                        options={options}
                        data={artifacts}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default AllArtifcats