import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { Col, OverlayTrigger, Popover } from "react-bootstrap";
import VotingOverlay from "../VotingOverlay";
import { Award } from "./Award";

const TQReadOnlyVoting = ({ votes, currentUserVote }) => {

  const getVoteCount = (approved) => {
    return votes.filter((v) => v.approved === approved).length;
  };

  return (
    <Col
      xs={1}
      className="d-flex justify-content-center w-auto"
    >
      <>
        {/* negative vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                {votes
                  .filter((vote) => vote.approved === false)
                  .map((vote, idx) => (
                    <div key={vote.voter.id}>
                      <VotingOverlay
                        voter={vote.voter}
                        date={vote.vote_date}
                      />
                    </div>
                  ))}
              </Popover.Body>
            </Popover>
          }
        >
          <div className="mx-3 d-flex">
            {currentUserVote?.approved === false ? (
              <ThumbDownIcon
                data-testid="thumb-down-icon"
                style={{
                  color: "var(--bs-danger)",
                }}
              />
            ) : (
              <ThumbDownOffAltIcon data-testid="thumb-down-off-icon" />
            )}
            <h4 className="mx-3">{getVoteCount(false)}</h4>
          </div>
        </OverlayTrigger>

        {/* positive vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                {votes
                  .filter((vote) => vote.approved === true)
                  .map((vote, idx) => (
                    <div key={vote.voter.id}>
                      <VotingOverlay
                        voter={vote.voter}
                        date={vote.vote_date}
                      />
                    </div>
                  ))}
              </Popover.Body>
            </Popover>
          }
        >
          <div className="d-flex">
            {currentUserVote?.approved ? (
              <ThumbUpIcon
                data-testid="thumb-up-icon"
                style={{
                  color: "var(--bs-success)",
                }}
              />
            ) : (
              <ThumbUpOffAltIcon data-testid="thumb-up-off-icon" />
            )}
            <h4 className="mx-3">{getVoteCount(true)}</h4>
          </div>
        </OverlayTrigger>

        {/* Award Icon */}
        <Award votes={votes} />
      </>
    </Col>
  );
};

export default TQReadOnlyVoting;
