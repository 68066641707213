import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const MFAselect = ({setStep, setMfaMethod, setQrCode}) => {
    const [cookies, setCookies] = useCookies()
    const csrfToken = cookies['csrftoken']
    const authToken = cookies['auth_token']
    const [formErrors, setFormErrors] = useState({})
    const user = useSelector(state => state.user.value);
    const [method, setMethod] = useState({})
    const navigate = useNavigate()

    const handleChange = (e) => {
        setMethod({...method, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const mfaMethod = method.method
        setMfaMethod(mfaMethod)
        if (mfaMethod === 'email' && !user.email) {
            setStep('add-detail')
        }
        else if (mfaMethod === 'sms_twilio' && !user.phone_number) {
            setStep('add-detail')
        } else {
            axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${mfaMethod}/activate/`, method, {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization':  `Token ${authToken}`}}).then((response) => {
                if (mfaMethod === 'app') {
                    const qrCode = response.data.details
                    setStep('qr')
                    setQrCode(qrCode)
                } else {
                    setStep('confirm')
                }
            }).catch((error) => {
                setFormErrors(error.response.data)
            })
        }
    }

    return (
        <Card className='login-card'>
            <Card.Body>
                <Card.Title as="h4">Choose Verification Method</Card.Title>
                <Card.Subtitle as="p">For additional security NIAP requires you select a method to recieve one time codes</Card.Subtitle>
                <Form className="centered-form" onSubmit={handleSubmit}>
                    <Form.Group className='mb-4 text-start' controlId='method'>
                        <Form.Check type="radio" label="Email" name='method' value="email" onChange={handleChange} id="method1"/>
                        <p className='small'>Your Email: {user.email}</p>
                        <Form.Check type="radio" label="SMS" name="method" value='sms_twilio' onChange={handleChange} id="method2"/>
                        <span className='small'>By selecting to recieve MFA codes by SMS you consent to recieve text messages from us. Message and data rates may apply. Messages will be sent on each login.</span>
                        <p className='small'>Your Phone Number: {user.phone_number}</p>
                        <Form.Check type="radio" label="Application" name="method" value='app' onChange={handleChange} id="method3"/>
                        <p className='small'>Such as Authy or Google Authenticator</p>
                        {/* <Form.Check type="radio" label="YubiKey" name='yubi' onChange={handleChange} /> */}
                    </Form.Group>
                    {formErrors && <p className='mb-4 text-danger'>{formErrors.error}</p>}
                    <Button variant='warning' type="submit">Continue</Button>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default MFAselect