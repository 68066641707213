import { useState, useEffect, useCallback } from 'react'
import Form from 'react-bootstrap/Form'
import { Paper } from "@material-ui/core";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ResponsiveMaterialTable from '../../UI/MaterialTable/ResponsiveMaterialTable'
import { useCookies } from 'react-cookie'
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add"
import axios from 'axios'
import './fileUpload.css'

const UploadFile = ({ show, handleClose, currentFolder, type, setUpdate, setAlert, currentPage }) => {
    const permissions = useSelector(state => state.role.value);
    const currentUser = useSelector(state => state.user.value)
    const [cookies] = useCookies()
    const csrfToken = cookies['csrftoken']
    const authToken = cookies['auth_token']
    const [folders, setFolders] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [file, setFile] = useState({})
    const [editedFileLabel, setEditedFileLabel] = useState("");
    const [myOrg, setMyOrg] = useState({})

    const loadFolders = () => {
        axios.get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_folders/?page=${type}`,
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
            }
        ).then(response => {
            setFolders(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const getMyOrg = useCallback(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`, 
        {   
            withCredentials: true, 
            headers: {
                "Authorization": `Token ${authToken}`
            }
        }).then(response => {
            setMyOrg(response.data)
        }).catch(error => console.log(error))
    }, [authToken])

    useEffect(() => {
        getMyOrg()
    }, [getMyOrg])

    useEffect(() => {
        loadFolders()
    }, [currentFolder])

    useEffect(() => {
        setFile({...file, parent: currentFolder?.file_id})
    }, [currentFolder])

    const handleSubmit = (e) => {
        e.preventDefault()
        const submittedData = new FormData()
        submittedData.append(`file`, file?.file)
        if (file?.parent) {
            submittedData.append('parent', file?.parent)
        }
        submittedData.append("file_type", type)
        submittedData.append("file_type_id", currentUser.company?.org_id)
        submittedData.append("file_display_name", type)
        submittedData.append("file_name", file?.file?.name)
        submittedData.append("file_label", file?.file_label)
        submittedData.append("file_location", "uploads")
        submittedData.append("file_mime_type", file?.file?.type)
        submittedData.append('uploaded_on', new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString())
        submittedData.append('file_source_type', "Other")
        submittedData.append('active', JSON.stringify(true))
        submittedData.append("private", JSON.stringify(false)) 
        axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/`, 
            submittedData,
            {
                withCredentials: true, 
                headers: {
                    'X-CSRFToken': csrfToken, 
                    'Authorization':  `Token ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                const currentFiles = uploadedFiles
                currentFiles.push(response.data)
                setUploadedFiles([...currentFiles])
                setUpdate(response.data.file_id)
                if(handleClose){
                    handleClose()
                    if(setAlert){
                        setAlert({
                            message: "File uploaded successfully",
                            variant: "success"
                        })
                    }
                }
            }).catch((error) => {
                console.log('Error')
                if(handleClose){
                    handleClose()
                    if(setAlert){
                        setAlert({
                            message: "Error uploading file. Please try again.",
                            variant: "danger"
                        })
                    }
                }
            })
    }

    const handleChange = (e) => {
        if (e.target.name === 'file') {
            setFile({...file, [e.target.name]: e.target.files[0]})
        } else if (e.target.name === "file_type") {
            setEditedFileLabel(e.target.value);
            setFile({...file, [e.target.name]: e.target.value, file_label: e.target.value})
        } else if (e.target.name === "file_label") {
            setEditedFileLabel(e.target.value);
            setFile({...file, [e.target.name]: e.target.value})
        } else {
            setFile({...file, [e.target.name]: e.target.value})
        }
    }

    const columns = [
        { title: "Label", field: "file_label" },
        { title: "Name", field: "file_name", render: (rowData) => (rowData.file_new_name ? rowData.file_new_name : rowData.file_name) },
        { title: "Location", field: "file_location" },
    ]

    const options = {
        showTitle: true,
        headerStyle: {
          fontWeight: "bold",
          borderBottom: "none",
          paddingBottom: "0",
        },
        pageSize: 20
      };

    return (
        <Modal size="lg" show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title as="h1"><AddIcon />&nbsp;Upload Files</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} >
                    <Container fluid>
                        <Form.Group controlId="parent_folder">
                            <Form.Label>Parent Folder</Form.Label>
                            <Form.Select name="parent" onChange={handleChange} defaultValue={currentFolder?.file_id}>
                                <option value={null}>Select a Parent</option>
                                {folders.map(folder => (
                                    <option key={folder.file_id} value={folder.file_id}>{folder.file_location}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Row className="pt-2">
                            <Col xl={5} sm={12}>
                                <Form.Group className='text-start' controlId="file_label">
                                <Form.Label className='small text-secondary'>File Label</Form.Label>
                                <Form.Control 
                                value={editedFileLabel}
                                type="text"
                                name="file_label"
                                onChange={handleChange}
                                >
                                </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xl={5} sm={12}>
                                <Form.Group controlId='file'>
                                    <Form.Label className='small text-secondary'>Attach Files</Form.Label>
                                    <Form.Control type="file" name="file" onChange={handleChange} disabled={!file.file_label} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-start mb-3 mt-2'>
                                <Button variant='primary' type="submit">Upload File</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <ResponsiveMaterialTable
                    title={'Uploaded Files'}
                    columns={columns}
                    data={[...uploadedFiles]}
                    options={options}
                    components={{
                        Container: (props) => <Paper {...props} />,
                    }}
                />
            </Modal.Body>
        </Modal>
    )
}

export default UploadFile