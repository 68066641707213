import axios from "axios"
import { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useParams } from "react-router-dom"
import FileDownload from "js-file-download";

const PolicyLetterView = () => {
    const { id } = useParams()
    const [ policyLetter, setPolicyLetter ] = useState({})
    const [currentUrl, setCurrentUrl] = useState("")
    const [displayIframe, setDisplayIframe] = useState(true)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}publish/policies/${id}`)
        .then(response => {
            setPolicyLetter(response.data)
            getFileUrl(response.data.filename)
        })
        .catch(error => console.log(error))
    }, [])

    const getFileUrl = (fileName) => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_policy_letter/?file_name=${fileName}`
        )
        .then((response) => {
          URL.revokeObjectURL(currentUrl)
          let newUrl = ""
          if (response.data.size > 0) {
            newUrl = URL.createObjectURL(response.data)
          }
          setCurrentUrl(newUrl)
          if (response.data.type === 'application/pdf' || response.data.type === 'text/html') {
            setDisplayIframe(true)
          } else {
            setDisplayIframe(false)
          }
        }).catch((error) => {
            console.log(error)
        })
    }
  
    const downloadFile = (fileName) => {
        axios
        .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_policy_letter/?file_name=${fileName}`,
            {
            responseType: "blob",
            }
        )
        .then((response) => {
            FileDownload(response.data, fileName);
        })
        .catch((error) => {
            console.log("Error");
        });
    };

    return (
        <Container className="py-3">
            <Row>
                <Col md={11}>
                    <h2>{policyLetter.policy_num} {policyLetter.policy_title}</h2>
                </Col>
                <Col md={1} className="text-end">
                    <Button onClick={() => downloadFile(policyLetter.filename)}>Download</Button>
                </Col>
            </Row>
            <Row>
                <Col style={{height: 800}}>
                    {displayIframe && <iframe width={"100%"} height={"100%"} src={currentUrl} />}
                    {!displayIframe && <h5>This document type cannot be rendered. Please click the download button.</h5>}
                </Col>
            </Row>
        </Container>
    )

}

export default PolicyLetterView