import { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './EvaluationCards.css';
import stepOneImage from '../../images/evaluation-process/step-one.png';
import stepTwoImage from '../../images/evaluation-process/step-two.png';
import stepThreeImage from '../../images/evaluation-process/step-three.png';
import stepFourImage from '../../images/evaluation-process/step-four.png';
import StepOneModal from './StepsModal/StepOneModal';
import StepTwoModal from './StepsModal/StepTwoModal';
import StepThreeModal from './StepsModal/StepThreeModal';
import {Link} from "react-router-dom"

export default function EvaluationSteps() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [removeLine, setRemoveLine] = useState(false);
  
  const [showStepOneModal, setShowStepOneModal] = useState(false);
  const [showStepTwoModal, setShowStepTwoModal] = useState(false);
  const [showStepThreeModal, setShowStepThreeModal] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth <= 992) {
      setRemoveLine(true);
    } else {
      setRemoveLine(false);
    }
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <Container>
    <StepOneModal show={showStepOneModal} setShowStepOneModal={setShowStepOneModal} />
    <StepTwoModal show={showStepTwoModal} setShowStepTwoModal={setShowStepTwoModal} />
    <StepThreeModal show={showStepThreeModal} setShowStepThreeModal={setShowStepThreeModal} />
      <Row className='mb-5'>
        <Col md={6} lg={2}>
          <div className='clickable' onClick={() => setShowStepOneModal(true)}>
            <div className='d-flex justify-content-center icon-border'>
              <img
                src={stepOneImage}
                alt='vendor contacts cctl image'
                className='w-auto'
                height='120px'
              />
            </div>
            <div className='text-center'>
              <h6 className='text-uppercase fw-bold mt-3 mb-3 text-primary'>
                Step 1
              </h6>
              <p>Vendor contacts CCTL</p>
                <Button variant="warning">Learn More</Button>
            </div>
          </div>
        </Col>
        {!removeLine && (
          <Col>
            <div className='horizontal-line'></div>
          </Col>
        )}
        <Col md={6} lg={2}>
          <div className='clickable' onClick={() => setShowStepTwoModal(true)}>
            <div className='d-flex justify-content-center icon-border'>
              <img
                src={stepTwoImage}
                alt='cctl sends niap documents image'
                className='w-auto'
                height='120px'
              />
            </div>
            <div className='text-center'>
              <h6 className='text-uppercase fw-bold mt-3 mb-3 text-primary'>
                Step 2
              </h6>
              <p>
                CCTL Sends NIAP documentation and the Check-In Process begins.
              </p>
                <Button variant="warning">Learn More</Button>
            </div>
          </div>
        </Col>
        {!removeLine && (
          <Col>
            <div className='horizontal-line'></div>
          </Col>
        )}
        <Col md={6} lg={2}>
          <div className='clickable' onClick={() => setShowStepThreeModal(true)}>
            <div className='d-flex justify-content-center icon-border'>
              <img
                src={stepThreeImage}
                alt='cctl works with validators image'
                className='w-auto'
                height='120px'
              />
            </div>
            <div className='text-center'>
              <h6 className='text-uppercase fw-bold mt-3 text-primary'>Step 3</h6>
              <p>
                CCTL works with Validators, Evaluation/ Checkout Proceedings
                happens, Validators Submit Final Package for approval to NIAP.
              </p>
                <Button variant="warning">Learn More</Button>
            </div>
          </div>
        </Col>
        {!removeLine && (
          <Col>
            <div className='horizontal-line'></div>
          </Col>
        )}
        <Col md={6} lg={2}>
          <div className='d-flex justify-content-center icon-border'>
            <img
              src={stepFourImage}
              alt='COTS IT product posted image'
              className='w-auto'
              height='120px'
            />
          </div>
          <div className='text-center'>
            <h6 className='text-uppercase fw-bold mt-3 text-primary'>Step 4</h6>
            <p>
              COTS IT Product is posted to NIAP <a href="/products">Product Compliant List</a> and {""} 
              <a href="https://www.commoncriteriaportal.org/">Common Criteria Portal</a>.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
