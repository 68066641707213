import { Editor } from '@tinymce/tinymce-react';
import './HTMLEditor.css'

export default function HTMLEditor({defaultValue, handleChange, name, isValid, setIsValid, disabled, setEnforceFocusModal}) {

    const handleEditorChange = (content) => {
        if(content === '') setIsValid(false);
        else setIsValid(true);
        handleChange({target: {name: name, value: content}})
    }

    return (
        <div className={isValid ? '' : 'border border-danger'}>
            <Editor
                id={name}
                initialValue={defaultValue}
                apiKey='lt8tuostooe14zsx3j3plyt9vuaj64y20js006h7k1evurrs'
                init={{
                    height: 320,
                    selector: 'textarea',
                    plugins: 'preview powerpaste casechange importcss searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media mediaembed codesample' 
                    + ' table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export',
                    mobile: {
                        plugins: 'preview powerpaste casechange importcss searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media mediaembed codesample' 
                        + ' table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist help formatpainter pageembed charmap quickbars emoticons advtable'
                    },
                    menubar: 'file edit view insert format tools table tc help',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor' 
                    + ' casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save | insertfile image media pageembed link anchor codesample | ltr rtl',
                    image_advtab: true,
                    importcss_append: true,
                    image_caption: true,
                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    toolbar_mode: 'sliding',
                    contextmenu: 'link image table configurepermanentpen',
                    link_default_protocol: 'https',
                    relative_urls: false,
                    remove_script_host: false,
                    document_base_url: process.env.REACT_APP_REACT_ENDPOINT,
                    setup: (editor) => {
                        editor.on('openWindow', (e)=>{
                            if (setEnforceFocusModal){
                                setEnforceFocusModal(false)

                            }
                        })
                        editor.on('CloseWindow', (e)=>{
                            if(setEnforceFocusModal){
                                setEnforceFocusModal(true)

                            }
                        })
                        editor.on('GetContent', (e) => {
                            // Replaces the content's \n to \r\n
                          e.content = e.content.replace(/\n/g, '\r\n');
                        });
                  
                        editor.on('SetContent', (e) => {
                            // replaces the content's saving \n to \r\n
                          e.content = e.content.replace(/\n/g, '\r\n');
                        });
                      }
                    }}
                onEditorChange={handleEditorChange}
                disabled={disabled}
            />
        </div>
    )
}
