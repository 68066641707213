import { Container, Row, Col, Button, Alert, Stack, Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "../../Products.css";
import "../ProductDetails.css";
import Notes from "../../../ProtectionProfiles/PPManager/Notes";
import { useCookies } from "react-cookie";
import FileUpload from "../../FileUpload";
import moment from "moment";
import AMHeader from "./AMHeader";
import AssuranceMaintenanceAccordion from "./AssuranceMaintenanceAccordion";
import AMProgressPoints from "./AMProgressPoints";
import ACMRCompletedModal from "./ACMRCompletedModal";
import ACMRRecommendationModal from "./ACMRRecommendationModal";
import AMSideInfo from "./AMSideInfo";
import { useInterval } from "../../../../hooks/useInterval";
import UnauthorizedView from "../../../UI/UnauthorizedView";

export default function CheckPermAMDetails({setOverlayActive, setOverlayText}){
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const { product_id, id } = useParams();
    const permissions = useSelector((state) => state.role.value);
    const [hasPerm, setHasPerm] = useState();
  
    useEffect(() => {
      let role = permissions?.role_type
        if (id) {
          axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/has_permissions_to_view_maintenance/?role=${role}&maintenance_id=${id}`, {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }).then(response => {
            if (response.data?.authorized === false) {
              setHasPerm(false)
            } else {
              setHasPerm(true)
            }
          }).catch(() => {
            setHasPerm(false)
          });
        }
    }, [id]);
  
    if(!permissions?.role_permissions?.find(permission => permission.area === 'Assurance Maintenance')?.read){
      return (<UnauthorizedView />)
    } else if (permissions?.role_permissions?.find(permission => permission.area === 'Assurance Maintenance')?.local_only && hasPerm === false){
      return (<UnauthorizedView />)
    } else if (permissions?.role_permissions?.find(permission => permission.area === 'Assurance Maintenance')?.local_only && hasPerm === true){
      return (<AssuranceMaintenanceDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />)
    } else if (hasPerm === true){ //this would be for NIAP and Validators
      return (<AssuranceMaintenanceDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />)
    }
    return (<div></div>)
}

function AssuranceMaintenanceDetails({setOverlayActive, setOverlayText}) {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const user = useSelector((state) => state.user.value);
    const permissions = useSelector((state) => state.role.value);
    const { product_id, id } = useParams();
    const [product, setProduct] = useState({});
    const [maintenance, setMaintenance] = useState({});
    const [refetchNotes, setRefetchNotes] = useState(false);
    const [amProgressPoints, setAmProgressPoints] = useState([]);
    const [alert, setAlert] = useState({});
    const [updateSidebarFiles, setUpdateSidebarFiles] = useState(0);
    const [showACMRCompletedModal, setShowACMRCompletedModal] = useState(false);
    const [showACMRModal, setShowACMRModal] = useState(false)

    useEffect(() => {
        document.title = "NIAP Community - Product Details";
    }, []);

    const loadAssuranceMaintenance = useCallback(() => {
        if (product_id) {
            axios
                .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${id}/`, {
                    withCredentials: true,
                    headers: { Authorization: `Token ${authToken}` },
                })
                .then((response) => {
                    setMaintenance(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [id]);

    useEffect(() => {
        loadAssuranceMaintenance();
    }, [loadAssuranceMaintenance]);

    useInterval(()=>{
        loadAssuranceMaintenance()
    }, 300000) //5 min

    const loadProject = useCallback(() => {
        if (product_id) {
            axios
                .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product_id}/`, {
                    withCredentials: true,
                    headers: { Authorization: `Token ${authToken}` },
                })
                .then((response) => {
                    setProduct(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [id]);

    useEffect(() => {
        loadProject();
    }, [loadProject]);

    useInterval(()=>{
        loadProject()
    }, 300000) //5 min

    const getAmProgressPoints = useCallback(() => {
        if (id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/progress_points_by_maintenance_id/?maintenance_id=${id}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setAmProgressPoints(
                        response.data.sort((a, b) => a.prog_id - b.prog_id)
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [id]);

    useEffect(() => {
        getAmProgressPoints();
    }, [getAmProgressPoints]);

    useInterval(()=>{
        getAmProgressPoints()
    }, 300000) //5 min

    const updateFiles = () => {
        console.log('updateFiles being called')
        setUpdateSidebarFiles(updateSidebarFiles + 1);
    };


    const showAMButtons = (pp) => {
        switch (pp?.status) {
            case "Not Started":
                switch (pp?.progress_point) {
                    case 3:
                        if (
                            amProgressPoints[1]?.status === "Completed" &&
                            permissions?.role_type === "Validator"
                        ) {
                            return (
                                <Button
                                    size="sm"
                                    variant="warning"
                                    className="rounded-pill"
                                    onClick={() => setShowACMRCompletedModal(true)}
                                >
                                    ACMR Completed
                                </Button>
                            );
                        } else {
                            return <></>;
                        }
                    default:
                        return;
                }
            case "Rejected":
                switch (pp?.progress_point) {
                    case 3:
                        if (
                            amProgressPoints[1]?.status === "Completed" &&
                            permissions?.role_type === "Validator"
                        ) {
                            return (
                                <Button
                                    size="sm"
                                    variant="warning"
                                    className="rounded-pill"
                                    onClick={() => setShowACMRCompletedModal(true)}
                                >
                                    Re-Upload ACMR
                                </Button>
                            );
                        } else {
                            return <></>;
                        }
                    default:
                        return;
                }
            case "In Progress":
                switch (pp?.progress_point) {
                    case 3:
                        if (
                            amProgressPoints[1]?.status === "Completed" &&
                            permissions?.role_type === "Validator" &&
                            permissions?.role_permissions?.find((permission) => permission.area === "AM Approval Authority")?.create
                        ) {
                            return (
                                <Button
                                    size="sm"
                                    variant="warning"
                                    className="rounded-pill"
                                    onClick={() => setShowACMRModal(true)}
                                >
                                    Approve/Recommend
                                </Button>
                            );
                        } else {
                            return <></>;
                        }
                    default:
                        return;
                }
            case "Completed":
                switch (pp?.progress_point) {
                    case 1:
                        return (
                            <Stack>
                                <small className="small-font">Requested by</small>
                                <small className="small-font">
                                    {product?.current_assurance_maintenance?.lab_request?.first_name}
                                    {" "}
                                    {product?.current_assurance_maintenance?.lab_request?.last_name}
                                </small>
                                <small className="mb-3 small-font">
                                    on {moment.utc(pp?.maintenance?.lab_request_date).format("MM/DD/YYYY")}
                                </small>
                            </Stack>
                        );
                    case 2:
                        return (
                            <Container>
                                <Row>
                                    <Col>
                                        <Row>
                                            <small className="small-font">
                                                Team Assigned
                                            </small>
                                        </Row>
                                        <Row>
                                            <small className="mb-3 small-font">
                                                on{" "}
                                                {moment(pp?.date).format(
                                                    "MM/DD/YYYY"
                                                )}
                                            </small>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        );
                    case 3:
                    case 4:
                    default:
                        return (
                            <Stack>
                                <small className="small-font">Submitted by</small>
                                <small className="small-font">
                                {pp?.editor 
                                    ? pp?.editor?.first_name + " " + pp?.editor?.last_name
                                    : pp?.creator?.first_name + " " + pp?.creator?.last_name
                                }
                                </small>
                                <small className="mb-3 small-font">
                                    on {moment.utc(pp?.created_on).format("MM/DD/YYYY")}
                                </small>
                            </Stack>
                        );
                }
            default:
                return <></>;
        }
    }

    return (
        <>
            <Container className="py-5" fluid>
                <Row>
                    <Col sm={12} md={8} lg={9}>
                        <Row>
                            {alert.message && (
                                <Alert
                                    variant={alert.variant}
                                    onClose={() => setAlert({})}
                                    dismissible
                                >
                                    {alert.message}
                                </Alert>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            href={`/community/products/details/${product?.product_id}`}>
                            VID {product?.v_id} Details
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{maintenance?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row>
                    <Col sm={12} md={8} lg={9}>
                        <Row>
                            <Col sm={12} className="mt-3">
                                <AMHeader 
                                    product={product} 
                                    maintenance={maintenance} 
                                    refetch={() => {
                                        loadAssuranceMaintenance(); 
                                        getAmProgressPoints();
                                        loadProject();
                                    }} 
                                    progressPoints={amProgressPoints} 
                                    setAlert={setAlert}
                                    setOverlayActive={setOverlayActive} 
                                    setOverlayText={setOverlayText} 
                                />
                            </Col>
                            <Col sm={12}>
                                <h5 className="fw-bold mt-3">MILESTONES</h5>
                            </Col>
                            <AMProgressPoints
                                progressPoints={amProgressPoints}
                                showButtons={showAMButtons}
                                maintenance={maintenance}
                            />
                            <Col sm={12} className="mt-3" id="accordion-section">
                                <AssuranceMaintenanceAccordion
                                    maintenance={maintenance}
                                    refetch={loadAssuranceMaintenance}
                                    user={user}
                                    permissions={permissions}
                                    amProgressPoints={amProgressPoints}
                                />
                                <Notes
                                    item={maintenance}
                                    note_type="assurance_maintenance"
                                    user={user}
                                    id={id}
                                    refetchNotes={refetchNotes}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={4} lg={3}>
                        <AMSideInfo
                            maintenance={maintenance}
                            product={product}
                            updateSidebarFiles={updateSidebarFiles}
                            refetch={loadAssuranceMaintenance}
                            setOverlayActive={setOverlayActive} 
                            setOverlayText={setOverlayText} 
                        />
                    </Col>
                </Row>
                <ACMRCompletedModal
                    show={showACMRCompletedModal}
                    handleClose={() => setShowACMRCompletedModal(false)}
                    project={product}
                    refetch={() => {
                        updateFiles();
                        getAmProgressPoints();
                    }}
                    setAlert={setAlert}
                />
                <ACMRRecommendationModal
                    show={showACMRModal}
                    setShow={setShowACMRModal}
                    product={product}
                    refetch={() => {
                        updateFiles();
                        getAmProgressPoints();
                    }}
                    setRefetchNotes={setRefetchNotes}
                />
            </Container>
        </>
    );
}