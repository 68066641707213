import { useRef, useState } from "react";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "../../UI/DeleteModal";
import SerialNumberModal from "./SerialNumberModal";
import axios from "axios";
import { useCookies } from "react-cookie";
import qs from "qs";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import moment from "moment";

const SerialNumberTable = () => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const tableRef = useRef();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [selectedRow, setSelectedRow] = useState({});

  const handleShowEditModal = (e, rowData) => {
    setSelectedRow(rowData);
    setShowEditModal(true);
  };

  const handleShowDeleteModal = (e, rowData) => {
    setSelectedRow(rowData);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedRow({});
  };

  const handleDeleteSerialNumber = async () => {
    await axios.delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/doc/${selectedRow?.snid}/`,
      {withCredentials: true, headers: {Authorization: `Token ${authToken}`, "X-CSRFToken": csrfToken}})
      .then(response => {
        setAlert({message: 'Successfully Deleted Document Serial Number!', type: 'success'})
        handleCloseDeleteModal();
        refetch();
      })
      .catch(error => setAlert({type: "Error Removing Document Serial Number.", variant: "danger"}));
  };

  const refetch = () => {
    if(tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }

  const columns = [
    { title: "Serial Number", field: "fullsn", defaultSort: "desc" },
    { title: "Document Type", field: "pcode" },
    { title: "VID", field: "pid" },
    { title: "Dated", field: "dtd", render: (rowData) => rowData.dtd && moment.utc(rowData.dtd).format("MM/DD/YYYY")},
    { title: "Generated", field: "entrydate", render: (rowData) => rowData.entrydate && moment.utc(rowData.entrydate).format('MM/DD/YYYY') },
    {
      title: "Actions",
      render: (rowData) => (
        <div>
          <Button
            variant="link"
            className="m-1 p-0 btn-link"
            onClick={(e) => handleShowEditModal(e, rowData)}
          >
            <EditIcon color="action" /> Edit
          </Button>
          <Button
            variant="link"
            className="m-1 p-0 btn-link"
            onClick={(e) => handleShowDeleteModal(e, rowData)}
          >
            <DeleteIcon color="action" /> Delete
          </Button>
        </div>
      ),
    },
  ];

  const options = {
    sorting: true,
    columnsButton: true,
    pageSize: 500,
    pageSizeOptions: [250, 500, 1000]
  };

  return (
    <>
      <SerialNumberModal
        show={showEditModal}
        handleClose={() => {
          setShowEditModal(false)
          refetch();
        }}
        serialNumberToEdit={selectedRow}
        setAlert={setAlert}
      />
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        type={`CCEVS Document Serial Number`}
        deleteFunction={handleDeleteSerialNumber}
      />
      <Container fluid>
        <Row>
          <Col>
            <Row>
            {alert?.message !== "" &&
              <Alert variant={alert.type} onClose={() => setAlert({message: '', type: ''})} dismissible>
                {alert.message}
              </Alert>
            }
              <Col>
                <h3 className="mt-3">CCEVS Document Serial Numbers Manager</h3>
              </Col>
              <Col className="d-flex justify-content-end mb-3">
                <Button variant="primary" onClick={() => setShowEditModal(true)}>
                  + Add
                </Button>
              </Col>
            </Row>
            <Row>
              <ResponsiveMaterialTable
                title="CCEVS Document Serial Number"
                columns={columns}
                tableRef={tableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const { page, pageSize, search, filters, orderBy, orderDirection } = query;

                    let newFilters = filters.map((filter) => {
                      let value = "";
                      if (Array.isArray(filter.value)) {
                        value = filter.value;
                      } else {
                        value = filter.value.replace(/['"]+/g, "");
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                      is_active: "true",
                    };

                    axios
                      .get(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/doc/?${qs.stringify(params, {
                          arrayFormat: "comma",
                        })}`,
                        { withCredentials: true, headers: { Authorization: `Token ${authToken}` } }
                      )
                      .then((response) => {
                        resolve({
                          data: response.data.results,
                          page: page,
                          totalCount: response.data.count,
                        });
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  })
                }
                options={options}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SerialNumberTable;
