import moment from "moment";
import { Card, Col, Row, Button, Popover, OverlayTrigger } from "react-bootstrap";

export default function ProgressPoints({ progressPoints, getEditButton, showButtons, product }) {
  const progressPointTitles = {
    1: "Check-In Package",
    2: "Check-In Meeting",
    3: "ST Evaluation",
    4: "Test Ready",
    5: "Testing Complete",
    6: "Evaluator Checkout",
    7: "Validator Checkout",
    8: "PCL Posting",
  };

  const getPointNumber = (pp) => {
    switch (pp?.progress_point) {
      case 6:
      case 7:
        if (pp?.resubmission_counter === null) {
          return `${pp?.progress_point}`;
        } else {
          return `${pp?.progress_point}${String.fromCharCode(97 + pp?.resubmission_counter)}`;
        }
      default:
        return pp?.progress_point;
    }
  };

  const style = {
    height: "122px",
    width: "122px",
    borderWidth: 3,
    margin: "auto",
  };

  const inReviewStyle = {
    height: "122px",
    width: "122px",
    // borderWidth: 3,
    margin: "auto",
    border: "3px solid #aa66cc",
  };
  const getEstimatedCompletionDate = (pp) => {
    if (pp === 1) {
      return progressPoints[0]?.est_completion_dates[0]?.estimated_date;
    } else if (pp === 2) {
        return progressPoints[1]?.est_completion_dates[0]?.estimated_date;
    } else if (pp === 3) {
      return product?.st_evaluation_date;
    } else if (pp === 4) {
      return product?.test_ready_date;
    } else if (pp === 5) {
      return product?.testing_complete_date;
    } else if (pp === 6) {
      return product?.evaluator_checkout_date;
    } else if (pp === 7) {
      return product?.validator_checkout_date;
    } else if (pp === 8) {
      return product?.pcl_posting_date;
    }
  };

  const showDate = (pp, estCompletionDates) => {
    if (pp?.progress_point === 6 || pp?.progress_point === 7) {
      if (estCompletionDates?.length > 0 && pp?.status !== "Not Started" && pp?.status !== "Est. Completion Date Set") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <Col className="mt-3">
      <Row>
        {progressPoints?.map((pp, idx) => (
          <Col key={idx} className="p-0">
            <Card
              border={
                pp.status === "Completed"
                  ? "success"
                  : pp.status === "In Progress" || pp.status === "Ready for Release"
                  ? "warning"
                  : pp.status === "Rejected" || pp.status === "Amended Package Needed"
                  ? "danger"
                  : pp.status === "Resubmitted" || pp.status === "Awaiting Changes"
                  ? "info"
                  : pp.status === "Awaiting Checkout"
                  ? false
                  : "dark"
              }
              bg="light"
              className="rounded-circle justify-content-center"
              style={pp.status === "Awaiting Checkout" ? inReviewStyle : style}
            >
              <Card.Body className="text-center active-bar">
                <Card.Title className="progress-point-number small-font">
                  {getPointNumber(pp)}
                </Card.Title>
                <Card.Subtitle className="progress-point-heading fw-bold my-0 small-font">
                  {progressPointTitles[pp.progress_point]}
                </Card.Subtitle>
                {getEditButton(pp)}
                <Card.Text className="small-font">
                  {
                    pp.status === "Est. Completion Date Set"
                    ? "Not Started" 
                    : pp.status === "In Progress"
                    ? "Initialized"
                    : pp.status === "Awaiting Checkout"
                    ? "In Review"
                    : pp.status === "Amended Package Needed"
                    ? "Changes Needed"
                    : pp.status}
                </Card.Text>
              </Card.Body>
            </Card>
            {pp?.progress_point === 6 && pp?.status !== "Not Started" && pp?.status !== "Est. Completion Date Set" && pp?.est_completion_dates?.length > 0 && (
              <div>
                <OverlayTrigger
                  trigger={["click", "click"]}
                  rootClose
                  placement="bottom"
                  key="bottom"
                  overlay={
                    <Popover>
                      <Popover.Body>
                        <Row className="p-0">
                          <p className="m-0 p-0 fw-bold">Current Est. Date:</p>
                          <p className="small-font m-0 p-0 text-secondary">
                            {moment.utc(product?.evaluator_checkout_date).format("YYYY.MM.DD")}
                          </p>
                        </Row>
                        {pp?.est_completion_dates?.map((date, idx) => (
                          <Row key={idx}>
                            <Col className="p-0">
                              <p className="mt-1 mb-0 fw-bold">
                                {idx === 0
                                  ? "6. Submission"
                                  : `6${String.fromCharCode(64 + idx).toLowerCase()}. Resubmission`}
                              </p>
                              <p className="small-font m-0 p-0 text-secondary">
                                {moment.utc(date?.estimated_date).format("YYYY.MM.DD")} (est.
                                completion)
                                <br></br>
                                {moment
                                  .utc(pp?.submission_dates[idx]?.estimated_date)
                                  .format("YYYY.MM.DD")}{" "}
                                (act. completion)
                              </p>
                            </Col>
                          </Row>
                        ))}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="link"
                      size="sm"
                      className="text-center p-0 m-0 text-bright-blue small-font"
                    >
                      Submissions
                    </Button>
                  </div>
                </OverlayTrigger>
              </div>
            )}
            {pp?.progress_point === 7 && pp?.status !== "Not Started" && pp?.status !== "Est. Completion Date Set" && pp?.est_completion_dates?.length > 0 && (
              <div>
                <OverlayTrigger
                  trigger={["click", "click"]}
                  rootClose
                  placement="bottom"
                  key="bottom"
                  overlay={
                    <Popover>
                      <Popover.Body>
                        <Row className="p-0">
                          <p className="m-0 p-0 fw-bold">Current Est. Date:</p>
                          <p className="small-font m-0 p-0 text-secondary">
                            {moment.utc(product?.validator_checkout_date).format("YYYY.MM.DD")}
                          </p>
                        </Row>
                        {pp?.submission_dates?.map((date, idx) => (
                          <Row key={idx}>
                            <Col className="p-0">
                              <p className="mt-1 mb-0 fw-bold">
                                {idx === 0
                                  ? "7. Submission"
                                  : `7${String.fromCharCode(64 + idx).toLowerCase()}. Resubmission`}
                              </p>
                              <p className="small-font m-0 p-0 text-secondary">
                                {moment
                                  .utc(pp?.est_completion_dates[idx]?.estimated_date)
                                  .format("YYYY.MM.DD")}{" "}
                                (est. completion)
                                <br></br>
                                {moment.utc(date?.estimated_date).format("YYYY.MM.DD")} (act.
                                completion)
                              </p>
                            </Col>
                          </Row>
                        ))}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="link"
                      size="sm"
                      className="text-center p-0 m-0 text-bright-blue small-font"
                    >
                      Submissions
                    </Button>
                  </div>
                </OverlayTrigger>
              </div>
            )}
            {showDate(pp, pp?.est_completion_dates) && (
              <Row key={idx}>
                <Col className="p-0 d-flex justify-content-center">
                  <p className="small-font m-0 p-0 text-secondary">
                    {getEstimatedCompletionDate(pp.progress_point)
                      ? `${moment
                          .utc(getEstimatedCompletionDate(pp.progress_point))
                          .format("YYYY.MM.DD")}`
                      : "No Date Set"}{" "}
                    (est.)
                    <br></br>
                    {pp?.submission_dates?.length > 0
                      ? `${moment
                          .utc(pp?.submission_dates[0].estimated_date)
                          .format("YYYY.MM.DD")}`
                      : "No Date Set"}{" "}
                    (act.)
                  </p>
                </Col>
              </Row>
              /* <div>
                <OverlayTrigger
                  trigger={["click", "click"]}
                  rootClose
                  placement="bottom"
                  key="bottom"
                  overlay={
                    <Popover>
                      <Popover.Body>
                        <Row key={idx}>
                          <Col className="p-0">
                            <p className="small-font m-0 p-0 text-secondary">
                              {getEstimatedCompletionDate(pp.progress_point)
                                ? `${moment
                                    .utc(getEstimatedCompletionDate(pp.progress_point))
                                    .format("YYYY.MM.DD")}`
                                : "No Date Set"}{" "}
                              (est. completion)
                              <br></br>
                              {pp?.submission_dates?.length > 0
                                ? `${moment
                                    .utc(pp?.submission_dates[0].estimated_date)
                                    .format("YYYY.MM.DD")}`
                                : "No Date Set"}{" "}
                              (act. completion)
                            </p>
                          </Col>
                        </Row>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="link"
                      size="sm"
                      className="text-center p-0 m-0 text-bright-blue small-font"
                    >
                      Dates
                    </Button>
                  </div>
                </OverlayTrigger>
              </div> */
            )}
            <Row className="mt-2 text-center justify-content-center" xs="auto">
              {pp.progress_point !== 1 && showButtons(pp)}
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  );
}
