import ProductsTable from "./ProductsTable";
import ProductForm from './ProductForm'
import Filter from "./ProductTableFilter";
import { Button, Row, Col } from 'react-bootstrap'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CCTLAssociatesVendorModal from '../../Dashboard/ProductsModule/CCTLAssociatesVendorModal'
import { useInterval } from "../../../hooks/useInterval";
import ManageEvalTeamModal from "../../Dashboard/ProductsModule/ManageEvalTeamModal";

const INITIAL_FILTERS = {
  status:
    "Proposed,Pre-Evaluation,In Progress,Finalizing,In Assurance Maintenance",
};

export default function ProductsTabs() {
    const [cookies] = useCookies()
    let authToken = cookies['auth_token']
    const [refreshKey, setRefreshKey] = useState(0)
    const [currentProduct, setCurrentProduct] = useState({});
    const [show, setShowModal] = useState(false);
    const permissions = useSelector(state => state.role.value)
    const [projpps, setProjpps] = useState([]);
    const [isDraft, setIsDraft] = useState(false)
    const [reload, setReload] = useState(false)
    const [showVendorModal, setShowVendorModal] = useState(false) //Close the Associates Modal
    const [isSwitch, setIsSwitch] = useState(false)
    const [filterOpen, setFilterOpen] = useState(true)
    const [filters, setFilters] = useState(INITIAL_FILTERS);
    const [showManageEvalTeamModal, setShowManageEvalTeamModal] = useState(false)

    useEffect(() => {
      if (window.innerWidth < 768) {
        setFilterOpen(false);
      }
    }, []);

    const changeFilter = () => {
      setFilterOpen(!filterOpen)
    }
    
    const handleShowModal = (e, rowData, review=false) => {
      setCurrentProduct( {...rowData} );
      setShowModal(true);
    }
  
    const handleCloseModal = (e) => {
      setShowModal(false);
      setCurrentProduct({});
      setRefreshKey(refreshKey + 1);
    }

    const handleShowVendorModal = () => {
      setShowVendorModal(true)
    }

    const handleCloseVendorModal = () => {
      setShowVendorModal(false)
    }
    const switchToVendor = () => {
      setIsSwitch(true)
      handleCloseSwitch()
      handleShowVendorModal()
    }
  
    const handleOpenEvalTeamModal = () => {
      setShowManageEvalTeamModal(true)
    }
    const switchToEvalTeam = () => {
      setIsSwitch(true)
      handleCloseSwitch()
      handleOpenEvalTeamModal()
    }


    const handleOpenDraft = () => {
      setIsDraft(true)
      setShowModal(true)
    }

    const handleCloseSwitch = () => {
      setShowModal(false);
      setRefreshKey(refreshKey + 1);
    };

    // const switchToVendor = (product) => {
    //   setCurrentProduct(product)
    //   setIsSwitch(true)
    //   handleCloseSwitch()
    //   handleShowVendorModal()
    // }
    const switchForms = (product, type) => {
      setCurrentProduct(product)
      if(type === "vendor"){
        switchToVendor()
      } else if (type === "eval") {
        switchToEvalTeam()
      }
    }

    const switchToProduct = () => {
      setIsSwitch(false)
      handleCloseVendorModal()
      handleOpenDraft()
    }

    const loadGetGroupPP = () =>{
      axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_grouped_pp`, {
        headers: {
          'Authorization': `Token ${authToken}`
        }
      })
      .then(response => {
        setProjpps(response.data);
      }).catch(error => {
        console.log(error)
      })
    }

    useEffect(() => {
      loadGetGroupPP()
    }, [])

    useInterval(()=>{
      loadGetGroupPP()
    }, 300000) //5 min

    useEffect(() => {
      document.title = 'NIAP Community - Products Table';
    }, []);

    return (
      permissions?.role_permissions?.find(permission => permission.area === 'Project')?.read &&
      <>
        <Row className="mt-3 justify-content-end">
          <Col xs={12} md={2}>
            { permissions?.role_permissions?.find(permission => permission.area === 'Project')?.create && permissions?.role_type === "Lab" &&
              <Button variant='primary' className='me-3 rounded-0 w-100 mb-xs-3' onClick={(e) => handleShowModal(e, {})}>+ Add New Product</Button>
            }
          </Col>
          <Col sm={12} md={2} className="d-flex justify-content-end align-content-center mb-2" style={{minWidth: 250}}>
              <Button variant="link rounded-pill" style={{backgroundColor: "#dedede", maxHeight: 40}} onClick={changeFilter}>
                {filterOpen && <>
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                  &nbsp;
                  Close Filter
                  &nbsp;
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                </>}
                {!filterOpen && <>
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                  &nbsp;
                  Open Filter
                  &nbsp;
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                </>}
              </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
                <ProductsTable
                  sidebarFilters={filters}
                  projpps={projpps}
                  refreshKey={refreshKey}
                />
          </Col>
          {filterOpen &&
            <Col xs={10} md={3}>
                <Filter
                  filters={filters}
                  setFilters={setFilters}
                  initialFilters={INITIAL_FILTERS}
                />
            </Col>
          }
        </Row>
        <ProductForm 
          show={show} 
          handleCloseModal={handleCloseModal} 
          product={currentProduct} 
          switchForms={switchForms} 
          draft={isDraft} 
          reload={reload}
        />
        <CCTLAssociatesVendorModal 
          show={showVendorModal} 
          handleCloseModal={handleCloseVendorModal} 
          isSwitch={isSwitch} 
          switchForms={switchToProduct}
          setReloadVendors={setReload}
        />
        <ManageEvalTeamModal
        show={showManageEvalTeamModal}
        setShow={setShowManageEvalTeamModal}
        currentProductInEval={currentProduct}
        switchForms={switchToProduct}
        isSwitch={isSwitch} 
        setReloadFormData={setReload}
      />
      </>
    );
}
