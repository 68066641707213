
  export const answersList = [

    {   id: 1, name: 'NIAP does not approve products', 
        description: `<p>Hello, </p> 

        <p>Thank you for your inquiry.</p> 
        
        <p>NIAP does not approve products. NIAP certifies products that have a baseline level of security and are eligible for procurement for use in National Security Systems (NSS). NIAP only validates the evaluation of COTS cybersecurity and cybersecurity-enabled products that are submitted to us by the vendor as defined below:  </p>
        
         
        
        <p>Cybersecurity Product: A product whose primary purpose is to provide security services (e.g., confidentiality, authentication, integrity, access control, non-repudiation of data); correct known vulnerabilities; and/or provide layered defense against various categories of non-authorized or malicious penetrations of information systems or networks. </p>
        
        <p>Cybersecurity-Enabled Product: A product whose primary role is not security, but provides security services as an associated feature of its intended operating capabilities. </p>
        
        <p>NIAP does not certify peripheral devices such as wireless remote controls, mice, keyboards, etc, (leave blank for product name) as these devices do not meet the definition of cybersecurity or cybersecurity-enabled commercial IT products.  </p>
        
        <p>Thank you, 
        <br />NIAP Staff </p>`
    },
    { 
        id: 2, name: 'NIAP does not sell products', 
        description: `<p>Hello, </p>

        <p>Thank you for your inquiry. </p>
        
        <p>NIAP does not sell products listed on the NIAP Product Compliant List. We only evaluate and certify products meet a baseline level of security and are eligible for procurement for use in National Security Systems (NSS).  Please contact the vendor or the distributor for pricing. They will be able to assist you for procurement purposes. </p>
        
        <p>Thank you, 
        <br />NIAP Staff </p>`
    },
    { 
        id: 3, name: `Up to the organization's Authorizing Official (AO)`,
        description: `<p>Hello, </p>

        <p>Thank you for your inquiry. </p>
        
        <p>NIAP certifies products meet a baseline level of security and are eligible for procurement for use in National Security Systems (NSS). NIAP cannot provide guidance in relation to the KVM devices (leave blank for product name). NIAP certifications are agnostic to the classification-level of the intended NSS use-case. NIAP is not an Approving Authority for devices to be used on your network. Ultimately, it is up to the organization's Authorizing Official (AO) to conduct a risk assessment and determine whether any particular product should be used in their environment.  </p>
        
        <p>Thank you, 
        <br />NIAP Staff</p>` 
    },
    {   id: 4, name: 'Product not evaluated, encourage vendor to submit', 
        description: `<p>Hello, </p>

        <p>Thank you for your inquiry. </p>
        
        <p>NIAP does not approve products. NIAP certifies products meet a baseline level of security by meeting the requirements in Protection Profiles (PP). Products on our Product Compliant List (PCL) are eligible for procurement for use in National Security Systems (NSS). NIAP only validates the evaluation of COTS cybersecurity and cybersecurity-enabled products that are submitted to us by the vendor.  </p>
        
        <p>The CISCO C1100TG-1N32A Terminal Services Gateway (leave blank for product name) has not been certified by NIAP because it has not been submitted for evaluation. If you wish to have this product certified by NIAP, please contact the vendor and encourage them to submit the product for evaluation.  </p>
        
        <p>Thank you, 
        <br />NIAP Staff </p>` 
    },
    { 
        id: 5, name: 'NIAP no longer accepts EAL-based evaluations', 
        description: `<p>Hello, </p>

        <p>Thank you for your inquiry. </p>
        
        <p>NIAP no longer accepts EAL-based evaluations and has transitioned to evaluations with exact compliance to technology-specific Protection Profiles (PP) in order to provide achievable, repeatable, testable evaluation results. Products being evaluated against a NIAP-approved PP must be in exact compliance with the PP. </p>
        
        <p>Products evaluated in other CCRA member nations demonstrating exact compliance with a NIAP-approved PP or collaborative Protection Profile (cPP) can be listed on the PCL. To request that NIAP post a product on the PCL that was evaluated in another CCRA Scheme against a NIAP-approved PP or cPP, the vendor/evaluating scheme must already have completed the evaluation and be listed on the CC Portal. The Security Target must claim exact conformance to one or more of the applicable NIAP-approved PPs/Extended Packages (EPs) or a cPP. Additional required documents are the Administrative Guidance (to configure the product to its evaluated configuration) and the Assurance Activity Report (AAR), as stated in NIAP Policy #1 and #24. The request for posting a product to the PCL must be submitted to NIAP here. </p>
        
        <p>Thank you, 
        <br />NIAP Staff </p>` 
    },
    { 
        id: 6, name: 'Archived Products',
        description: `<p>Hello, </p>

        <p>Thank you for your inquiry. </p>
        
        <p>Products are eligible for procurement for use in National Security Systems (NSS) as long as they are on the Product Compliant List (PCL). Products evaluated and certified by NIAP remain on the PCL for two years, after which they are archived. Per CNSSP #11, archived products are still acceptable for use in NSS if already deployed in an organization's IT infrastructure, but are not eligible for new procurements. It is up to the organization's Authorizing Official (AO) to determine whether any particular product can continue to be used in their environment based on risk analysis and system accreditation requirements. </p>
        
        <p>Thank you, 
        <br />NIAP Staff </p>` 
    },
    { id: 7, name: 'Assurance Maintenance', 
        description: `<p>Hello, </p>

        <p>Thank you for your inquiry. </p>
        
        <p>Common Criteria certifications are valid for a maximum of 5 years. However, a NIAP evaluated product is typically good for two years from the certification date and will be archived from the Product Compliant List (PCL) on their Assurance Maintenance date. Assurance Maintenance dates may be used to extend the date of a NIAP evaluated product.  </p>
        
        <p>You can view more details about our Assurance Maintenance Process in NIAP's Publication #6 here: https://www.niap-ccevs.org/Documents_and_Guidance/ccevs/scheme-pub-6.pdf. </p>
        
        <p>Thank you, 
        <br />NIAP Staff </p>` 
    },
    { 
        id: 8, name: 'NIAP certifies products…', 
        description: `<p>Hello, </p>

        <p>Thank you for your inquiry. </p>
        
        <p>NIAP certifies products meet a baseline level of security by meeting the requirements in Protection Profiles (PP). Products on our Product Compliant List (PCL) are eligible for procurement for use in National Security Systems (NSS). NIAP certifications are agnostic to the classification-level of the intended NSS use-case. NIAP only validates the evaluation of COTS cybersecurity and cybersecurity-enabled products that are submitted to us by the vendor as defined below:  </p>
        
        <p>Cybersecurity Product: A product whose primary purpose is to provide security services (e.g., confidentiality, authentication, integrity, access control, non-repudiation of data); correct known vulnerabilities; and/or provide layered defense against various categories of non-authorized or malicious penetrations of information systems or networks. </p>
        
        <p>Cybersecurity-Enabled Product: A product whose primary role is not security, but provides security services as an associated feature of its intended operating capabilities. </p>
        
        <p>All products certified by NIAP can be found on our Product Compliant List (PCL) here.  </p>
        
        <p>For more information, please visit our website here and you can view our FAQs here.  </p>
        
        <p>If there is a specific Technical Community (TC) you wish to participate in, please visit the TC webpage here. </p>
        
        <p>You may also wish to check with the DISA Unified Capabilities Certification Office, as it provides approval for use of products on DoD networks which can be found here. </p>
        
        <p>Thank you, 
        <br />NIAP Staff </p>` 
    }
  ];

  const QAAnswersList = () => {
    return null;
  }

export default QAAnswersList;