import Container from 'react-bootstrap/Container'
import { Route, Routes } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import LoginForm from './LoginForm';
import MFAselect from './LoginMFASelect';
import MFAConfirm from './LoginMFAConfirm';
import MFADisplayQR from './LoginMFADisplayQR';
import LoginSuccess from './LoginSuccess';
import './Login.css'
import { useSelector } from 'react-redux';
import ForgotPassword from './ForgotPassword';
import CheckEmail from './CheckEmail';
import ResetPassword from './ResetPassword';
import ResetSuccess from './ResetSuccess';
import ResendCode from './LoginResendCode';
import AddDetails from './AddMethodDetails';
import axios from "axios";

const Login = () => {
    const [cookies, setCookies] = useCookies()
    const authToken = cookies['auth_token']
    const userRole = useSelector(state => state.role.value);

    useEffect(() => {
        if (userRole.role_type && authToken) {
            if(userRole.role_type !== 'External') window.location.pathname = '/community/';
            else window.location.pathname = '/';
        }
    }, [])

    return (
        <div className="login-page">
            {!authToken && <Container>
                <Routes>
                    <Route path="" element={<LoginForm />} />
                    <Route path="mfa-select" element={<MFAselect />} />
                    <Route path="resend-code" element={<ResendCode />} />
                    <Route path="mfa-confirm" element={<MFAConfirm />} />
                    <Route path="mfa-qr" element={<MFADisplayQR />} />
                    <Route path="success" element={<LoginSuccess />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="check-email" element={<CheckEmail />} />
                    <Route path="add-details" element={<AddDetails />} />
                    <Route path="reset-password/:token?" element={<ResetPassword />} />
                    <Route path="reset-success" element={<ResetSuccess />} />
                </Routes>
            </Container>}
        </div> 
    )
}

export default Login