import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeRole } from "../../app/role";
import { removeUser } from "../../app/user";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  
const AppLogout = ({ children }) => {
    const location = useLocation()
    const dispatch = useDispatch()

    const [cookies, setCookies, removeCookie] = useCookies()
    let timer;
  
  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = (time) => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, time); // 10000ms = 10secs. You can change the time.
  };
  
  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const restartTimer = () => {
    if (location.pathname.includes('/login')) {
        resetTimer();
        handleLogoutTimer(300000);
    } else {
        resetTimer();
        handleLogoutTimer(28800000);
    }
  }
  
  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
        window.addEventListener(item, restartTimer);
    })
  }, []);
  
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    if (location.pathname.includes('/login')) {
        removeCookie('temp_token')
        window.location.pathname = "/login"
    } else {
        removeCookie('auth_token')
        dispatch(removeRole());
        dispatch(removeUser());
        window.location.pathname = "/logout";
    }
  };
  
    return children;
  };
  
  export default AppLogout;