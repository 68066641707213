import { Modal } from 'react-bootstrap';
import ArchivedProductsLogo from "../../../../images/products/ArchivedProducts.png";
import TheRelaOfNiap from "../../../Publications/PublicationPDFs/CCEVSGuidance/TheRelaOfNIAP.pdf"

export default function ArchivedProductsModal({showModal, handleShowModal}) {
  return (
    <Modal show={showModal} onHide={handleShowModal} size='lg'>
        <Modal.Header className='border-0' closeButton></Modal.Header>
        <Modal.Body className='px-5'>
          <div className="text-center"><img src={ArchivedProductsLogo} height='150px' alt='Archived Products Logo' /></div>
          <h2 className="text-uppercase fw-bold text-primary text-center pb-3">Archived Products</h2>
          <p>The Archived Validated Products List is a repository of products validated under CCEVS which have 
          expired Assurance Maintenance Dates as detailed in Publication #6. Per CNSSP #11, products listed 
          below are no longer procurable for use on National Security Systems, but may continue to be used if 
          already employed within your organization's IT infrastructure. For further information, see <a href={TheRelaOfNiap}>Relationship of NIAP Archived Products to CNSSP-11</a>.</p>
          <p>These products must be considered in the context of the environment of use,
          including appropriate risk analysis and system accreditation requirements. 
          Customers must ensure that the products selected will provide the necessary security functionality for their architecture.</p>
        </Modal.Body>
    </Modal>
  );
}
