import { useEffect, useState } from 'react'
import AnnouncementModal from "../EventsAndNewsModal"
import Carousel from 'react-multi-carousel';
import Button from 'react-bootstrap/Button'
import 'react-multi-carousel/lib/styles.css';
import './EventsCarousel.css'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import axios from 'axios';
import parse from 'html-react-parser'
import { useInterval } from '../../../hooks/useInterval'
import qs from "qs"
import moment from 'moment'

export default function EventsCarousel({ responsiveCarousel, setOverlayActive, setOverlayText }) {

  const [events, setEvents] = useState([]);
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState()
  const [showModal, setShowModal] = useState(false)
  const [currentEvent, setCurrentEvent] = useState({})

  useEffect(() => {
    Object.keys(responsiveCarousel).forEach(item => {
      const {breakpoint, items} = responsiveCarousel[item]
      const {min, max} = breakpoint

      const widths = [window.innerWidth]

      if (window.screen && window.screen.width) {
        widths.push(window.screen.width)
      }

      const screenWidth = Math.min(...widths)

      if (screenWidth >= min && screenWidth <= max) {
        setLimit(items + 1)
      }
    })
  }, [responsiveCarousel])

  const loadEvents = () =>{
    const params = {
      limit: limit,
      offset: offset,
      current: true
    }
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_events_frontend/?${qs.stringify(params, {arrayFormat: "comma"})}`)
      .then(res => {
        setCount(res.data.count)
        setEvents(res.data.results);
      }).catch(error => {
        console.log(error)
    })
  }

  useEffect(() => {
    if (limit) {
      loadEvents()
    }
  }, [offset, limit])

  useInterval(()=>{
    if (limit) {
      loadEvents()
    }
  }, 300000) //5 min

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div style={{position: "absolute", width: "100%", padding: "20px"}}>
        <Button className={(currentSlide === 0) ? "visually-hidden" : ""} onClick={() => previous()} style={{float: "left", backgroundColor: "rgba(0,0,0,.5)", minWidth: "50px", height: "50px", borderRadius: "50%"}} aria-label='Back Arrow'><ArrowBackIosIcon /></Button>
        <Button className={(currentSlide === count - 4) ? "visually-hidden" : ""} onClick={() => {if (limit < count) setLimit(limit + 1); next()}} style={{float: "right", backgroundColor: "rgba(0,0,0,.5)", minWidth: "50px", height: "50px", borderRadius: "50%"}} aria-label='Forward Arrow'><ArrowForwardIosIcon /></Button>
      </div>
    );
  }

  return (
    <>
    <AnnouncementModal
      currentAnnouncement={currentEvent}
      show={showModal}
      handleClose={() => setShowModal(false)}
      setOverlayActive={setOverlayActive} 
      setOverlayText={setOverlayText} 
    />
    <Carousel 
      responsive={responsiveCarousel}
      arrows={false}
      customButtonGroup={<ButtonGroup />}
    >
      {events.map(event => (
        <div className='events p-4 bg-primary text-white' key={event.id}>
          <p className='news-title p-1 bg-white text-primary'>
            {moment.utc(event?.begins).format('MMM D, YYYY')} - 
            {" "}{moment.utc(event?.expires).format('MMM D, YYYY')}
          </p>
          <h6 className='fw-bold'>{event.title}</h6>
          <p className="overflow-ellipsis">{event?.announcement && parse(event.announcement)}</p>
          <div className="d-flex justify-content-end">
            <Button 
              className="text-decoration-underline text-white" 
              variant="link"
              onClick={() => {
                setCurrentEvent(event);
                setShowModal(true);
              }}
            >
              View Details
            </Button>
          </div>
        </div>
      ))}
    </Carousel>
    </>
  )
}
