import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import HTMLEditor from "../../UI/HTMLEditor";
import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { handleAlertAndMailer } from "../../Products/Helper/functions"
import {getCurrentDate} from "../../../utils/timeUtils"

export default function EscalateToTDModal({
    show,
    handleClose,
    technicalQuery,
    editTeamsView,
    setAlertInfo,
    updateTechnicalHistory
}) {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const user = useSelector((state) => state.user.value);
    const [primaryTRRTOptions, setPrimaryTRRTOptions] = useState([]);
    const [primaryTeamUpdate, setPrimaryTeamUpdate] = useState({});
    const [tqTrrtTeams, setTqTrrtTeams] = useState([]);
    const [note, setNote] = useState("");
    const [areYouSureMessage, setAreYouSureMessage] = useState(false);
    const [enforceFocusModal, setEnforceFocusModal] = useState(true)

    const loadVirtualTeam = ()=>{
        axios
            .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/get_all_virtual_teams/`)
            .then((response) => {
                const filteredTeams = response.data.filter(
                    (trrt) => trrt.active === true && trrt.team_type === "TRRT Team"
                );
                const options = filteredTeams.map((team) => ({
                    value: team.vtid,
                    label: team.team,
                }));
                setPrimaryTRRTOptions(options);
            })
            .catch((error) => {
                console.log("Error getting TRRT Teams: ", error);
            });
    }

    useEffect(() => {
        setAreYouSureMessage(false);
        loadVirtualTeam()
    }, []);

    useEffect(() => {
        if (technicalQuery?.trrt_team) {
            const updatedTeams = technicalQuery.trrt_team.map((team, idx) => ({
                teamIdx: idx,
                [`trrt_team_${idx}`]: team?.vtid,
            }));
            setTqTrrtTeams(updatedTeams);
        }
    }, [technicalQuery]);

    const handleAddTrrt = () => {
        const updatedTeams = tqTrrtTeams.concat({
            teamIdx: tqTrrtTeams.length,
            [`trrt_team_${tqTrrtTeams.length}`]: "",
        });
        setTqTrrtTeams(updatedTeams);
    };

    const handleChange = (selectedValue, teamIdx) => {
        const updatedTeams = tqTrrtTeams.map((team) => {
            if (team.teamIdx === teamIdx) {
                return { ...team, [`trrt_team_${teamIdx}`]: selectedValue };
            }
            return team;
        });
        setTqTrrtTeams(updatedTeams);
    };

    const handleRemoveTrrt = (teamIdx) => {
        const updatedTeams = tqTrrtTeams.filter(
            (team) => team.teamIdx !== teamIdx
        );
        setTqTrrtTeams(updatedTeams);
    };

    const handleAddNote = () => {
        axios
            .post(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
                {
                    note: note,
                    note_type: "technical_query",
                    note_type_id: technicalQuery?.tq_id,
                    submitted_on: getCurrentDate(),
                    submitted_by: user.id,
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => console.log(response))
            .catch((error) => alert("Unable to add note: " + error));
    };

    const handleSendTRRTEmail = (td) => {
        handleAlertAndMailer(
            csrfToken, 
            authToken, 
            {
                alert_type_id: td?.td_id,
                alert_type: "Technical Decision",
                subject: `New Technical Decision ${td?.td_id} Awaiting Review.`,
                recipients: { to: ["TRRT Team"], cc: [user.email] },
                alert_text: `New Technical Decision Awaiting Review.`,
            }
        )
    };

    const handleSubmit = () => {
        const trrtTeamIds = tqTrrtTeams.map((team) => {
            if (typeof team[`trrt_team_${team.teamIdx}`] === "object") {
                return team[`trrt_team_${team.teamIdx}`].value;
            } else {
                return team[`trrt_team_${team.teamIdx}`];
            }
        });

        var team;
        if (Object.keys(primaryTeamUpdate).length > 0) {
            team = primaryTeamUpdate?.value;
        } else {
            team = technicalQuery?.team?.vtid;
        }

        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
                {
                    team: team,
                    trrt_team: trrtTeamIds,
                    status: "TD Required",
                    recaptcha_token: "member_portal",
                    modified_on: getCurrentDate()
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                        "X-CSRFToken": csrfToken,
                    },
                }
            )
            .then((response) => {
                axios
                    .post(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/`,
                        {
                            tq_id: [technicalQuery?.tq_id],
                            title: technicalQuery?.title,
                            description: technicalQuery?.question,
                            status: "Pending",
                            publication_date: getCurrentDate(),
                            created_by: user.id,
                            created_on: getCurrentDate(),
                        },
                        {
                            withCredentials: true,
                            headers: {
                                "X-CSRFToken": csrfToken,
                                Authorization: `Token ${authToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        updateTechnicalHistory({reason_for_update: "Escalated to TD"})
                        setAlertInfo({message:"Successfully Escalated Technical Query", variant:"success"})
                        handleSendTRRTEmail(response?.data)
                        handleClose();
                        if (note) {
                            handleAddNote();
                        }
                    })
                    .catch((error) =>
                        setAlertInfo({message:"Error Escalating Technical Query. Please try again.", variant:"danger"})
                    );
            })
            .catch((error) => {
                setAlertInfo({message:"Error Updating Technical Query. Please try again.", variant:"danger"})
            });
    };

    const handleTeamUpdate = () => {
        const trrtTeamIds = tqTrrtTeams.map((team) => {
            if (typeof team[`trrt_team_${team.teamIdx}`] === "object") {
                return team[`trrt_team_${team.teamIdx}`].value;
            } else {
                return team[`trrt_team_${team.teamIdx}`];
            }
        });

        var team;
        if (Object.keys(primaryTeamUpdate).length > 0) {
            team = primaryTeamUpdate?.value;
        } else {
            team = technicalQuery?.team?.vtid;
        }

        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
                {
                    team: team,
                    trrt_team: trrtTeamIds,
                    recaptcha_token: "member_portal",
                    modified_on: getCurrentDate()
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                        "X-CSRFToken": csrfToken,
                    },
                }
            )
            .then((response) => {
                handleAlertAndMailer(
                    csrfToken, 
                    authToken, 
                    {
                        alert_type_id: response.data?.tq_id,
                        alert_type: "Technical Query",
                        subject: `Technical Query ${response.data?.title} - Assigned Team Change`,
                        recipients: { to: ["TRRT Team"], cc: [] },
                        alert_text: `Technical Query ${response.data?.title} - Assigned Team Change`,
                    }
                )
                updateTechnicalHistory({reason_for_update: "Updated Team Assignment"})
                setAlertInfo({message:"Succesfully Saved Team Information", variant:"success"})
                handleClose();
            })
            .catch((error) => {
                setAlertInfo({message:"Error saving team. Please try again.", variant:"danger"})
            });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="escalate-td-modal"
            size="lg"
            enforceFocus={enforceFocusModal}
        >
            <Modal.Header closeButton className="border-0">
                <h3 className="text-center text-primary">
                    {editTeamsView
                        ? "EDIT TEAMS"
                        : "ACCEPT/ESCALATE"} FOR TQ: {technicalQuery?.tq_id}
                </h3>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            {!editTeamsView && (
                                <>
                                    <Row>
                                        <Col>
                                            <h5 className="text-primary fw-bold">
                                                Question
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mx-4">
                                            {technicalQuery?.question &&
                                                parse(technicalQuery?.question)}
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Row className="mt-4">
                                <Col>
                                    <h5 className="text-primary fw-bold">
                                        Assigned TRRT Team
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="react-select-6-input"
                                    >
                                        <Select
                                            defaultValue={primaryTRRTOptions.find(
                                                (team) =>
                                                    team.value ===
                                                    technicalQuery?.team?.vtid
                                            )}
                                            options={primaryTRRTOptions}
                                            onChange={(e) =>
                                                setPrimaryTeamUpdate({
                                                    name: "team",
                                                    value: e.value,
                                                })
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <h5 className="text-primary fw-bold">
                                        Assigned Extended TRRT Teams
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="react-select-6-input"
                                    >
                                        {tqTrrtTeams &&
                                            tqTrrtTeams.map((extendedTeam) => (
                                                <Row key={extendedTeam.teamIdx}>
                                                    <Col>
                                                        <Select
                                                            defaultValue={primaryTRRTOptions.find(
                                                                (team) =>
                                                                    team.value ===
                                                                    extendedTeam[
                                                                        `trrt_team_${extendedTeam.teamIdx}`
                                                                    ]
                                                            )}
                                                            className="mb-2"
                                                            options={
                                                                primaryTRRTOptions
                                                            }
                                                            onChange={(
                                                                selectedValue
                                                            ) =>
                                                                handleChange(
                                                                    selectedValue,
                                                                    extendedTeam.teamIdx
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        xs={2}
                                                        className="mt-2"
                                                    >
                                                        <Button
                                                            variant="outline-primary"
                                                            className="attachment-remove"
                                                            onClick={() => {
                                                                handleRemoveTrrt(
                                                                    extendedTeam.teamIdx
                                                                );
                                                            }}
                                                        >
                                                            X
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="w-auto mb-4">
                                    <Button
                                        variant="outline-primary"
                                        onClick={handleAddTrrt}
                                    >
                                        <ControlPointIcon className="me-1" />
                                        ADD ADDITIONAL REVIEWERS
                                    </Button>
                                </Col>
                            </Row>
                            {!editTeamsView && (
                                <>
                                    <Row className="mt-4">
                                        <Col>
                                            <h5 className="text-primary fw-bold">
                                                Additional Information:
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="px-3 pe-3">
                                            <HTMLEditor
                                                name="note"
                                                handleChange={(e) =>
                                                    setNote(e.target.value)
                                                }
                                                setIsValid={() => {}}
                                                isValid={true}
                                                setEnforceFocusModal={setEnforceFocusModal}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            {editTeamsView ? (
                <Modal.Footer className="border-0">
                    <Button variant="outline-primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handleTeamUpdate()}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            ) : (
                <Modal.Footer className="border-0">
                    {areYouSureMessage ? (
                        <>
                            <p className="fw-bold">
                                Are You Sure You Want to Escalate to TRRT Team?
                            </p>
                            <Button
                                variant="outline-danger"
                                onClick={handleClose}
                            >
                                No, Cancel
                            </Button>
                            <Button variant="success" onClick={handleSubmit}>
                                Yes, Submit
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="outline-primary"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => setAreYouSureMessage(true)}
                            >
                                Accept/Escalate
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
}
