import MaterialTable from "material-table";
import TablePagination from '@mui/material/TablePagination'
import Input from '@mui/material/Input'
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from 'react';
import axios from "axios";
import moment from "moment";
import Button from 'react-bootstrap/Button'
import { useCookies } from "react-cookie";
import FileDownload from 'js-file-download';
import FileUpload from "../Products/FileUpload";
import {Row, Container, Col, OverlayTrigger, Popover} from 'react-bootstrap'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RemoveIcon from '@mui/icons-material/RemoveCircle'
import AddIcon from "@mui/icons-material/AddCircle"
import CheckIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import SwapIcon from '@mui/icons-material/SwapHorizontalCircle'
import ReplaceFileModal from "./ReplaceFileModal";
import DeleteModal from "../UI/DeleteModal";
import { useInterval } from "../../hooks/useInterval"
import { useSelector } from "react-redux";
import parse from "html-react-parser"
import qs from 'qs'
import RenameObject from "../DocumentManager/RenameObject";
import RelabelObject from "../DocumentManager/RelabelObject";
import UnauthorizedView from "../UI/UnauthorizedView";

const FileTableCheckPermissions = ({setOverlayActive, setOverlayText}) => {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    const { id, type } = useParams()
    const [ product, setProduct ] = useState({})
    const permissions = useSelector(state => state.role.value)

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${id}/`, {
    //         withCredentials: true,
    //         headers: {
    //             Authorization: `Token ${authToken}`,
    //         },
    //     }).then(response => {
    //         setProduct(response.data)
    //     }).catch(error => {});
    // }, [])

    if (type === "protection-profile" && permissions.role_type !== "NIAP") {
        return <UnauthorizedView />
    }
    // else if (type === "product_files" && product.status === "Certified" && permissions.role_type === "Lab") {
    //     return <UnauthorizedView />
    // }
    else {
        return <FileTable setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
    }
}

const FileTable = ({setOverlayActive, setOverlayText}) => {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    const { id, type, ccid } = useParams()
    const [ product, setProduct ] = useState({})
    const [ filtering, setFiltering ] = useState(false)
    const [ show, setShow ] = useState(false)
    const [ showReplaceFileModal, setShowReplaceFileModal ] = useState(false)
    const [ currFile, setCurrFile] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [updatedType, setUpdatedType] = useState('')
    const [updatedId, setUpdatedId] = useState('')
    const csrfToken = cookies["csrftoken"];
    const [currentObject, setCurrentObject] = useState({})
    const [openRenameModal, setOpenRenameModal] = useState(false)
    const [openRelabelModal, setOpenRelabelModal] = useState(false)
    const [endpoint, setEndpoint] = useState("get_files_by_type_and_type_id/")
    const permissions = useSelector(state => state.role.value)

    const tableRef = useRef()

    const reloadDocuments = () => {
        if (tableRef.current) {
            tableRef.current.onQueryChange()
        }
    }

    const fetchProduct = useCallback(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${id}/`, {
            withCredentials: true,
            headers: {
                Authorization: `Token ${authToken}`,
            },
        }).then(response => {
            setProduct(response.data)
        }).catch(error => {});
    }, [])

    const updateFiles = () => {
        setShow(false)
        if (tableRef.current) {
            tableRef.current.onQueryChange()
        }
    }

    useEffect(() => {
        if (type === 'pcm_files') {
            setEndpoint("get_pcm_files_by_ccid/")
        } else {
            setEndpoint("get_files_by_type_and_type_id/")
        }
    }, [type])

    // update type based on url passed to the component
    useEffect(() => {
        if(type === 'product_files') {
            setUpdatedType('product')
            setUpdatedId(id)
            fetchProduct()
        } else if (type === 'am_files') {
            setUpdatedType('assurance-maintenance')
            setUpdatedId(id)
        } else if (type === 'pcm_files') {
            setUpdatedType('ccp')
            setUpdatedId(ccid)
        } else {
            setUpdatedType(type)
            setUpdatedId(id)
        }
        // if (tableRef.current) {
        //     tableRef.current.onQueryChange()
        // }
    }, [type])

    useInterval(()=>{
        if (tableRef.current) {
            tableRef.current.onQueryChange()
        }
    }, 300000) //5 min

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
            withCredentials: true, 
            headers: {
                'Authorization':  `Token ${authToken}`,
            },
            responseType: 'blob'
        }).then((response) => {
            setOverlayActive(false)
            FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name))
        }).catch((error) => {
            console.log('Error')
        })
    }
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const retractFile = (rowData) => {
        axios
        .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
            published: JSON.stringify(false),
            approved: JSON.stringify(false)
        },
        {
            withCredentials: true,
            headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`
            },
        }
        )
        .then((response) => {
            updateFiles()
            handleCloseDeleteModal()
        })
        .catch((error) => {
        console.log(error);
        });
    
    }

    const publishFile = (rowData) => {
        axios
        .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
            published: JSON.stringify(true),
            approved: JSON.stringify(true)
        },
        {
            withCredentials: true,
            headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`
            },
        }
        )
        .then((response) => {
            updateFiles()
            handleCloseDeleteModal()
        })
        .catch((error) => {
        console.log(error);
        });
    
    }

    const disapproveFile = (rowData) => {
        axios
        .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
            approved: JSON.stringify(false),
            published: JSON.stringify(false),
        },
        {
            withCredentials: true,
            headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`
            },
        }
        )
        .then((response) => {
            updateFiles()
            handleCloseDeleteModal()
        })
        .catch((error) => {
        console.log(error);
        });
    
    }

    const approveFile = (rowData) => {
        axios
        .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
            approved: JSON.stringify(true),
            published: JSON.stringify(false),
        },
        {
            withCredentials: true,
            headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`
            },
        }
        )
        .then((response) => {
            updateFiles()
            handleCloseDeleteModal()
        })
        .catch((error) => {
        console.log(error);
        });
    
    }

    const handleOpenRenameModal = (object) => {
        setCurrentObject(object)
        setOpenRenameModal(true)
    }

    const handleCloseRenameModal = () => {
        setCurrentObject({})
        setOpenRenameModal(false)
    }

    const handleOpenRelabelModal = (object) => {
        setCurrentObject(object)
        setOpenRelabelModal(true)
    }

    const handleCloseRelabelModal = () => {
        setCurrentObject({})
        setOpenRelabelModal(false)
    }

    const visibility = {
        "true": "Proprietary",
        "false": "Public"
    }

    const options = {
        headerStyle: {
            fontWeight: "bold",
            borderBottom: "none",
            paddingBottom: "0"
        },
        filtering: filtering,
        columnsButton: true,
        pageSize: 20
    }

    const getDisposition = (rowData) => {
        if (rowData.private) return "<p>Not Approved for Publication<p>"
        else {
            let output = "<p>"
            if (rowData.approved) output += "Approved<br />"
            if (!rowData.approved) output += "Not Approved<br />"
            if (rowData.published) output += "Published"
            if (!rowData.published) output += "Not Published"
            output += "</p>"
            return output
        }
    }

    const publishRetract = (rowData) => {
        if (rowData.private || !rowData.approved) return ""
        else {
            if (rowData.published) {
                return (
                    <Button variant='link' className='m-1 p-0 btn-link' onClick={() => {
                        retractFile(rowData)
                    }}><RemoveIcon color="action" />Retract</Button>
                )
            } else {
                return (
                    <Button variant='link' className='m-1 p-0 btn-link' onClick={() => {
                        publishFile(rowData)
                    }}><AddIcon color="action" />Publish</Button>
                )
            }
        }
    }

    const approveDisapprove = (rowData) => {
        if (rowData.private || rowData.published || permissions?.role_type !== "NIAP") return ""
        else {
            if (rowData.approved) {
                return (
                    <Button variant='link' className='m-1 p-0 btn-link' onClick={() => {
                        disapproveFile(rowData)
                    }}><CancelIcon color="action" />Disapprove</Button>
                )
            } else {
                return (
                    <Button variant='link' className='m-1 p-0 btn-link' onClick={() => {
                        approveFile(rowData)
                    }}><CheckIcon color="action" />Approve</Button>
                )
            }
        }
    }

    const initCaps = (text) => {
        let split = text.split('-')
        split.forEach((word, idx) => {
            word = word.charAt(0).toUpperCase() + word.slice(1);
            split[idx] = word;
        })
        text = split.join('-')
        return text
    }

    const columns = [
        { title: "Label", field: "file_label"},
        { title: 'Name', field: 'file_name', render: rowData => (<Button style={{overflowWrap: "break-word"}} variant="link" onClick={() => downloadFile(rowData)}>{rowData.file_new_name ? rowData.file_new_name : rowData.file_name}</Button>) },
        { title: 'Type', field: 'file_display_name' },
        { title: "Visiblity", field: 'private', render: rowData => visibility[String(rowData.private)] },
        { title: 'Uploaded By', field: 'uploaded_by', render: rowData => (rowData?.uploaded_by?.first_name + " " + rowData?.uploaded_by?.last_name) },
        { title: 'Uploaded On', field: 'uploaded_on', render: rowData =>  (rowData?.uploaded_on ? moment.utc(rowData?.uploaded_on).format("MM/DD/YYYY [at] HH:mm") : rowData?.uploaded_on)},
        { title: "Disposition", field: "published", render: rowData => (parse(getDisposition(rowData)))},
        { title: "Actions", field: 'actions', filtering: false, sorting: false, render: (rowData) => {
            return ( 
                <OverlayTrigger trigger="click" rootClose placement='bottom' key='bottom'
                    overlay={ 
                        <Popover>
                            <Popover.Body>                  
                                    <Button 
                                        variant='link' 
                                        className='m-1 p-0 btn-link' 
                                        onClick={() => {
                                            setShowReplaceFileModal(true)
                                            setCurrFile(rowData)
                                        }}
                                    >
                                        <SwapIcon color="action" />Replace
                                    </Button>
                                    {approveDisapprove(rowData)}
                                    {publishRetract(rowData)}
                                    <Button variant="link" onClick={() => handleOpenRelabelModal(rowData)}>Relabel</Button>
                                    <Button variant="link" onClick={() => handleOpenRenameModal(rowData)}>Rename</Button>
                            </Popover.Body>
                        </Popover>
                    }>
                    <Button variant='link'><MoreVertIcon className='float-end' /></Button>
                </OverlayTrigger>
            )
        }}
    ]

    return (
        <Container className="mt-3">
            <RenameObject show={openRenameModal} handleClose={handleCloseRenameModal} reloadDocuments={reloadDocuments} object={currentObject} />
            <RelabelObject show={openRelabelModal} handleClose={handleCloseRelabelModal} reloadDocuments={reloadDocuments} object={currentObject} />
            <Row className="justify-content-end mb-3">
                <Col md={2}>
                    <Button onClick={() => setShow(true)} className="text-capitalize">Upload {updatedType === 'product' ? "Project" : updatedType === 'ccp' ? 'PCM' : updatedType } File</Button>
                </Col>
            </Row>
            {updatedType !== "" &&
            <MaterialTable
                title={updatedType === 'product' ? `Project File Area for VID ${product?.v_id}` : (updatedType === 'ccp' ? 'PCM' : initCaps(updatedType)) + " File Area"}
                columns={columns}
                tableRef={tableRef}
                data={(query) =>
                    new Promise((resolve, reject) => {
                      // Extract the necessary information from the query object
                      const {
                          page,
                          pageSize,
                          search,
                          filters,
                          orderBy,
                          orderDirection,
                      } = query;
          
                      let newFilters = filters.map((filter) => {
                          let value = ""
                          if (Array.isArray(filter.value)) {
                              value = filter.value
                          } else {
                              value = filter.value.replace(/['"]+/g, '')
                          }
                          return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                      });
                      const params = {
                          offset: page * pageSize,
                          limit: pageSize,
                          search: search,
                          filters: newFilters,
                          orderBy: orderBy?.field,
                          orderDirection: orderDirection,
                          file_type: updatedType,
                          file_type_id: id,
                          show_hidden: true,
                          show_latest_ear_file: true
                      }
                      if (updatedType === 'ccp') {
                        params['ccid'] = ccid
                      }
                      axios
                          .get(
                              `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${endpoint}?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                              {
                                  withCredentials: true,
                                  headers: {
                                      Authorization: `Token ${authToken}`,
                                  },
                              }
                          )
                          .then((response) => {
                              resolve({
                                  data: response.data.results,
                                  page: page,
                                  totalCount: response.data.count,
                              });
                          })
                          .catch((error) => {
                              reject(error);
                          });
                      })
                    }
                options={options}
                components={{
                    Pagination: props => <TablePagination {...props} labelRowsPerPage={<label htmlFor='rowsPerPage'>Rows Per Page</label>} SelectProps={{input: <Input id="rowsPerPage" />}} />
                }}
            />
            }
            <FileUpload
                show={show}
                updateFiles={updateFiles}
                type={updatedType}
                id={id}
                ccid={ccid}
                vid={product.v_id}
            />
            <ReplaceFileModal 
                show={showReplaceFileModal}
                setShow={setShowReplaceFileModal}
                updateFiles={updateFiles}
                currFile={currFile}
                type={updatedType}
                id={id}
                ccid={ccid}
                vid={product.v_id}/>
        </Container>
    )
}

export default FileTableCheckPermissions;