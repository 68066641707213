import axios from "axios";
import moment from "moment";
import FileDownload from "js-file-download";

export const downloadFile = (file, authToken, setOverlayActive, setOverlayText) => {
  setOverlayText("Downloading")
  setOverlayActive(true)
  axios
    .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`, {
      withCredentials: true,
      headers: {
        Authorization: `Token ${authToken}`,
      },
      responseType: "blob",
    })
    .then((response) => {
      setOverlayActive(false)
      FileDownload(response.data, file.file_new_name ? file.file_new_name : file.file_name);
    })
    .catch((error) => {
      console.log("unable to download file", error);
    });
};

export const downloadAllFiles = (id, source_type, authToken, setOverlayActive, setOverlayText) => {
  setOverlayText("Downloading")
  setOverlayActive(true)
  axios
    .get(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}file/download_all_files_by_type_type_id_source_type/?file_type=product&file_type_id=${id}&file_source_type=${source_type}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
        responseType: "blob",
      }
    )
    .then((response) => {
      setOverlayActive(false)
      FileDownload(response.data, source_type + ".zip");
    })
    .catch((error) => {});
};

export const getFilesBySourceAndId = async (id, file_source_type, authToken) => {
  if (id && file_source_type && authToken) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=product&file_source_type=${file_source_type}&file_type_id=${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const getPclFiles = async (product_id, authToken) => {
  if (product_id && authToken) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_pcl_files/?product_id=${product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
  }
};

export function getSecurityTargetFiles(evalFiles) {
  const regex = /^(Security Targets?)$/g;

  // filters out eval files with a "Security Target"
  // or "Security Targets" display name
  return evalFiles?.eval_files?.filter((file) => {
    return file?.file_display_name.match(regex);
  });
}

export const getSortedTargetFiles = (pclFiles) => {
  // calls the getSecurityTargetFiles service method to generate an array
  // of all the security target history files from the existing files
  const securityTargetFilesArray = getSecurityTargetFiles(pclFiles);

  if (securityTargetFilesArray?.length === 0) {
    return [];
  }
  // orders new securityTargetFilesArray by most recent uploaded_on date
  securityTargetFilesArray?.forEach((targetFile) => {
    return (targetFile.uploaded_on = moment(targetFile?.uploaded_on));
  });
  securityTargetFilesArray?.sort((a, b) => b.uploaded_on - a.uploaded_on);
  return securityTargetFilesArray;
};
