import { Route, Routes } from "react-router-dom";
import OrganizationManagerView from "./OrganizationManagerView";
import UnauthorizedView from "../UI/UnauthorizedView"
import { useSelector } from "react-redux";

export default function OrganizationManager() {
  const permissions = useSelector(state => state.role.value);

  if (!permissions?.role_permissions?.find(permission => permission.area === 'Organization')?.read) {
    return (
      <UnauthorizedView />
    )
  }

  return (
    <Routes>
        <Route path="/" element={<OrganizationManagerView />} />
    </Routes>
  )
}
