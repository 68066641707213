import { useEffect, useState } from 'react';
import { Modal, Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import Select from "react-select";
import { useCookies } from 'react-cookie';
import axios from "axios";

export default function UserRolesForm({
  show,
  setShow,
  setRolesSuccess,
  editRole,
  setEditRole,
  refetch
}) {
  const [cookies] = useCookies();
  let authToken = cookies['auth_token'];
  let csrfToken = cookies['csrftoken'];
  const [role, setRole] = useState({});
  const [fail, setFail] = useState(false);
  const [areaPermissions, setAreaPermissions] = useState([]);
  const [permissionChecks, setPermissionChecks] = useState([
    {name: "local_only", label: "Local Only", isChecked: false},
    {name: "create", label: "Create", isChecked: false},
    {name: "read", label: "Read", isChecked: false},
    {name: "update", label: "Update", isChecked: false},
    {name: "destroy", label: "Destroy", isChecked: false},
  ])

  const allAreas = [
    {value: "Community", label: "Community"},
    {value: "Accounts", label: "Accounts"},
    {value: "Organization", label: "Organization"},
    {value: "Organization Projects", label: "Organization Projects"},
    {value: "Organization Members", label: "Organization Members"},
    {value: "Project", label: "Project"},
    {value: "Project Members", label: "Project Members"},
    {value: "Protection Profile", label: "Protection Profile"},
    {value: "Technical Questions", label: "Technical Questions"},
    {value: "Technical Decisions", label: "Technical Decisions"},
    {value: "Virtual Teams", label: "Virtual Teams"},
    {value: "ECR Comments", label: "ECR Comments"},
    {value: "EAR Reviews", label: "EAR Reviews"},
    {value: "Company Profile", label: "Company Profile"},
    {value: "AM Approval Authority", label: "AM Approval Authority"},
    {value: "Evaluation Team", label: "Evaluation Team"},
    {value: "Document Manager", label: "Document Manager"},
    {value: "Assurance Maintenance", label: "Assurance Maintenance"},
  ]

  useEffect(() => {
    if(editRole?.role_id){
      setAreaPermissions([...editRole.role_permissions]);
      setRole({...editRole})
    }
  }, [editRole?.role_id])

  const handleClose = () => {
    setShow(false);
    setAreaPermissions([]);
    setRole({});
    setFail(false);
    setEditRole({});
    permissionChecks.forEach(check => check.isChecked = false);
  }

  const handleClick = (e) => {
    const role_permissions = {
      area: role.area,
      local_only: permissionChecks[0].isChecked,
      create: permissionChecks[1].isChecked,
      read: permissionChecks[2].isChecked,
      update: permissionChecks[3].isChecked,
      destroy: permissionChecks[4].isChecked,
    };
    const areaArr = [...areaPermissions];
    areaArr.push(role_permissions);
    setAreaPermissions(areaArr);
    // permissionChecks.forEach(check => check.isChecked = false)
    setRole({...role, area: ""})
  }

  const handleChange = (e) => {
    if(e.target.type === 'checkbox') {
      const newPermissions = permissionChecks.slice();
      newPermissions.find((p) => p.name === e.target.id).isChecked = e.target.checked;
      setPermissionChecks(newPermissions);
    } else if (e.target.type === "radio"){
      if(e.target.value === 'lead'){
        setRole({...role, is_lead: true, is_senior: false})
      } else if (e.target.value === 'senior'){
        setRole({...role, is_lead: false, is_senior: true})
      }
    } else {
      setRole({ ...role, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios
    .post(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/`,
      {
        ...role,
        role_permissions: areaPermissions
      },
      {
        withCredentials: true,
        headers: {
          "Authorization": `Token ${authToken}`,
          "X-CSRFToken": csrfToken
        },
      }
    )
    .then((response) => {
      setRolesSuccess(true);
      handleClose();
      refetch();
    })
    .catch((error) => {
      console.log("Unable to create user role: " + error);
      setFail(true);
    });
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    await axios
    .put(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/${editRole?.role_id}/`,
      {
        ...role,
        role_permissions: areaPermissions
      },
      {
        withCredentials: true,
        headers: {
          "Authorization": `Token ${authToken}`,
          "X-CSRFToken": csrfToken
        },
      }
    )
    .then((response) => {
      setRolesSuccess(true);
      handleClose();
      refetch();
    })
    .catch((error) => {
      console.log("Unable to update user role: " + error);
      setFail(true);
    });
  }

  const removeAreaPermission = async (permission, idx) => {
    if(editRole?.role_id){
      await axios.delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/permissions/${permission.id}/`,
      {withCredentials: true, headers: {"Authorization": `Token ${authToken}`, "X-CSRFToken": csrfToken}})
      .then()
      .catch(error => console.log("Unable to delete permission: " + error));
    }
    areaPermissions.splice(idx, 1)
    setAreaPermissions([...areaPermissions])
}

  const customFilter = (value) => {
    for(let i in areaPermissions) {
      if(areaPermissions[i].area === value) {
        return false;
      }
    }
    return value;
  }

  const areaOptions = allAreas
  .filter((oneArea) => customFilter(oneArea.value))
  .map((oneArea) => {
    return {value: oneArea.value, label: oneArea.label}
  });
    

  const AreaSelect = () => {
    return (
      <Select
        onChange={(e) =>
          handleChange({ target: { name: "area", value: e.value } })
        }
        defaultValue={areaOptions.find((area) => area.value === role.area)}
        value={areaOptions.find((area) => area.value === role.area)}
        options={areaOptions}
      />
    );
  };

  return (
    <Modal size='lg' show={show} onHide={handleClose} onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>{editRole?.role_id ? "Edit" : "Add"} User Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Container>
            <Row>
              <Col>
                <Form.Group className='mb-3' controlId='userRole'>
                  <Form.Label>User Role*</Form.Label>
                  <Form.Control
                    type='text'
                    name='role_name'
                    onChange={handleChange}
                    defaultValue={editRole?.role_name}
                    placeholder='User Role'
                    required={true}
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3' controlId='role_type'>
                  <Form.Label>Role Type*</Form.Label>
                  <Form.Select name='role_type'  onChange={handleChange} >
                    <option value={editRole?.role_type}>{editRole?.role_type}</option>
                    {['NIAP', 'Lab', 'Validator', 'Tech Community', 'Vendor', 'SME'].filter((val) => val !== editRole?.role_type).map((filteredVals, i) => (
                      <option key={i} value={filteredVals}>{filteredVals}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {["Validator", "Lab"].includes(role?.role_type) &&
            <Row className='mt-2'>
              <Col>
                <Form.Group controlId='role_position'>
                  <Form.Label>Role Position<span className="fst-italic"> (Optional)</span></Form.Label>
                  <br></br>
                  <Form.Check type='radio' value="lead" checked={role?.is_lead === true} name="role_position" label="Lead" inline onChange={handleChange} />
                  <Form.Check type='radio' value="senior" checked={role?.is_senior === true} name="role_position" label="Senior" inline onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>
            }
            <Row className='mt-4'>
              <Col>
                <Form.Group controlId='react-select-18-input'>
                  <Form.Label>Select an Area and Permission*</Form.Label>
                  <Form.Control name="area" as={AreaSelect} />
                </Form.Group>
              </Col>
              <Col className="mt-4">
                <Form.Group>
                {permissionChecks.map((check, i) => (
                  <Form.Check
                    inline
                    type='checkbox'
                    key={i}
                    id={check.name}
                    name={check.name}
                    label={check.label}
                    checked={check.isChecked}
                    onChange={handleChange}
                    disabled={!role?.area}
                  ></Form.Check>
                ))}
                </Form.Group>
              </Col>
              <Col className="mt-4" xs={12} sm={2}>
                <Button 
                  variant='outline-primary' 
                  disabled={!(permissionChecks.some(el => el.isChecked))} 
                  onClick={handleClick}
                  className='w-100'
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                  {areaPermissions?.map((permission, i) => (
                    <div className='border p-3' key={i}>
                    <Row>
                      <Col sm={4}>{permission?.area}</Col>
                      <Col sm={6}>
                        <ul>
                        {permission?.local_only && <li>Local Only</li>}
                        {permission?.create && <li>Create</li>}
                        {permission?.read && <li>Read</li>}
                        {permission?.destroy && <li>Destroy</li>}
                        {permission?.update && <li>Update</li>}
                        </ul>
                      </Col>
                      <Col sm={2}>
                        <Button
                          variant='outline-primary'
                          className='attachment-remove'
                          onClick={() => removeAreaPermission(permission, i)}
                        >
                          X
                        </Button>
                      </Col>
                    </Row>
                    </div>
                  ))}
              </Col>
            </Row>
            <Row className="mt-4 justify-content-end">
              <Col xs={12} sm={2}>
              {Object.keys(editRole).length !== 0 ?
                <Button 
                  variant='primary' 
                  onClick={(e) => handleUpdate(e)} 
                  disabled={!role.role_name || !role.role_type || areaPermissions.length === 0}
                  className='w-100 mb-xs-3'
                >
                  Update
                </Button>
              :
                <Button 
                  variant='primary' 
                  type="submit"  
                  disabled={!role.role_name || !role.role_type || areaPermissions.length === 0}
                  className='w-100 mb-xs-3'
                >
                  Submit
                </Button>
              }
              </Col>
              <Col xs={12} sm={2}>
                <Button variant='warning' onClick={handleClose} className='w-100 mb-xs-3'>
                  Cancel
                </Button>
              </Col>
            </Row>
            {editRole?.role_id &&
              <Row className='mt-3'>
                <p className='fw-bold'>**Note: Users with edited role will need to log out and log back in to see permission update.**</p>
              </Row>
            }
          </Container>
        </Form>
        {fail && <Alert className="mt-3" variant='danger'>User Role Failed to be Created.</Alert>}
      </Modal.Body>
    </Modal>
  );
}
