import { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux'
import HTMLEditor from '../../UI/HTMLEditor';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Modal,
} from 'react-bootstrap';
import reqClassData from './requirementClass.json';
import ReactSelect from '../../ReactSelect';

export default function ContactTRRTModal({ show, setShow, loadCaptcha, memberPortal, refetch, setAlert }) {
  const [validated, setValidated] = useState(false);
  const [newTRRT, setNewTRRT] = useState({});
  const currentUser = useSelector(state => state.user.value);
  const [trrtTeams, setTrrtTeams] = useState([]);
  const [products, setProducts] = useState([]);
  const [ip, setIP] = useState('');
  const [error, setError] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  //htmlEditor valid states
  const [questionValid, setQuestionValid] = useState(true);
  const [avenuesValid, setAvenuesValid] = useState(true);
  const [impactValid, setImpactValid] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)

  const siteKey = '6Lf29aAjAAAAAIagG9XUSNUz_nXKnwxrK_fDcGf5';
  const recaptchaRef = useRef(null);

  const [cookies] = useCookies();
  let csrfToken = cookies['csrftoken'];

  //sets isValid on ReactSelect component
  const [primaryTRRTValid, setPrimaryTRRTValid] = useState(true);
  const [reqClassValid, setReqClassValid] = useState(true);
  const [projectEvalValid, setProjectEvalValid] = useState(true);

  // Set Constants
  const priorityOptions = [
    { "value": "1. Delaying an Evaluation" },
    { "value": "2. On-going Evaluation"},
    { "value": "3. Project in Pre-Eval or General"}
 ]

  const handleClose = () => setShow(false);

  useEffect(() => {
    setValidated(false);
    setNewTRRT({});
    setInvalidToken(false);
    setPrimaryTRRTValid(true);
    setReqClassValid(true);
    setProjectEvalValid(true);
  }, [show])

  useEffect(() => {
    const getIP = async () => {
      const res = await axios.get('https://api.ipify.org/?format=json');
      setIP(res.data.ip);
    };
    getIP().catch((error) => {
      console.log('unable to get IP: ', error);
    });
  }, []);

  const loadVirtualTeam = () =>{
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/get_all_virtual_teams/`)
      .then((response) => {
        setTrrtTeams(
          response.data?.filter(
            (trrt) => trrt.active === true && trrt.team_type === "TRRT Team"
          )
        );
      })
      .catch((error) => {
        console.log('Error getting TRRT Teams: ', error);
    });
  }

  useEffect(() => {
    loadVirtualTeam()
  }, []);

  const loadProduct = ()=> { 
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products_not_certified_or_in_am/`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => { 
        console.log('Error getting Projects: ', error);
      });
  }

  useEffect(() => {
    loadProduct()
  }, []);

  const testSelects = () => {
    let invalid = false
    if (!('team' in newTRRT)) {
      setPrimaryTRRTValid(false)
      invalid = true
    }
    if (!('requirement' in newTRRT)) {
      setReqClassValid(false)
      invalid = true
    }
    if (!('product_evaluation' in newTRRT)) {
      setProjectEvalValid(false)
      invalid = true
    }
    if(invalid === true){
      return false
    } else {
      return true
    }
  }

  const testHtmlEditors = () => {
    let invalid = false
    if(!newTRRT?.question){
      setQuestionValid(false);
      invalid = true
    }
    if(!newTRRT?.previously_expolored){
      setAvenuesValid(false);
      invalid = true
    }
    if (!newTRRT?.impact) {
      setImpactValid(false);
      invalid = true
    }
    if(invalid === true){
      return false
    } else {
      return true;
    }
  }

  const handleDueDate = () =>{
    switch (newTRRT?.urgency){
      case priorityOptions[0].value:
        return moment().add(2, 'days')
      case priorityOptions[1].value:
        return moment().add(7, 'days')
      case priorityOptions[2].value:
        return moment().add(14, 'days')
      default:
        return null; // Add a default case to handle other values of newTRRT?.urgency
    }    
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    testSelects()
    testHtmlEditors()
    if (form.checkValidity() === false || testSelects() === false || testHtmlEditors() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      let currentTime = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()));
      let token = '';
      if(!memberPortal){
        token = event.target['g-recaptcha-response'].value;
      } else if (memberPortal) {
        token = 'member_portal';
      }

      let data = {
          ...newTRRT,
          deadline: handleDueDate(),
          status: "Proposed",
          submitted_on: currentTime,
          modified_on: currentTime,
          submitted_by: currentUser?.id,
          modified_by: currentUser?.id,
          submitter_ip: ip ? ip : null,
          recaptcha_token: token
      }
      
      if (data.product_evaluation.includes('no_vid')) {
        delete data.product_evaluation;
      }

      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/`,
          data,
          {
            withCredentials: true,
            headers: {
              'X-CSRFToken': csrfToken,
            },
          }
        )
        .then((response) => {
          handleClose();
          setAlert({message: 'TRRT Query Submitted Successfully!', type: 'success'});
          if(!memberPortal){
            setInvalidToken(false);
            recaptchaRef.current.reset();
          }
          setNewTRRT({});
          setPrimaryTRRTValid(true);
          setReqClassValid(true);
          setProjectEvalValid(true);
          refetch()
        })
        .catch((error) => {
          if(error.response?.data?.recaptcha_token){
            setInvalidToken(true);
          } else {
            setError(true);
          }
        });
    }

    setValidated(true);
  };

  const handleChange = (e) => {
    setNewTRRT({ ...newTRRT, [e.target.name]: e.target.value });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValidated(false);
    setNewTRRT({});
    recaptchaRef.current.reset();
    setError(false);
    setInvalidToken(false);
    setPrimaryTRRTValid(true);
    setReqClassValid(true);
    setProjectEvalValid(true);
  };

  const showCaptcha = () => {
    try {
      window.grecaptcha.render('form-recaptcha', {
        sitekey: siteKey,
        ref: recaptchaRef,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const primaryTRRTOptions = trrtTeams.map((team) => {
    return { value: team.vtid, label: team.team };
  });

  const extendedTRRTOptions = trrtTeams
    .filter((team) => team.vtid !== newTRRT?.team)
    .map((team) => {
      return { value: team.vtid, label: team.team };
    });

    const productOptions = [
      { value: 'no_vid', label: 'No VID' },
      ...products?.map((product) => ({
        value: product.product_id,
        label: `VID${product.v_id}`,
      }))
    ];

  return (
    <>
        <Modal
          show={show}
          onHide={handleClose}
          onEntered={loadCaptcha ? showCaptcha : ''}
          size='lg'
          aria-labelledby='contained-modal-title'
          enforceFocus={enforceFocusModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>CONTACT A TECHNICAL RAPID RESPONSE TEAM</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please use the form below to send technical queries to the
              Technical Rapid Response Teams (TRRT) for resolution. Prior to
              entering a TRRT Request, all other options for resolution should
              already have been exhausted through working with a NIAP CCTL or
              assigned Validator.
            </p>
            <div>
              <Container>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  id='submit-trrt-form'
                >
                  <Row xs={1} md={2}>
                    <Col>
                    {!memberPortal &&
                      <Form.Group className='mb-3' controlId='name'>
                        <Form.Label>Name:*</Form.Label>
                        <Form.Control
                          type='text'
                          name='name'
                          value={newTRRT?.name ?? ''}
                          onChange={handleChange}
                          placeholder='Enter Name'
                          required
                        />
                        <Form.Control.Feedback type='invalid'>
                          Please provide a valid Name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    }
                      <Form.Group className='mb-3' controlId='title'>
                        <Form.Label>Title or Summary of the Question:</Form.Label>
                        <Form.Control
                          type='text'
                          name='title'
                          onChange={handleChange}
                          value={newTRRT?.title ?? ''}
                          placeholder='Enter a Title'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Please provide a valid Title.
                        </Form.Control.Feedback>
                      </Form.Group>
                      {!memberPortal &&
                      <Form.Group className='mb-3' controlId='affiliation'>
                        <Form.Label>Affiliation:*</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='affiliation'
                          onChange={handleChange}
                          value={newTRRT?.affiliation ?? ''}
                          placeholder='Enter Affiliation'
                        />
                        <Form.Text className='text-muted'>
                          Vendor, CCTL, Academic Institution, Other
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                          Please provide a valid Affiliation.
                        </Form.Control.Feedback>
                      </Form.Group>
                      }
                      <Form.Group className='mb-3' controlId='email'>
                        <Form.Label>Email Address:*</Form.Label>
                      <Form.Control
                          required
                          type='email'
                          name='email'
                          onChange={handleChange}
                          value={newTRRT?.email ?? ''}
                          placeholder='Enter Email'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Please provide a valid Email.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='rapid-response-team'>
                        <Form.Label>
                          Primary Technical Rapid Response Team:*
                        </Form.Label>
                        <ReactSelect id={"rapid-response-team"} options={primaryTRRTOptions} newObj={newTRRT} handleChange={handleChange} name={'team'} isValid={primaryTRRTValid} />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='family_component_element'>
                        <Form.Label>Family/Component/Element:*</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='family_component_element'
                          onChange={handleChange}
                          value={newTRRT?.family_component_element ?? ''}
                          placeholder='Enter a Family/Component/Element'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Please provide a valid Family/Component/Element.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='urgency'>
                        <Form.Label>Priority:*</Form.Label>
                        <Form.Select
                          name='urgency'
                          value={newTRRT?.urgency ?? ''}
                          onChange={handleChange}
                          required
                        >
                          <option value=''>Select...</option>
                          {priorityOptions.map((option, index) =>(
                            <option key={index} value={option.value}>{option.value}</option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className='mb-3'
                        controlId='related-evaluation-project'
                      >
                        <Form.Label>Related Evaluation Project VID:*</Form.Label>
                        <ReactSelect 
                          id={"related-evaluation-project"}
                          isMulti={true}
                          options={productOptions} 
                          newObj={newTRRT} 
                          handleChange={handleChange} 
                          name={'product_evaluation'} 
                          isValid={projectEvalValid} 
                        />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='additional_emails'>
                          <Form.Label>Additional Emails:</Form.Label>
                          <Form.Control
                            type='text'
                            name='additional_emails'
                            onChange={handleChange}
                            value={newTRRT?.additional_emails ?? ''}
                            placeholder="Enter Additional Emails Separated by ';'"
                          />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='extended-technical-rapid-respone-team'>
                        <Form.Label>
                          Extended Technical Rapid Response Teams:
                        </Form.Label>
                        <ReactSelect id={'extended-technical-rapid-respone-team'} isMulti options={extendedTRRTOptions} newObj={newTRRT} handleChange={handleChange} name={'trrt_team'} isValid={true} />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='requirement-class'>
                        <Form.Label>Requirement Class:*</Form.Label>
                        <ReactSelect
                          id={"requirement-class"} 
                          options={reqClassData} 
                          newObj={newTRRT} 
                          handleChange={handleChange} 
                          name={'requirement'} 
                          isValid={reqClassValid} 
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3' controlId='question'>
                        <Form.Label>Question:*</Form.Label>
                        <HTMLEditor 
                          id={'question'}
                          name='question' 
                          handleChange={handleChange} 
                          setIsValid={setQuestionValid} 
                          isValid={questionValid} 
                          defaultValue={""} 
                          setEnforceFocusModal={setEnforceFocusModal}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className='mb-3'
                        controlId='previously_expolored'
                      >
                        <Form.Label>
                          Avenues Previously Explored for Answers:*
                        </Form.Label>
                        <HTMLEditor 
                          id={'previously_expolored'}
                          name='previously_expolored' 
                          handleChange={handleChange} 
                          setIsValid={setAvenuesValid} 
                          isValid={avenuesValid} 
                          defaultValue={""} 
                          setEnforceFocusModal={setEnforceFocusModal}
                        />
                      </Form.Group>
                    </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='impact'>
                      <Form.Label>Impact:*</Form.Label>
                      <HTMLEditor 
                          name='impact' 
                          handleChange={handleChange} 
                          setIsValid={setImpactValid} 
                          isValid={impactValid} 
                          defaultValue={""} 
                          setEnforceFocusModal={setEnforceFocusModal}
                        />
                    </Form.Group>
                  </Col>
                </Row>
                  <Row>
                  {!memberPortal &&
                    <Col>
                      <Form.Group className='mb-3' controlId='token'>
                        <ReCAPTCHA
                          id='form-recaptcha'
                          sitekey={siteKey}
                          ref={recaptchaRef}
                        />
                        {invalidToken && (
                          <p className='text-danger'>Please Confirm.</p>
                        )}
                        <Form.Text className="text-muted">Required to submit any information via this form.</Form.Text>
                      </Form.Group>
                    </Col>
                  }
                  </Row>
                  <Row className='pt-4'>
                    <Col md={2}>
                      <Button variant='primary' type='submit'>
                        Submit
                      </Button>
                    </Col>
                    <Col md={2}>
                      <Button variant='outline-primary' type='reset'>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {error && (
                  <Alert variant='danger' className='mt-3'>
                    Failed to submit TRRT Query
                  </Alert>
                )}
              </Container>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
}
