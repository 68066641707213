import { useEffect, useState } from "react";
import Select from "react-select";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import ReactSelect from "../../ReactSelect";
import HTMLEditor from "../../UI/HTMLEditor";
import "./PPForm.css";
import moment from "moment";

export default function PPForm({ show, handleCloseModal, pp, refetch }) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [editedPp, setEditedPp] = useState({});
  const [ppList, setPpList] = useState([]);
  const currentUser = useSelector((state) => state.user.value);
  const [validated, setValidated] = useState(false);
  const [statusValid, setStatusValid] = useState(true);
  const [techTypeValid, setTechTypeValid] = useState(true);
  const [ppOverviewValid, setPpOverviewValid] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)

  const fetchPps = () => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/`, {
      withCredentials: true,
      headers: {
        Authorization: `Token ${authToken}`
      }
    })
    .then(response => {
      setPpList(response.data.results);
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    if(show){
      fetchPps()
    }
  }, [show===true])

  const handleChange = (e) => {
    setEditedPp({ ...editedPp, [e.target.name]: e.target.value })
  };

  const statusOptions = [
    { value: "Initiation", label: "Initiation" },
    { value: "Planning", label: "Planning" },
    { value: "Development", label: "Development" },
    { value: "Publishing", label: "Publishing" },
    { value: "Archived", label: "Archived" },
  ];

  const techTypeOptions = [
    { value: "Application Software", label: "Application Software" },
    { value: "Biometrics", label: "Biometrics" },
    { value: "BIOS Update", label: "BIOS Update" },
    { value: "Certificate Authority", label: "Certificate Authority" },
    { value: "Hardware Platform and Components", label: "Hardware Platform and Components" },
    { value: "Peripheral Sharing Devices", label: "Peripheral Sharing Devices" },
    {value: "Retransmission Device", label: "Retransmission Device"}
  ];

  const priorityOptions = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const testSelects = () => {
    if ("status" in editedPp && "tech_type" in editedPp) {
      return true;
    }
    if (!("status" in editedPp)) {
      setStatusValid(false);
    }
    if (!("tech_type" in editedPp)) {
      setTechTypeValid(false);
    }
    // if (!("pp_priority" in editedPp)) {
    //   setPriorityValid(false);
    // }
    return false;
  };

  const testHtmlEditors = () => {
    if (
      "pp_overview" in editedPp
    ) {
      return true;
    }
    if (!("pp_overview" in editedPp)) {
      setPpOverviewValid(false);
    }
    return false;
  };

  const ppOptions = ppList?.map((pp) => ({ value: pp.pp_id, label: pp.pp_name }));

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    testSelects();
    testHtmlEditors();
    if (form.checkValidity() === false || testSelects() === false || testHtmlEditors() === false) {
      e.stopPropagation();
    } else {
      editedPp.csfceligible ? (editedPp.csfceligible = true) : (editedPp.csfceligible = false);
      if (!editedPp.pp_id) {
        await axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/`,
            { ...editedPp, creator: currentUser.id, entrydate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) },
            {
              withCredentials: true,
              headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
            }
          )
          .then(() => {
            handleCloseModal();
            setValidated(false);
            setStatusValid(true);
            setTechTypeValid(true);
          })
          .catch((error) => console.log("Unable to add pp: " + error));
      } else if (editedPp.pp_id) {
        await axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/${editedPp.pp_id}/`,
            { ...editedPp },
            {
              withCredentials: true,
              headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
            }
          )
          .then(() => {
            handleCloseModal();
            setValidated(false);
            setStatusValid(true);
            setTechTypeValid(true);
            refetch();
          })
          .catch((error) => console.log("Unable to update pp: " + error));
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    setEditedPp({
      ...pp,
      predecessor_id: pp?.predecessor_id?.pp_id,
      successor_id: pp?.successor_id?.pp_id,
      creator: editedPp.creator?.id,
      editor: editedPp.editor?.id,
    });
  }, [pp]);

  useEffect(() => {
    setValidated(false);
    setStatusValid(true);
    setTechTypeValid(true);
    setPpOverviewValid(true);
    // setAssuranceValid(true);
    // setSecurityEvalSummaryValid(true);
    // setEnvironmentalStrengthsValid(true);
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={handleCloseModal} enforceFocus={enforceFocusModal}>
      <Modal.Header closeButton>
        {pp?.pp_id ? (
          <Modal.Title>
            EDIT PROTECTION PROFILE: <span className="text-primary">{pp?.pp_short_name}</span>
          </Modal.Title>
        ) : (
          <Modal.Title>+ New Protection Profile</Modal.Title>
        )}
      </Modal.Header>
      <Form className="mx-auto" noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="pp_short_name">
                <Form.Label>Short Name/Abbreviation:*</Form.Label>
                <Form.Control
                  type="text"
                  name="pp_short_name"
                  defaultValue={pp?.pp_short_name}
                  onChange={handleChange}
                  placeholder="Short Name"
                  autoFocus
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="status">
                <Form.Label>Status:*</Form.Label>
                <ReactSelect
                  id={"status"}
                  handleChange={handleChange}
                  defaultValue={statusOptions.find((status) => status.value === editedPp.status)}
                  options={statusOptions}
                  newObj={editedPp}
                  name="status"
                  isValid={statusValid}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="tech_type">
                <Form.Label>Technology Type:*</Form.Label>
                <ReactSelect
                  id={"tech_type"}
                  handleChange={handleChange}
                  defaultValue={techTypeOptions.find((techType) => techType.value === editedPp?.tech_type)}
                  options={techTypeOptions}
                  newObj={editedPp}
                  name="tech_type"
                  isValid={techTypeValid}
                />
              </Form.Group>
              <Form.Label>CSfC-Eligible:*</Form.Label>
              <Form.Group className="mb-3" controlId="csfceligible">
                <Form.Check
                  id="csfceligible-yes"
                  inline
                  label="Yes"
                  name="csfceligible"
                  type="radio"
                  value={true}
                  checked={editedPp.csfceligible === true || editedPp.csfceligible === "true"}
                  onChange={handleChange}
                  required
                />
                <Form.Check
                  id="csfceligible-no"
                  inline
                  label="No"
                  name="csfceligible"
                  type="radio"
                  value={false}
                  checked={editedPp.csfceligible === false || editedPp.csfceligible === "false"}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Label>DoD Annex Reference(s):*</Form.Label>
              <Form.Group className="mb-3" controlId="dod_compliant">
                <Form.Check
                  id="dod-compliant-yes"
                  inline
                  label="Yes"
                  name="dod_compliant"
                  type="radio"
                  checked={editedPp.dod_compliant === true || editedPp.dod_compliant === "true"}
                  value={true}
                  onChange={handleChange}
                  required
                />
                <Form.Check
                  id="dod-compliant-no"
                  inline
                  label="No"
                  name="dod_compliant"
                  type="radio"
                  checked={editedPp.dod_compliant === false || editedPp.dod_compliant === "false"}
                  value={false}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Label>Archived:*</Form.Label>
              <Form.Group className="mb-3" controlId="archived">
                <Form.Check
                  id="archived-yes"
                  inline
                  label="Yes"
                  name="archived"
                  type="radio"
                  checked={editedPp.archived === true || editedPp.archived === "true"}
                  value={true}
                  onChange={handleChange}
                  required
                />
                <Form.Check
                  id="archived-no"
                  inline
                  label="No"
                  name="archived"
                  type="radio"
                  checked={editedPp.archived === false || editedPp.archived === "false"}
                  value={false}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="pp_name">
                <Form.Label>Long Name:*</Form.Label>
                <Form.Control
                  type="text"
                  name="pp_name"
                  defaultValue={pp?.pp_name}
                  onChange={handleChange}
                  placeholder="Long Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pp_priority">
                <Form.Label>Priority:</Form.Label>
                <ReactSelect
                  id={"pp_priority"}
                  handleChange={handleChange}
                  defaultValue={priorityOptions.find(
                    (priority) => priority.value === pp.pp_priority
                  )}
                  value={priorityOptions.find(
                    (priority) => priority.value === editedPp.pp_priority
                  )}
                  options={priorityOptions}
                  newObj={editedPp}
                  name={"pp_priority"}
                  isValid={true}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="cc_version">
                <Form.Label>CC Version:*</Form.Label>
                <Form.Control
                  type="text"
                  name="cc_version"
                  defaultValue={pp?.cc_version}
                  onChange={handleChange}
                  placeholder="CC Version"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pp_estcompletiondate">
                <Form.Label>Estimated Completion:*</Form.Label>
                <Form.Control
                  type="date"
                  name="pp_estcompletiondate"
                  defaultValue={pp?.pp_estcompletiondate ? moment.utc(pp?.pp_estcompletiondate).format("YYYY-MM-DD") : ""}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pp_transition">
                <Form.Label>Transition Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="pp_transition"
                  defaultValue={pp?.pp_transition ? moment.utc(pp?.pp_transition).format("YYYY-MM-DD") : ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="predecessor">
                <Form.Label>Predecessor:</Form.Label>
                <Select
                  onChange={(e) =>
                    handleChange({ target: { name: "predecessor_id", value: e.value } })
                  }
                  defaultValue={ppOptions?.find((pp) => pp.value === editedPp.predecessor_id)}
                  value={ppOptions?.find((pp) => pp.value === editedPp.predecessor_id)}
                  options={ppOptions}
                  className="pp-form-dropdown"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="pp_common_name">
                <Form.Label>Common Name:*</Form.Label>
                <Form.Control
                  type="text"
                  name="pp_common_name"
                  defaultValue={pp?.pp_common_name}
                  onChange={handleChange}
                  placeholder="Common Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pp_sponsor_id">
                <Form.Label>Sponsor:*</Form.Label>
                <Form.Control
                  type="text"
                  name="pp_sponsor_id"
                  defaultValue={pp?.pp_sponsor_id}
                  onChange={handleChange}
                  placeholder="Sponsor"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="mr_version">
                <Form.Label>MR Version:</Form.Label>
                <Form.Control
                  type="text"
                  name="mr_version"
                  defaultValue={pp?.mr_version}
                  onChange={handleChange}
                  placeholder="MR Version"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pp_date">
                <Form.Label>Approval Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="pp_date"
                  defaultValue={pp?.pp_date && moment.utc(pp?.pp_date).format("YYYY-MM-DD")}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="sunset_date">
                <Form.Label>Sunset Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="sunset_date"
                  defaultValue={pp?.sunset_date && moment.utc(pp?.sunset_date).format("YYYY-MM-DD")}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="successor">
                <Form.Label>Successor:</Form.Label>
                <Select
                  onChange={(e) =>
                    handleChange({ target: { name: "successor_id", value: e.value } })
                  }
                  defaultValue={ppOptions?.find((pp) => pp.value === editedPp.successor_id)}
                  value={ppOptions?.find((pp) => pp.value === editedPp.successor_id)}
                  options={ppOptions}
                  className="pp-form-dropdown"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="pp_overview">
                <Form.Label className="fw-bold">Overview:*</Form.Label>
                <HTMLEditor
                  name="pp_overview"
                  handleChange={handleChange}
                  setIsValid={setPpOverviewValid}
                  isValid={ppOverviewValid}
                  defaultValue={pp?.pp_overview}
                  setEnforceFocusModal={setEnforceFocusModal}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="assurance_maintenance">
                <Form.Label className="fw-bold">Assurance Maintenance:</Form.Label>
                <HTMLEditor
                  name="assurance_maintenance"
                  handleChange={handleChange}
                  setIsValid={() => {}}
                  isValid={true}
                  defaultValue={pp?.assurance_maintenance}
                  setEnforceFocusModal={setEnforceFocusModal}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="security_evaluation_summary">
                <Form.Label className="fw-bold">Security Evaluation Summary:</Form.Label>
                <HTMLEditor
                  name="security_evaluation_summary"
                  handleChange={handleChange}
                  setIsValid={() => {}}
                  isValid={true}
                  defaultValue={pp?.security_evaluation_summary}
                  setEnforceFocusModal={setEnforceFocusModal}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="environmental_strengths">
                <Form.Label className="fw-bold">Environmental Strengths:</Form.Label>
                <HTMLEditor
                  name="environmental_strengths"
                  handleChange={handleChange}
                  setIsValid={() => {}}
                  isValid={true}
                  defaultValue={pp?.environmental_strengths}
                  setEnforceFocusModal={setEnforceFocusModal}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {pp?.pp_id ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
