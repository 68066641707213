import { Route, Routes } from "react-router-dom";
import VirtualTeams from "./VirtualTeams";
import VirtualTeamInfoTable from "./VirtualTeamInfoTable";
import MemberVirtTeamsTable from "./MemberVirtTeamsTable";
import { useSelector } from "react-redux";
import UnauthorizedView from '../UI/UnauthorizedView';

const VirtTeams = () => {
    const permissions = useSelector(state => state.role.value);

    if (!permissions?.role_permissions?.find(permission => permission.area === 'Virtual Teams')?.read) {
        return (
            <UnauthorizedView />
        )
    }

    return (
        <Routes>
            <Route
            path=""
            element={<VirtualTeams />}
            />
            <Route
            path=":id"
            element={
            <VirtualTeamInfoTable />
            }
            />
            <Route
            path=":id/:member_id"
            element={<MemberVirtTeamsTable />}
            />
        </Routes>
    )
}

export default VirtTeams