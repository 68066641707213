import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../../app/user";
import { Container, Row, Col, Form, Button, Alert, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import AddMfa from "./addMfa";
import Subheader from "../Subheader";
import "./profile.css";

const Profile = () => {
  const currentUser = useSelector((state) => state.user.value);
  const [userData, setUserData] = useState(currentUser);
  const [activeMethods, setActiveMethods] = useState([]);
  const methodTypeKeys = {
    sms_twilio: "phone_number",
    email: "email",
    app: "",
  };
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [notEqual, setNotEqual] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({});
  const [desiredMethod, setDesiredMethod] = useState("");
  const [currentPrimaryMethod, setCurrentPrimaryMethod] = useState("");
  const [code, setCode] = useState("");
  const [openCodeInputModal, setOpenCodeInputModal] = useState(false);

  useEffect(() => {
    loadMethods();
  }, []);

  const loadMethods = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}auth/mfa/user-active-methods/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setActiveMethods(response.data.results);
        setCurrentPrimaryMethod(response.data.results.filter((method) => method.is_primary)[0]);
      })
      .catch((error) => {
        console.log("Error");
        console.log(error.response.data);
      });
  };

  const deleteMethod = (event, method) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${method.name}/deactivate/`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        loadMethods();
      })
      .catch((error) => {
        console.log("Error");
        console.log(error.response.data);
      });
  };

  const loadUser = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        dispatch(addUser(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseModal = () => {
    setShow(false);
    loadMethods();
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const checkPhones = (phone_number) => {
    if (
      phone_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        phone_number
      )
    ) {
      setErrors({ phone_number: "Invalid phone number" });
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "phone_number") {
      checkPhones(e.target.value);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      if (!e.target.value || e.target.value === "") {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
      if (e.target.value !== userData.confirm_password) {
        setNotEqual(true);
      } else {
        setNotEqual(false);
      }
    } else if (e.target.name === "confirm_password") {
      if (!e.target.value || e.target.value === "") {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
      if (e.target.value !== userData.password) {
        setNotEqual(true);
      } else {
        setNotEqual(false);
      }
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (
      form.checkValidity() === false ||
      userData.password !== userData.confirm_password ||
      !checkPhones(userData.phone_number)
    ) {
      e.stopPropagation();
      if (
        !userData.password ||
        userData.password === "" ||
        !userData.confirm_password ||
        userData.confirm_password === ""
      ) {
        setIsEmpty(true);
        setAlert({
          variant: "warning",
          message: "Please input a password and confirm password before saving account updates.",
        });
      }
      if (userData.password !== userData.confirm_password) {
        setNotEqual(true);
        setAlert({
          variant: "warning",
          message: "Passwords must match before saving account updates.",
        });
      }
    } else {
      let collapsedUserData = userData;
      collapsedUserData.user_role = userData?.user_role?.role_id;
      collapsedUserData.account_group = userData?.account_group?.group_id;
      collapsedUserData.scheme = userData?.scheme?.sid;
      collapsedUserData.company = userData?.company?.org_id;
      collapsedUserData.creator = userData?.creator?.id;
      collapsedUserData.editor = userData?.editor?.id;
      collapsedUserData.affiliation = userData?.affiliation?.afid;
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${userData.id}/`,
          collapsedUserData,
          {
            withCredentials: true,
            headers: {
              "Authorization": `Token ${authToken}`,
              "X-CSRFToken": csrfToken
            },
          }
        )
        .then((response) => {
          setValidated(true);
          setShowPassword(false);
          loadUser();
          setAlert({
            variant: "success",
            message:
              "Successfully updated account! Please log out and log back into the site to see changes.",
          });
        })
        .catch((error) => {
          console.log(error.response.data);
          setAlert({ variant: "danger", message: "Error updating account. Please try again." });
        });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const highlightEditBox = (event, method) => {
    event.preventDefault();
    const id = methodTypeKeys[method.name];
    document.getElementById(id).focus();
  };

    //These are functions for handling updating primary MFA method
  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };
  
  const sendCode = (event, method) => {
    setDesiredMethod(method.name);
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/code/request/`,
        { method: currentPrimaryMethod.name },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        }
      )
      .then((response) => {
        setOpenCodeInputModal(true);
      })
      .catch((error) => {
        console.log("Error");
        console.log(error.response.data);
      });
    };
  
  const setPrimaryMFA = (event) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/mfa/change-primary-method/`,
        { method: desiredMethod, code: code },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        }
      )
      .then((response) => {
        setOpenCodeInputModal(false);
        loadMethods();
        setAlert({
          variant: "success",
          message: "Successfully updated primary MFA method!",
        });
      })
      .catch((error) => {
        console.log("Error");
        console.log(error.response.data);
        setAlert({
          variant: "danger",
          message: "Error updating primary MFA method. Please try again.",
        });
      });
  };

  useEffect(() => {
    document.title = "NIAP - Profile";
  }, []);

  return (
    <>
      <Modal show={openCodeInputModal} onHide={() => setOpenCodeInputModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter MFA Code Sent to{" "}
          {currentPrimaryMethod?.name !== "undefined" ? (currentPrimaryMethod?.name !== "sms_twilio" ? capitalize(currentPrimaryMethod?.name) : "Phone") : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="code">
              <Form.Label>MFA Code *</Form.Label>
              <Form.Control type="text" name="code" required onChange={handleChangeCode} />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant="warning"
                type="submit"
                onClick={(e) => sendCode(e, { name: currentPrimaryMethod?.name })}
              >
                Resend Code
              </Button>
              <Button className="mx-3" variant="success" type="submit" onClick={(e) => setPrimaryMFA(e)}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Subheader pageName={`${userData.first_name} ${userData.last_name}`} />
      <Container fluid className="p-5">
        {alert?.message && (
          <Alert variant={alert?.variant} dismissible onClose={() => setAlert({})}>
            {alert?.message}
          </Alert>
        )}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} sm={12}>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="first_name">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      defaultValue={userData.first_name}
                      onChange={handleChange}
                      placeholder="First Name"
                      autoFocus
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="last_name">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      defaultValue={userData.last_name}
                      onChange={handleChange}
                      placeholder="Last Name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      defaultValue={userData.email}
                      onChange={handleChange}
                      placeholder="email@example.com"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="phone_number">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone_number"
                      defaultValue={userData.phone_number}
                      onChange={handleChange}
                      placeholder="111-111-1111"
                      isInvalid={errors.phone_number}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12}>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password *</Form.Label>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      id="password"
                      className="form-control"
                      name="password"
                      disableUnderline={true}
                      error={isEmpty || notEqual}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            <span className="visually-hidden">Toggle Visiblity</span>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <p className="small text-secondary">
                      Must be at least 6 characters, using a combination of letters, numbers and
                      symbols.
                    </p>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="confirm_password">
                    <Form.Label>Confirm Password *</Form.Label>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      id="confirm_password"
                      className="form-control"
                      name="confirm_password"
                      error={isEmpty || notEqual}
                      disableUnderline={true}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            <span className="visually-hidden">Toggle Visiblity</span>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12}>
              <div className="overflow-auto">
                <h6>Active Multifactor Authentication</h6>
                <table role="presentation" className="multifactor-table">
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <Link onClick={() => setShow(true)}>+ Add Sign-in Method</Link> (max 1 each
                        of App, SMS and Email)
                      </td>
                    </tr>
                    {activeMethods.map((method, idx) => (
                      <tr key={idx}>
                        <td>{method.name === "sms_twilio" ? "SMS" : capitalize(method.name)}</td>
                        <td>
                          {method.name !== "app" ? currentUser[methodTypeKeys[method.name]] : ""}
                        </td>
                        <td>
                          {method.name !== "app" && (
                            <Link onClick={(e) => highlightEditBox(e, method)}>Change</Link>
                          )}
                        </td>
                        <td>
                          <Link onClick={(e) => deleteMethod(e, method)}>Delete</Link>
                        </td>
                        {method?.is_primary ? (
                          <td>
                            <p className="mb-0 fw-bold text-bright-navy">Current Primary MFA</p>
                          </td>
                        ) : (
                          <td>
                            <Link onClick={(e) => sendCode(e, method)}>Set Primary</Link>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Button className="mt-5" variant="warning" type="submit">
                Update my Account
              </Button>
            </Col>
          </Row>
        </Form>
        <AddMfa show={show} handleCloseModal={handleCloseModal} />
      </Container>
    </>
  );
};

export default Profile;
