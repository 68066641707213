import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";

export default function NiapCCEVSListingFormSection({product, isResubmission, editedProduct, handleChange}) {

    // handles state for DO NOT LIST radio btn
    // to allow for automatic selection
    const [isChecked, setIsChecked] = useState(false);

    const handleChangeAndCheck = (e) => {
        handleChange(e)
        if(isResubmission === true) {
            // when resubmission is selected set isChecked to true
            // so that DO NOT List is automatically selected
            setIsChecked(true);
        } else {
            if(e.target.value === 'Published'){
                setIsChecked(false)
            } else {
                setIsChecked(true)
            }
        }
    }

    useEffect(() => {
        if(isResubmission === true) {
            // when resubmission is selected set isChecked to true
            // so that DO NOT List is automatically selected
            setIsChecked(true);
        }
        else {
            // handles toggling for radio btns
            if(product?.publish_status !== undefined) {
                if(editedProduct?.publish_status === "Published"){
                    setIsChecked(false)
                } if(product?.publish_status === "Published" && isChecked !== false) {
                    setIsChecked(false);
                } if(product?.publish_status !== "Published" && isChecked !== true) {
                    setIsChecked(true)
                } else {
                    setIsChecked(false)
                }
            }
        }
    }, [isResubmission, product?.publish_status]);

    return (
        <Row>
            <Row>
                <Col>
                    <Row>
                        <p className="fw-bold form-title text-uppercase">
                            { !isResubmission ? "Vendor’s approval to be listed on NIAP In Evaluation:" :
                                "PER NIAP POLICY, RESUBMITTED EVALUATIONS ARE NOT ELIGIBLE TO BE LISTED ON THE IN-EVALUATION LIST ON THE NIAP WEBSITE."
                            }
                        </p>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Form.Group className="my-3 mt-2" controlId="publish_status">
                    <Form.Check
                        inline
                        label={
                            <p className="text-dark">
                                <span className="fw-bold">LIST</span> on NIAP
                                CCEVS website*
                            </p>
                        }
                        name="publish_status"
                        type="radio"
                        value="Published"
                        defaultChecked={product.publish_status === "Published"}
                        onChange={handleChangeAndCheck}
                        id={"publish_status"}
                        required
                        disabled={isResubmission}
                    />
                    <Form.Check
                        inline
                        label={
                            <p className="text-dark">
                                <span className="fw-bold">DO NOT LIST</span> on
                                NIAP CCEVS website*
                            </p>
                        }
                        name="publish_status"
                        type="radio"
                        value="Unpublished"
                        onChange={handleChangeAndCheck}
                        id={"publish_status2"}
                        checked={isChecked}
                        required
                    />
                </Form.Group>
            </Row>
        </Row>
    );
}
