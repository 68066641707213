import { Container, Row, Col } from "react-bootstrap";
import SMETabs from '../SMEModules/SMETabs';
import AlertsModule from "../AlertsModule";

const SMEDashboard = () => {
    return (
        <Container fluid>
            <Row className="my-5">
                <Col>
                    <Row>
                        <Col>
                            <SMETabs />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={6} className="alertsColumn">
                            <AlertsModule />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default SMEDashboard;