import Accordion from 'react-bootstrap/Accordion';
import EventDatesColumn from './EventDatesColumn';
import MultipleEstimatedEventDatesColumn from './MultipleEstimatedEventDatesColumn';

const EventsDatesColumnAccordion = ( { ppData, rowData } ) => {
  return (
    <Accordion id='accordion-custom' data-testid='event_dates_accordion'>
      <Accordion.Item eventKey={rowData?.product_id}>
        <Accordion.Header id='accordion-header'>
        {ppData?.estimated_completion?.length > 0 && (
            <div data-testid={`pp${ppData?.progress_point}_estimated`}>
            <EventDatesColumn ppData={ppData} dateType={'Estimated'}/>
            </div>
        )}
        </Accordion.Header>
        <Accordion.Body id='accordion-body-custom'>
          {ppData?.estimated_completion?.length > 0 &&
            ppData?.estimated_completion?.map((date, index) =>
              (date &&  index > 0) && (
                <div 
                  key={index} 
                  className='me-2 mb-2 mt-2' 
                  data-testid={`pp${ppData?.progress_point}_estimated`}
                >
                  <MultipleEstimatedEventDatesColumn
                    ppData={ppData}
                    date={date}
                    index={index}
                  />
                </div>
              )
            )
          }
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EventsDatesColumnAccordion;
