import { useState, useEffect, useCallback } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import axios from "axios";
import { useCookies } from "react-cookie";
import ReactSelect from "../../ReactSelect"

const Filter = ({filters, setFilters}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const [currentFilters, setCurrentFilters] = useState({...filters, status: filters?.status?.split(',')})
    const [ qaCategories, setQaCategores] = useState([])
    const [formKey, setFormKey] = useState("")

    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            setCurrentFilters({})
        }
    }, [filters])

    const handleChange = (event) => {
        if (event.target.name === "status") {
            let filter = currentFilters[event.target.name]
            if (!filter) {
                filter = [event.target.value]
            } else {
                if (event.target.checked) {
                    filter.push(event.target.value)
                } else {
                    const idx = filter.indexOf(event.target.value)
                    filter.splice(idx, 1)
                }
            }
            setCurrentFilters({...currentFilters, [event.target.name]: filter})
        } else { 
            setCurrentFilters({...currentFilters, [event.target.name]: event.target.value})
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setFilters({...currentFilters, status: currentFilters?.status?.join(','), category: currentFilters?.category?.join(',')})
    }

    const handleClear = (event) => {
        event.preventDefault()
        setCurrentFilters({})
        setFilters({})
        setFormKey(Date.now());
    }

    const fetchQACategories = useCallback(() => {
        const headers = {}
        if (authToken) {
            headers['Authorization'] = `Token ${authToken}`
        }
        axios
            .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/cat/get_all_cats/`, {
                withCredentials: true,
                headers: headers,
            })
            .then((response) => {
                const categories = response.data.map(cat => {return {value: cat.cid, label: cat.cat}})
                setQaCategores(categories);
            })
            .catch((error) => console.log(error));
    }, [])
    
    useEffect(() => {
        fetchQACategories()
    }, []);

    return (
        <Form onSubmit={handleSubmit} id="filters" key={formKey}>
            <Form.Group className="d-inline-block" controlId="from-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="from_date" onChange={handleChange} />
            </Form.Group>
            <p className="d-inline-block p-3">to</p>
            <Form.Group className="d-inline-block" controlId="to-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="to_date" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="date-type" className="mb-3">
                <Form.Label>For The</Form.Label>
                <Form.Select name="date_type" onChange={handleChange}>
                    <option value="">Select...</option>
                    <option value="entrydate">Date of Receipt</option>
                    <option value="deadline">Deadline</option>
                    <option value="enddate">Completion/Cancellation</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="rid" className="mb-3">
                <Form.Label>Ticket No.</Form.Label>
                <Form.Control type="text" name="rid" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="req" className="mb-3">
                <Form.Label>Question</Form.Label>
                <Form.Control type="text" name="req" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="agent" className="mb-3">
                <Form.Label>Agent</Form.Label>
                <Form.Control type="text" name="agent" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="custuname" className="mb-3">
                <Form.Label>Customer</Form.Label>
                <Form.Control type="text" name="custuname" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="status" className="mb-3">
                <Form.Label>Status</Form.Label>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Pending Review" label="Pending Review" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Pending Review')}  id="status1" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Assigned" label="Assigned" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Assigned')}  id="status2"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="In Progress" label="In Progress" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'In Progress')}  id="status3"/>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Completed" label="Completed" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Completed')}  id="status4"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Cancelled" label="Cancelled" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Cancelled')}  id="status5"/>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Published" label="Published" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Published')}  id="status6"/>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group controlId="category" className="mb-3">
                <Form.Label>Category</Form.Label>
                <ReactSelect 
                    id={"category"}
                    name="category"
                    handleChange={handleChange}
                    options={qaCategories}
                    newObj={currentFilters}
                    isMulti={true}
                    isValid={true}
                />
            </Form.Group>
            <div className="mb-3">
                <Button type="submit" variant="primary" onClick={handleSubmit}>Apply</Button>
                &nbsp;
                <Button type="reset" variant="outline-dark" onClick={handleClear}>Clear</Button>
            </div>
        </Form>
    )
}

export default Filter