import { Container, Row, Col, Stack, Accordion, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../../Products/Products.css";
import Subheader from "../../Subheader";
import FileDownload from "js-file-download";
import parse from "html-react-parser";
import { useInterval } from "../../../hooks/useInterval";
import moment from "moment";

export default function CheckProductPermDetails({setOverlayActive, setOverlayText}) {
  const { id } = useParams();
  const [hasPerm, setHasPerm] = useState();
  useEffect(() => {
      if (id) {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/is_frontend_pp/?pp_id=${id}`)
        .then(response => {
          if (response.data?.is_frontend_pp === false) {
            setHasPerm(false)
          } else {
            setHasPerm(true)
          }
        }).catch(() => {
          setHasPerm(false)
        });
      }
  }, [id]);

  if (hasPerm === true){
    return (<ProtectionProfileDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />)
  } else if (hasPerm === false) {
    return (<div>Protection Profile does not exist or is not viewable.</div>)
  }
  return (<div></div>)
}

function ProtectionProfileDetails({setOverlayActive, setOverlayText}) {
  const { id } = useParams();
  const [pp, setPp] = useState({});
  const [tds, setTds] = useState([]);
  const [archivedTds, setArchivedTds] = useState([]);
  const [files, setFiles] = useState([]);

  const [associatedEvaluations, setAssociatedEvaluations] = useState([]);
  const [associatedPcmEvaluations, setAssociatedPcmEvaluations] = useState([]);

  const pullFiles = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_public_files_by_type_and_type_id/?file_type=protection-profile&file_type_id=${id}`
      )
      .then((response) => {
        setFiles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading")
    setOverlayActive(true)
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_public_file/?file_id=${file.file_id}`, {
        responseType: "blob",
      })
      .then((response) => {
        setOverlayActive(false)
        FileDownload(response.data, file.file_new_name ? file.file_new_name : file.file_name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    pullFiles();
  }, [pp]);

  useInterval(() => {
    pullFiles();
  }, 300000); //5 min

  const loadTechDecFromProfileId = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_all_archived_or_related_tds_by_id/?profile=${id}`
      )
      .then((response) => {
        setTds(response.data.active)
        setArchivedTds(response.data.archived);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    loadTechDecFromProfileId();
  }, [loadTechDecFromProfileId]);

  const fetchPP = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_pp_by_id/?pp_id=${id}`)
      .then((response) => {
        setPp(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    fetchPP();
  }, [fetchPP]);

  useInterval(() => {
    fetchPP();
  }, 300000); //5 min

  const loadPPId = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_project_pp/?pp=${id}&pcl=true`
      )
      .then((response) => {
        const products = response.data.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (
                t // Get all unique associated products
              ) => t.product__product_id === value.product__product_id
            )
        );
        setAssociatedEvaluations(products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    loadPPId();
  }, [loadPPId]);

  useInterval(() => {
    loadPPId();
  }, 300000); //5 min

  const loadPcmPPId = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/pp/get_all_product_cc_by_pp/?pp=${id}`
      )
      .then((response) => {
        setAssociatedPcmEvaluations(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    loadPcmPPId();
  }, [loadPcmPPId]);

  useInterval(() => {
    loadPcmPPId();
  }, 300000); //5 min

  useEffect(() => {
    document.title = "NIAP - Protection Profile Details";
  }, []);
  return (
    <>
      <Subheader pageName={"Protection Profiles"} />
      <Container className="p-5" fluid>
        <Row className="my-4">
          <Col>
            <h6 className="fw-bold">U.S. Government Approved Protection Profile</h6>
            <h3 className="fw-bold">{pp?.pp_name}</h3>
          </Col>
          {/* <Col className='d-flex flex-wrap'>
            <Stack direction="horizontal" className='ms-auto'>
              <FontAwesomeIcon icon={faFilePdf} color='red' size='xl' />
              <small className='fw-bold me-5 ms-2'>EXPORT PDF</small>
            </Stack>
            <Stack direction="horizontal">
              <FontAwesomeIcon icon={faFileLines} size='xl' />
              <small className='fw-bold ms-2'>EXPORT EXCEL</small>
            </Stack>
          </Col> */}
        </Row>
        <Row className="gx-2">
          <Col sm={12} md={3} lg={2} className="bg-dark p-4 text-white">
            <Stack className="border-bottom pb-4">
              <small className="pb-2">Documents</small>
              {files?.map((file) => (
                <Stack
                  direction="horizontal"
                  className="align-items-center mb-3"
                  key={file.file_id}
                >
                  {file.file_mime_type === "application/pdf" && (
                    <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
                  )}
                  {file.file_mime_type !== "application/pdf" && (
                    <FontAwesomeIcon icon={faFileLines} color="gray" size="xl" />
                  )}
                  {file.file_display_name === "Protection Profile (HTML)" && file.file_name.includes('.htm') && (
                      <Button variant="link"  href={`/static_html/protection-profile/${id}/${file.file_name}`} target='_blank' className="text-white">
                        {file.file_display_name}
                      </Button>
                  )}  
                  {file.file_display_name === "Protection Profile (HTML)" && !file.file_name.includes('.htm') && (
                      <Button variant="link"  href={`/static_html/protection-profile/${id}/${file.file_name}/index.html`} target='_blank' className="text-white">
                        {file.file_display_name}
                      </Button>
                  )}    
                  {file.file_display_name !== "Protection Profile (HTML)" && file.isFolder && (
                      <Button variant="link"  href={`/static_html/protection-profile/${id}/${file.file_name}/index.html`} target='_blank' className="text-white">
                        {file.file_display_name}
                      </Button>
                  )} 
                  {file.file_display_name === "Configuration Document" && !file.isFolder && (
                      <Button variant="link" onClick={() => downloadFile(file)} className="text-white">
                        {file.file_label ? file.file_label : file.file_name}
                      </Button>
                  )} 
                  {file.file_display_name !== "Protection Profile (HTML)" && file.file_display_name !== "Configuration Document" && !file.isFolder && (
                      <Button variant="link" onClick={() => downloadFile(file)} className="text-white">
                        {file.file_display_name}
                      </Button>
                  )}  
                </Stack>
              ))}
            </Stack>
            <Stack className="border-bottom pt-4">
              <small className="pb-2">Short Name</small>
              <p>{pp?.pp_short_name}</p>
              <small className="pb-2">CC Version</small>
              <p>{pp?.cc_version}</p>
              {pp?.pp_transition && 
                <>
                  <small className="pb-2">Transistion Date</small>
                  <p>{moment.utc(pp?.pp_transition).format('MM/DD/YYYY')}</p>
                </>
              }
              {pp?.predecessor_id__sunset_date && 
                <>
                  <small className="pb-2">Predecessor Date</small>
                  <p>{moment.utc(pp?.predecessor_id__sunset_date).format('MM/DD/YYYY')}</p>
                </>
              }
            </Stack>
            <Stack className="border-bottom pt-4">
              <small className="pb-2">Tech Type</small>
              <p>{pp?.tech_type}</p>
            </Stack>
          </Col>
          <Col sm={12} md={9} lg={10} className="px-4">
            <Accordion className="mb-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">PROTECTION PROFILE OVERVIEW</h6>
                </Accordion.Header>
                <Accordion.Body>{pp?.pp_overview && parse(pp?.pp_overview)}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">SECURITY EVALUATION SUMMARY</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {pp?.security_evaluation_summary && parse(pp?.security_evaluation_summary)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">
                    ASSIGNED TO THE FOLLOWING VALIDATED PRODUCTS
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  {associatedEvaluations?.map((projpp) => (
                    <div className="mt-2" key={projpp?.projpp_id}>
                      <Link
                        to={`/products/${projpp?.product__product_id}`}
                        className="text-primary fw-bold ms-3"
                      >
                        VID{projpp?.product__v_id} - {projpp?.product__product_name}
                      </Link>
                      <br></br>
                    </div>
                  ))}
                  {associatedPcmEvaluations?.map((projectcc) => (
                    <div className="mt-2" key={projectcc?.ccid}>
                      <Link
                        to={`/products/international-product/${projectcc?.ccid}`}
                        className="text-primary fw-bold ms-3"
                      >
                        {projectcc?.name}
                      </Link>
                      <br></br>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">RELATED TECHNICAL DECISIONS</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {tds?.map((td) => (
                    <>
                    <Link to={{ pathname: `/technical-decisions/${td?.identifier}` }} key={td?.td_id}>
                      <h6 className="mb-3 ms-3">
                        <strong>
                          {td?.identifier} - {td?.title}
                        </strong>
                        &nbsp;
                        {td.status==="Archived" && <small>(Archived)</small>}
                      </h6>
                    </Link>
                    {td?.related_to && <p className='mb-3 ms-5'>Reference: {td?.related_to}</p>}
                    </>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion><Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">ARCHIVED TECHNICAL DECISIONS</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {archivedTds?.map((td) => (
                    <>
                    <Link to={{ pathname: `/technical-decisions/${td?.identifier}` }} key={td?.td_id}>
                      <h6 className="mb-3 ms-3">
                        <strong>
                          {td?.identifier} - {td?.title}
                        </strong>
                        &nbsp;
                        {td.status==="Archived" && <small>(Archived)</small>}
                      </h6>
                    </Link>
                    {td?.related_to && <p className='mb-3 ms-5'>Reference: {td?.related_to}</p>}
                    </>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}
