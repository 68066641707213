import { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useCookies } from 'react-cookie';

export default function ConfirmModal({show, setShow, deleteRole, setDeleteSuccess,title, buttonText, removeContacts}) {

  const [cookies] = useCookies();
  let authToken = cookies['auth_token'];
  let csrfToken = cookies['csrftoken'];

  const handleClose = () => {
    setShow(false)
    
  };

  const handleDeleteRole = async () => {

    await axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/${deleteRole.role_id}/`,
    {
      ...deleteRole,
      deleted: true
    },
    {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})
    .then(response => {
      setDeleteSuccess(response.data.role_id)
      
    })
    .catch(error => console.log("Unable to delete role: " + error));
    handleClose();
  }




  return (
    <>
      <Modal show={show} onHide={handleClose}  aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><h4 className="text-center">{title}</h4></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} >
            Cancel
          </Button>
          <Button variant="danger" onClick={removeContacts ? removeContacts : handleDeleteRole}>
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

