import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import axios from "axios";
import { useCookies } from "react-cookie";
import ReactSelect from "../../ReactSelect";

const EditOrgProfile = ({showModal, refresh, org, closeModal}) => {
    const [cookies] = useCookies();
    let csrfToken = cookies['csrftoken'];
    let authToken = cookies['auth_token'];
    const [editedOrg, setEditedOrg] = useState({});
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [stateValid, setStateValid] = useState(true);
    const [countryValid, setCountryValid] = useState(true);
    const [errors, setErrors] = useState({})

    useEffect(() => {
      setEditedOrg({ ...org });
    }, [org]);

    const handleChange = (e) => {
        if (e.target.name === 'primary_phone') {
          setErrors({...errors, [e.target.name]: !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(e.target.value)})
        }
        if (e.target.name === 'logo_file') {
          setEditedOrg({ ...editedOrg, [e.target.name]: e.target.files[0] });
        } else {
          setEditedOrg({ ...editedOrg, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
      setStateValid(true);
      setCountryValid(true);
    }, [showModal])

    const testReactSelects = () => {
      if(editedOrg.primary_country !== "" && editedOrg.primary_state !== ""){
        return true;
      } else {
        if(editedOrg.primary_country === ""){
          setCountryValid(false);
        }
        if(editedOrg.primary_state === ""){
          setStateValid(false);
        }
        return false
      }
    }

    const checkPhones = () => {
      if (editedOrg?.primary_phone && !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(editedOrg?.primary_phone)) {
        return false
      }
      else {
        return true
      }
    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault()
        testReactSelects()
        if (!form.checkValidity() || testReactSelects() === false || checkPhones() === false) {
            e.stopPropagation();
        } else {
            const submittedData = new FormData()
            for (let key in editedOrg) {
            submittedData.append(key, editedOrg[key])
            }
            axios
            .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/${editedOrg.org_id}/`,
            submittedData,
            {
                withCredentials: true,
                headers: { 
                Authorization: `Token ${authToken}`,
                "X-CSRFToken": csrfToken,
                'Content-Type': 'multipart/form-data'
                },
            }
            )
            .then((response) => {
                refresh()
                closeModal()
            })
            .catch((error) => console.log("Unable to update organization: " + error));
        }
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}address/states/get_all_states/`, {
          withCredentials: true,
          headers: {
            "Authorization": `Token ${authToken}`
          }
        })
        .then((response) => {
          const states = response.data.map(data => {
            return {value: data.us_state_abbrv, label: data.us_state_name}
          })
          setStates(states)
        })
        .catch((error) => console.log(error))
    }, [])
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}address/country/get_all_countries/`, {
          withCredentials: true,
          headers: {
            "Authorization": `Token ${authToken}`
          }
        })
        .then((response) => {
          const countries = response.data.map(data => {
            return {value: data.code2_iso, label: data.name}
          })
          setCountries(countries)
        })
        .catch((error) => console.log(error))
    }, [])

    return (
        <Modal show={showModal} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Company Profile</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group controlId="logo">
                        <Form.Label>{org.logo ? 'Edit' : 'Add'} Company Logo For {org?.name}</Form.Label>
                        <Row >
                            <Col 
                              sm={12}
                              md={6}
                              className="d-flex justify-content-center align-items-center" 
                              style={{height: "150px"}}
                            >
                                {org.logo ? 
                                  <img 
                                    src={org.logo}
                                    alt={org?.name + ' logo'} 
                                    style={{maxWidth: '50%', maxHeight: '50%'}}
                                  />
                                :
                                  <div>No Logo Set</div>
                                }
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center" sm={12} md={6}>
                                <Form.Control type="file" name="logo_file" onChange={handleChange} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Organization Type: {org?.org_type}</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="addressLine1">
                        <Form.Label>Company Name*</Form.Label>
                        <Form.Control
                        type="text"
                        name="name"
                        defaultValue={org.name}
                        onChange={handleChange}
                        placeholder="Name"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="addressLine1">
                        <Form.Label>Website*</Form.Label>
                        <Form.Control
                        type="text"
                        name="website"
                        defaultValue={org.website}
                        onChange={handleChange}
                        placeholder="Website"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="addressLine1">
                        <Form.Label>Address Line 1*</Form.Label>
                        <Form.Control
                        type="text"
                        name="primary_address_1"
                        defaultValue={org.primary_address_1}
                        onChange={handleChange}
                        placeholder="Address Line 1"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="addressLine2">
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control
                        type="text"
                        name="primary_address_2"
                        defaultValue={org.primary_address_2}
                        onChange={handleChange}
                        placeholder="Address Line 2"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="city">
                        <Form.Label>City*</Form.Label>
                        <Form.Control
                        type="text"
                        name="primary_city"
                        defaultValue={org.primary_city}
                        onChange={handleChange}
                        placeholder="City"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>State*</Form.Label>
                        <ReactSelect
                          options={states}
                          newObj={editedOrg}
                          handleChange={handleChange}
                          name="primary_state"
                          isValid={stateValid}
                          defaultValue={states?.find(state => state?.value === editedOrg?.primary_state)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="country">
                        <Form.Label>Country*</Form.Label>
                        <ReactSelect
                          options={countries}
                          newObj={editedOrg}
                          handleChange={handleChange}
                          name="primary_country"
                          isValid={countryValid}
                          defaultValue={countries?.find(country => country?.value === editedOrg?.primary_country)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="zipCode">
                        <Form.Label>Zip*</Form.Label>
                        <Form.Control
                        type="text"
                        name="primary_zip"
                        defaultValue={org.primary_zip}
                        onChange={handleChange}
                        placeholder="Postal"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="poc">
                        <Form.Label>POC*</Form.Label>
                        <Form.Control
                        type="text"
                        name="poc"
                        defaultValue={org.poc}
                        onChange={handleChange}
                        placeholder="Point of Contact"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                        <Form.Label>Phone*</Form.Label>
                        <Form.Control
                        type="tel"
                        name="primary_phone"
                        defaultValue={org.primary_phone}
                        onChange={handleChange}
                        placeholder="111-111-1111"
                        isInvalid={errors.primary_phone} 
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                        <Form.Label>Ext.</Form.Label>
                        <Form.Control
                        type="text"
                        name="ext_phone"
                        defaultValue={org.ext_phone}
                        onChange={handleChange}
                        placeholder="Ext. 1111"
                        isInvalid={errors.ext_phone} 
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="poc_email">
                        <Form.Label>POC Email*</Form.Label>
                        <Form.Control
                        type="email"
                        name="poc_email"
                        defaultValue={org.poc_email}
                        onChange={handleChange}
                        placeholder="POC Email"
                        required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={closeModal}>Cancel</Button>
                    <Button type='submit'>Update Organization</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default EditOrgProfile