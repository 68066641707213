import { Modal, Container, Row, Col } from 'react-bootstrap';
import inEvalPPImg from '../../../../images/pp_page/inEvalPP.png';

export default function ApprovedPPModal({show, setShowInEvalPPModal}) {

  const handleClose = () => {
    setShowInEvalPPModal(false);
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
    <Modal.Header className='border-0' closeButton></Modal.Header>
    <Modal.Body className='px-5'>
    <Container fluid>
      <Row>
        <Col>
          <Row>
            <Col>
              <div className='d-flex justify-content-center'>
                <img
                  src={inEvalPPImg}
                  alt='in evaluation protection profile image'
                  className='w-auto'
                  height='120px'
                />
              </div>
              <div className='text-center'>
                <h3 className='text-uppercase fw-bold mt-3 mb-4 text-primary'>
                  Protection Profiles in Evaluation
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <p>
              The security products listed are in various stages of NIAP
              evaluation. There is no guarantee that any of these products will
              complete evaluation successfully.
            </p>
          </Row>
          <Row className='mb-5'>
            <p>
              In order for a product to be placed on this list the product must
              currently be undergoing evaluation in the United States with a
              NIAP-approved Common Criteria Testing Laboratory (CCTL) and have
              completed an Evaluation Kick off Meeting (or equivalent) whereby
              CCEVS management has officially accepted the product into
              evaluation. CCEVS will only accept new products to be posted to
              this site through a NIAP-approved CCTL. Contact your CCTL to
              submit evaluation acceptance paperwork to CCEVS through
              appropriate channels. Please be aware that information in entries
              listed below such as product or protection profile names,
              versions, etc. may change as the evaluation progresses; therefore,
              the viewer should always confirm the below entry information with
              the sponsor contact. Entries on this list will be removed when
              either a Common Criteria Certificate has been issued for the
              product, or the CCTL or vendor is no longer pursuing evaluation of
              the product under CCEVS procedures.
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
    </Modal.Body>
    </Modal>
  );
}
