import { Route, Routes } from "react-router-dom";
import TechnicalQueriesTable from "./TechnicalQueriesTable";
import TRRTDetailsPage from "./TRRTDetailsPage";
import UnauthorizedView from "../../UI/UnauthorizedView"
import { useSelector } from "react-redux";

export default function TechnicalQueryManager({setOverlayActive, setOverlayText}) {
  const permissions = useSelector(state => state.role.value);

  if (!permissions?.role_permissions?.find(permission => permission.area === 'Technical Questions')?.read) {
      return (
          <UnauthorizedView />
      )
  }

  return (
    <Routes>
        <Route path="/" element={<TechnicalQueriesTable />} />
        <Route path="details/:id" element={<TRRTDetailsPage setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
    </Routes>
  )
}
