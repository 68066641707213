import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
} from "react-bootstrap";
import "../../Products/Products.css";
import Filter from "./Filter";
import PPGridView from "./UI/PPGridView";
import PPListView from "./UI/PPListView";
import PPTableView from "./UI/PPTableView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faBorderAll, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Subheader from "../../Subheader";
import PPProcessModal from "./PPProcessModal";
import developmentProcessImg from "../../../images/pp_page/developmentProcess.png";
import approvedPPImg from "../../../images/pp_page/approvedCCTL.png";
import archivedPPImg from "../../../images/pp_page/archivedPP.png";
import ApprovedPPModal from "./PPModals/ApprovedPPModal";
import InEvalPPModal from "./PPModals/InEvalPPModal";
import ArchivedPPModal from "./PPModals/ArchivedPPModal";
import { useInterval } from "../../../hooks/useInterval";
import qs from "qs";
import moment from "moment";

export default function ProtectionProfiles() {
  const [ppList, setPpList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const PAGE_SIZE = 6;
  const [tablePageSize, setTablePageSize] = useState()
  const [showAll, setShowAll] = useState(true)
  const [ppCount, setPpCount] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [filters, setFilters] = useState({ status: "Publishing", archived: "false", not_sunset: "True" });
  const [search, setSearch] = useState("");
  const [ppView, setPPView] = useState("Table");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [removeLine, setRemoveLine] = useState(false);
  const [ppFilterOptions, setPpFilterOptions] = useState([
    { name: "tech_type", label: "Technology Type", options: [] },
    { name: "cc_version", label: "CC Version", options: [] },
    { name: "sunset_date", label: "Sunset Date", options: [] },
  ]);

  const [showApprovedPPModal, setShowApprovedPPModal] = useState(false);
  const [showInEvalPPModal, setShowInEvalPPModal] = useState(false);
  const [showArchivedPPModal, setShowArchivePPModal] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth <= 992) {
      setRemoveLine(true);
    } else {
      setRemoveLine(false);
    }
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleProductDisplay = (view) => {
    setPPView(view);
    if (view === "Table") setShowFilters(false);
  };
  const handleShowFilter = () => setShowFilters(!showFilters);
  const handleSetShowProcess = () => setShowProcessModal(!showProcessModal);

  const handleChangeFilters = (name, value) => {
    if (name === "status") {
      filters["archived"] = "false";
      filters["not_sunset"] = "True";
    } else if (name === "archived") {
      delete filters["status"];
      delete filters["not_sunset"]
    }
    setFilters({ ...filters, [name]: value });
  };

  const ppStatuses = [
    { name: "status", value: "Publishing", display: "Approved" },
    // { name: "status", value: ["Planning", "Development", "Initiation"], display: "In Development" },
    { name: "archived", value: "true", display: "Archived" },
  ];

  const loadPPCounts = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_pp_counts/`)
      .then((response) => {
        setPpCount(response.data);
        if(filters.status === 'Publishing' && showAll === true){
          setTablePageSize(response.data.approved)
        }
        else if(filters.archived === 'true' && showAll === true){
          setTablePageSize(response.data.archived)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadProtectionProfile = () => {
    let orderBy = filters.status === "Publishing" ? "pp_date" : "tech_type"
    let orderByDirection = filters.status === "Publishing" ? "desc" : "asc"
    let params = {
      ...filters,
      limit: PAGE_SIZE,
      offset: offset,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/frontend_pps/?${qs.stringify(
          params,
          { arrayFormat: "comma" }
        )}`
      )
      .then((response) => {
        const filterOptions = [...ppFilterOptions];
        filterOptions[0].options = response.data?.results?.tech_types.map((tech_type) => ({
          label: tech_type,
          value: tech_type,
        }))?.sort((a, b) => a.label?.localeCompare(b.label))
        filterOptions[1].options = response.data?.results?.cc_version.map((cc_version) => ({
          label: cc_version,
          value: cc_version,
        }))?.sort((a, b) => a.label?.localeCompare(b.label))
        filterOptions[2].options = response.data?.results?.sunset_date.map((sunset_date) => ({
          label: sunset_date ? moment(sunset_date)?.format("MM/DD/YYYY") : "No Sunset Date",
          value: sunset_date,
        }))?.sort((a, b) => new Date(b.value) - new Date(a.value))
        setPpFilterOptions([...filterOptions]);
        setPpList(response.data?.results?.pps);
        setTotal(response.data?.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handles logic for displaying the protection profiles
  // sub header text that follows "PROTECTION PROFILES -"
  const showProtectionProfileSubHeader = () => {
    let subHeaderText = "";
    const subNavText = [
      "Approved Protection Profiles",
      "Protection Profiles In Development",
      "Archived Protection Profiles",
    ];
    if (filters.status === "Publishing") {
      subHeaderText = subNavText[0];
    } else if (filters.status?.includes("Development")) {
      subHeaderText = subNavText[1];
    } else if (filters.archived === "true") {
      subHeaderText = subNavText[2];
    }
    return subHeaderText;
  };

  useEffect(() => {
    loadPPCounts();
    loadProtectionProfile();

  }, [offset, filters]);

  useInterval(() => {
    loadProtectionProfile();
    loadPPCounts();
  }, 300000); //5 min

  useEffect(() => {
    document.title = "NIAP - Protection Profiles";
  }, []);

  return (
    <>
      <PPProcessModal
        show={showProcessModal}
        handleCloseModal={handleSetShowProcess}
        ppList={ppList}
      />
      <ApprovedPPModal show={showApprovedPPModal} setShowApprovedPPModal={setShowApprovedPPModal} />
      <InEvalPPModal show={showInEvalPPModal} setShowInEvalPPModal={setShowInEvalPPModal} />
      <ArchivedPPModal show={showArchivedPPModal} setShowArchivedPPModal={setShowArchivePPModal} />
      <Subheader pageName={"Protection Profiles"} />
      <Container fluid>
        <Row className="px-5 pt-2">
          <Col>
            <Row>
              <h4 className="text-black fw-bold mb-3" data-testid="profilesHeaderText">
                PROTECTION PROFILES -{" "}
                <span className="text-bright-navy fw-bold">
                  {showProtectionProfileSubHeader()?.toUpperCase()}
                </span>
              </h4>
            </Row>
            <Row>
              {filters.status === "Publishing" ?
              <p>
                To ensure consistency in evaluations, all schemes currently evaluating or
                considering evaluation of products compliant with NIAP-approved Protection Profiles
                (PP) should notify niap@niap-ccevs.org. The notification should include the product
                name, vendor, evaluation start date, and NIAP-approved PP/EP with which compliance
                is being claimed. NIAP will collaborate with the scheme to address questions,
                provide guidance, and solicit feedback for improvements to the PPs.
                </p>
                :
                <p>
                  Protection Profiles (PPs) listed on this page are for reference
                  only and are not to be used as the basis for new evaluations in NIAP.
                  PPs are reviewed periodically to determine if the security functional and 
                  assurance requirements are still acceptable, given rapid technology changes and 
                  increasing threat levels.
                </p>
              }
            </Row>
            <Row className="mb-5">
              <Col md={6} lg={2}>
                <div className="d-flex justify-content-center icon-border">
                  <img
                    src={developmentProcessImg}
                    alt="development process"
                    className="w-auto"
                    height="120px"
                  />
                </div>
                <div className="text-center">
                  <h6 className="text-uppercase fw-bold mt-3 mb-3 text-primary">
                    Development Process
                  </h6>
                  <Button variant="warning" onClick={handleSetShowProcess}>
                    Learn More
                  </Button>
                </div>
              </Col>
              {!removeLine && (
                <Col>
                  <div className="horizontal-line"></div>
                </Col>
              )}
              <Col md={6} lg={2}>
                <div className="d-flex justify-content-center icon-border">
                  <img
                    src={approvedPPImg}
                    alt="approved protection profile"
                    className="w-auto"
                    height="120px"
                  />
                </div>
                <div className="text-center">
                  <h6 className="text-uppercase fw-bold mt-3 mb-3 text-primary">
                    Approved Protection Profiles
                  </h6>
                  <Button variant="warning" onClick={() => setShowApprovedPPModal(true)}>
                    Learn More
                  </Button>
                </div>
              </Col>
              {!removeLine && (
                <Col>
                  <div className="horizontal-line"></div>
                </Col>
              )}
              <Col md={6} lg={2}>
                <div className="d-flex justify-content-center icon-border">
                  <img
                    src={archivedPPImg}
                    alt="certify product"
                    className="w-auto"
                    height="120px"
                  />
                </div>
                <div className="text-center">
                  <h6 className="text-uppercase fw-bold mt-3 text-primary">
                    Archived Protection Profiles
                  </h6>
                  <Button variant="warning" onClick={() => setShowArchivePPModal(true)}>
                    Learn More
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="px-5 pb-5 justify-content-between align-items-center">
          <Col className="mt-4">
            {filters.status === "Publishing" && (
              <h3 className="fw-bold text-primary text-uppercase">
                Approved Protection Profiles - <span>{total}</span>
              </h3>
            )}
            {/* {filters.status?.includes("Development") && (
              <h3 className="fw-bold text-primary text-uppercase">
                In Development PPs<span className="border my-2 mx-4 p-2">{total}</span>
              </h3>
            )} */}
            {filters.archived === "true" && (
              <h3 className="fw-bold text-primary text-uppercase">
                Archived Protection Profiles - <span>{total}</span>
              </h3>
            )}
            {!filters.status && !filters.archived && (
              <h3 className="fw-bold text-primary text-uppercase">
                All Protection Profiles - <span>{total}</span>
              </h3>
            )}
          </Col>
          <Col className="mt-4 text-end">
            <ButtonGroup size="md">
              <Button
                variant={filters.status === "Publishing" ? "primary" : "light"}
                className="me-4 rounded-0 shadow"
                onClick={() => handleChangeFilters("status", "Publishing")}
              >
                <h2 className="text-gray-400">{ppCount.approved}</h2>
                <p className="m-0">Approved Protection Profiles</p>
              </Button>
              {/* <Button
                variant={filters.status?.includes("Development") ? "primary" : "light"}
                className="me-4 rounded-0 shadow"
                onClick={() =>
                  handleChangeFilters("status", ["Planning", "Development", "Initiation"])
                }
              >
                <h2 className="text-gray-400">{ppCount.in_dev}</h2>
                <p className="m-0">Protection Profiles In Development</p>
              </Button> */}
              <Button
                variant={filters.archived === "true" ? "primary" : "light"}
                className="me-4 rounded-0 shadow"
                onClick={() => handleChangeFilters("archived", "true")}
              >
                <h2 className="text-gray-400">{ppCount.archived}</h2>
                <p className="m-0">Archived Protection Profiles</p>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="p-0 my-4">
            <Filter
              show={showFilters}
              handleShowFilter={handleShowFilter}
              handleChangeFilters={handleChangeFilters}
              clearFilters={() => setFilters({})}
              filters={filters}
              filterOptions={ppFilterOptions}
              statusOptions={ppStatuses}
            />
          </Col>
          <Col md={showFilters ? 9 : 12} className="p-0 my-4">
            <Row className="justify-content-between g-3">
              <Col sm={12} md={3} className="d-flex justify-content-start">
                <Stack direction="horizontal">
                  {!showFilters && (
                    <Button
                      className="ms-5 my-1 border-dark"
                      variant="light"
                      size="sm"
                      onClick={handleShowFilter}
                    >
                      Show Filter
                    </Button>
                  )}
                </Stack>
              </Col>
              <Col sm={12} md={9} className="d-flex flex-wrap justify-content-start">
                <ToggleButtonGroup type="radio" name="options" defaultValue={1} className="px-4">
                  {ppView === "Table" &&
                    <Button
                      variant="link"
                      className='text-dark' 
                      onClick={() => {
                        setShowAll(!showAll);
                        setTablePageSize(showAll ? null : filters.status === "Publishing" ? ppCount.approved : filters.status?.includes("Development") ? ppCount.in_dev : ppCount.archived);
                      }}
                    >
                      {showAll ? "Cancel " : ""}Show All
                    </Button>
                  }
                  <ToggleButton
                    variant="light"
                    className="toggle-buttons-hover d-flex justify-content-center p-2"
                    value={1}
                    id="tbg-btn-1"
                    onClick={(e) => handleProductDisplay("Table")}
                    size="sm"
                    active={ppView === "Table"}
                  >
                    <FontAwesomeIcon
                      className="align-self-center p-0 m-0"
                      icon={faList}
                      size="lg"
                    />
                    <span className="visually-hidden">Table View</span>
                  </ToggleButton>
                  <ToggleButton
                    variant="light"
                    className="toggle-buttons-hover d-flex justify-content-center p-2"
                    value={2}
                    id="tbg-btn-2"
                    onClick={(e) => handleProductDisplay("List")}
                    size="sm"
                    active={ppView === "List"}
                  >
                    <FontAwesomeIcon
                      className="align-self-center p-0 m-0"
                      icon={faLayerGroup}
                      size="lg"
                    />
                    <span className="visually-hidden">List View</span>
                  </ToggleButton>
                  <ToggleButton
                    variant="light"
                    className="toggle-buttons-hover d-flex justify-content-center p-2"
                    value={3}
                    id="tbg-btn-3"
                    onClick={(e) => handleProductDisplay("Grid")}
                    size="sm"
                    active={ppView === "Grid"}
                  >
                    <FontAwesomeIcon
                      className="align-self-center p-0 m-0"
                      icon={faBorderAll}
                      size="lg"
                    />
                    <span className="visually-hidden">Grid View</span>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {ppView === "Grid" && (
                  <PPGridView
                    items={ppList}
                    setOffset={setOffset}
                    offset={offset}
                    total={total}
                    PAGE_SIZE={PAGE_SIZE}
                    filters={filters}
                    search={search}
                  />
                )}
                {ppView === "List" && (
                  <PPListView
                    items={ppList}
                    setOffset={setOffset}
                    offset={offset}
                    total={total}
                    PAGE_SIZE={PAGE_SIZE}
                    filters={filters}
                    search={search}
                  />
                )}
                {ppView === "Table" && <PPTableView sidebarFilters={filters} tablePageSize={tablePageSize} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
