import { Modal } from 'react-bootstrap';
import ApprovedProductsLogo from "../../../../images/products/ApprovedProducts.png";

export default function ApprovedProductsModal({showModal, handleShowModal}) {
  return (
    <Modal show={showModal} onHide={handleShowModal} size='lg'>
        <Modal.Header className='border-0' closeButton></Modal.Header>
        <Modal.Body className='px-5'>
            <div className="text-center"><img src={ApprovedProductsLogo} height='150px' alt='Compliant Products Logo' /></div>
            <h2 className="text-uppercase fw-bold text-primary text-center pb-3">PRODUCT COMPLIANT LIST</h2>
            <p>The products listed on the Product Compliant List (PCL) must be considered in the context of the environment of use, including appropriate risk analysis and system 
                accreditation requirements. Customers must ensure that the products selected will provide the necessary security functionality for 
                their architecture.</p>
            <p>The products listed on the PCL, are evaluated and granted certificates by NIAP or under CCRA partnering schemes, comply with the requirements of the 
                NIAP program and, where applicable, the requirements of the Federal Information Processing Standard (FIPS) Cryptographic validation program(s). 
                Products on the PCL are evaluated and accredited at licensed/approved evaluation facilities for conformance to the Common Criteria for IT Security
                Evaluation (ISO Standard 15408). U.S. Customers (designated approving authorities, authorizing officials, integrators, etc.) may treat these 
                mutually-recognized evaluation results as complying with the Committee on National Security Systems Policy (CNSSP) 11, National Policy Governing
                the Acquisition of Information Assurance (IA) and IA-Enabled Information Technology Products - dated June 2013 (https://www.cnss.gov/CNSS/issuances/Policies.cfm). </p>
            <p>NIAP has implemented the CCRA Management Committee Vision Statement for the application of the CC and the CCRA and no longer evaluates 
                against Evaluation Assurance Levels (EAL). This strengthens evaluations by focusing on technology specific security requirements. The products 
                listed below are evaluated against a NIAP-approved Protection Profile, which encompasses the security requirements and test activities suitable 
                across the technology with no EAL assigned - hence the conformance claim is “PP”.</p>
        </Modal.Body>
    </Modal>
  );
}
