import logo from '../../images/logo.png';
import ccLogo from '../../images/cc-logo.png';
import nsaLogos from '../../images/nsaLogos.png';
import magnify from '../../images/magnify.png';
import profile from '../../images/profile.png';
import jira from '../../images/JiraRound.png';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { Nav, Navbar, NavDropdown, Button, OverlayTrigger, Popover  } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './NavigationBar.css';
import { useCookies } from 'react-cookie';
import { SearchBox } from 'react-instantsearch-dom';
import ContactUsForm from '../ContactUs/ContactUsForm';
import axios from "axios";

export default function NavigationBar() {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const role = useSelector(state => state.role.value)
  const location = useLocation();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  let authToken = cookies['auth_token'];

  const handleCloseContactUsModal = () => {
      setShowContactUsModal(false);
  };

  return (
      <Navbar expand='xl' bg='dark' variant='dark'>
        <ContactUsForm handleClose={handleCloseContactUsModal} show={showContactUsModal} />
        <Navbar.Brand className='d-flex'>
          <img src={nsaLogos} className='me-3 mx-3' height='35px' alt='NSA logos' />
          <OverlayTrigger 
                trigger={["hover", "hover"]} 
                rootClose 
                placement='bottom' 
                overlay={
                  <Popover
                      style={{minWidth: '200px', maxWidth: '600px', width: 'max-content'}}
                  >
                    <Popover.Body>
                    <>Navigate to NIAP Homepage</>
                    </Popover.Body>
                  </Popover>
                }
            >
          <Nav.Link href='/'>
          <img
              src={logo}
              className='me-3'
              height='35px'
              alt='NIAP Logo'
            />
          </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger 
                trigger={["hover", "hover"]} 
                rootClose 
                placement='bottom' 
                overlay={
                  <Popover
                      style={{minWidth: '200px', maxWidth: '600px', width: 'max-content'}}
                  >
                    <Popover.Body>
                    <>Navigate to the CC Portal</>
                    </Popover.Body>
                  </Popover>
                }
            >
          <Nav.Link href='https://www.commoncriteriaportal.org' target="_blank">
            <img src={ccLogo} height='35px' alt='Common Criteria Logo' />
          </Nav.Link>
          </OverlayTrigger>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' className='ml-auto me-4' />
        <Navbar.Collapse id='navbarCollapse' className='py-2 flex-column'>
          <Nav className='ms-auto align-items-center' id='hide-dropdown'>
          <OverlayTrigger 
                trigger={["hover", "hover"]}
                rootClose 
                placement='bottom' 
                overlay={
                  <Popover
                      style={{minWidth: '200px', maxWidth: '600px', width: 'max-content'}}
                  >
                    <Popover.Body>
                    <>Contact Us Here!</>
                    </Popover.Body>
                  </Popover>
                }
            >
              <ConnectWithoutContactIcon className='text-white clickable' style={{width: "37.27", height: "auto"}} onClick={() => setShowContactUsModal(true)}/>
            </OverlayTrigger>
            <OverlayTrigger 
                trigger={["hover", "hover"]}
                rootClose 
                placement='bottom' 
                overlay={
                  <Popover
                      style={{minWidth: '200px', maxWidth: '600px', width: 'max-content'}}
                  >
                    <Popover.Body>
                    <>Search Site Terms Here!</>
                    </Popover.Body>
                  </Popover>
                }
            >
              <Nav.Link onClick={() => setShowSearch(!showSearch)}>
              <img
                src={magnify}
                className='bg-transparent border-0 mx-3 p-0'
                height='25px'
                width='25px'
                alt='Search'
              />
              </Nav.Link>
            </OverlayTrigger>
            {showSearch && <SearchBox onSubmit={(e) => {
              e.preventDefault(); 
              navigate('/search')
            }}/>}
          <Nav.Link href="https://niap.atlassian.net/servicedesk/customer/portals" target='_blank'>
            <OverlayTrigger 
                trigger={["hover", "hover"]}
                rootClose 
                placement='bottom' 
                overlay={
                  <Popover
                      style={{minWidth: '200px', maxWidth: '600px', width: 'max-content'}}
                  >
                    <Popover.Body>
                    <>Submit a Ticket To Our Service Desk Here!</>
                    </Popover.Body>
                  </Popover>
                }
            >
                <img src={jira} height='35px' alt="Jira Service Desk" />
            </OverlayTrigger>
            </Nav.Link>
            <Nav.Link
              href={authToken ? '/logout' : '/login'}
              id='login-nav'
            >
              <Button className='btn-warning rounded-pill me-3'>
                {authToken ? 'Logout' : 'Login'}
              </Button>
            </Nav.Link>
            {authToken && 
            <OverlayTrigger 
                trigger={["hover", "hover"]}
                rootClose 
                placement='bottom' 
                
                overlay={
                  <Popover
                      style={{minWidth: '200px', maxWidth: '600px', width: 'max-content'}}
                  >
                    <Popover.Body>
                    <>View Account here!</>
                    </Popover.Body>
                  </Popover>
                }
            >
            <Nav.Link href='/profile' id='profile-nav'>
              <img
                src={profile}
                className='bg-transparent border-0 me-3 p-0'
                height='30px'
                width='30px'
                alt='Profile'
              />
            </Nav.Link>
            </OverlayTrigger>
              }
          </Nav>
          <Nav className='white-text-nav ms-auto border-bottom border-white'>
            <Nav.Link href='/' eventKey="home" className={`mx-3 ${['/', ''].includes(location.pathname) ? "active-navlink" : ""}`}>
              HOME
            </Nav.Link>
            <NavDropdown title='About Us' className={`mx-3 ${['/terms-acronyms', '/ccra', '/evaluation-process', '/niap-ccevs'].includes(location.pathname) ? "active-navlink" : ""}`} id="dropdown-about-us">
              <NavDropdown.Item href='/niap-ccevs' className='mx-3'>
                WHAT IS NIAP/CCEVS
              </NavDropdown.Item>
              <NavDropdown.Item href='/evaluation-process' className='mx-3'>
                EVALUATION PROCESS
              </NavDropdown.Item>
              <NavDropdown.Item href='/ccra' className='mx-3' style={{whiteSpace: 'normal'}}>
              COMMON CRITERIA RECOGNITION ARRANGEMENT
              </NavDropdown.Item>
              <NavDropdown.Item
                href='/terms-acronyms'
                className='mx-3'
              >
                TERMS AND ACRONYMS
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href='/products' eventKey="products" className={`mx-3 ${location.pathname.includes("/products") && !location.pathname.includes("community") ? "active-navlink" : ""}`}>
              PRODUCTS
            </Nav.Link>
            <NavDropdown title='Protection Profiles' id="dropdown-pp" className={`mx-3 ${["/protectionprofiles", "/technical-communities", "/trrt", "/technical-decisions"].includes(location.pathname) ? "active-navlink" : ""}`}>
              <NavDropdown.Item
                href='/protectionprofiles'
                className='mx-3'
              >
                NIAP APPROVED PP
              </NavDropdown.Item>
              <NavDropdown.Item href='/technical-communities' className='mx-3'>
                TECHNICAL COMMUNITIES
              </NavDropdown.Item>
              <NavDropdown.Item href='/trrt' className='mx-3'>
                TECHNICAL RAPID RESPONSE TEAM
              </NavDropdown.Item>
              <NavDropdown.Item href='/technical-decisions' className='mx-3'>
                TECHNICAL DECISIONS
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Resources' id="dropdown-resources" className={`mx-3 ${["/events", "/policies", "/publications", "/formsandtemplates", "/labgrams", "/cctl", "/announcements"].includes(location.pathname) ? "active-navlink" : ""}`}>
              <NavDropdown.Item href='/policies' className='mx-3'>
                POLICIES
              </NavDropdown.Item>
              <NavDropdown.Item href='/publications' className='mx-3'>
                PUBLICATIONS
              </NavDropdown.Item>
              <NavDropdown.Item
                href='/formsandtemplates'
                className='mx-3'
              >
                FORMS AND TEMPLATES
              </NavDropdown.Item>
              <NavDropdown.Item href='/labgrams' className='mx-3'>
                LABGRAMS
              </NavDropdown.Item>
              <NavDropdown.Item href='/cctl' className='mx-3'>
                COMMON CRITERIA <br />
                TESTING LABORATORIES (CCTL)
              </NavDropdown.Item>
              <NavDropdown.Item href='/events' className='mx-3'>
                EVENTS
              </NavDropdown.Item>
              <NavDropdown.Item href='/announcements' className='mx-3'>
                NEWS
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href='/faqs' eventKey="faqs" className={`mx-3 ${location.pathname.includes("/faqs") ? "active-navlink" : ""}`}>
              FAQ
            </Nav.Link>
            { (authToken && role?.role_type !== 'External') && 
              <Nav.Link href='/community' className={`mx-3 ${location.pathname.includes("/community") ? "active-navlink" : ""}`} >
                Member Portal
              </Nav.Link>
            }
            <Nav.Link
              href={authToken ? '/logout' : '/login'}
              id='login-dropdown'
              className='mx-3'
            >
              {authToken ? 'Logout' : 'Login'}
            </Nav.Link>
            {authToken && <Nav.Link
              href='/profile'
              id='profile-dropdown'
              className='mx-3'
            >
              Profile
            </Nav.Link>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
}
