import { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const NISTDateModal = ({show, onHide, certReview, update}) => {
    const [cookies] = useCookies();
    let csrfToken = cookies['csrftoken'];
    let authToken = cookies['auth_token'];
    const [ newDate, setNewDate ] = useState({})

    const handleChange = (event) => {
        setNewDate({...newDate, [event.target.name]: event.target.value})
    }

    const handleReset = () => {
        setNewDate({})
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        axios.put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview?.crid}/`,
            {
                ...newDate, moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) 
            },
            {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                },
            }
        ).then(response => {
            update()
            onHide()
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>Edit NIST Termination/Completion Date Details for VID {certReview?.pid?.v_id}</Modal.Header>
                 <Modal.Body>
                    <Form.Group controlId='note'>
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" name="enddate" defaultValue={moment.utc(certReview?.enddate).format('YYYY-MM-DD')} onChange={handleChange} />
                    </Form.Group>
                 </Modal.Body>
                <Modal.Footer>
                    <Button type="reset" onClick={handleReset} variant='primary'>Reset</Button>
                    <Button onClick={onHide} variant='danger'>Cancel</Button>
                    <Button type="submit" onClick={handleSubmit} variant='success'>Update</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NISTDateModal