
import moment from 'moment';

const EventDatesColumn = ( { ppData, dateType } ) => {
  return (
    <>
      {ppData &&
        <>
          <span style={{marginRight:'3px'}}>{`${ppData.progress_point}.`}</span>
          {dateType}:
          <span style={{marginLeft:'3px', marginRight:'5px'}}>
            {dateType === 'Estimated' &&
              moment.utc(ppData?.estimated_completion?.[0]).format('MM/DD/YYYY')
            }
            {dateType === 'Completed' &&
            moment.utc(ppData?.submission?.[ppData.submission.length - 1]).format('MM/DD/YYYY')
            }
          </span>
        </>
      }
    </>
  );
};

export default EventDatesColumn;

