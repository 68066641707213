import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Subheader from '../Subheader';
import { useCookies } from "react-cookie";
import { Container, Button } from "react-bootstrap";
import { useInterval } from '../../hooks/useInterval'
import parse from 'html-react-parser'
import moment from 'moment'

export default function LabgramDetails () {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    const { id } = useParams();
    const navigate = useNavigate();
    const [labgram, setLabgram] = useState({})
    const [notes, setNotes] = useState([])

    const loadLabgramWithId = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/get_frontend_labgram_by_id/?id=${id}`)
        .then(response => {
            setLabgram(response.data)
        }).catch(error => {console.log('error getting labs: ', error)})
    }, [authToken, id])

    useEffect(() => {
        loadLabgramWithId()
    }, [loadLabgramWithId])

    useInterval(()=>{
        loadLabgramWithId()
    }, 60000) //1 min

    const getLabNotesWithId = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}lab/notes/get_all_for_id?labgram=${id}`)
        .then(response => {
            const sortedNotes = response.data.sort((a,b) => a.seq > b.seq ? 1 : -1)
            setNotes(sortedNotes)
        }).catch(error => {console.log('error getting specified lab: ', error)})
    }, [authToken, id])

    useEffect(() => {
        getLabNotesWithId()
    }, [getLabNotesWithId])

    useInterval(()=>{
        getLabNotesWithId()
    }, 180000) //3 min

    return (
        <>
            <Subheader pageName={"Labgrams"} />
            <Container className="mb-5 py-3">
            <Button variant="warning" className="text-uppercase m-4" onClick={() => navigate(-1)}>
                Back to labgrams
            </Button>
            <div className="p-4">
                <h4 className="text-uppercase fw-bold pb-3">RESOURCES - <span className="text-primary text-uppercase">LABGRAMS</span></h4>
                <h5 className="text-primary text-uppercase fw-bold">{labgram?.subject}</h5>
                <p>{labgram?.labgram ? parse(labgram?.labgram) : ""}</p>
            </div>
            <div className="p-4">
            {notes?.map((note, i) => (
                <div key={i}>
                    <p>{note.title}</p>
                    <p>{note.body}</p>
                </div>
            ))}
          </div>
          <div className="p-4 d-flex d-flex justify-content-end">
            <p>
              Posted on {moment.utc(labgram?.labgram_date).format("MM/DD/YYYY")} by {labgram?.author}
            </p>
          </div>
        </Container>
      </>
    );
}