import QADetails from './QADetails'
import { Route, Routes } from "react-router-dom";
import QATable from './QATable';
import { useSelector } from "react-redux";
import UnauthorizedView from '../UI/UnauthorizedView';

export default function QA() {
  const permissions = useSelector(state => state.role.value);

  if (permissions?.role_type !== "NIAP") {
      return (
          <UnauthorizedView />
      )
  }

  return (
    <Routes>
        <Route path="/" element={<QATable manager={true}/>} />
        <Route path="details/:id" element={<QADetails />} />
    </Routes>
  )
}