import { Modal } from 'react-bootstrap';
import technicalImg from '../../../images/tech-communities/tech-comm.png';

export default function TechnicalCommBody({showModal, handleShowModal}) {
  return (
    <Modal show={showModal} onHide={handleShowModal} size='lg'>
        <Modal.Header className='border-0' closeButton></Modal.Header>
        <Modal.Body className='px-5'>
          <div className="text-center"><img src={technicalImg} height='150px' alt='Technical Community Logo' /></div>
          <h2 className="text-uppercase text-center fw-bold text-primary pb-3">Technical Community</h2>
          <h6 className='fw-bold'>Technical Community Purpose and Approach</h6>
          <p>TCs are intended to be Government/Industry/Academia partnerships formed for the purposes of:</p>
          <ul className='mx-4'>
              <li>Developing, managing, and maintaining PPs to support evaluations of specific categories of technology</li>
              <li>Influencing the evolution of identified technologies to ensure they are able to satisfy US government protection needs in the face of changing threats</li>
              <li>Ensuring PP content supports a process that is objective, efficient, yields repeatable results, 
                  and produces outcomes that have relevance and added value to the operational user community.</li>
          </ul>
          <h5 className='fw-bold text-primary'>TCs will be responsible for the following PP content</h5>
          <ul className='mx-4'>
              <li>A set of technology-specific threats</li>
              <li>The minimal security functionality sufficient to mitigate the identified threats</li>
              <li>A collection of assurance activities tailored to the technology and covering each functional requirement. These activities are
                  to be objective, measurable, repeatable, effective, and scoped such that they can be completed within a reasonable timeframe.</li>
          </ul>
        </Modal.Body>
    </Modal>
  );
}
