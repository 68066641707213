import { useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Row, Col, Form, Button } from "react-bootstrap";

export default function FileFormSection({
    product,
    editedProduct,
    handleChange,
    reload,
    setFiles,
    files,
    editedFileLabel
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];

    const fetchFiles = useCallback(() => {
        axios
            .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=product&file_type_id=${product?.product_id}`,
                {
                    withCredentials: true,
                    headers: { Authorization: `Token ${authToken}` },
                }
            )
            .then((response) => setFiles(response.data))
            .catch((error) => setFiles([]));
    }, []);

    useEffect(() => {
        fetchFiles();
    }, [product, reload]);

    const removeFile = async (file, idx) => {
        if (file.file_id) {
            await axios
                .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
                    {
                        active: false,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => fetchFiles())
                .catch((error) => console.log("Unable to update file", error));
        } else {
            const newFiles = files.slice();
            newFiles.splice(idx, 1);
            setFiles(newFiles);
        }
    };

    return (
        <Row className="mb-3">
            <p className="fw-bold form-title">ATTACH FILES</p>
            <Col xl={3} sm={12}>
                <Form.Group
                    className="mb-4 text-start"
                    controlId="file_display_name"
                >
                    <Form.Label className="small text-secondary">
                        Type
                    </Form.Label>
                    <Form.Select
                        name="file_display_name"
                        value={editedProduct?.file_display_name ?? ""}
                        onChange={handleChange}
                    >
                        <option value=""></option>
                        <option value="Security Target">
                            Security Target
                        </option>
                        <option value="Sync Meeting Notes">
                            Sync Meeting Notes
                        </option>
                        <option value="Product Information">
                            Product Information
                        </option>
                        {/* <option value="User Guide">User Guide</option> */}
                        <option value="Remote Testing Requests">Remote Testing Requests</option>
                        <option value="Other Documents">Other Documents</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xl={3} sm={12}>
                <Form.Group
                    className="mb-4 text-start"
                    controlId="file_public_status"
                >
                    <Form.Label className="small text-secondary">
                        Document Visibility
                    </Form.Label>
                    <Form.Select
                        name="file_public_status"
                        value={editedProduct?.file_public_status ?? ""}
                        onChange={handleChange}
                    >
                        <option value=""></option>
                        <option value="Public">Public</option>
                        <option value="Proprietary">Proprietary</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xl={6} sm={12}>
                <Form.Group controlId="file_label">
                    <Form.Label className="small text-secondary">
                        File Label
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="file_label"
                        onChange={handleChange}
                        value={editedFileLabel}
                    />
                </Form.Group>
            </Col>
            <Col xl={6} sm={12}>
                <Form.Group controlId="file">
                    <Form.Label className="small text-secondary">
                        Attach Files
                    </Form.Label>
                    <Form.Control
                        type="file"
                        name="file"
                        onChange={handleChange}
                        disabled={
                            !editedProduct?.file_display_name ||
                            !editedProduct?.file_public_status ||
                            !editedProduct?.file_label
                        }
                    />
                </Form.Group>
            </Col>
            <Form.Text className="text-muted fst-italic">Attachment of Security Targets Required to Submit Form</Form.Text>
            {files
                ?.filter((file) => file.active === true)
                .map((file, idx) => (
                    <Row className="m-3 border-bottom text-center" key={idx}>
                        <Col sm={4}>{file.file_display_name}</Col>
                        <Col sm={3}>{file.file_label}</Col>
                        <Col sm={3}>
                            {file.private ? "Proprietary" : "Public"}
                        </Col>
                        <Col sm={2}>
                            <Button
                                variant="outline-primary"
                                className="attachment-remove"
                                onClick={() => removeFile(file, "product", idx)}
                            >
                                X
                            </Button>
                        </Col>
                    </Row>
                ))}
        </Row>
    );
}
