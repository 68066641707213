import { useEffect, useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";
import axios from "axios";

export const PreviewDocModal = ({
  documentHtml,
  setDocumentHtml,
  show,
  handleClose,
  setShowEditModal,
  document,
  product,
  setShowInfoModal,
}) => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [isGenerated, setIsGenerated] = useState(false);

  useEffect(() => {
    if (show) {
      setAlert({ message: "", type: "" });
      setIsGenerated(false);
    }
  }, [show]);

  useEffect(() => {
    if(isGenerated){
      let newDocumentHtml = documentHtml.replace("#toolbar=0", "");
      setDocumentHtml(newDocumentHtml);
    }
  }, [isGenerated]);

  const handleGenerate = async () => {
    if (document.type === "Draft or Final Certificate") {
      setIsGenerated(true);
      setAlert({
        message: "Successfully Generated Document! You can now download it.",
        type: "success",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}org/doc/`,
          { ...document, pid: product?.v_id, dtd: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          setIsGenerated(true);
          setAlert({
            message: "Successfully Generated Document! You can now download it.",
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({ message: "Error Generating Document. Please Try Again.", type: "danger" });
        });
    }
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Title className="m-3 mb-0">
        <h4>
          Process Document Generator: Preview for{" "}
          <span className="fw-bold">
            {document.type} for VID{product?.v_id}
          </span>
        </h4>
      </Modal.Title>
      <Modal.Body style={{ minHeight: "75vh", overflow: "auto" }}>
        {alert?.message !== "" && (
          <Alert
            variant={alert.type}
            dismissible
            onClose={() => setAlert({ message: "", type: "" })}
          >
            {alert.message}
          </Alert>
        )}
        <iframe title="Document" style={{ width: "100%", height: "75vh" }} src={documentHtml} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Close
        </Button>
        {!isGenerated && (
          <>
            <Button
              variant="primary"
              onClick={() => {
                handleClose();
                if (document.type === "Draft or Final Certificate") {
                  setShowInfoModal(true);
                } else {
                  setShowEditModal(true);
                }
              }}
            >
              Edit
            </Button>
            <Button variant="success" onClick={() => handleGenerate()}>
              Generate
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
