import { Container, Row, Col, Stack, Accordion, Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import amFileIcon from "../../../images/assurance-maintenance-file-icon.svg";
import "../Products.css";
import Subheader from "../../Subheader";
import FileDownload from "js-file-download";
import parse from "html-react-parser";
import moment from "moment";
import { useInterval } from "../../../hooks/useInterval";

export default function CheckProductPermDetails({setOverlayActive, setOverlayText}) {
  const { id } = useParams();
  const [hasPerm, setHasPerm] = useState();

  useEffect(() => {
      if (id) {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/is_pcl_product/?product_id=${id}`)
        .then(response => {
          if (response.data?.is_pcl_product === false) {
            setHasPerm(false)
          } else {
            setHasPerm(true)
          }
        }).catch(() => {
          setHasPerm(false)
        });
      }
  }, [id]);

  if (hasPerm === true){
    return (<ProductDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />)
  } else if (hasPerm === false) {
    return <div>Product does not exist or is not viewable on the PCL.</div>
  }
  return (<div></div>)
}

function ProductDetails({setOverlayActive, setOverlayText}) {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [projpps, setProjpps] = useState([]);
  const [files, setFiles] = useState([]);
  const [listOfAms, setListOfAms] = useState([]);
  const [validationReportNumber, setValidationReportNumber] = useState("");
  const [cctlInfo, setCctlInfo] = useState({});
  // const [displayPDF, setDisplayPDF] = useState(false);
  // const [currentUrl, setCurrentUrl] = useState("");
  // const [currentFile, setCurrentFile] = useState({});
  // const [displayIframe, setDisplayIframe] = useState(true);

  const pullFiles = () => {
    if (project?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_pcl_files/?product_id=${project?.product_id}`
        )
        .then((response) => {
          setFiles(response.data);
          let validationReport = response.data?.eval_files?.find(file => file?.file_display_name === "Validation Report");
          if (validationReport) {
            setValidationReportNumber(
              `CCEVS-VR-VID${id}-${new Date(validationReport?.uploaded_on).getFullYear()}`
            );
          }
        })
        .catch((error) => {
          setFiles([]);
        });
    }
  };

  const getAMs = () => {
    if (project?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/get_maintenance_by_product/?product_id=${project?.product_id}`
        )
        .then((response) => {
          //only show approved AMs on product
          setListOfAms(response.data?.filter((am) => am.status === "Approved"));
        })
        .catch((error) => {
          setListOfAms([]);
        });
    }
  };

  const fetchCCTLDirectory = useCallback(() => {
    if(project?.assigned_lab?.cctl_name){
      axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}cctl/directory/get_frontend_cctl_by_name/?cctl_name=${project?.assigned_lab?.cctl_name}`)
      .then((response) => {
        setCctlInfo(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [project?.assigned_lab?.cctl_name]);

  // const getFileUrl = (file) => {
  //   axios
  //     .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`, {
  //       responseType: "blob",
  //     })
  //     .then((response) => {
  //       URL.revokeObjectURL(currentUrl);
  //       let newUrl = "";
  //       if (response.data.size > 0) {
  //         newUrl = URL.createObjectURL(response.data);
  //       }
  //       setCurrentUrl(newUrl);
  //       if (response.data.type === "application/pdf" || response.data.type === "text/html") {
  //         setDisplayIframe(true);
  //       } else {
  //         setDisplayIframe(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const downloadFile = (file) => {
    setOverlayText("Downloading")
    setOverlayActive(true)
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_public_file/?file_id=${file.file_id}`, {
        responseType: "blob",
      })
      .then((response) => {
        setOverlayActive(false)
        FileDownload(response.data, file.file_new_name ? file.file_new_name : file.file_name);
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  useEffect(() => {
    pullFiles();
    getAMs();
    fetchCCTLDirectory();
  }, [project]);

  useInterval(() => {
    pullFiles();
    getAMs();
  }, 300000); //5 min

  const loadPPByID = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_projpp_by_product_id?product_id=${id}`
      )
      .then((response) => {
        setProjpps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    loadPPByID();
  }, [loadPPByID]);

  useInterval(() => {
    loadPPByID();
  }, 300000); //5 min

  const loadProductByID = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/pcl_products/${id}`)
      .then((response) => {
        setProject(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  // const showPdf = (file) => {
  //   setCurrentFile(file);
  //   getFileUrl(file);
  //   setDisplayPDF(true);
  // };

  useEffect(() => {
    loadProductByID();
  }, [loadProductByID]);

  useInterval(() => {
    loadProductByID();
  }, 300000); //5 min

  useEffect(() => {
    document.title = "NIAP - Product Details";
  }, []);

  return (
    <>
      {/* <Modal show={displayPDF} onHide={() => setDisplayPDF(false)} size="xl">
        <Modal.Header closeButton>
          <h5 className="w-100 mb-0">
            {currentFile.file_name}{" "}
            <span style={{ float: "right" }} className="mx-5">
              <Button onClick={() => downloadFile(currentFile)}>Download</Button>
            </span>
          </h5>
        </Modal.Header>
        <Modal.Body style={{ height: 800 }}>
          {displayIframe && <iframe title="PDF Display" width={"100%"} height={"100%"} src={currentUrl} />}
          {!displayIframe && (
            <h5>This document type cannot be rendered. Please click the download button.</h5>
          )}
        </Modal.Body>
      </Modal> */}
      <Subheader pageName={"Products"} />
      <Container className="p-5" fluid>
        <Row>
          <Col className="d-flex">
            <h3 className="fw-bold">
              Compliant Product -{" "}
              <span className="text-primary">
                {project?.certification_status === "Certified"
                  ? project?.product_name
                  : "EVALUATION"}{" "}
                {/* PRODUCT */}
              </span>
            </h3>
            {/* <img className='ms-auto' src={require('../../../images/card-logo.png')} height='60px' alt='Product Logo' /> */}
          </Col>
        </Row>
        <Row className="gx-2">
          <Col sm={12} md={3} lg={2} className="bg-primary p-4 text-white">
            <Stack className="border-bottom pb-4">
              <Accordion className="border-0" defaultActiveKey={'1'}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-accordion-header">
                    <small className="p-0 text-white">Documents</small>
                  </Accordion.Header>
                  <Accordion.Body className="p-0 mt-3">
                    <Container className="p-0">
                      {/* {files?.cc_certificate && (
                        <Row className="mt-3">
                          <Stack direction="horizontal">
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              color="red"
                              size="xl"
                              className="px-1"
                            />
                            <Button
                              variant="link"
                              onClick={() => downloadFile(files?.cc_certificate)}
                              className="text-white d-flex justify-content-start p-0 m-0 small-font"
                            >
                              {files.cc_certificate?.file_display_name}
                            </Button>
                          </Stack>
                        </Row>
                      )} */}
                      {/* {files?.validation_report && (
                        <Row className="mt-3">
                          <Stack direction="horizontal">
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              color="red"
                              size="xl"
                              className="px-1"
                            />
                            <Button
                              variant="link"
                              onClick={() => downloadFile(files?.validation_report)}
                              className="text-white d-flex justify-content-start p-0 m-0 small-font"
                            >
                              {files.validation_report?.file_display_name}
                            </Button>
                          </Stack>
                        </Row>
                      )} */}
                      {/* {files?.administrative_guides?.map((ag_file, idx) => (
                        <Row className="mt-3" key={idx}>
                          <Stack direction="horizontal">
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              color="red"
                              size="xl"
                              className="px-1"
                            />
                            <Button
                              variant="link"
                              onClick={() => downloadFile(ag_file?.object)}
                              className="text-white d-flex justify-content-start p-0 m-0 small-font"
                            >
                              {ag_file?.file_display_name}
                            </Button>
                          </Stack>
                        </Row>
                      ))} */}
                      {/* {files?.assurance_activity && (
                        <Row className="mt-3">
                          <Stack direction="horizontal">
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              color="red"
                              size="xl"
                              className="px-1"
                            />
                            <Button
                              variant="link"
                              onClick={() => downloadFile(files?.administrative_guide)}
                              className="text-white d-flex justify-content-start p-0 m-0 small-font"
                            >
                              {files.assurance_activity?.file_display_name}
                            </Button>
                          </Stack>
                        </Row>
                      )} */}
                      {files?.eval_files?.map((eval_file, idx) => (
                        <Row className="mt-3" key={idx}>
                          <Stack direction="horizontal">
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              color="red"
                              size="xl"
                              className="px-1"
                            />
                            <Button
                              variant="link"
                              onClick={() => downloadFile(eval_file)}
                              className="text-white d-flex justify-content-start p-0 m-0 small-font"
                              style={{textAlign: "left"}}
                            >
                              {eval_file?.file_label}
                            </Button>
                          </Stack>
                        </Row>
                      ))}
                      {files?.am_files?.map((amFile, idx) => (
                        <Row className="mt-3" key={idx}>
                          <Stack direction="horizontal">
                            <img src={amFileIcon} alt="am-file-icon" className="me-2" />
                            <Button
                              variant="link"
                              onClick={() => downloadFile(amFile)}
                              className="text-white d-flex justify-content-start p-0 m-0 small-font"
                            >
                              {amFile?.file_label}
                            </Button>
                          </Stack>
                        </Row>
                      ))}
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
                {files.am_files_by_year &&
                  Object.entries(files?.am_files_by_year).map(([year, files]) => (
                    <Accordion.Item key={year} eventKey={year} className="mt-3">
                      <Accordion.Header className="custom-accordion-header">
                        <small className="p-0 text-white">Assurance Maintenance {year}</small>
                      </Accordion.Header>
                      <Accordion.Body className="p-0 mt-3">
                        <Container className="p-0">
                          {files?.map((file, idx) => (
                            <Row className="mt-3" key={idx}>
                              <Stack direction="horizontal">
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  color="yellow"
                                  size="xl"
                                  className="px-1"
                                />
                                <Button
                                  variant="link"
                                  onClick={() => downloadFile(file)}
                                  className="text-white d-flex justify-content-start p-0 m-0 small-font"
                                >
                                  {file?.file_display_name}
                                </Button>
                              </Stack>
                            </Row>
                          ))}
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </Stack>
            {project?.certification_date && (
              <Stack className="border-bottom pt-4">
                <small className="pb-2">Certification Date</small>
                <p>{moment.utc(project?.certification_date).format("MM/DD/YYYY")}</p>
              </Stack>
            )}
            {/* { project?.kicked_off_date && 
              <Stack className='border-bottom pt-4'>
                <small className='pb-2'>Kickoff Date</small>
                <p>{moment.utc(project?.kicked_off_date).format('MM/DD/YYYY')}</p>
              </Stack>
            } */}
            {listOfAms?.length > 0 && (
              <Stack className="border-bottom pt-4">
                <small className="pb-2">Maintenance Release</small>
                {listOfAms?.map((am, idx) => (
                  <Button
                    key={idx}
                    variant="link"
                    href={`/products/${id}/assurancemaintenance/${am?.maintenance_id}`}
                    className="text-decoration-underline text-white mb-3"
                  >
                    {am?.title}{" "}
                    {am?.completion_date && moment.utc(am?.completion_date).format("MM/DD/YYYY")}
                  </Button>
                ))}
              </Stack>
            )}
            <Stack className="border-bottom pt-4">
              <small className="pb-2">Validation Report Number</small>
              <p>{validationReportNumber}</p>
            </Stack>
            <Stack className="border-bottom pt-4">
              <small className="pb-2">Product Type</small>
              <p>{project?.tech_type}</p>
            </Stack>
            <small className="py-5 mt-5 text-white">PP Claim</small>
            {projpps?.map((projpp) => (
              <div key={projpp?.projpp_id} className="d-flex align-items-center py-2">
                <FontAwesomeIcon
                  icon={faFileLines}
                  color="var(--bs-yellow)"
                  className="me-2"
                  size="lg"
                />
                <Link to={`/protectionprofiles/${projpp?.pp__pp_id}`} className="text-white">
                  <p className="py-0 my-0">{projpp?.pp__pp_name}</p>
                </Link>
              </div>
            ))}
            {project?.assigned_lab && (
              <Stack className="pt-4 border-top" style={{wordBreak: "break-word"}}>
                <small className="pb-2">CC Testing Lab:</small>
                <small className="fw-bold">Lab Name</small>
                <p className="mx-2 mb-0">{cctlInfo?.cctl_name} {(cctlInfo?.cctl_abbrv)}</p>
                <p className="mx-2 mb-0">{cctlInfo?.address_1} {cctlInfo?.address_2}</p>
                <p className="mx-2 mb-0">{cctlInfo?.city}, {cctlInfo?.state} {cctlInfo?.zip}</p>
                <small className="fw-bold mt-2">Lab POC</small>
                <p className="mx-2 mb-0">{cctlInfo?.poc}</p>
                {cctlInfo?.work_phone && <p className="mx-2 mb-0">{cctlInfo?.work_phone} (phone)</p>}
                {cctlInfo?.work_fax && <p className="mx-2 mb-0">{cctlInfo?.work_fax} (fax)</p>}
                {cctlInfo?.poc_email && <p className="mx-2 mb-0">{cctlInfo?.poc_email}</p>}
                {cctlInfo?.nvlap_lab_code && <small className="mt-2"><span className="fw-bold">NVLAP CODE: </span>{cctlInfo?.nvlap_lab_code}</small>}
                {cctlInfo?.lab_test_methods && <small className="mt-2"><span className="fw-bold">TEST METHODS: </span>{cctlInfo?.lab_test_methods}</small>}
              </Stack>
            )}
          </Col>
          <Col sm={12} md={9} lg={10} className="px-4">
            <Accordion className="mb-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">PRODUCT DESCRIPTION</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {project?.product_description && parse(project?.product_description)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">EVALUATED CONFIGURATION</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {project?.evaluation_configuration && parse(project?.evaluation_configuration)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">SECURITY EVALUATION SUMMARY</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {project?.security_evaluation_summary &&
                    parse(project?.security_evaluation_summary)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">ENVIRONMENTAL STRENGTHS</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {project?.environmental_strengths && parse(project?.environmental_strengths)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row className="mt-2 mb-2">
              <Col>
                <Stack>
                  <h6 className="fw-bold">VENDOR INFORMATION</h6>
                  <p>{project?.vendor_id?.name}</p>
                  <p>{project?.vendor_poc}</p>
                  <p>{project?.vendor_phone}</p>
                  <p>{project?.vendor_email}</p>
                  <a 
                    href={
                      project?.vendor_id?.website?.includes("https://")
                        ? project?.vendor_id?.website
                        : "https://" + project?.vendor_id?.website
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {project?.vendor_id?.website}
                  </a>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
