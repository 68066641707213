import { Button, Container, Col, Row } from "react-bootstrap";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import ExpandedEcrAssignmentReport from "./ExpandedEcrAssignmentReport";
import UnauthorizedView from "../../UI/UnauthorizedView";
import { useSelector } from "react-redux";

export default function Reports() {
  const navigate = useNavigate();
  const permissions = useSelector(state => state.role.value);

  if(permissions?.role_type !== "Validator" && permissions.role_type !== "NIAP") {
    return (
      <UnauthorizedView />
    )
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Container fluid>
            <Row>
              <Col>
                <Button
                  variant="link"
                  className="m-5 p-0 btn-link fw-bold"
                  style={{ fontSize: "20px" }}
                  onClick={() => navigate("Expanded ECR Assignment Report")}
                >
                  <AssessmentIcon color="action" /> Expanded ECR Assignment Report
                </Button>
              </Col>
            </Row>
          </Container>
        }
      />
      <Route path="Expanded ECR Assignment Report" element={<ExpandedEcrAssignmentReport />} />
    </Routes>
  );
}
