import { Hits } from 'react-instantsearch-dom';
import { Container } from 'react-bootstrap';
import Subheader from '../Subheader';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import './search.css'

const Hit = ({ hit }) => {
    const [cookies] = useCookies()
    const authToken = cookies['auth_token']
    const permissions = useSelector(state => state.role.value)

    if (hit.public) {
        return <div><a href={hit.link} className="h5 text-bolder">{hit.page}</a><p className="m-1">{hit.blurb}</p></div>
    } else if (authToken && permissions.role_type !== 'External') {
        return <div><a href={hit.link} className="h5 text-bolder">{hit.page}</a><p className="m-1">{hit.blurb}</p></div>
    }
    
    return (<></>)
};

const Search = () => {
    return (
        <>
            <Subheader pageName={"Search"} />
            <Container className="my-5">
                <Hits hitComponent={Hit} />
            </Container>
        </>
    )
}

export default Search