import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal, Button, Form, Alert } from "react-bootstrap";

export default function ECRNoteForm({
  show,
  currentNote,
  noteType,
  comment,
  handleClose,
  fetchNotes,
  inResponseTo,
  responseType,
  setAlert,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const user = useSelector((state) => state.user.value);
  const [note, setNote] = useState(currentNote);
  const [showValid, setShowValid] = useState(false);
  const [product, setProduct] = useState({});

  const handleChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const ecrTitle = () => {
    if (note?.gist) {
      return note?.gist;
    } else if (responseType === "ECR Cancelled") {
      return responseType;
    } else {
      return `Response to ${responseType}`;
    }
  };

  const updateLabResponse = () => {
    axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${comment.ecrid}/`, 
        {
          response: note.note1,
          responseauthor: user.id,
          responsedtg: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
        }, 
        {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(() => {
        console.log('success')
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const sendNote = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      setShowValid(true);
      e.stopPropagation();
    } else {
      const update = {
        ecrid: comment?.ecrid,
        gist: ecrTitle(),
        note1: note.note1,
        private: note?.private,
        pid: comment?.pid?.product_id,
        creator: note?.upid ? note.creator?.id : user?.id,
        entrydate: note?.upid ? note.entrydate : new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
        editor: note?.upid ? user?.id : undefined,
        moddate: note?.upid ? new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) : undefined,
        in_response_to: inResponseTo,
        response_type: responseType,
        released: note?.released ?? responseType === "ECR Cancelled",
        visible_to_labs: note.visible_to_labs,
        visible_to_validators: note.visible_to_validators,
      };

      if (note?.upid) {
        axios
          .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/update/${note.upid}/`, update, {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          .then(() => {
            if (responseType === "Validator") {
              updateLabResponse()
            }
            handleClose();
            fetchNotes();
            setAlert({ message: "Update Successful!", variant: "success" });
          })
          .catch((error) => {
            console.log(error);
            setAlert({ message: "Error updating note. Please try again.", variant: "danger" });
            handleClose();
          });
      } else {
        axios
          .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/update/`, update, {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          .then(() => {
            // if (responseType === 'ECR Cancelled') {
            //   cancelNoteAdded()
            // }
            if (responseType === "Validator") {
              updateLabResponse()
            }
            handleClose();
            fetchNotes();
            setAlert({ message: "Successfully Created Note!", variant: "success" });
          })
          .catch((error) => {
            console.log(error);
            setAlert({ message: "Error creating note. Please try again.", variant: "danger" });
            handleClose();
          });
      }
    }
  };

  const noteTitle = () => {
    switch (noteType) {
      case "response":
        return "ADD RESPONSE for";
      case "initiate":
        return "INITIATE";
      default:
        return `${note?.upid ? "EDIT" : "ADD"} NOTE for`;
    }
  };

  const commentCategoryOptions = [
    { label: "General", value: "General" },
    { label: "Checkout", value: "Checkout" },
    { label: "CPS", value: "CPOS" },
  ];

  useEffect(() => {
    if(!show) {
      setNote({});
      return;
    }
    if (noteType === "response") {
      setNote({ ...currentNote, category: "Response to Validator" });
    } else { 
      setNote({
        ...currentNote,
        private: currentNote?.private ?? false,
        visible_to_labs: currentNote?.visible_to_labs ?? true,
        visible_to_validators: currentNote?.visible_to_validators ?? true,
      });
    }
  }, [noteType, currentNote]);

  const getProduct = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${comment?.pid?.product_id}`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  useEffect(() => {
    if(show){
      getProduct();
    }
  }, [getProduct]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Form onSubmit={sendNote} key={note?.upid}>
        <Modal.Header closeButton>
          <Modal.Title>+ {noteTitle()} ECR COMMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseType === "ECR Cancelled" && (
            <Alert variant="danger">You must add a note to save a status of cancelled</Alert>
          )}
          <h2>Details for VID {comment?.pid?.v_id}</h2>
          {permissions?.role_type === "NIAP" && (
            <Form.Group className="my-3">
              <Form.Label>Is Private?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  name="private"
                  value={true}
                  inline
                  label="Yes"
                  onChange={handleChange}
                  checked={note?.private === "true" || note?.private === true}
                  data-testid="private1"
                  required
                />
                <Form.Check
                  type="radio"
                  name="private"
                  value={false}
                  inline
                  label="No"
                  onChange={handleChange}
                  checked={note?.private === "false" || note?.private === false}
                  data-testid="private2"
                  required
                />
              </div>
            </Form.Group>
          )}
          {(note?.private === "false" || note?.private === false) && (
            <>
              <Form.Group className="mb-3" controlId="role_type">
                <Form.Label className="me-3">Visible To Labs?*</Form.Label>
                <Form.Check
                  type="radio"
                  name="visible_to_labs"
                  value={true}
                  inline
                  label="Yes"
                  onChange={handleChange}
                  checked={note?.visible_to_labs === "true" || note?.visible_to_labs === true}
                  data-testid="visible_to_labs1"
                  required
                />
                <Form.Check
                  type="radio"
                  name="visible_to_labs"
                  value={false}
                  inline
                  label="No"
                  onChange={handleChange}
                  checked={note?.visible_to_labs === "false" || note?.visible_to_labs === false}
                  data-testid="visible_to_labs2"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="role_type">
                <Form.Label className="me-3">Visible To Validators?*</Form.Label>
                <Form.Check
                  type="radio"
                  name="visible_to_validators"
                  value={true}
                  inline
                  label="Yes"
                  onChange={handleChange}
                  checked={
                    note?.visible_to_validators === "true" || note?.visible_to_validators === true
                  }
                  data-testid="visible_to_validators1"
                  required
                />
                <Form.Check
                  type="radio"
                  name="visible_to_validators"
                  value={false}
                  inline
                  label="No"
                  onChange={handleChange}
                  checked={
                    note?.visible_to_validators === "false" || note?.visible_to_validators === false
                  }
                  data-testid="visible_to_validators2"
                  required
                />
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-3" controlId="title">
            <Form.Label className="fw-bold">Title of Note:</Form.Label>
            <Form.Control
              rows={8}
              name="gist"
              onChange={handleChange}
              placeholder="Title"
              defaultValue={ecrTitle()}
              disabled={noteType === "response"}
              // required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="overview">
            <Form.Label className="fw-bold">Overview*</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              name="note1"
              onChange={handleChange}
              placeholder="Overview"
              value={note?.note1 ? note.note1 : ""}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
