import { Modal, Button } from "react-bootstrap";
import "./QAResponsesModal.css";
import parse from 'html-react-parser'

export default function QAResponsesModal({ show, onHide, response, handleSendToCustomer }) {

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          SEND TO CUSTOMER
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <h6 id="modalSubHeading">
              Are you sure you want to send this response to the customer?
          </h6>
          <div style={{ whiteSpace: "break-spaces" }} className="modal-text border p-2">
              {response && parse(response)}

          </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} id="noButton" size="sm">
          No, Cancel
        </Button>
        <Button onClick={handleSendToCustomer} id="yesButton" size="sm">
          YES, Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
