import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import { useCookies } from 'react-cookie';


const NAIPChangeAgentModal = ({show, handleCloseModal,refetch, }) => {
    const [cookies] = useCookies()
    const authToken = cookies['auth_token']
    let csrfToken = cookies["csrftoken"];
    const [agents, setAgents] = useState([])
    const [newSelectedAgent, setNewSelectedAgent] = useState('');
    const [currentAgent, setCurrentAgent] = useState('');

    
    const getOnDutyAgent = () => {
        axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_on_duty_agent/`,  
        {
            withCredentials: true,
            headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
            },
        })
        .then(response => {
            setCurrentAgent(response.data)
            setNewSelectedAgent(response.data?.id)
        }).catch(error => {
            console.log(error)
        })
    }

    const handleAgentChange = (userId) => {
        axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/set_on_duty_agent/`,  
        {
            user_id: userId
        },
        {
            withCredentials: true,
            headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
            },
        }
        )
        .then((response) => {
            setCurrentAgent(response.data);
            refetch()
            handleCloseModal()
        }).catch((error)=>{
            console.log(error)
        })
      };
    
      const fetchAgents = useCallback(() => {
        axios
          .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/users_by_role/?role=NIAP`, {
            headers: {
              Authorization: `Token ${authToken}`,
            },
            withCredentials: true,
          })
          .then((response) => {
            setAgents(response.data?.map(agent => 
                {
                    return {value: agent.id, label: agent.last_name}
                }
            ));
          })
          .catch((error) => {
            console.log(error);
          });
    }, [])

    useEffect(() => {
        fetchAgents()
        getOnDutyAgent()
    }, [])
    

    return (
        <>
        <Modal
        size="sm"
        show={show}
        onHide={handleCloseModal}
        >
            <Form>
                <Modal.Header closeButton className=" align-items-start">
                    <Modal.Title className="mt-2"> CHANGE AGENT ON DUTY </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <Form.Group controlId='agent'>
                    <Form.Label>
                        NIAP AGENT
                    </Form.Label>
                    <Form.Select 
                        className='mb-4'
                        name="vendor" 
                        defaultValue={currentAgent.id}
                        onChange={(e) => {setNewSelectedAgent(e.target.value)}}
                    >
                        <option value="">Select an Agent</option>
                        {agents.map((agent, index) => (
                        <option key={index} value={agent?.value}>
                            {agent?.label}
                        </option>
                        ))}
                    </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Row >
                        <Col>
                            <Button onClick={handleCloseModal} variant='outline-primary' >Cancel</Button>
                        </Col>
                        <Col>
                            <Button onClick={() => handleAgentChange(newSelectedAgent)} >Reassign</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
    )
    
}

export default NAIPChangeAgentModal