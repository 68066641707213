import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useState } from "react";
import { handleAlertAndMailer } from "../../Helper/functions"

export default function ACMRRecommendationModal({ show, setShow, product, refetch, setRefetchNotes }) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [note, setNote] = useState("");
  const currentUser = useSelector((state) => state.user.value);

  const sendApprovalAlert = () => {
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        alert_source: "Assurance Maintenance",
        subject: `ACMR Approval Recommendation for VID ${product.v_id}.`,
        recipients: { to: ["NIAP Management"], cc: []},
        alert_text: `ACMR Approval Recommendation for VID ${product.v_id}.`
      }
    )
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const handleChange = (e) => {
    setNote(e.target.value);
  };

  const handleAddNote = () => {
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: note,
          note_type: "product",
          note_type_id: product?.product_id,
          submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          submitted_by: currentUser.id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => setRefetchNotes(response?.data?.note_id))
      .catch((error) => console.log("Unable to add note: " + error));
  };

  const setACMRFileInactive = () => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=assurance-maintenance&file_source_type=Assurance Maintenance&file_type_id=${product?.current_assurance_maintenance?.maintenance_id}`,
    {
        withCredentials: true, 
        headers: {
            'Authorization':  `Token ${authToken}`,
        },
    }).then((response) => {
        let acmrFiles = response.data.filter(file => 
          file?.file_display_name === "Assurance Continuity Maintenance Report (ACMR)" &&
          file?.active === true
        )
        if(acmrFiles.length > 0){
          acmrFiles.forEach(file => {
            axios
              .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file?.file_id}/`,
                {
                  active: false
                },
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                }
              )
              .then((response) => {
                console.log('file removed')
              })
              .catch((error) => console.log(error));
          })
        }
        setShow(false);
        refetch();
    }).catch((error) => {
        console.log('Error')
    })
  }

  const updatePP3Completed = async () => {
      await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          maintenance: product?.current_assurance_maintenance?.maintenance_id,
          progress_point: 3,
          status: "Completed"
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "Update Already Exists") {
          refreshPage();
        } else {
          sendApprovalAlert();
        }
      })
      .catch((error) => console.log(error));
  }

  const handleUpdateProgress = (e, status, pp) => {
    e.preventDefault();
    axios
    .put(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
      {
        maintenance: product?.current_assurance_maintenance?.maintenance_id,
        progress_point: pp,
        status: status
      },
      {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      }
    )
    .then((response) => {
      if (response.data.status === "Update Already Exists") {
        refreshPage();
      } else {
        if(note !== ""){
          handleAddNote();
        }
        if(status === "Rejected"){
          setACMRFileInactive()
        } else {
          setShow(false);
          refetch();
        }
      }
    })
    .catch((error) => console.log(error));
  }

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setShow(false);
      }}
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-primary">
            +ACMR RECOMMENDATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to recommend for ACMR POSTING?</h5>
          <Form.Group className="mt-5">
          <Form.Label>Note:</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="rejection_rational"
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShow(false)}>Cancel</Button>
          <Button
            variant="danger"
            onClick={(e) => {
              handleUpdateProgress(e, "Rejected", 3)
            }}
          >
            NO
          </Button>
          <Button
            type="submit"
            variant="success"
            onClick={(e) => {
              updatePP3Completed();
              handleUpdateProgress(e, "In Progress", 4);
            }}
          >
            YES
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
