import axios from "axios";
import { useState } from "react";

const CalendarEventCreator = ({ product, cookies }) => {
  let csrfToken;
  let authToken;
  let statuses;
  let colors = [
    '#C0C0C0',
    '#FF0000',
    '#00FF00',
    '#00FFFF',
    '#FF00FF'
  ]
  if (cookies) {
    csrfToken = cookies["csrftoken"];
    authToken = cookies["auth_token"];
  }
  const getStatuses = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_all_statuses/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        statuses = response.data;
      })
      .catch((error) =>
        console.log("Unable to get Calendar Statuses: ", error)
      );
  };
  getStatuses();

  const getNewStatusColor = () => {
    let idx = Math.floor(Math.random() * 5)
    return colors[idx]
  }

  const createStatus = (statusFilter) => {
    return new Promise(async (resolve, reject) => {
      try {
        let newStatus = await 
          axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/`, 
            {
              text: statusFilter,
              color: getNewStatusColor()
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
        resolve(newStatus.data)
      }
      catch(error) {
        reject(error)
      }
    })
  }

  const getCurrentStatus = (statusFilter) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (statuses.length > 0) {
          let currStatus = statuses.filter((status) =>
            status.text === statusFilter,
          );
          if (currStatus.length > 0) {
            resolve(currStatus[0].id)
          } 
          else {
            currStatus = await createStatus(statusFilter)
            resolve(currStatus?.id)
          }
        }
        else {
          let currStatus = await createStatus(statusFilter)
          resolve(currStatus?.id)
        }
      }
      catch (error) {
        reject(error)
      }
    })
  }

  const createCalendarEvent = async (title, statusFilter, startDate) => {
    let statusId
    try {
      statusId = await getCurrentStatus(statusFilter)
    }
    catch (error) {
      console.log(error)
      statusId=null
    }
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 999);
    if (statusId) {
      axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_event_by_status_and_product/?statusId=${statusId}&productId=${product?.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${response.data?.id}/`,
            {
              startDate: startDate,
              endDate: endDate,
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) =>
            console.log("Unable to update Calendar event: ", error)
          );
        statusId = null
      })
      .catch(() => {
        // if event doesnt exist
        const requestData = {
          title: title,
          startDate: startDate,
          endDate: endDate,
          statusId: statusId,
          allDay: true,
          productId: product?.product_id,
        };
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/`,
            requestData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) =>
            console.log("Unable to create Calendar event: ", error)
          );
        statusId=null
      });
    }
  };

  return {
    createCalendarEvent,
  };
};

export default CalendarEventCreator;
