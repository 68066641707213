import {
    Accordion,
    Container,
    Row,
    Col,
    Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import HTMLEditor from "../../UI/HTMLEditor";
import parse from "html-react-parser";
import { useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import TQActiveVoting from "./TQVoting/TQActiveVoting";
import TQReadOnlyVoting from "./TQVoting/TQReadOnlyVoting";
import { getCurrentDate } from "../../../utils/timeUtils"

export default function TQResponseAccordion({ technicalQuery, trrtMember, updateTechnicalHistory, updateTechnicalQuery, fetchTQNotes, votes, getVotes, currentUserVote }) {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const user = useSelector((state) => state.user.value);
    const permissions = useSelector((state) => state.role.value);
    const [response, setResponse] = useState({});
    const [saveDisabled, setSaveDisabled]=useState(true)

    const handleChange = (e) => {
        setSaveDisabled(false)
        setResponse({ ...response, [e.target.name]: e.target.value });
    };

    const handleResetVotes = async () => {
        for(let vote of votes){
            await axios
            .delete(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/voting/${vote.id}`,
                {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                },
                }
            )
            .then(() => console.log("Vote deleted"))
            .catch((error) => console.log("Unable to reset votes: " + error));  
        }
        await updateTechnicalHistory({reason_for_update: "Reset Votes"})
        getVotes()
    };

    const handleSaveResponse = async () => {
        setSaveDisabled(true)
        let data = {
            ...response,
        }

        if(technicalQuery?.response_by === null){
            data["response_by"] = user?.id
            data["response_on"] = getCurrentDate()
        }

        if(technicalQuery?.status === "Proposed"){
            data["status"] = "In Progress"
        }

        await updateTechnicalQuery(data)
        await updateTechnicalHistory({reason_for_update: "Updated Draft Response"})
        handleAddNote(data)
    };

    const handleAddNote = (response) => {
        axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/`, 
            {
            visible_to_validators: true,
            visible_to_labs: false,
            note: response?.response, 
            title: "Updated Draft Response",
            submitted_by: user.id, 
            submitted_on: getCurrentDate(), 
            note_type: "technical_query", 
            note_type_id: technicalQuery?.tq_id,
            }, 
            {
            withCredentials: true, 
            headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}
            }
        )
        .then(() => {
            fetchTQNotes();
        })
        .catch((error) => {
            console("Unable to add note: " + error);
        });
    }

    const handleOpenForVoting = async () => {
        await updateTechnicalQuery({...response, status: "Resolution Proposed"})
        await updateTechnicalHistory({reason_for_update: "Opened for Voting"})
    };

    const handleSendToCustomer = async () => {
        let data = {
            status: "Resolved",
            official_response: technicalQuery?.response,
            official_response_by: user?.id,
            official_response_on: getCurrentDate(),
        }
        await updateTechnicalQuery(data)
        await updateTechnicalHistory({reason_for_update: "Sent Official Response"})
    };

    return (
      <>
        {permissions?.role_type !== "Lab" && (
          <Accordion>
            {technicalQuery?.status === "Rejected" ? (
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="border-0">
                    <h6 className="text-primary fw-bold">REJECTION RATIONALE:</h6>
                  </Accordion.Header>
                  <Accordion.Body>{technicalQuery?.response}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              <Accordion.Item eventKey="1">
                <Accordion.Header className="border-0">
                  <h6 className="text-primary fw-bold">DRAFT RESPONSE:</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {permissions.role_permissions.find((permission) => permission.area === "Technical Questions")
                    ?.update && !["TD Required", "Resolved", "TD Proposed"].includes(technicalQuery?.status) ? (
                    <Container>
                      <Row>
                        <div className="d-flex justify-content-end">
                          {technicalQuery?.response_by && (
                            <p
                              className="mx-2"
                              style={{ flex: 1 }}
                            >
                              Original Response by{" "}
                              <span className="fst-italic">
                                {technicalQuery?.response_by?.first_name} {technicalQuery?.response_by?.last_name}
                              </span>{" "}
                              on{" "}
                              <span className="fst-italic">
                                {moment(technicalQuery?.response_on).format("MM/DD/YYYY")}
                              </span>
                            </p>
                          )}

                          {technicalQuery?.status === "Resolution Proposed" && (
                            <div className="d-flex justify-content-between">
                              <TQActiveVoting
                                technicalQuery={technicalQuery}
                                updateTechnicalQuery={updateTechnicalQuery}
                                updateTechnicalHistory={updateTechnicalHistory}
                                votes={votes}
                                getVotes={getVotes}
                                currentUserVote={currentUserVote}
                              />
                            </div>
                          )}
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <HTMLEditor
                            name="response"
                            handleChange={handleChange}
                            setIsValid={() => {}}
                            isValid={true}
                            defaultValue={technicalQuery?.response}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3 mb-3 d-flex justify-content-end">
                        <Col
                          xs={3}
                          className="w-auto"
                        >
                          <Button
                            variant="success"
                            onClick={handleSaveResponse}
                            disabled={saveDisabled}
                          >
                            Save
                          </Button>
                        </Col>
                        <Col
                          xs={3}
                          className="w-auto"
                        >
                          {technicalQuery?.status !== "Resolved" &&
                            permissions.role_permissions.find((permission) => permission.area === "Technical Questions")
                              ?.update &&
                            permissions?.role_type === "NIAP" && (
                              <Button
                                variant="primary"
                                disabled={
                                  //if not niap, needs 3 upvotes, else only 1 upvote
                                  votes.filter((vote) => vote.approved === true).length <
                                  (permissions?.role_type === "NIAP" ? 1 : 3)
                                }
                                onClick={handleSendToCustomer}
                              >
                                Send Official Response
                              </Button>
                            )}
                        </Col>
                        {(permissions?.role_type === "NIAP" || permissions.role_type === "SME" || trrtMember) &&
                          votes.length === 0 &&
                          technicalQuery?.status !== "Resolution Proposed" && (
                            <Col
                              xs={3}
                              className="w-auto"
                            >
                              <Button
                                variant="warning"
                                onClick={() => handleOpenForVoting()}
                                disabled={!technicalQuery?.response}
                              >
                                Open For Voting
                              </Button>
                            </Col>
                          )}
                        {votes.length > 0 && permissions?.role_type === "NIAP" && (
                          <Col
                            xs={3}
                            className="w-auto"
                          >
                            <Button
                              variant="danger"
                              onClick={handleResetVotes}
                            >
                              Reset Votes
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Container>
                  ) : (
                    <Container>
                      {(permissions?.role_type === "NIAP" || trrtMember) && (
                        <TQReadOnlyVoting
                          technicalQuery={technicalQuery}
                          votes={votes}
                          currentUserVote={currentUserVote}
                        />
                      )}
                      {technicalQuery?.response && parse(technicalQuery?.response)}
                    </Container>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        )}
        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="border-0">
              <h6 className="text-primary fw-bold">OFFICIAL RESPONSE:</h6>
            </Accordion.Header>
            <Accordion.Body>
              <Container>{technicalQuery?.official_response && parse(technicalQuery?.official_response)}</Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
}
