import { useState, useEffect, useCallback } from "react";
import { useInterval } from "../../../../hooks/useInterval";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import {
    Alert,
    Button,
    Accordion,
    Container,
    Row,
    Col,
    Form,
} from "react-bootstrap";
import "../../Products.css";
import HTMLEditor from "../../../UI/HTMLEditor";
import { useSelector } from "react-redux";
import Notes from "../../../ProtectionProfiles/PPManager/Notes";
import "./ProjectDetailsAccordion.css";
import ECRCommentsTable from "../../../ECR/ECRCommentsTable";
import EcrCommentsCount from "./EcrCommentsCount";

export default function PostingAccordion({
    project,
    refetch,
    readyToPostChecks,
    handleReadyToPostCheck,
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const { id } = useParams();
    const user = useSelector((state) => state.user.value);
    const permissions = useSelector((state) => state.role.value);
    const [activeKey, setActiveKey] = useState([0]);
    const [editedHtmlFields, setEditedHtmlFields] = useState({});
    const [showAlert, setShowAlert] = useState("");
    const [ecrCounts, setEcrCounts] = useState({});


  const fetchEcrCounts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/count_comments?vid=${project.v_id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        setEcrCounts(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [project])

    useEffect(() => {
        fetchEcrCounts()
    }, [fetchEcrCounts])

    useInterval(()=>{
        fetchEcrCounts()
    }, 180000) //3 min
   
    const expandAll = () => {
        const allKeys = [];
        projectDescriptionFields.forEach((pd, idx) => {
            allKeys.push(idx);
        });
        setActiveKey(allKeys);
    };

    const collapseAll = () => {
        setActiveKey([]);
    };

    const handleItemClick = (idx) => {
        let newKeys = [...activeKey];
        if (newKeys.includes(idx)) {
            newKeys = newKeys.filter((key) => key !== idx);
        } else {
            newKeys.push(idx);
        }
        setActiveKey(newKeys);
    };

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            handleReadyToPostCheck({ [e.target.name]: e.target.checked });
        } else {
            setEditedHtmlFields({
                ...editedHtmlFields,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSaveAll = () => {
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${project.product_id}/`,
                editedHtmlFields,
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                setEditedHtmlFields({});
                setShowAlert("success");
                refetch();
            })
            .catch((error) => setShowAlert("fail"));
    };

    let projectDescriptionFields = [
        {
            title: "Product Description",
            name: "product_description",
            text: project?.product_description,
        },
        {
            title: "Security Evaluation Summary",
            name: "security_evaluation_summary",
            text: project?.security_evaluation_summary,
        },
        {
            title: "Environmental Strengths",
            name: "environmental_strengths",
            text: project?.environmental_strengths,
        },
        {
            title: "Evaluation Configuration",
            name: "evaluation_configuration",
            text: project?.evaluation_configuration,
        },
        {
            title: "ECR Comments",
        }
    ];
    
    if(permissions?.role_type === "NIAP"){
        // if NIAP, we want them to always be able to see the ECR Comments section
        projectDescriptionFields[4] = {
            title: "ECR Comments"
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    {Object.keys(editedHtmlFields).length > 0 &&
                        showAlert === "" && (
                            <Alert variant="danger">Please Save Changes!</Alert>
                        )}
                    {showAlert === "success" && (
                        <Alert
                            variant="success"
                            dismissible
                            onClose={() => setShowAlert("")}
                        >
                            Changes successfully saved!
                        </Alert>
                    )}
                    {showAlert === "fail" && (
                        <Alert
                            variant="danger"
                            dismissible
                            onClose={() => setShowAlert("")}
                        >
                            Changes failed to save!
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {activeKey.length !== projectDescriptionFields.length && (
                        <Button
                            variant="link"
                            onClick={expandAll}
                            className="text-primary ml-auto"
                        >
                            Expand All
                        </Button>
                    )}
                    {activeKey.length === projectDescriptionFields.length && (
                        <Button
                            variant="link"
                            onClick={collapseAll}
                            className="text-primary ml-auto"
                        >
                            Collapse All
                        </Button>
                    )}
                </Col>
                <Col className="d-flex justify-content-end mb-2">
                    {Object.keys(editedHtmlFields).length > 0 && (
                        <Button variant="success" onClick={handleSaveAll}>
                            Save All
                        </Button>
                    )}
                </Col>
            </Row>
            <Accordion activeKey={activeKey} alwaysOpen>
                {projectDescriptionFields.map((pd, idx) => (
                    <Accordion.Item
                        eventKey={idx}
                        key={idx}
                        className={pd.title !== "ECR Comments" ? "border-bottom-dark" : ""}
                    >
                    <Accordion.Header onClick={() => handleItemClick(idx)}>
                        <Container fluid>
                        <Row>
                            <Col xs={12} sm={3}>
                            <h6 className="mb-2 mb-lg-0">{pd.title}</h6>
                            </Col>
                            {pd.title === "ECR Comments" && (
                                <EcrCommentsCount ecrCounts={ecrCounts} />
                            )}
                        </Row>
                        </Container>
                    </Accordion.Header>
                        <Accordion.Body className="p-0">
                            <Container>
                                <Row>
                                    <Col>
                                        {!["ECR Comments"]?.includes(pd.title) &&
                                        <Row>
                                            <Col className="d-flex justify-content-end mb-2">
                                                <Form.Check
                                                    type={"checkbox"}
                                                    name={`ready-to-post-${idx}`}
                                                    id={`ready-to-post-${idx}`}
                                                    label="Reviewed"
                                                    onChange={handleChange}
                                                    checked={
                                                        readyToPostChecks?.find((check) =>
                                                            `ready-to-post-${idx}` in check
                                                        )?.[`ready-to-post-${idx}`]
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        }
                                        <Row className="mb-2">
                                        {pd.title === "ECR Comments" && project.v_id && (
                                            <ECRCommentsTable
                                            vidNumber={project.v_id}
                                            fetchEcrCounts={fetchEcrCounts}
                                            />
                                        )}
                                        {!["ECR Comments"]?.includes(pd.title) &&
                                            <HTMLEditor
                                                name={pd.name}
                                                handleChange={handleChange}
                                                setIsValid={() => {}}
                                                isValid={true}
                                                defaultValue={pd.text}
                                            />
                                        }
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <Notes
                item={project}
                note_type="product"
                user={user}
                id={id}
            />
        </Container>
    );
}
