import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
import Filter2OutlinedIcon from '@mui/icons-material/Filter2Outlined';
import LaunchProcess from './Launch.Process.png'
import LaunchPreProcess from './Launch.PreProcess.png'

const TrainingHome = () => {
    return (
        <Container fluid>
            <Row className="mt-5 mb-3">
                <Col sm={12}>
                    <h3 className="text-bright-navy">Training Center</h3>
                </Col>
            </Row>
            <Row className="mt-5 mb-3">
                <Col sm={6} className='text-center'>
                    <a href="training-center/p1-pre-evaluation">
                        <img src={LaunchPreProcess} alt="Evaluation Stage 1: Pre-Evaluation" />
                        <span className='visually-hidden'>Evaluation Stage 1: Pre-Evaluation</span>
                    </a>
                </Col>
                <Col sm={6} className='text-center'>
                    <a href="training-center/p2-evaluation">
                        <img src={LaunchProcess} alt="Evaluation Stage 2: Evaluation" />
                        <span className='visually-hidden'>Evaluation Stage 2: Evaluation</span>
                    </a>
                </Col>
            </Row>
            <Row className="mt-5 mb-3">
                <Col className='text-center'>
                    <Button variant='secondary' style={{width: '90%'}} href="training-center/all-artifacts"><ArticleOutlinedIcon />&nbsp;All Artifacts</Button>
                </Col>
                <Col className='text-center'>
                    <Button variant='success' style={{width: '90%'}} href="training-center/references"><AccountBalanceOutlinedIcon />&nbsp;NIAP References</Button>
                </Col>
                <Col className='text-center'>
                    <Button variant='primary' style={{width: '90%'}} href="training-center/glossary"><LibraryBooksOutlinedIcon />&nbsp;Glossary</Button>
                </Col>
                <Col className='text-center'>
                    <Button variant='warning' style={{width: '90%'}} href="training-center/p1-pre-evaluation"><Filter1OutlinedIcon />&nbsp;P1 Pre-Eval Flow</Button>
                </Col>
                <Col className='text-center'>
                    <Button variant='info' style={{width: '90%'}} href="training-center/p2-evaluation"><Filter2OutlinedIcon />&nbsp;P2 Eval Flow</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default TrainingHome