import { Stack, Accordion, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import FileDownload from 'js-file-download';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';



export default function SideInfo({ pp, files, setOverlayActive, setOverlayText }) {

    const [cookies] = useCookies() 
    const authToken = cookies['auth_token']

    const permissions = useSelector(state => state.role.value);

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
            {
                withCredentials: true, 
                headers: {
                    'Authorization':  `Token ${authToken}`,
                },
                responseType: 'blob'
            }).then((response) => {
                setOverlayActive(false)
                FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name))
            }).catch((error) => {
                console.log('Error')
            })
      }

  return (
    <div>
        <Accordion flush className='border border-dark mb-3'>
            <Accordion.Item>
                <Accordion.Header className='bg-secondary'><h6 className='text-white'>IMPORTANT DATES</h6></Accordion.Header>
                    <Accordion.Body className='bg-light p-1'>
                        <Stack direction='horizontal' className='mb-2'>
                            <small className='me-3'>Estimated Completion Date</small>
                            <small className='text-break ms-auto'>{pp.pp_estcompletiondate && moment.utc(pp.pp_estcompletiondate).format("MM/DD/YYYY")}</small>
                        </Stack>
                        <Stack direction='horizontal' className='mb-2'>
                            <small className='me-3'>Approval Date</small>
                            <small className='text-break ms-auto'>{pp.pp_date && moment.utc(pp.pp_date).format("MM/DD/YYYY")}</small>
                        </Stack>
                        <Stack direction='horizontal' className='mb-2'>
                            <small className='me-3'>Transition Date</small>
                            <small className='text-break ms-auto'>{pp.pp_transition && moment.utc(pp.pp_transition).format("MM/DD/YYYY")}</small>
                        </Stack>
                        <Stack direction='horizontal' className='mb-2'>
                            <small className='me-3'>Sunset Date</small>
                            <small className='text-break ms-auto'>{pp.sunset_date && moment.utc(pp.sunset_date).format("MM/DD/YYYY")}</small>
                        </Stack>
                    </Accordion.Body>
                </Accordion.Item>
        </Accordion>
        <Accordion flush className='border border-dark mb-3'>
        <Accordion.Item>
            <Accordion.Header className='bg-secondary'><h6 className='text-white'>PP DOCUMENTS</h6></Accordion.Header>
            <Accordion.Body className='bg-light p-1'>
            {permissions?.role_type === "NIAP" && <div style={{textAlign: "center"}}>
                <Button as={Link} to={`/community/protectionprofiles/details/${pp?.pp_id}/protection-profile/files`} variant="primary">
                    View All
                </Button>
            </div>}
            {files?.map((file, idx) => (
                <Stack  direction='horizontal' className='d-flex justify-content-left align-items-start' key={idx}>
                    {file.file_mime_type === 'application/pdf' && <FontAwesomeIcon icon={faFilePdf} color='red' size='xl' />}
                    {file.file_mime_type !== 'application/pdf' && <FontAwesomeIcon icon={faFileLines} color='gray' size='xl' />}
                    <div className="ms-2 text-break">
                        {file.file_display_name === "Protection Profile (HTML)" && file.file_name.includes('.htm') && (
                            <Button style={{overflowWrap: "break-word"}} className="text-start m-0 p-0" variant="link" href={`/static_html${file.file_location}${file.file_name}`} target='_blank'>
                                {file.file_label ? file.file_label : file.file_new_name ? file.file_new_name : file.file_name}
                            </Button>
                        )}  
                        {file.file_display_name === "Protection Profile (HTML)" && !file.file_name.includes('.htm') && (
                            <Button style={{overflowWrap: "break-word"}} className="text-start m-0 p-0" variant="link" href={`/static_html${file.file_location}${file.file_name}/index.html`} target='_blank'>
                                {file.file_label ? file.file_label : file.file_new_name ? file.file_new_name : file.file_name}
                            </Button>
                        )}  
                        {file.file_display_name !== "Protection Profile (HTML)" && file.isFolder && (
                            <Button style={{overflowWrap: "break-word"}} className="text-start m-0 p-0" variant="link" href={`/static_html${file.file_location}${file.file_name}/index.html`} target='_blank'>
                                {file.file_label ? file.file_label : file.file_new_name ? file.file_new_name : file.file_name}
                            </Button>
                        )} 
                        {file.file_display_name === "Configuration Document" && !file.isFolder && (
                            <Button variant="link" onClick={() => downloadFile(file)} className="text-start m-0 p-0">
                                {file.file_label ? file.file_label : file.file_name}
                            </Button>
                        )} 
                        {file.file_display_name !== "Protection Profile (HTML)" && file.file_display_name !== "Configuration Document" && !file.isFolder && (
                            <Button style={{overflowWrap: "break-word"}} className="text-start m-0 p-0" variant="link" onClick={() => downloadFile(file)}>
                                {file.file_label ? file.file_label : file.file_new_name ? file.file_new_name : file.file_name}
                            </Button>
                        )}  
                        <p className="mb-0 ms-3">
                            {file.file_new_name ? file.file_new_name : file.file_name}
                        </p>
                        <p className="mb-0 ms-3">
                            Attached by {file?.uploaded_by?.first_name} {file?.uploaded_by?.last_name} on {file?.uploaded_on && moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
                        </p>
                    </div>
                </Stack>
            ))}
            </Accordion.Body>
        </Accordion.Item>
        </Accordion>
        <Accordion flush className='border border-dark mb-3'>
        <Accordion.Item>
            <Accordion.Header className='bg-secondary'><h6 className='text-white'>PP INFORMATION</h6></Accordion.Header>
            <Accordion.Body className='bg-light p-0'>
                <Stack className='pb-2 ps-2 border border-dark'>
                    <small>Common Name</small>
                    <p className='text-break'>{pp.pp_common_name}</p>
                </Stack>
                <Stack className='pb-2 ps-2 border border-dark'>
                    <small>Priority</small>
                    <p className='text-break'>{pp.pp_priority}</p>
                </Stack>
                <Stack className='pb-2 ps-2 border border-dark'>
                    <small>Sponsor</small>
                    <p className='text-break'>{pp.pp_sponsor_id}</p>
                </Stack>
                <Stack className='pb-2 ps-2 border border-dark'>
                    <small>MR Version</small>
                    <p className='text-break'>{pp.mr_version}</p>
                </Stack>
                <Stack className='pb-2 ps-2 border border-dark'>
                    <small>CC Version</small>
                    <p className='text-break'>{pp.cc_version}</p>
                </Stack>
                <Stack className='pb-2 ps-2 border border-dark'>
                    <small>Record Created:</small>
                    <p className='text-break'>{pp.entrydate && new Date(pp.entrydate)?.toLocaleDateString()}</p>
                </Stack>
            </Accordion.Body>
        </Accordion.Item>
        </Accordion>
    </div>
  )
}
