import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { useCookies } from "react-cookie"
import { useSelector } from "react-redux"
import moment from "moment"
import axios from "axios"

const ReplaceObject = ({show, handleClose, reloadDocuments, object}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    let csrfToken = cookies['csrftoken'];
    const currentUser = useSelector(state => state.user.value)
    const [file, setFile] = useState({})

    const handleChange = (event) => {
        setFile({...file, [event.target.name]: event.target.files[0]})
    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {
          e.stopPropagation();
        } else {
          const submittedData = new FormData();
          delete object['parent']
          delete object['removed_on']
          delete object['removed_by']
          object['replaced_on'] = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString()
          object['uploaded_on'] = new Date(object.uploaded_on).toJSON()
          object['uploaded_by'] = currentUser.id
          object['replaced_by'] = currentUser.id
          object["replaced"] = JSON.stringify(true);
          if (!object.file_source_type) {
              delete object['file_source_type']
          }
          Object.keys(object).forEach(key => {
              submittedData.append(key, object[key])
          })
          submittedData.append(`file`, file?.file);
          submittedData.append("file_name", file?.file?.name);
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${object?.file_id}/`,
              submittedData,
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              }
            )
            .then((response) => {
              reloadDocuments();
              handleClose();
            })
            .catch((error) => {
              console.log(error);
            });
        }
    };

    const handleReset = () => {
        setFile({})
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Replace {object?.isFolder ? "Folder" : "Document"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="file_name">
                        <Form.Label>Upload new {object?.isFolder ? "Folder" : "Document"}</Form.Label>
                        <Form.Control
                            type="file"
                            name="file"
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={handleSubmit}>Update</Button>
                    <Button variant="primary" type="reset" onClick={handleReset}>Reset</Button>
                    <Button variant="primary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ReplaceObject