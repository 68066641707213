import { Route, Routes } from "react-router-dom";
import CommunityTechDecisionTable from './CommunityTechDecisionTable'
import CommunityTechDecisionDetails from "./CommunityTechDecisionDetails";
import UnauthorizedView from "../../UI/UnauthorizedView"
import { useSelector } from "react-redux";

export default function TechDecisionManager({}) {
  const permissions = useSelector(state => state.role.value);

  if (!permissions?.role_permissions?.find(permission => permission.area === 'Technical Decisions')?.read) {
      return (
          <UnauthorizedView />
      )
  }

  return (
    <Routes>
        <Route path="/" element={<CommunityTechDecisionTable />} />
        <Route path="details/:id" element={<CommunityTechDecisionDetails />} />
    </Routes>
  )
}