import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import ReactSelect from "../../ReactSelect";
import HTMLEditor from '../../UI/HTMLEditor'

export default function FAQModal({
  show,
  handleClose,
  selectedFAQ,
  setAlert,
  refetch,
  testMock, //only to be used in FAQModal.test.js
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const user = useSelector((state) => state.user.value);
  const [currFAQ, setCurrFAQ] = useState({});
  const [categories, setCategories] = useState([]);
  const [validated, setValidated] = useState(false);
  const [answerValid, setAnswerValid] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)

  const fetchCategories = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/categories/get_all_categories/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (e) => {
    if(e.target.name === "publish" || e.target.name === "reviewed"){
      setCurrFAQ({ ...currFAQ, [e.target.name]: e.target.value === "true" ? true : false });
    } else {
      setCurrFAQ({ ...currFAQ, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if(show && !testMock){
      setCurrFAQ({})
    } else if (show && testMock) {
      setCurrFAQ(testMock)
    }
    fetchCategories();
    setValidated(false)
    setAnswerValid(true)
  }, [show]);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false || (!currFAQ.answer && !selectedFAQ?.qa_id)) {
      if(!currFAQ.answer && !selectedFAQ?.qa_id){
        setAnswerValid(false);
      }
      e.stopPropagation();
    } else {
      if (selectedFAQ?.qa_id) {
        handleSave();
      } else {
        setAnswerValid(true);
        let data = {
          ...currFAQ,
          category: categoryOptions?.find(category => category.value === currFAQ.category_id)?.label,
          submitter_name: `${user.first_name} ${user.last_name}`,
          recaptcha_token: "member_portal",
          status: "Published"
        }

        delete data['category_id']; //on the backend, we are checking for "category" to set the category_id

        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/`,
            data,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then((response) => {
            refetch();
            handleClose();
            setAlert({
              type: "success",
              message: "FAQ Added Successfully",
            });
          })
          .catch((error) => {
            console.error("Error putting resource:", error);
            setAlert({
              type: "danger",
              message: "Error adding FAQ.",
            });
          });
      }
    }
    setValidated(true);
  };
  
  const handleSave = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/${selectedFAQ?.qa_id}/`,
        { ...currFAQ, recaptcha_token: "member_portal"},
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        refetch();
        handleClose();
        setAlert({
          type: "success",
          message: "FAQ Updated Successfully",
        });
      })
      .catch((error) => {
        console.error("Error Updating resource:", error);
        setAlert({
          type: "danger",
          message: "Error updating FAQ.",
        });
      });
  };

  let categoryOptions = categories?.map((category) => {
    return { value: category.category_id, label: category.category_name };
  });

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      enforceFocus={enforceFocusModal}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedFAQ?.qa_id ? "EDIT FAQ" : "+NEW FAQ"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="question">
            <Form.Label>Question*</Form.Label>
            <Form.Control
              as="textarea"
              name="question"
              onChange={handleChange}
              defaultValue={selectedFAQ?.question}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="answer">
            <Form.Label>Answer*</Form.Label>
            <HTMLEditor 
              name="answer" 
              handleChange={handleChange} 
              setIsValid={setAnswerValid} 
              isValid={answerValid} 
              defaultValue={
                selectedFAQ?.answer 
                ? 
                selectedFAQ.answer.replace(/<a[^>]*>Send a Response<\/a>/g, "")
                : 
                ""
              }
              setEnforceFocusModal={setEnforceFocusModal}
              />
          </Form.Group>
          <Form.Group className="mb-3" controlId="categories">
            <Form.Label>Categories*</Form.Label>
            <ReactSelect
                id={"categories"}
                options={categoryOptions}
                newObj={currFAQ}
                handleChange={handleChange}
                name="category_id"
                isValid={true}
                defaultValue={
                  currFAQ?.category_id ? 
                  categoryOptions?.find(category => category.value === currFAQ?.category_id)
                  :
                  categoryOptions?.find(category => category.value === selectedFAQ?.category_id?.category_id)
                }
              />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="published" required>
                <Form.Label>Published*</Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      id={"published-yes"}
                      type="radio"
                      label="Yes"
                      name="publish"
                      value={true}
                      onChange={handleChange}
                      defaultChecked={
                        selectedFAQ?.publish === true
                      }
                      required
                    ></Form.Check>
                  </Col>
                  <Col>
                    <Form.Check
                      id={"published-no"}
                      type="radio"
                      label="No"
                      name="publish"
                      value={false}
                      defaultChecked={
                        selectedFAQ?.publish === false
                      }
                      onChange={handleChange}
                    ></Form.Check>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="reviewed" required>
                <Form.Label>Reviewed*</Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      id={"reviewed-yes"}
                      type="radio"
                      label="Yes"
                      name="reviewed"
                      value={true}
                      onChange={handleChange}
                      defaultChecked={
                        selectedFAQ?.reviewed === true
                      }
                      required
                    ></Form.Check>
                  </Col>
                  <Col>
                    <Form.Check
                      id={"reviewed-no"}
                      type="radio"
                      label="No"
                      name="reviewed"
                      value={false}
                      onChange={handleChange}
                      defaultChecked={
                        selectedFAQ?.reviewed === false
                      }
                    ></Form.Check>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {selectedFAQ?.qa_id ? (
            <Button type="submit">Save</Button>
          ) : (
            <Button type="submit">Add</Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
