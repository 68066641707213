import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import VotingOverlay from "../VotingOverlay";
import { useSelector } from "react-redux";
import { getCurrentDate } from "../../../../utils/timeUtils";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Award } from "./Award";

const TQActiveVoting = ({ technicalQuery, updateTechnicalHistory, votes, getVotes, dashboardTable }) => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const currentUserVote = votes.find((v) => v.voter.id === user?.id);

  const handleVote = async (vote) => {
    if (currentUserVote) {
      if (vote === currentUserVote.approved) {
        await axios
          .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/voting/${currentUserVote.id}/`, {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          .then(() => {
            getVotes();
          })
          .catch((error) => console.log("Unable to delete vote: " + error));
      } else {
        await axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/voting/${currentUserVote.id}/`,
            {
              approved: vote,
              vote_date: getCurrentDate(),
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then(() => {
            getVotes();
            if (dashboardTable) {
              updateTechnicalHistory({ tq_id: technicalQuery.tq_id, reason_for_update: "Updated Draft Response Vote" });
            } else {
              updateTechnicalHistory({ reason_for_update: "Updated Draft Response Vote" });
            }
          })
          .catch((error) => console.log("Unable to update vote: " + error));
      }
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/voting/`,
          {
            tq_id: technicalQuery.tq_id,
            voter: user?.id,
            approved: vote,
            vote_date: getCurrentDate(),
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then(() => {
          getVotes();
          if (dashboardTable) {
            updateTechnicalHistory({ tq_id: technicalQuery.tq_id, reason_for_update: "Updated Draft Response Vote" });
          } else {
            updateTechnicalHistory({ reason_for_update: "Updated Draft Response Vote" });
          }
        })
        .catch((error) => console.log("Unable to add vote: " + error));
    }
  };

  const getVoteCount = (approved) => {
    return votes.filter((v) => v.approved === approved).length;
  };

  const approvedVoteCount = getVoteCount(true);
  const rejectedVoteCount = getVoteCount(false);

  return (
    <div>
      <Col
        xs={1}
        className="d-flex align-items-center w-auto p-0 justify-content-end"
      >
        {!dashboardTable && <p className="m-0 mx-1">Please Vote Here!</p>}
        {/* negative vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            rejectedVoteCount > 0 ? (
              <Popover
                style={{
                  maxWidth: "500px",
                  width: "fit-content",
                }}
              >
                <Popover.Body>
                  {votes
                    .filter((vote) => vote.approved === false)
                    .map((vote, idx, arr) => (
                      <div key={vote.voter.id}>
                        <VotingOverlay
                          voter={vote.voter}
                          date={vote.vote_date}
                          divider={idx !== arr.length - 1}
                        />
                      </div>
                    ))}
                </Popover.Body>
              </Popover>
            ) : (
              <span></span>
            )
          }
        >
          <div className={`mx-2 d-flex ${dashboardTable ? "flex-column" : ""} align-items-center`}>
            {currentUserVote?.approved === false ? (
              <ThumbDownIcon
                data-testid="thumb-down-icon"
                style={{
                  color: "var(--bs-danger)",
                }}
                className="clickable"
                onClick={() => handleVote(false)}
              />
            ) : (
              <ThumbDownOffAltIcon
                data-testid="thumb-down-off-icon"
                className="clickable"
                onClick={() => handleVote(false)}
              />
            )}
            <p className={`${dashboardTable ? "mt-1" : "mx-2"} mb-0`}>{rejectedVoteCount}</p>
          </div>
        </OverlayTrigger>

        {/* positive vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            approvedVoteCount > 0 ? (
              <Popover
                style={{
                  maxWidth: "500px",
                  width: "fit-content",
                }}
              >
                <Popover.Body>
                  {votes
                    .filter((vote) => vote.approved === true)
                    .map((vote, idx, arr) => (
                      <div key={vote.voter.id}>
                        <VotingOverlay
                          voter={vote.voter}
                          date={vote.vote_date}
                          divider={idx !== arr.length - 1}
                        />
                      </div>
                    ))}
                </Popover.Body>
              </Popover>
            ) : (
              <span></span>
            )
          }
        >
          <div className={`mx-2 d-flex ${dashboardTable ? "flex-column" : ""} align-items-center`}>
            {currentUserVote?.approved ? (
              <ThumbUpIcon
                data-testid="thumb-up-icon"
                style={{
                  color: "var(--bs-success)",
                }}
                className="clickable"
                onClick={() => handleVote(true)}
              />
            ) : (
              <ThumbUpOffAltIcon
                data-testid="thumb-up-off-icon"
                className="clickable"
                onClick={() => handleVote(true)}
              />
            )}
            <p className={`${dashboardTable ? "mt-1" : "mx-2"} mb-0`}>{approvedVoteCount}</p>
          </div>
        </OverlayTrigger>

        {/* Award Icon */}
        <div className="mx-2 d-flex flex-column align-items-center">
          <Award votes={votes} />
          {dashboardTable && <p />}
        </div>
      </Col>
      {!["TD Required", "Resolved", "Proposed", "Accepted"].includes(technicalQuery?.status) &&
        permissions?.role_type === "NIAP" &&
        !dashboardTable && (
          <Row>
            <Col className="d-flex justify-content-end">
              <p
                className={`text-muted m-0 mt-1`}
                style={{ textWrapMode: "nowrap" }}
              >
                A total of {permissions?.role_type === "NIAP" ? "1 vote" : "3 votes"} needed to save and send to
                customer as {permissions?.role_type}.
              </p>
            </Col>
          </Row>
        )}
    </div>
  );
};

export default TQActiveVoting;
