import Accordion from 'react-bootstrap/Accordion';
import TrrtsLinkView from './TrrtsLinkView';

const MultipleTrrtsAccordionColumn = ( { rowData, filteredTrrts} ) => {
  return (
    <>
      <Accordion id='accordion-custom' data-testid='tq_accordion_view'>
        <Accordion.Item eventKey={rowData?.product_id}>
          <Accordion.Header id='accordion-header' data-testid='accordion_header'>
            <div className='me-1'>
              <TrrtsLinkView
                tq_id={filteredTrrts?.[0]?.tq_id}
                tq_title={filteredTrrts?.[0]?.title}
              />
            </div>
          </Accordion.Header>
          <Accordion.Body id='accordion-body-custom' data-testid='accordion_body'>
            {filteredTrrts?.map((trrt, index) =>
              <div key={index} className='mb-2'>
                {index > 0 &&
                  <TrrtsLinkView
                    tq_id={trrt?.tq_id}
                    tq_title={trrt?.title}
                  />
                }
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default MultipleTrrtsAccordionColumn;