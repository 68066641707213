import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const IndexedTabContentSite = ({ index, data }) => {
  return (
    <>
      {data.map((item, key) => {
        if (index == 'All') {
          return (
            <Row key={key} className='px-4 py-1'>
              <Col>
                <Link to={item?.link}>{item?.page}</Link>
              </Col>
            </Row>
          );
        } else if (
          item?.page?.toLowerCase().startsWith(`${index.toLowerCase()}`) ||
          item?.page?.toLowerCase().startsWith(`the ${index.toLowerCase()}`)
        ) {
          return (
            <Row key={key} className='px-4 py-1'>
              <Col>
                <Link to={item?.link}>{item?.page}</Link>
              </Col>
            </Row>
          );
        }
      })}
    </>
  );
};

export default IndexedTabContentSite;
