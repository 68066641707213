import { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

function ProductPagination({ setOffset, offset, total, PAGE_SIZE }) {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / PAGE_SIZE);

  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
    setOffset((pageNum - 1) * PAGE_SIZE);
  };

  useEffect(() => {
    setCurrentPage(Math.ceil(offset / PAGE_SIZE) + 1);
  }, [offset, PAGE_SIZE]);

  const paginationItems = () => {
    const pages = [];

    // Add first and previous pages
    pages.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageClick(1)}
        disabled={currentPage === 1}
      />,
      <Pagination.Prev
        key="prev"
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1}
      />
    );

    let startPage = Math.max(1, currentPage - Math.floor(PAGE_SIZE / 2));
    let endPage = Math.min(totalPages, startPage + PAGE_SIZE - 1);

    // adjusting the start and end page if current page is near the beginning or end
    if (currentPage <= Math.floor(PAGE_SIZE / 2)) {
      endPage = Math.min(totalPages, PAGE_SIZE);
    } else if (currentPage + Math.floor(PAGE_SIZE / 2) >= totalPages) {
      startPage = Math.max(1, totalPages - PAGE_SIZE + 1);
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    // add next and last pages
    pages.push(
      <Pagination.Next
        key="next"
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      />,
      <Pagination.Last
        key="last"
        onClick={() => handlePageClick(totalPages)}
        disabled={currentPage === totalPages}
      />
    );

    return pages;
  };

  return <Pagination className="pagination-controls">{paginationItems()}</Pagination>;
}

export default ProductPagination;
