import { Container, Row, Col, InputGroup, Accordion, Button, Form } from 'react-bootstrap';
import SearchIcon from "@material-ui/icons/Search";
import { useState, useEffect, useCallback } from "react";
import PaginationComponent from "../UI/Pagination/PaginationComponent";
import axios from 'axios';
import './faq.css';
import Subheader from '../Subheader';
import parse from "html-react-parser";
import qs from 'qs';

export default function FAQs() {

    const [categoryId, setCategoryId] = useState(1);
    const [categories, setCategories] = useState([])
    const [currentFaqs, setCurrentFaqs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSearch, setCurrentSearch] = useState()
    const [numFaqs, setNumFaqs] = useState(0)
    const faqsPerPage = 5;

    //set current page
    const paginate = (pageNumber, pageNumberLength) => pageNumber < 1 || pageNumber > pageNumberLength ? setCurrentPage(1) : setCurrentPage(pageNumber);

    const loadCategories =()=>{
         axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/categories/all_categories_with_qas`)
        .then(response => {
            setCategories(response.data);
            changeFaqCategory(response.data[0]?.category_id)
        }).catch(error => {
            console.log('error getting categories: ', error)
        })
    }

    const loadQuestions = useCallback(() => {
        const offset = (currentPage - 1) * faqsPerPage
        const params = {
            category: categoryId,
            limit: faqsPerPage,
            offset: offset,
            search: currentSearch
        }
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/published_questions/?${qs.stringify(params, {arrayFormat: "comma"})}`)
        .then(response => {
            setCurrentFaqs(response.data.results);
            setNumFaqs(response.data.count)
        }).catch(error => {
            console.log('error getting question and answers: ', error)
        })
    }, [categoryId, currentPage, currentSearch])

    useEffect(() => {
        loadCategories()
    }, [])

    useEffect(() => {
        loadQuestions()
    }, [loadQuestions])

    const handleSearch = (e) => {
        const search = e.target.value.toLowerCase()
        setCurrentSearch(search)
    }

    const changeFaqCategory = (category_id) => {
        setCategoryId(category_id);
        setCurrentPage(1)
    }

    useEffect(() => {
        document.title = 'NIAP - FAQs';
    }, []);

    return (
        <>
            <Subheader pageName={"FAQs"} />
            <Container id="faq-container">
            <Row className='mt-4 mb-3'><h2 className='text-uppercase text-primary'>Frequently asked questions</h2></Row>
            <Row className='d-flex'>
                <Col sm={4} className='ms-auto' id="search-col">
                    <InputGroup>
                        <Form.Label htmlFor="search" className="visually-hidden">Search</Form.Label>
                        <Form.Control type="text" name="search" id="search" placeholder="Search" onChange={handleSearch} />
                        <InputGroup.Text className='bg-transparent'><SearchIcon color="action" /></InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
                <Row>
                    <Col xs={3} sm={2} className='bg-transparent px-0'>
                        {categories.map((category, i) => (
                            <Button key={i} style={{ wordWrap: 'break-word' }} className='rounded-0 border-0 border-bottom border-white w-100 py-3 text-white' 
                                variant={(category?.category_id === categoryId) ? 'bright-navy' : 'primary'} onClick={() => changeFaqCategory(category?.category_id)}>
                                <h6>{category?.category_name}</h6>
                            </Button>
                        ))}
                    </Col>
                    <Col xs={9} sm={10}>
                        <Row>
                            <Col className='m-md-5'>
                                {(categories.length > 0) && <h4 className='text-primary text-uppercase mb-5'>{categories.find(c => c?.category_id === categoryId)?.category_name}</h4>}
                                {currentFaqs.map((faq, i) => {
                                    return <Accordion key={i} className='mb-4' flush>
                                        <Accordion.Item>
                                            <Accordion.Header><h6 className='text-primary fw-bold text-uppercase'>{faq?.question}</h6></Accordion.Header>
                                            <Accordion.Body style={{ wordWrap: 'break-word' }}>{faq?.answer && parse(faq.answer.replace(/<p><a href=.*?>Send a Response<\/a><\/p>/, ''))}</Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion> 
                                })}
                            </Col>
                        </Row>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <PaginationComponent
                            tabAlertsLength={numFaqs}
                            alertsPerPage={faqsPerPage}
                            currentPage={currentPage}
                            paginate={paginate}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
