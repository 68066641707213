import { Container, Row, Col, Modal } from 'react-bootstrap';
import govCustomersImg from '../../../images/niap_ccevs/gov_customers.png';

export default function GovCustomers({show, setShowGovCustomersModal}) {

  const handleCloseModal = () => {
    setShowGovCustomersModal(false)
  }

  return (
    <Modal show={show} onHide={handleCloseModal} size='lg'>
    <Modal.Header className='border-0' closeButton></Modal.Header>
    <Modal.Body className='px-5'>
    <Container fluid>
      <Row>
        <Col>
          <Row>
              <Col>
                <div className='d-flex justify-content-center'>
                  <img
                    src={govCustomersImg}
                    alt='gov customers image'
                    className='w-auto'
                    height='120px'
                  />
                </div>
                <div className='text-center'>
                  <h3 className='fw-bold mt-3 mb-4 text-primary'>
                    U.S. Government Customers
                  </h3>
                </div>
              </Col>
            </Row>
          <Row>
            <h5 className='fw-bold text-primary'>
              About Us
            </h5>
          </Row>
          <Row>
            <ul className='remove-dots mx-4'>
                <li>NIAP/Common Criteria Fact Sheet</li>
                <li>Evaluation Process</li>
            </ul>
          </Row>
          <Row className='mt-5'>
            <h5 className='fw-bold text-primary'>Products</h5>
          </Row>
          <Row>
            <ul className='remove-dots mx-4'>
                <li>Product Compliant List</li>
                <li>Validated Products List</li>
                <li>Archived Product Compliance List</li>
                <li>Products in Evaluation</li>
            </ul>
          </Row>
          <Row className='mt-5'>
            <h5 className='fw-bold text-primary'>Technical Communities</h5>
          </Row>
          <Row className='mt-5'>
            <h5 className='fw-bold text-primary'>
              Protection Profiles
            </h5>
          </Row>
          <Row>
            <ul className='remove-dots mx-4'>
                <li>NIAP Approved Protection Profiles</li>
                <li>Protection Profiles in Development</li>
                <li>Archived Protection Profiles</li>
                <li>Guidelines When No Protection Profile Exists</li>
            </ul>
          </Row>
          <Row className='mt-5'>
            <h5 className='fw-bold text-primary'>
            Policies that Influence Adherence to the Common Criteria
            </h5>
          </Row>
          <Row className='mb-3'>
            <ul className='remove-dots mx-4'>
                <li>NSD 42</li>
                <li>CNSSP-11</li>
                <li>NIST Special Pub 800-53</li>
                <li>Relationship of NIAP Archived Products to CNSSP-11</li>
            </ul>
          </Row>
        </Col>
      </Row>
    </Container>
    </Modal.Body>
    </Modal>
  );
}