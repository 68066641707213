import { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import NewUser from '../NewUser';
import Spinner from 'react-bootstrap/Spinner'
const LoginForm = () => {
    const [cookies, setCookies, removeCookies] = useCookies()
    const csrfToken = cookies['csrftoken']
    const [loginInfo, setLoginInfo] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const handleCloseModal = () => {
        setShow(false)
    }

    const navigate = useNavigate()

    const handleChange = (e) => {
        setLoginInfo({...loginInfo, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}auth/login/`, loginInfo, {withCredentials: true, headers: {'X-CSRFToken': csrfToken}}).then((response) => {
            const ephemeralToken = response.data['ephemeral_token']
            const mfaMethod = response.data['method']
            const authToken = response.data['auth_token']
            if (!ephemeralToken) {
                const currentDate = new Date()
                const expiresDate = new Date()
                expiresDate.setMinutes(currentDate.getMinutes() + 5)
                setCookies('temp_token', authToken, {path: '/'})
                navigate('/login/mfa-select')
            } else {
                axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}temp-token/`, loginInfo, {withCredentials: true, headers: {'X-CSRFToken': csrfToken}}).then((response) => {
                    const authToken = response.data['token']
                    const currentDate = new Date()
                    const expiresDate = new Date()
                    expiresDate.setMinutes(currentDate.getMinutes() + 5)
                    setCookies('temp_token', authToken, {path: '/'})
                    navigate(`/login/mfa-confirm?ephemeral-token=${ephemeralToken}&mfa-method=${mfaMethod}`)
                }).catch((token_error) => {
                    console.log(token_error.response.data)
                })
                .finally(()=>{
                  setLoading(false)
                })
            }
        }).catch((error) => {
            console.log(error.response)
            if (error.response.data?.detail?.includes("CSRF Failed")) {
                removeCookies('csrftoken', {path: '/'})
                axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}reload_csrf`).then(response => {
                    setCookies('csrftoken', response.data.csrfToken, {path: '/'})
                })
                setFormErrors({error: 'Incorrect Cookies Cleared. Please Try Again'})
            } else {
                setFormErrors(error.response.data)
            }
        })
        .finally(()=>{
          setLoading(false)
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      
      const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };

    return (
        <>
            <Row className="border border-danger border-5 text-danger m-5 p-5 text-center">
                <Col>
                    <h4>Notice concerning account migration</h4>
                    <h5>The migration progress to the new site preserved accounts, but not passwords. If you had an account on the previous site and are recieving a credentials error, please first attempt to reset your password.
                        If the password reset fails then please submit a <a href="https://niap.atlassian.net/servicedesk/customer/portals">ticket to our service desk here</a> with your account username and a contact email.
                        A service desk technician will update your account and contact you with your temporary password.
                    </h5>
                </Col>
            </Row>
            <Card className='login-card'>
                <Card.Body>
                    <Card.Title as='h4'>Welcome to the Login Page for the NIAP Community</Card.Title>
                    <p>If you are currently a NIAP Community member, please login below.</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='mb-4 text-start' controlId='username'>
                            <Form.Label className='small text-secondary'>Username*</Form.Label>
                            <Form.Control type="text" name="username" onChange={handleChange} required={true} />
                        </Form.Group>
                        <Form.Group className='mb-4 text-start' controlId='password'>
                            <Form.Label className='small text-secondary'>Password*</Form.Label>
                            <Input
                                type={showPassword ? "text" : "password"}
                                onChange={handleChange}
                                id="password"
                                className="form-control"
                                name="password"
                                disableUnderline={true}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                    <span className='visually-hidden'>Toggle Visiblity</span>
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </Form.Group>
                        {formErrors && <p className='mb-4 text-danger'>{formErrors.error}</p>}
                        <Button variant="warning" disabled={loading} type={loading ? "button" : "submit"}>
                            {loading ? <><Spinner animation="border" size="sm" /> Login</> : 'Login'}
                        </Button>
                    </Form>
                    <p className="m-4 small text-secondary"><Link to="/login/forgot-password">Forgotten Password?</Link></p>
                </Card.Body>
            </Card>
        </>
    )
}

export default LoginForm