import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from 'react-redux'


export default function CheckOutSignatureModal({
  show,
  handleClose,
  product,
  setOverlayActive,
  setOverlayText
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [postingData, setPostingData] = useState({})
  const permissions = useSelector(state => state.role.value)

  useEffect(() => {
    setPostingData({...postingData, product_id: product?.product_id, post_certificate: "false", post_to_iccc: "false", post_to_ccuf: "false"})
  }, [product])

  const handleChange = (e) => {
    if (e.target.name === "post_certificate" && e.target.value === "false") {
      setPostingData({...postingData, [e.target.name]: e.target.value, post_to_iccc: "false", post_to_ccuf: "false"})
    } else {
      setPostingData({...postingData, [e.target.name]: e.target.value})
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setOverlayText("Redirecting")
    setOverlayActive(true)
    handleClose()
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/sign_checkout/`,
        {...postingData, signer: permissions.role_type === 'Lab' ? 'CCTL' : 'Vendor'},
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setOverlayActive(false)
        window.location.href = response.data.redirect_url
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
       handleClose();
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title color="primary">
            Certificate Posting and Signature
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Would you like the certificate presented publicly? If yes, please select a venue below. If no, the signed certificate will be emailed to the POC for {product?.assigned_lab?.name}.</p>
            <Form.Check type='radio' value={true} name="post_certificate" label="Yes" inline onChange={handleChange} />
            <Form.Check type='radio' value={false} name="post_certificate" label="No" inline onChange={handleChange} />
            <Form.Check type='checkbox' value={true} name="post_to_iccc" onChange={handleChange} label="International Common Criteria Conference (ICCC) - September timeframe, rotates Internationally" disabled={!postingData.post_certificate || postingData.post_certificate === 'false'} checked={postingData.post_to_iccc === 'true'} />
            <Form.Check type='checkbox' value={true} name="post_to_ccuf" onChange={handleChange} label="Common Criteria User’s Forum Meeting - April a& September timeframe, rotates Internationally." disabled={!postingData.post_certificate || postingData.post_certificate === 'false'} checked={postingData.post_to_ccuf === 'true'} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            type="button"
            onClick={() => {
              handleClose();
            }}>
            Cancel
          </Button>
          <Button 
            variant="primary"
            type="submit"
            onClick={() => handleSubmit()}>
                Sign
            </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
