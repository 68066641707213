import { Route, Routes } from "react-router-dom";
import AccountManagerView from "./AccountManager";
import UnauthorizedView from "../UI/UnauthorizedView"
import { useSelector } from "react-redux";

export default function AccountManager() {
  const permissions = useSelector(state => state.role.value);

  if (!permissions?.role_permissions?.find(permission => permission.area === 'Accounts')?.read && 
    !permissions?.role_permissions?.find(permission => permission.area === 'Organization Members')?.read) {
      return (
          <UnauthorizedView />
      )
  }

  return (
    <Routes>
        <Route path="/" element={<AccountManagerView />} />
    </Routes>
  )
}
