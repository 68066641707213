import { useState } from "react";
import { Accordion } from "react-bootstrap";
import "../../Products.css";
import parse from "html-react-parser";

import "./ProjectDetailsAccordion.css";

export default function ProjectDetailsAccordion({ project }) {
  const [activeKey, setActiveKey] = useState([0]);

  const handleItemClick = (idx) => {
    let newKeys = [...activeKey];
    if (newKeys.includes(idx)) {
      newKeys = newKeys.filter((key) => key !== idx);
    } else {
      newKeys.push(idx);
    }
    setActiveKey(newKeys);
  };

  const projectDescriptionFields = [
    {
      title: "Product Description",
      name: "product_description",
      text: project?.product_description,
    },
    {
      title: "Security Evaluation Summary",
      name: "security_evaluation_summary",
      text: project?.security_evaluation_summary,
    },
    {
      title: "Environmental Strengths",
      name: "environmental_strengths",
      text: project?.environmental_strengths,
    },
    {
      title: "Evaluation Configuration",
      name: "evaluation_configuration",
      text: project?.evaluation_configuration,
    },
  ];

  return (
    <>
      <Accordion activeKey={activeKey} alwaysOpen>
        {projectDescriptionFields.map((pd, idx) => (
          <Accordion.Item eventKey={idx} key={idx} className="border-bottom-dark">
            <Accordion.Header as="h6" onClick={(e) => handleItemClick(idx)}>
              {pd.title}
            </Accordion.Header>
            <Accordion.Body>{pd.text && parse(pd.text)}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}
