import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { Stack, Accordion, Button, Container, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import axios from "axios";
import FileDownload from "js-file-download";
import FileUpload from "../Products/FileUpload";
import moment from "moment";

import { useInterval } from "../../hooks/useInterval";

export default function ECRSideInfo({
  comment,
  id,
  setOverlayActive,
  setOverlayText
}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [showUpload, setShowUpload] = useState(false);
  const [files, setFiles] = useState([]);

  const downloadFile = (file) => {
    setOverlayText("Downloading")
    setOverlayActive(true)
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        setOverlayActive(false)
        FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pullFiles = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=ecr-comment&file_type_id=${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setFiles(response.data?.filter(file => file.active == true));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken, id]);

  const updateFiles = () => {
    setShowUpload(false);
    pullFiles();
  };

  useEffect(() => {
    pullFiles();
  }, [pullFiles]);

  useInterval(()=>{
    pullFiles()
  }, 180000) //3 min

  const sortedFiles = files?.sort((a, b) => new Date(b.uploaded_on) - new Date(a.uploaded_on));

  return (
    <div>
      <FileUpload
        show={showUpload}
        updateFiles={updateFiles}
        type="ecr-comment"
        id={id}
      />
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item>
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">ATTACHED DOCUMENTS</h6>
          </Accordion.Header>
          <Accordion.Body className="text-center bg-light p-1">
            {sortedFiles?.map((file, idx) => (
                <Stack  direction='horizontal' className='d-flex justify-content-left align-items-start' key={idx}>
                    {file.file_mime_type === 'application/pdf' && <FontAwesomeIcon icon={faFilePdf} color='red' size='xl' />}
                    {file.file_mime_type !== 'application/pdf' && <FontAwesomeIcon icon={faFileLines} color='gray' size='xl' />}
                    <div className="ms-2 text-break">
                        <Button style={{overflowWrap: "break-word"}} className="text-start m-0 p-0" variant="link" onClick={() => downloadFile(file)}>
                            {file.file_label ? file.file_label : file.file_new_name ? file.file_new_name : file.file_name}
                        </Button>
                        <p className="mb-0 ms-3">
                            {file.file_new_name ? file.file_new_name : file.file_name}
                        </p>
                        <p className="mb-0 ms-3">
                            Attached by {file?.uploaded_by?.first_name} {file?.uploaded_by?.last_name} on {file?.uploaded_on && moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
                        </p>
                    </div>
                </Stack>
            ))}
            <Button
              variant="outline-primary rounded-pill mb-2"
              onClick={() => setShowUpload(true)}
            >
              Upload Files
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item>
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">ATTRIBUTION INFORMATION</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container className="">
              <Row className="border-dark border-bottom">
                <p>
                  Record Created: {moment.utc(comment?.entrydate).format("YYYY.MM.DD [at] HHmm")}{" "}
                  by {comment?.creator?.first_name} {comment?.creator?.last_name}
                </p>
              </Row>
              <Row className="border-dark border-bottom">
                <p>
                  Last Modified:{" "}
                  {comment?.moddate
                    ? `${moment.utc(comment?.moddate).format("YYYY.MM.DD [at] HHmm")} by
                  ${comment?.editor?.first_name} ${comment?.editor?.last_name}`
                    : ""}
                </p>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
