import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useState } from "react";

const TechDecisionAuditModal = ({
  show,
  handleSave,
  saveTextFields,
  id,
  auditType,
  handleClose,
}) => {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [update, setUpdate] = useState({});

  const handleChange = (e) => {
    setUpdate({ ...update, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/history/`,
        {
          td_id: id,
          ...update,
        },
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
        }
      )
      .then(() => {
        if (auditType === "detail") {
          handleSave();
        } else if (auditType === "reset") {
          saveTextFields(e, "Reset Votes");
        } else {
          saveTextFields(e);
        }
      })
      .catch((error) => console.log(error.response.data));
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase">Update Technical Decision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="reason_for_update" className="my-2">
            <Form.Label>Reason For Update*</Form.Label>
            <Form.Control
              as="textarea"
              name="reason_for_update"
              onChange={handleChange}
              required={true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit">Save Updates</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TechDecisionAuditModal;
