import { Row, Col } from "react-bootstrap";

const IndexedTabContent = ({index, data}) => {
    return (
        <>
            {data.map((item, key) => {
                if (index == 'All') {
                    return (
                        <Row key={key} className="px-4 py-1">
                            <Col md={3} sm={6} xs={12}>
                                {item?.term}
                            </Col>
                            <Col md={9} sm={6} xs={12}>
                                {item?.definition}
                            </Col>
                        </Row>
                    )
                } else if (item?.term?.toLowerCase().startsWith(`${index.toLowerCase()}`) || item?.term?.toLowerCase().startsWith(`the ${index.toLowerCase()}`)) {
                    return (
                        <Row key={key} className="px-4 py-1">
                            <Col md={3} sm={6} xs={12}>
                                {item?.term}
                            </Col>
                            <Col md={9} sm={6} xs={12}>
                                {item?.definition}
                            </Col>
                        </Row>
                    )
                }
            })}
        </>
    )
}

export default IndexedTabContent