import { Modal } from 'react-bootstrap';
import InEvaluationProductsLogo from "../../../../images/products/ProductsInEvaluation.png";

export default function ProductsInEvalModal({showModal, handleShowModal}) {
  return (
    <Modal show={showModal} onHide={handleShowModal} size='lg'>
        <Modal.Header className='border-0' closeButton></Modal.Header>
        <Modal.Body className='px-5'>
          <div className="text-center"><img src={InEvaluationProductsLogo} height='150px' alt='In Evaluation Products Logo' /></div>
          <h2 className="text-uppercase fw-bold text-primary text-center pb-3">Products In Evaluation</h2>
          <p>The security products in the evaluation list are in various stages of NIAP evaluation. 
          There is no guarantee that any of these products will complete evaluation successfully.</p>
          <p>In order for a product to be placed on this list the product must currently be undergoing evaluation in the United States 
          with a NIAP-approved Common Criteria Testing Laboratory (CCTL) and have completed an Evaluation Kick off Meeting (or equivalent) 
          whereby CCEVS management has officially accepted the product into evaluation. CCEVS will only accept new products to be posted to 
          this site through a NIAP-approved CCTL. Contact your CCTL to submit evaluation acceptance paperwork to CCEVS. 
          Please be aware that information in entries listed below such as product or protection profile names, versions, etc. may change as the evaluation 
          progresses; therefore, the viewer should always confirm the below entry information with the sponsor contact. Entries on this list will be 
          removed when either a Common Criteria Certificate has been issued for the product, or the CCTL or vendor is no longer pursuing evaluation 
          of the product under CCEVS procedures.</p>
        </Modal.Body>
    </Modal>
  );
}
