import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Alert, Button, Accordion, Container, Row, Col } from "react-bootstrap";
import "../../Products.css";
import parse from "html-react-parser";
import ECRCommentsTable from "../../../ECR/ECRCommentsTable";
import HTMLEditor from '../../../UI/HTMLEditor';
import "../ProductDetailsAccordions/ProjectDetailsAccordion.css"
import { useInterval } from "../../../../hooks/useInterval";

export default function AssuranceMaintenanceAccordion({
    maintenance,
    refetch,
    permissions,
    amProgressPoints
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [activeKey, setActiveKey] = useState([0,1]);
    const [editedHtmlFields, setEditedHtmlFields] = useState({});
    const [showAlert, setShowAlert] = useState("");
    const [ecrCounts, setEcrCounts] = useState({});
    const [isEditing, setIsEditing] = useState(false)
    const expandAll = () => {
        const allKeys = [];
        projectDescriptionFields.forEach((pd, idx) => {
            allKeys.push(idx);
        });
        setActiveKey(allKeys);
    };

    const collapseAll = () => {
        setActiveKey([]);
    };

    const handleItemClick = (idx) => {
        let newKeys = [...activeKey];
        if (newKeys.includes(idx)) {
            newKeys = newKeys.filter((key) => key !== idx);
        } else {
            newKeys.push(idx);
        }
        setActiveKey(newKeys);
    };

    const handleChange = (e) => {
        setEditedHtmlFields({ ...editedHtmlFields, [e.target.name]: e.target.value })
    }

    const fetchEcrCounts = useCallback(() => {
        axios
            .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/count_comments?maintenance=${maintenance?.maintenance_id}`,
                {
                    withCredentials: true,
                    headers: { Authorization: `Token ${authToken}` },
                }
            )
            .then((response) => {
                setEcrCounts(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [maintenance])

    useEffect(() => {
        fetchEcrCounts()
    }, [fetchEcrCounts])

    useInterval(()=>{
        fetchEcrCounts()
    }, 180000) //3 min

    const handleSaveAll = () => {
        axios
            .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance.maintenance_id}/`,
                editedHtmlFields,
                {
                    withCredentials: true,
                    headers: { 'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}` }
                })
            .then((response) => {
                setEditedHtmlFields({});
                setShowAlert('success');
                refetch();
            })
            .catch((error) => setShowAlert('fail'));
    }

    const projectDescriptionFields = [
        {
            title: "Maintenance Description",
            name: "maintenance_description",
            text: maintenance?.maintenance_description
        },
        {
            title: "Change Summary",
            name: "change_summary",
            text: maintenance?.change_summary
        },
        { title: "ECR Comments" },
    ];
    return (
        <Container>
            <Row>
                <Col>
                    {(
                        Object.keys(editedHtmlFields).length > 0 &&
                        showAlert === ""
                    ) &&
                        <Alert variant="danger">
                            Please Save Changes!
                        </Alert>
                    }
                    {showAlert === "success" &&
                        <Alert variant="success" dismissible onClose={() => setShowAlert("")}>
                            Changes successfully saved!
                        </Alert>
                    }
                    {showAlert === 'fail' &&
                        <Alert variant="danger" dismissible onClose={() => setShowAlert("")}>
                            Changes failed to save!
                        </Alert>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {activeKey.length !== projectDescriptionFields.length && (
                        <Button
                            variant="link"
                            onClick={expandAll}
                            className="text-primary ml-auto"
                        >
                            Expand All
                        </Button>
                    )}
                    {activeKey.length === projectDescriptionFields.length && (
                        <Button
                            variant="link"
                            onClick={collapseAll}
                            className="text-primary ml-auto"
                        >
                            Collapse All
                        </Button>
                    )}
                </Col>
                <Col className="d-flex justify-content-end mb-2">
                    {Object.keys(editedHtmlFields).length > 0 ? (
                        <>
                            <Button
                                className="mx-2"
                                variant="success"
                                onClick={() => {
                                    handleSaveAll();
                                    setIsEditing(false);
                                }}
                            >
                                Save All
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setIsEditing(false);
                                    setEditedHtmlFields({});
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (permissions?.role_type === "NIAP" && amProgressPoints[3]?.status === "Completed" && (
                        !isEditing ? (
                        <Button variant="success" onClick={() => setIsEditing(true)}>
                            Edit Fields
                        </Button>):(
                        <Button variant="secondary" onClick={() => setIsEditing(false)}>
                            Cancel
                        </Button>)
                        )
                    )}
                </Col>
            </Row>
            <Accordion activeKey={activeKey} alwaysOpen flush>
                {projectDescriptionFields.map((pd, idx) => (
                    <Accordion.Item eventKey={idx} key={idx} className={pd.title !== "ECR Comments" ? "border-bottom-dark" : ""}>
                        <Accordion.Header onClick={(e) => handleItemClick(idx)}>
                            <h6>{pd.title}</h6>
                            {pd.title === "ECR Comments" && <div>
                                <span className='border my-2 mx-4 p-2'>Resolved: {ecrCounts?.resolved}</span>
                                {permissions?.role_type !== "Lab" &&
                                <span className='border my-2 mx-4 p-2'>Unresolved: {ecrCounts?.unresolved}</span>
                                }
                                <span className='border my-2 mx-4 p-2'>Total: {ecrCounts?.total}</span>
                            </div>}
                        </Accordion.Header>
                        <Accordion.Body>
                            <>  
                                {pd.title === "ECR Comments" && maintenance?.maintenance_id &&(
                                    <ECRCommentsTable
                                        maintenance={maintenance}
                                        fetchEcrCounts={fetchEcrCounts}
                                    />
                                )}
                                {
                                    <>
                                        {
                                            ((amProgressPoints[3]?.status === "In Progress" &&
                                                permissions?.role_type === "NIAP") || isEditing) &&
                                                (
                                                    pd.title === "Maintenance Description" ||
                                                    pd.title === "Change Summary"
                                                )
                                                ?
                                                <HTMLEditor
                                                    name={pd.name}
                                                    handleChange={handleChange}
                                                    setIsValid={() => { }}
                                                    isValid={true}
                                                    defaultValue={pd.text}
                                                />
                                                :
                                                pd.text && parse(pd.text)
                                        }
                                    </>
                                }
                            </>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </Container>
    );
}