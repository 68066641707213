import { Row, Col, Button } from "react-bootstrap";
import { useState, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useInterval } from "../../../../hooks/useInterval";
import { useSelector } from "react-redux";
import axios from "axios";
import ValidatorProficiencyFeedbackForm from "../ProficiencyFeedbackForms/ValidatorProficiencyFeedbackSections";
import VendorProficiencyFeedbackForm from "../ProficiencyFeedbackForms/VendorProficiencyFeedbackForm";
import moment from "moment";
import DeleteModal from "../../../UI/DeleteModal";

export default function WithdrawalTerminationFeedbackHeader({
    product,
    setAlertInfo,
    progressPoints,
    validationReport,
    refetch
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const user = useSelector((state) => state.user.value);
    const permissions = useSelector((state) => state.role.value);
    const [requestWithdrawalInitiated, setRequestWithdrawalInitiated] = useState(false);
    const [niapReviewWithdrawal, setNiapReviewWithdrawal] = useState("");
    const [proficiencyFeedbackForms, setProficiencyFeedbackForms] = useState({});
    const [uploadedProficiencyFeedbackForms, setUploadedProficiencyFeedbackForms] = useState([]);
    const [showValidatorProficiencyFeedbackForm, setShowValidatorProficiencyFeedbackForm] = useState(false);
    const [showVendorProficiencyFeedbackForm, setShowVendorProficiencyFeedbackForm] = useState(false);
    const [requestWithdrawalCompleted, setRequestWithdrawalCompleted] = useState(false)
    const [hideWithdrawalRequest, setHideWithdrawalRequest] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)


    const handleCreateAlerts = async (groups, text) => {
        //sending alert to labs, vendors, and NIAP
        for (let group in groups) {
            let data = {
                alert_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                alert_type: "Product",
                alert_text: text,
                alert_type_id: product?.product_id,
                active: true,
                alert_to: null,
                alert_source: "Withdrawal Request",
                alert_to_vt: null,
                alert_to_group: null,
            };
            if (groups[group] === "NIAP") {
                data["alert_to_role"] = "NIAP";
            } else {
                data["alert_to_group"] = groups[group];
            }
            await axios
                .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/`, data, {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                })
                .then((response) => {})
                .catch((error) =>
                    console.log(
                        "Unable to create alert for Withdrawal Request.",
                        error
                    )
                );
        }
    };

    // const handleAlertAndMailer = (type) => {
    //     let recipients = {to: ["NIAP Management"], cc: []}
    //     let subject = ""
    //     let alert_text = ""
    //     if(type === "Withdrawal"){
    //         subject = `VID${product?.v_id} – Withdrawal Letter`
    //         alert_text = `VID${product?.v_id} has been successfully withdrawn.`
    //     } else {
    //         subject = `VID${product?.v_id} – Termination`
    //         alert_text = `VID${product?.v_id} has been successfully terminated.`
    //     }
    //     axios 
    //         .post( 
    //         `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
    //         { 
    //             subject: subject,
    //             recipients: recipients,
    //             alert_type: "Product",
    //             alert_text: alert_text,
    //             alert_type_id: product?.product_id,
    //         },
    //         {
    //             withCredentials: true,
    //             headers: {
    //             "X-CSRFToken": csrfToken,
    //             Authorization: `Token ${authToken}`,
    //             },
    //         })
    //         .then((response) => {})
    //         .catch((error) => console.log("Failed to send mail.")
    //         );
    // }

    // const handleRequestWithdrawalAlertAndMailer = () => {
    //     let recipients = {to: ["NIAP Management"], cc: []}
    //     axios 
    //         .post( 
    //         `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
    //         { 
    //             subject: "Request for Withdrawal Received",
    //             recipients: recipients,
    //             alert_type: "Product",
    //             alert_text: "A request for withdrawal has been received for VID" + product?.v_id + ".",
    //             alert_type_id: product?.product_id,
    //         },
    //         {
    //             withCredentials: true,
    //             headers: {
    //             "X-CSRFToken": csrfToken,
    //             Authorization: `Token ${authToken}`,
    //             },
    //         })
    //         .then((response) => {})
    //         .catch((error) => console.log("Failed to send mail.")
    //         );
    // }

    const handleWithdrawalRequest = (e, by, type) => {
        let data = {};
        if (by === "NIAP") {
            if (type === "Accept") {
                data = {
                    status: "Withdrawn",
                    withdrawal_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                    request_withdrawal: false,
                };
            } else {
                data = {
                    request_withdrawal: false,
                    request_withdrawal_date: null,
                    request_withdrawal_user: null,
                };
            }
        } else {
            data = {
                request_withdrawal: true,
                request_withdrawal_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                request_withdrawal_user: user?.id,
            };
        }

        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
                data,
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                //sending alert to labs, vendors, and NIAP
                if(type === "Accept"){
                    // handleAlertAndMailer("Withdrawal") //is sending success email and alert
                    setAlertInfo({
                        variant: "success",
                        message: `Product VID${product.v_id} successfully withdrawn.`,
                      });
                }else if(data.request_withdrawal === true){
                    setAlertInfo({
                        variant: "success",
                        message: `Product VID${product.v_id} withdrawal successfully requested.`,
                      });
                    //   handleRequestWithdrawalAlertAndMailer()
                }else{
                    setAlertInfo({
                        variant: "success",
                        message: `Product VID${product.v_id} withdrawal successfully denied.`,
                      });
                }
                setRequestWithdrawalInitiated(false)
                setRequestWithdrawalCompleted(true)

                let message = "";
                if (by === "NIAP") {
                    if (type !== "Accept") {
                        message = `VID ${product?.v_id} ${product?.product_name} has been denied for withdrawal.`;
                    }
                } else {
                    message = `VID ${product?.v_id} ${product?.product_name} has been requested for withdrawal.`;
                }
                handleCreateAlerts(
                    [
                        product?.vendor_id?.org_id,
                        product?.assigned_lab?.org_id,
                        "NIAP",
                    ],
                    message
                );
                refresh();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getProficiencyFeedbackForms = useCallback(() => {
        if(product.product_id){
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}proficiency-feedback/get_all_proficiency_feedback_forms_by_product/?product_id=${product?.product_id}`,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    setProficiencyFeedbackForms(response.data);
                })
                .catch((error) =>
                    setProficiencyFeedbackForms([])
                );
        }
    }, [product?.product_id]);

    const getUploadedProficiencyFeedbackForms = useCallback(() => {
        if(product.product_id){
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_proficiency_feedback_forms_by_product/?product_id=${product?.product_id}`,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    setUploadedProficiencyFeedbackForms(response.data);
                })
                .catch((error) =>
                    setUploadedProficiencyFeedbackForms([])
                );
        }
    }, [product?.product_id]);

    const refresh = () => {
        getProficiencyFeedbackForms();
        getUploadedProficiencyFeedbackForms()
    };

    useEffect(() => {
        getProficiencyFeedbackForms();
    }, [getProficiencyFeedbackForms]);

    useInterval(() => {
        getProficiencyFeedbackForms();
    }, 600000); //10 min

    useEffect(() => {
        getUploadedProficiencyFeedbackForms();
    }, [getUploadedProficiencyFeedbackForms]);

    useInterval(() => {
        getUploadedProficiencyFeedbackForms();
    }, 600000); //10 min

    const terminateProduct = () =>{
        axios
        .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
            {
                status: "Terminated",
                publish_status: "Unpublished"
            },
            {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                },
            }
        )
        .then((response) => {
            // handleAlertAndMailer("Terminate")
            refetch();
            setShowDeleteModal(false)

        })
        .catch((error) => {
            console.log(error);
        }); 
    }

    const proficiencyFeedbackFormButtons = () => {
        if (
            (
                progressPoints[6]?.status === 'Completed' || 
                product?.status === "Withdrawn"
            ) &&
            permissions?.role_type === "Validator" &&
            proficiencyFeedbackForms?.validator_form?.is_draft !== false && 
            uploadedProficiencyFeedbackForms.length === 0
        ) {
            return (
                <Col sm={4} className="w-auto">
                    <Button
                        variant={
                            proficiencyFeedbackForms?.validator_form
                                ?.is_draft === true
                                ? "outline-success"
                                : "success"
                        }
                        className="me-3 mb-2 rounded-pill"
                        onClick={() =>
                            setShowValidatorProficiencyFeedbackForm(true)
                        }
                    >
                        {proficiencyFeedbackForms?.validator_form?.is_draft ===
                        true
                            ? "Continue"
                            : "Complete"}{" "}
                        Proficiency Feedback Form
                    </Button>
                </Col>
            );
        } else if (
            (
                // progressPoints[6]?.status === 'Completed' ||
                // product?.status === "Withdrawn"
                product?.status === "Certified"
            ) &&
            permissions?.role_type === "Vendor" &&
            proficiencyFeedbackForms?.vendor_form?.is_final_submission !== true && 
            uploadedProficiencyFeedbackForms.length === 0
        ) {
            return (
                <Col sm={4} className="w-auto">
                    <Button
                        variant={
                            proficiencyFeedbackForms?.vendor_form
                                ?.is_final_submission === false
                                ? "outline-success"
                                : "success"
                        }
                        className="me-3 mb-2 rounded-pill"
                        onClick={() => setShowVendorProficiencyFeedbackForm(true)}
                    >
                        {proficiencyFeedbackForms?.vendor_form?.is_final_submission ===
                        false
                            ? "Continue"
                            : "Complete"}{" "}
                        End of Evaluation Survey
                    </Button>
                </Col>
            );
        } else if (
            permissions?.role_type === 'NIAP' && (
                progressPoints[6]?.status === 'Completed' || 
                ["Certified", "Withdrawn", "Terminated"]?.includes(product?.status)
            )
        ){
            return (
                <>
                {proficiencyFeedbackForms?.validator_form?.is_draft === false && (
                    <Col sm={4} className="w-auto">
                        <Button
                            variant="primary"
                            className="me-3 mb-2 rounded-pill"
                            onClick={() =>
                                setShowValidatorProficiencyFeedbackForm(true)
                            }
                        >
                            View Proficiency Feedback Form
                        </Button>
                    </Col>
                )}
                {
                    proficiencyFeedbackForms?.vendor_form?.is_final_submission && 
                    proficiencyFeedbackForms?.vendor_form?.is_final_submission === true && (
                    <Col sm={4} className="w-auto">
                        <Button
                            variant="primary"
                            className="me-3 mb-2 rounded-pill"
                            onClick={() =>
                                setShowVendorProficiencyFeedbackForm(true)
                            }
                        >
                            View End of Evaluation Survey
                        </Button>
                    </Col>
                )}
                </>
            )
        }
    };

    const withdrawalButtons = () => {
        if (product?.request_withdrawal === true) {
            return (
                <Row>
                    <Col className="px-0" sm={12} md={4}>
                        {(niapReviewWithdrawal === "") ? (
                            !hideWithdrawalRequest &&
                            <h5 className="fw-bold text-danger">
                                Withdrawal Requested by{" "}
                                {product?.request_withdrawal_user?.first_name}{" "}
                                {product?.request_withdrawal_user?.last_name} on{" "}
                                {moment.utc(product?.request_withdrawal_date).format("MM/DD/YYYY")}
                            </h5>
                        ) : (
                            <h5>
                                {niapReviewWithdrawal} Withdrawal Request by{" "}
                                {product?.request_withdrawal_user?.first_name}{" "}
                                {product?.request_withdrawal_user?.last_name} on{" "}
                                {moment.utc(product?.request_withdrawal_date).format("MM/DD/YYYY")}
                                ?
                            </h5>
                        )}
                    </Col>
                    {permissions?.role_type === "NIAP" && !hideWithdrawalRequest && niapReviewWithdrawal === "" && (
                        <Col sm={12} md={4} className="d-flex align-items-center">
                            <Button
                                variant="danger"
                                className="rounded-pill me-2"
                                onClick={() => {
                                    setNiapReviewWithdrawal("Reject");
                                    setHideWithdrawalRequest(true);
                                }}
                            >
                                Reject Withdrawal
                            </Button>
                            <Button
                                variant="success"
                                className="rounded-pill"
                                onClick={() => {
                                    setNiapReviewWithdrawal("Accept");
                                    setHideWithdrawalRequest(true);
                                }}
                            >
                                Accept Withdrawal
                            </Button>
                        </Col>
                    )}
                    {permissions?.role_type === "NIAP" && niapReviewWithdrawal !== "" && (
                        <>
                            <Col sm={12} md={2} className="mt-1 mb-4">
                                <Button
                                    variant="warning"
                                    className="rounded-pill"
                                    onClick={() =>{
                                        setNiapReviewWithdrawal("")
                                        setHideWithdrawalRequest(false)
                                        }
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col sm={12} md={2} className="mt-1 mb-4">
                                <Button
                                    variant={
                                        niapReviewWithdrawal === "Reject"
                                            ? "danger"
                                            : "success"
                                    }
                                    className="rounded-pill"
                                    onClick={(e) => {
                                        handleWithdrawalRequest(
                                            e,
                                            "NIAP",
                                            niapReviewWithdrawal
                                        );
                                        setNiapReviewWithdrawal("");
                                    }}
                                >
                                    {niapReviewWithdrawal}
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            );
        } else if (requestWithdrawalInitiated && permissions?.role_type !== "NIAP" && permissions?.role_type !== "Validator") {
            return (
                <Col className="px-0">
                    <p>Are you sure you want to Request Withdrawal?</p>
                    <Button
                        variant="warning"
                        className="me-3 mb-2 rounded-pill"
                        onClick={() => setRequestWithdrawalInitiated(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        className="me-3 mb-2 rounded-pill"
                        onClick={handleWithdrawalRequest}
                    >
                        Request Withdrawal
                    </Button>
                </Col>
            );
        } else if (
            product?.certification_status !== "Certified" &&
            product?.status !== "Withdrawn" &&
            permissions?.role_type !== "NIAP" &&
            permissions?.role_type !== "Validator" &&
            requestWithdrawalCompleted === false
        ) {
            return (
                <Col className="px-0">
                    <Button
                        variant="outline-danger"
                        className="me-3 mb-2 rounded-pill"
                        onClick={() => setRequestWithdrawalInitiated(true)}
                    >
                        Request Withdrawal
                    </Button>
                </Col>
            );
        }
    };


    return (
        <>
            <ValidatorProficiencyFeedbackForm
                show={showValidatorProficiencyFeedbackForm}
                handleClose={() => {
                    setShowValidatorProficiencyFeedbackForm(false);
                    refresh();
                }}
                currentFeedbackInfo={proficiencyFeedbackForms?.validator_form}
                product={product}
                viewOnly={permissions?.role_type === "NIAP"}
                setAlertInfo={setAlertInfo}
                validationReport={validationReport}
            />
            <VendorProficiencyFeedbackForm
                show={showVendorProficiencyFeedbackForm}
                handleClose={() => {
                    setShowVendorProficiencyFeedbackForm(false);
                    refresh();
                }}
                product={product}
                currForm={proficiencyFeedbackForms?.vendor_form}
                viewOnly={permissions?.role_type === "NIAP"}
            />
            <Row>
                <Col sm={8}>
                    {proficiencyFeedbackFormButtons()}
                    {withdrawalButtons()}
                </Col>
            </Row>
            <Row>
                <Col sm={4} className="mt-3 mb-0">
                    {permissions?.role_type === 'NIAP' &&
                    (['In Progress', 'Pre-Evaluation'].includes(product?.status)) &&
                        <Button
                            variant="danger"
                            className="me-3 mb-2 rounded-pill"
                            onClick={() =>
                                setShowDeleteModal(true)
                            }
                        >
                            Terminate
                        </Button>
                    }
                </Col>
            </Row>
            <DeleteModal
            show={showDeleteModal}
            handleClose={() =>setShowDeleteModal(false)}
            deleteFunction={terminateProduct}
            type={"Product"}
            isTerminate={true}
            />
        </>
    );
}
