import { useState, useEffect, useCallback } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useCookies } from "react-cookie";
import ReactSelect from "../../ReactSelect";

export default function Filter({ filters, setFilters }) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [currentFilters, setCurrentFilters] = useState({
    ...filters,
    status: filters?.status?.split(","),
  });
  const [agents, setAgents] = useState([]);
  const [formKey, setFormKey] = useState(Date.now())

  const handleChange = (event) => {
    if (event.target.name === "status") {
      let filter = currentFilters[event.target.name];
      if (!filter) {
        filter = [event.target.value];
      } else {
        if (event.target.checked) {
          filter.push(event.target.value);
        } else {
          const idx = filter.indexOf(event.target.value);
          filter.splice(idx, 1);
        }
      }
      setCurrentFilters({ ...currentFilters, [event.target.name]: filter });
    } else if (event.target.value.length === 0) {
      const newFilters = currentFilters;
      delete newFilters[event.target.name];
      setCurrentFilters({ ...newFilters });
    } else {
      setCurrentFilters({ ...currentFilters, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilters({
      ...currentFilters,
      status: currentFilters?.status?.join(","),
    });
  };

  const handleClear = () => {
    setFilters({});
    setCurrentFilters({});
    setFormKey(Date.now())
  };

  const loadAgents = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/agents/get_agents_info/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        let agents = response.data.map((agent) => {
          return {
            value: agent?.account_id__id,
            label: agent?.account_id__first_name + " " + agent?.account_id__last_name,
          };
        });
        setAgents(agents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    loadAgents();
  }, [loadAgents]);

  return (
    <Form onSubmit={handleSubmit} id="filters" key={formKey}>
      <Form.Group className="d-inline-block" controlId="from-date">
        <Form.Label>Time Frame</Form.Label>
        <Form.Control type="date" name="from_date" onChange={handleChange} />
      </Form.Group>
      <p className="d-inline-block p-3">to</p>
      <Form.Group className="d-inline-block" controlId="to-date">
        <Form.Label>Time Frame</Form.Label>
        <Form.Control type="date" name="to_date" onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="date-type" className="mb-3">
        <Form.Label>For The</Form.Label>
        <Form.Select name="date_type" onChange={handleChange}>
          <option value="">Select a type for the time frame</option>
          <option value="entrydate">Submitted</option>
          <option value="moddate">Modification Date</option>
          <option value="rcvd">Date of Receipt</option>
          <option value="due">Completion or Cancellation Date</option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="response" className="mb-3">
        <Form.Label>General Text:</Form.Label>
        <Form.Control type="text" name="response" onChange={handleChange} />
      </Form.Group>
      <Row>
        <Form.Label>VID Range</Form.Label>
        <Col>
          <Form.Group controlId="vid_start" className="mb-3">
            <Form.Label htmlFor="vid_start" className="visually-hidden">Start VID</Form.Label>
            <Form.Control type="number" name="vid_start" onChange={handleChange} />
          </Form.Group>
        </Col>
        -
        <Col>
          <Form.Group controlId="vid_end" className="mb-3">
            <Form.Label htmlFor="vid_end" className="visually-hidden">End VID</Form.Label>
            <Form.Control type="number" name="vid_end" onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="status" className="mb-3">
        <Form.Label>Status</Form.Label>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Pending Review"
              label="Pending Review"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some((status) => status === "Pending Review")}
              id="status1"
            />
          </Col>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="In Review"
              label="In Review"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some((status) => status === "In Review")}
              id="status2"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="No Certificate Review Needed"
              label="No Certificate Review Needed"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "No Certificate Review Needed"
              )}
              id="status3"
            />
          </Col>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Approved"
              label="Approved"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some((status) => status === "Approved")}
              id="status4"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Rejected - Revision Required"
              label="Rejected - Revision Required"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Rejected - Revision Required"
              )}
              id="status5"
            />
          </Col>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Pending Final Review"
              label="Pending Final Review"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Pending Final Review"
              )}
              id="status6"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Rejected - Pending Revision"
              label="Rejected - Pending Revision"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Rejected - Pending Revision"
              )}
              id="status7"
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="agent" className="mb-3">
        <Form.Label>Agent</Form.Label>
        <ReactSelect
          id={"agent"}
          name="agent"
          handleChange={handleChange}
          options={agents}
          newObj={currentFilters}
          isMulti={true}
          isValid={true}
        />
      </Form.Group>
      <div className="mb-3">
        <Button type="submit" variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
        &nbsp;
        <Button type="reset" variant="outline-dark" onClick={handleClear}>
          Clear
        </Button>
      </div>
    </Form>
  );
}
