import { Button, Card, Tab, Tabs, Alert } from "react-bootstrap";
import axios from "axios";
import { useCallback, useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import NIAPValidationTeamModal from "./NIAPValidationTeamModal";
import ReadOnlyProductForm from "../../Products/ProductManager/ProductForm/ReadOnlyProductForm";
import { Link } from "react-router-dom";
import { useInterval } from "../../../hooks/useInterval";
import AMTable from "../AMTableModule/AMTable";
import ACMRTable from "../ACMRTableModule/ACMRTable";
import qs from 'qs'
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import moment from 'moment';

export default function NIAPProductsModule({setOverlayActive, setOverlayText}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const csrfToken = cookies['csrftoken'];
  const [proposedProducts, setProposedProducts] = useState([]);
  const [kickoffProducts, setKickoffProducts] = useState([]);
  const [validatorClaims, setValidatorClaims] = useState([]);
  const [closedOutProducts, setClosedOutProducts] = useState([]);
  const [amProducts, setAMProducts] = useState([]);
  const [acmrProducts, setACMRProducts] = useState([])
  const [ears, setEars] = useState([]);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showCheckInForm, setShowCheckInForm] = useState(false);
  const [product, setProduct] = useState({});
  const [alertInfo, setAlertInfo] = useState({});

  const proposedTableRef = useRef()
  const requestedTableRef = useRef()
  const kickoffTableRef = useRef()
  const closeOutTableRef = useRef()
  const amTableRef = useRef()
  const acmrTableRef = useRef()

  const refetch = () => {
    fetchProposedProducts();
    fetchKickoffProducts();
    fetchValidatorClaims();
    fetchCloseOutProducts();
    fetchEars();
    proposedTableRef.current.onQueryChange({page: 0})
    requestedTableRef.current.onQueryChange({page: 0})
    kickoffTableRef.current.onQueryChange({page: 0})
    closeOutTableRef.current.onQueryChange({page: 0})
  };

  const handleCloseModal = (product, action, type) => {
    if(type === "Validation"){
      setShowReviewModal(false);
    } else {
      setShowCheckInForm(false);
    }
    if(product){
      if(action === "Approved" || action === "approved"){
        setAlertInfo({show: true, approved: true, vid: product.v_id, type: type})
      } else {
        setAlertInfo({show: true, approved: false, vid: product.v_id, type: type})
      }
    }
    refetch();
  };

  const fetchAMProducts = useCallback(() => {
    if (amTableRef.current) {
      amTableRef.current.onQueryChange()
    }
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/niap_am/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(response => {
        setAMProducts(response.data);
        
      })
      .catch((error)=>{
        console.log(error)
      });
  }, []);

  const fetchACMRProducts = useCallback(() => {
    if (acmrTableRef.current) {
      acmrTableRef.current.onQueryChange()
    }
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/niap_acmr/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(response => {
        setACMRProducts(response.data);
        
      })
      .catch((error)=>{
        console.log(error)
      });;
  }, []);

  const fetchCloseOutProducts  = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/niap_close_out`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => setClosedOutProducts(response.data))
      .catch((error) => setClosedOutProducts([]));
  }, []);

  const fetchProposedProducts = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/?status=Proposed&pre_eval_status=Signed`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => setProposedProducts(response.data))
      .catch((error) => setProposedProducts([]));
  }, []);

  const fetchKickoffProducts = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/?status=Pre-Evaluation&kickoff_status=Recommended for Kickoff`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => setKickoffProducts(response.data))
      .catch((error) => setKickoffProducts([]));
  }, []);

  const fetchValidatorClaims = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/validator_claims/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => setValidatorClaims(response.data))
      .catch((error) => setValidatorClaims([]));
  }, []);

  const fetchEars = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => setEars(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const loadAMProducts = async() => {
      fetchAMProducts();
    }
    loadAMProducts();
  }, []);

  useEffect(() => {
    const loadACMRProducts = async() => {
      fetchACMRProducts();
    }
    loadACMRProducts();
  }, []);

  useEffect(() => {
    const loadProducts = async() => {
      fetchProposedProducts();
      fetchKickoffProducts();
      fetchCloseOutProducts()
    }
    loadProducts()
  }, []);

  useInterval(()=>{
    const loadProducts = async() => {
      fetchProposedProducts();
      fetchKickoffProducts();
      fetchCloseOutProducts();
      proposedTableRef.current.onQueryChange()
      kickoffTableRef.current.onQueryChange()
      closeOutTableRef.current.onQueryChange()
    }
    loadProducts();
  }, 300000) //5 min

  useInterval(() => {

    const loadAMProducts = async() => {
      fetchAMProducts();
      amTableRef.current.onQueryChange()
    }
    loadAMProducts();
    
    const loadACMRProducts = async() => {
      fetchACMRProducts();
      acmrTableRef.current.onQueryChange()
    }
    loadACMRProducts();
    
  }, [600000]) //10 min

  useEffect(() => {
    fetchEars();
  }, []);

  useEffect(() => {
    fetchValidatorClaims();
  }, []);
  
  useInterval(()=>{
    fetchValidatorClaims();
    requestedTableRef.current.onQueryChange()
  }, 300000)  //5 min

  const options = {
    sorting: true,
    columnsButton: true,
  };

  return (
    <>
    {alertInfo.show === true &&
      <div style={{ position: "sticky", top: 10, zIndex: 999 }}>
        <Alert variant="success" dismissible onClose={() => setAlertInfo({})}>
          {
            alertInfo.approved === true ? 
            `Approved VID: ${alertInfo.vid} for ${alertInfo.type}.` 
            : `Rejected VID: ${alertInfo.vid} for ${alertInfo.type}.`
          }
        </Alert>
      </div>
    }
      <Card>
        <Card.Body>
          <Tabs defaultActiveKey="proposed" className="mb-3">
            <Tab
              eventKey="proposed"
              title={
                <div className="d-flex align-items-center">
                  Proposed Products
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {proposedProducts.count}
                  </h4>
                </div>
              }
            >
              <ResponsiveMaterialTable
                id={"proposed-products-table"}
                title="Proposed Products"
                tableRef={proposedTableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                    } = query;
        
                    let newFilters = filters.map((filter) => {
                        let value = ""
                        if (Array.isArray(filter.value)) {
                            value = filter.value
                        } else {
                            value = filter.value.replace(/['"]+/g, '')
                        }
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                        status: "Proposed",
                        pre_eval_status: "Signed"
                    }
                    axios
                        .get(
                            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            resolve({
                                data: response.data.results,
                                page: page,
                                totalCount: response.data.count,
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    })
                  }
                options={options}
                columns={[
                  { title: "VID", field: "v_id", render: (rowData) => (
                    <Link to={`/community/products/details/${rowData.product_id}`} className='text-decoration-underline'>
                      {rowData?.v_id}
                    </Link>
                  ),},
                  { title: "Vendor", field: "vendor_id.name" },
                  { title: "Product", field: "product_name" },
                  { title: "CCTL", field: "assigned_lab.name" },
                  {
                    title: "Submit Date",
                    render: (rowData) =>
                      moment.utc(rowData.check_in_date).format('MM/DD/YYYY'),
                  },
                  {
                    render: (rowData) => (
                      <Button
                        variant="primary rounded-pill"
                        type="button"
                        onClick={() => {
                          setProduct(rowData);
                          setShowCheckInForm(true);
                        }}
                      >
                        Review
                      </Button>
                    ),
                  },
                ]}
              />
            </Tab>
            <Tab
              eventKey="requested"
              title={
                <div className="d-flex align-items-center">
                  Requested Teams
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {validatorClaims.count}
                  </h4>
                </div>
              }
            >
              <ResponsiveMaterialTable
                id={"requested-teams-table"}
                title="Validator Requested Teams"
                tableRef={requestedTableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                    } = query;
        
                    let newFilters = filters.map((filter) => {
                        let value = ""
                        if (Array.isArray(filter.value)) {
                            value = filter.value
                        } else {
                            value = filter.value.replace(/['"]+/g, '')
                        }
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                    }
                    axios
                        .get(
                            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/validator_claims/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            resolve({
                                data: response.data.results,
                                page: page,
                                totalCount: response.data.count,
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    })
                  }
                options={options}
                columns={[
                  { title: "VID", field: "product_id.v_id",render: (rowData) => (
                    rowData.product_id ? 
                    <Link to={`products/details/${rowData.product_id.product_id}`} className='text-decoration-underline'>
                      {rowData?.product_id?.v_id}
                    </Link>
                    :
                    <Link to={`products/details/${rowData.maintenance_id?.product?.product_id}`} className='text-decoration-underline'>
                      {rowData.maintenance_id?.product?.v_id}
                    </Link>
                  ),},
                  { 
                    title: "Requested", 
                    field: "pp_team_id.name",
                  },
                  { 
                    title: "Submission Type",
                    field: "product_id.submission_type",
                    render: (rowData) => {
                      return (
                        <>
                        {
                          rowData?.product_id?.submission_type ? 
                          rowData?.product_id?.submission_type : 
                          rowData?.maintenance_id?.product?.submission_type
                        }
                        </>
                      )
                    }
                  },
                  { 
                    title: "Vendor", 
                    field: "product_id.vendor_id.name",
                    render: (rowData) => (
                      rowData.product_id?.vendor_id?.name ? rowData.product_id?.vendor_id?.name : rowData.maintenance_id?.product?.vendor_id?.name
                    )
                  },
                  { 
                    title: "Product", 
                    field: "product_id.product_name",
                    render: (rowData) => (
                      rowData.product_id?.product_name ? rowData.product_id?.product_name : rowData.maintenance_id?.product?.product_name
                    )
                  },
                  { 
                    title: "CCTL", 
                    field: "product_id.assigned_lab.name",
                    render: (rowData) => (
                      rowData.product_id?.assigned_lab?.name ? rowData.product_id?.assigned_lab?.name : rowData.maintenance_id?.product?.assigned_lab?.name
                    )
                  },
                  {
                    title: "Request Date",
                    render: (rowData) =>
                      moment.utc(rowData.assigned_on).format('MM/DD/YYYY'),
                  },
                  {
                    render: (rowData) => (
                      <Button
                        variant="primary rounded-pill"
                        type="button"
                        onClick={() => {
                          setProduct(rowData);
                          setShowReviewModal(true);
                        }}
                      >
                        Review
                      </Button>
                    ),
                  },
                ]}
              />
            </Tab>
            <Tab
              eventKey="kickoff"
              title={
                <div className="d-flex align-items-center">
                  Kickoff Recommended
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {kickoffProducts.count}
                  </h4>
                </div>
              }
            >
              <ResponsiveMaterialTable
                id={"kickoff-recommended-table"}
                title="Recommendation for Kickoff"
                tableRef={kickoffTableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                    } = query;
        
                    let newFilters = filters.map((filter) => {
                        let value = ""
                        if (Array.isArray(filter.value)) {
                            value = filter.value
                        } else {
                            value = filter.value.replace(/['"]+/g, '')
                        }
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                        status: "Pre-Evaluation",
                        kickoff_status: "Recommended for Kickoff"
                    }
                    axios
                        .get(
                            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            resolve({
                                data: response.data.results,
                                page: page,
                                totalCount: response.data.count,
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    })
                  }
                options={options}
                columns={[
                  { title: "VID", field: "v_id",render: (rowData) => (
                    <Link to={`products/details/${rowData.product_id}`} className='text-decoration-underline'>
                    {rowData?.v_id}
                  </Link>
                  ),},
                  { title: "Vendor", field: "vendor_id.name" },
                  { title: "Product", field: "product_name" },
                  { title: "CCTL", field: "assigned_lab.name" },
                  {
                    title: "Recommended Date",
                    render: (rowData) =>
                      moment.utc(
                        rowData.kickoff_recommended_date
                      ).format('MM/DD/YYYY'),
                  },
                  {
                    render: (rowData) => (
                      <Link
                        to={`products/details/${rowData.product_id}`}
                        className="text-decoration-none"
                      >
                        <Button size="sm" className="rounded-pill">
                          REVIEW
                        </Button>
                      </Link>
                    ),
                  },
                ]}
              />
            </Tab>
            <Tab
              eventKey="CloseOut"
              title={
                <div className="d-flex align-items-center">
                  Posting Recommended
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {closedOutProducts.count}
                  </h4>
                </div>
              }
            >
              <ResponsiveMaterialTable
                id={"posting-recommendation-table"}
                title="Recommendation for PCL Posting"
                tableRef={closeOutTableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                    } = query;
        
                    let newFilters = filters.map((filter) => {
                        let value = ""
                        if (Array.isArray(filter.value)) {
                            value = filter.value
                        } else {
                            value = filter.value.replace(/['"]+/g, '')
                        }
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                    }
                    axios
                        .get(
                            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/niap_close_out/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            resolve({
                                data: response.data.results,
                                page: page,
                                totalCount: response.data.count,
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    })
                  }
                options={options}
                columns={[
                  { title: "VID", field: "v_id",render: (rowData) => (
                    <Link to={`products/details/${rowData.product_id}`} className='text-decoration-underline'>
                      {rowData?.v_id}
                    </Link>
                  ),},
                  { title: "Vendor", field: "vendor_id.name" },
                  { title: "Product", field: "product_name" },
                  { title: "CCTL", field: "assigned_lab.name" },
                  {
                    render: (rowData) => (
                      <Link
                        to={`products/details/${rowData.product_id}`}
                        className="text-decoration-none"
                      >
                        <Button size="sm" className="rounded-pill">
                          REVIEW
                        </Button>
                      </Link>
                    ),
                  },
                ]}
              />
            </Tab>
            <Tab eventKey="IARs" title={
                <div className="d-flex align-items-center">
                  AM Requests             
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {amProducts?.count}
                  </h4>
              </div>    
              }
             >     
             <AMTable refetch={fetchAMProducts} tableRef={amTableRef} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
            </Tab>
            <Tab eventKey="ACMRs" title={
              <div className="d-flex align-items-center">
               ACMR Recommended              
               <h4 className="text-primary ms-2 my-0 fw-bold">
                 {acmrProducts?.count}
               </h4>
              </div>               
              }
            >
              <ACMRTable tableRef={acmrTableRef} acmrProducts={acmrProducts} refetch={fetchACMRProducts}/>
            </Tab>
          </Tabs>
          <ReadOnlyProductForm
            show={showCheckInForm}
            handleCloseModal={handleCloseModal}
            product={product}
            refetch={refetch}
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
          <NIAPValidationTeamModal
            show={showReviewModal}
            handleClose={handleCloseModal}
            productInfo={product}
            ears={ears}
            refetch={refetch}
          />
        </Card.Body>
      </Card>
    </>
  );
}
