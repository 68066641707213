import { useState, useRef, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Modal, Form, Alert } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux'
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactSelect from '../ReactSelect';
import moment from 'moment';
import ConfirmationModal from './ConfirmationModal';

export default function ContactTRRTForm({handleClose, show }) {
  const maxLengthForReq = 2000; //setting max length of characters for remarks, comments, and questions
  const [validated, setValidated] = useState(false);
  const [newContact, setNewContact] = useState({});
  const [invalidToken, setInvalidToken] = useState(false);
  const [error, setError] = useState(false);
  const [openContactForm, setOpenContactForm] = useState(false)
  const [errors, setErrors] = useState({})
  const [qaCategories, setQaCategories] = useState([])
  const [categoryValid, setCategoryValid] = useState(true)
  const currentUser = useSelector(state => state.user.value);
  const [qa, setQa] = useState({})
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [currentAgent, setCurrentAgent] = useState({})

  const siteKey = '6Lf29aAjAAAAAIagG9XUSNUz_nXKnwxrK_fDcGf5';
  const recaptchaRef = useRef(null);

  const [cookies] = useCookies();
  const csrfToken = cookies['csrftoken']
  const authToken = cookies['auth_token']

  const showCaptcha = () => {
    try {
      window.grecaptcha.render('form-recaptcha', {
        sitekey: siteKey,
        ref: recaptchaRef,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getOnDutyAgent = useCallback(() => {
    axios
    .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_on_duty_agent_id/`)
    .then(response => {
        setCurrentAgent(response.data)
    }).catch(error => {
        console.log(error)
    })
  }, [])

  useEffect(() => {
    if(show){
      getOnDutyAgent();
      setError(false)
    }
  }, [show]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    const headers = {
      'X-CSRFToken': csrfToken,
    } 
    if (authToken) {
      headers['Authorization'] = `Token ${authToken}`
    }
    if (!('cid' in newContact)) {
      setCategoryValid(false)
    }
    if (form.checkValidity() === false || !('cid' in newContact)) {
      event.preventDefault();
      event.stopPropagation();
    } 
     else {
      if (window.grecaptcha.getResponse() === '') {
        event.stopPropagation();
        event.preventDefault();
        setInvalidToken(true);
      }
      event.preventDefault();
      //if any fields are empty (user added text and then removed), set them to null
      for (const key of Object.keys(newContact)) {
        if (newContact[key] === '') {
          newContact[key] = null;
        }
      }

      axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/create_req_on_frontend/`, 
      {
        ...newContact,
        //status auto being set on backend to Pending Review
        deadline: moment().add(7, 'days'),
        creator: currentUser?.id ?? null,
        recaptcha_token: event.target['g-recaptcha-response'].value,
        agent: currentAgent?.id ?? null,
      },
      {
          withCredentials: true, 
          headers: headers
      }).then((response) => {
          setQa(response.data)
          setShowConfirmationModal(true)
          handleClose();
          setOpenContactForm(false)
          setInvalidToken(false);
          setValidated(false);
          recaptchaRef.current.reset();
          setNewContact({});
      }).catch((error) => {
        console.log('Unable to submit contact request: ' + error);
        setError(true);
      })
    }
    setValidated(true);
  };

  const fetchQACategories = useCallback(() => {
    const headers = {}
    if (authToken) {
        headers['Authorization'] = `Token ${authToken}`
    }
    axios
    .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/cat/get_all_active_cats/`, {
        withCredentials: true,
        headers: headers,
    })
    .then((response) => {
        let allCategories = response.data
        if (allCategories?.length > 0){
          const categories = allCategories.filter(cat => cat.cat !== 'Spam').map(cat => {return {value: cat.cid, label: cat.cat}})
          setQaCategories(categories);
        }
    })
    .catch((error) => console.log(error));
    }, [])

  useEffect(() => {
    if(show){
      fetchQACategories();
    }
  }, [fetchQACategories, show]);

  const handleChange = (e) => {  
    if (e.target.name === 'submitter_phone') {
      setErrors({...errors, [e.target.name]: !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(e.target.value)})
    }
    setNewContact({ ...newContact, [e.target.name]: e.target.value });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValidated(false);
    recaptchaRef.current.reset();
    setInvalidToken(false)
    setNewContact({});
  };
  

  return (
    <>
    <ConfirmationModal
      qa={qa}
      show={showConfirmationModal}
      handleClose={() => {
        setShowConfirmationModal(false); 
        handleClose();
      }}
    />
    <Modal
      show={show}
      onHide={() => {handleClose(); setOpenContactForm(false)}}
      onEntered={showCaptcha}
      size='lg'
      aria-labelledby='contained-modal-title'
    >
    <Modal.Header closeButton>
      <Modal.Title><h3 className="mb-0">CONTACT US: <span className='text-primary'>NIAP/CCEVS</span></h3></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3>
        Before submitting a question, have you researched through our&nbsp;
        <a href="/faqs" style={{ textDecoration: 'underline' }}>
          FAQs and Knowledge Center
        </a>
        ?
      </h3>
      
      <Container>
          <Row className='justify-content-end text-end'> 
          {openContactForm === false ? (
      <Row  className='justify-content-end mt-5 mb-4'>
        <Col sm={4}>
          <Button variant='danger' onClick={() => { window.location.href = '/faqs' }}>
            No, take me to FAQs
          </Button>
        </Col>
        <Col sm={2}>
          <Button variant='success' onClick={() => { setOpenContactForm(true); }}>
            Yes
          </Button>
        </Col>
      </Row>
    ) : (
      <Row className='justify-content-end my-5'>
        <Col sm={4}>
          <Button variant='danger' onClick={() => { window.location.href = '/faqs' }}>
            No, Take me to FAQs
          </Button>
        </Col>
        </Row>
      )}
      </Row>
    </Container>
      <div className={openContactForm ? "" : "d-none"}>
        <p>
          Please use the form below to send comments or suggestions to
          NIAP/CCEVS. Your contact information is optional unless you desire a response.
        </p>
        <div>
          <Container>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              onReset={handleReset}
              id='submit-contact-us-form'
            >
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='custuname'>
                    <Form.Label>Name:*</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      name='custuname'
                      value={newContact?.custuname ?? ''}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='custemail'>
                    <Form.Label>Email Address:*</Form.Label>
                    <Form.Control
                      type='email'
                      name='custemail'
                      value={newContact?.custemail ?? ''}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a valid Email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='custorg'>
                    <Form.Label>Organization:*</Form.Label>
                    <Form.Control
                      type='text'
                      name='custorg'
                      value={newContact?.custorg ?? ''}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide an Organization.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='phone1'>
                    <Form.Label>Telephone Number:</Form.Label>
                    <Form.Control
                      type='tel'
                      name='phone1'
                      onChange={handleChange}
                      value={newContact?.phone1 ?? ''}
                      isInvalid={newContact?.phone1 && errors.phone1} 
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide an valid Phone Number.
                    </Form.Control.Feedback>
                    <Form.Text className='text-muted'>
                      Specify country code, DSN, comm., etc., as applicable.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='cid'>
                  <Form.Label>Category:*</Form.Label>
                  <ReactSelect
                      handleChange={handleChange}
                      value={qaCategories.find(category => category.value === newContact.cid)}
                      options={qaCategories}
                      newObj={newContact}
                      name={'cid'}
                      isValid={categoryValid}
                  />
                  </Form.Group>
                  
                  <Form.Group className='mb-5' controlId='req'>
                    <Form.Label>Remarks, Comments, Questions:*</Form.Label>
                    <Form.Control
                      required
                      as='textarea'
                      rows={5}
                      name='req'
                      onChange={handleChange}
                      value={newContact?.req ?? ''}
                      maxLength={2000}
                    />
                    <Row>
                      <Col md={8} sm={12}>
                        <Form.Text className='text-muted'>
                          Please limit to 2000 characters.
                        </Form.Text>
                      </Col>
                      <Col className='d-flex justify-content-end'>
                        <Form.Text className='text-muted'>
                          {newContact?.req?.length} / {maxLengthForReq}
                        </Form.Text>
                      </Col>
                    </Row>
                    <Form.Control.Feedback type='invalid'>
                      Please provide a Remark, Comment, and/or Question.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3 text-end' controlId='token'>
                    
                    <div className='d-flex justify-content-end'>
                    
                    <ReCAPTCHA 
                      id='form-recaptcha'
                      sitekey={siteKey}
                      ref={recaptchaRef}
                    />

                    
                    </div>
                    {invalidToken ? 
                      <p className='text-danger'>Please Confirm.</p>
                    :
                    <Form.Text className='text-muted '>
                      Required to submit any information via this form.
                    </Form.Text>
                    }
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-3 justify-content-end'>
                <Col md={2}>
                  <Button variant='primary' type='submit'>
                    Submit
                  </Button>
                </Col>
                <Col md={2}>
                  <Button variant='outline-primary' type='reset' >Reset</Button>
                </Col>
                <Col md={2}>
                  <Button variant='outline-primary' onClick={() => {handleClose(setOpenContactForm(false));}} >Cancel</Button>
                </Col>
              </Row>
            </Form>
            {error && (
              <Alert variant='danger' className='mt-3'>
                Failed to send Message
              </Alert>
            )}
          </Container>
        </div>
      </div>
    </Modal.Body>
    </Modal>
    </>
  );
}
