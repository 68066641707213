import axios from "axios";
import { useCookies } from "react-cookie";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import FlagIcon from "@material-ui/icons/Flag";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import qs from "qs";

const OrganizationTable = ({
    handleShowModal,
    handleArchive,
    role,
    isArchivedView,
    tableRef
}) => {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];

    const showActions =
        role?.role_permissions?.find(
            (permission) => permission.area === "Organization"
        )?.update ||
        role?.role_permissions?.find(
            (permission) => permission.area === "Organization"
        )?.destroy;

    const columns = [
        { title: "Name", field: "name"},
        { title: "Type", field: "org_type" },
        { title: "Primary Address 1", field: "primary_address_1" },
        { title: "Primary Address 2", field: "primary_address_2" },
        { title: "State", field: "primary_state" },
        { title: "Country", field: "primary_country" },
        { title: "Zip Code", field: "primary_zip" },
        { title: "Website", field: "website" },
        { title: "Phone", field: "primary_phone" },
        { title: "Ext.", field: "ext_phone" },
        {
            title: "Actions",
            field: "actions",
            filtering: false,
            sorting: false,
            hidden: !showActions,
            headerStyle: {background: "white", position: "sticky", right: 0},
            cellStyle: {background: "white", position: "sticky", right: 0},
            render: (rowData) => {
                return (
                    showActions && (
                        <>
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                rootClose
                                key="bottom"
                                overlay={
                                    <Popover>
                                        <Popover.Body>
                                            {role?.role_permissions?.find(
                                                (permission) =>
                                                    permission.area ===
                                                    "Organization"
                                            )?.update && (
                                                <Button
                                                    variant="link"
                                                    className="m-1 p-0 btn-link"
                                                    onClick={(e) =>
                                                        handleShowModal(
                                                            e,
                                                            rowData
                                                        )
                                                    }
                                                >
                                                    <EditIcon color="action" />{" "}
                                                    Edit
                                                </Button>
                                            )}
                                            {role?.role_permissions?.find(
                                                (permission) =>
                                                    permission.area ===
                                                    "Organization"
                                            )?.destroy && (
                                              !isArchivedView ?
                                                <Button
                                                    variant="link"
                                                    className="m-1 p-0 btn-link"
                                                    onClick={(e) =>
                                                        handleArchive(
                                                            e,
                                                            rowData,
                                                            true
                                                        )
                                                    }
                                                >
                                                    <FlagIcon color="action" />
                                                    Archive
                                                </Button>
                                                :
                                                <Button
                                                    variant="link"
                                                    className="m-1 p-0 btn-link"
                                                    onClick={(e) =>
                                                        handleArchive(
                                                            e,
                                                            rowData,
                                                            false
                                                        )
                                                    }
                                                >
                                                    <FlagIcon color="action" />{" "}
                                                    Set Active
                                                </Button>
                                            )}
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <Button variant="link">
                                    <MoreVertIcon className="float-end" />
                                    <span className="visually-hidden">
                                        Table Row Actions
                                    </span>
                                </Button>
                            </OverlayTrigger>
                        </>
                    )
                );
            },
        },
    ];

    const options = {
        columnsButton: true,
        showTitle: true,
        headerStyle: {
            fontWeight: "bold",
            borderBottom: "none",
            paddingBottom: "0",
        },
        filtering: true,
        pageSize: 20
    };

    return (
        <ResponsiveMaterialTable
            title="Organizations Manager"
            tableRef={tableRef}
            data={(query) =>
                new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                    } = query;

                    let newFilters = filters.map((filter) => {
                        let value = "";
                        if (Array.isArray(filter.value)) {
                            value = filter.value;
                        } else {
                            value = filter.value.replace(/['"]+/g, "");
                        }
                        return `"${filter.column.field.replace(
                            /\./g,
                            "__"
                        )}":"${value}"`;
                    });
                    const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                        archived: isArchivedView,
                        local_only_orgs: role.role_permissions?.find(
                            (permission) => permission.area === "Organization"
                        )?.local_only,
                        non_ccp_vendor: true
                    };

                    axios
                        .get(
                            `${
                                process.env.REACT_APP_DJANGO_ENDPOINT
                            }org/organization/?${qs.stringify(params, {
                                arrayFormat: "comma",
                            })}`,

                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            resolve({
                                data: response.data.results,
                                page: page,
                                totalCount: response.data.count,
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            }
            options={options}
            columns={columns}
        />
    );
};

export default OrganizationTable;
