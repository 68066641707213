import { Modal, Button } from "react-bootstrap";

export default function ConfirmationModal({qa, show, handleClose}) {
    return (
        <Modal size="md" show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>Thank you for your submission!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className="text-center">
                    Your submission has been successfully sent and will be reviewed.
                </h4>
                <p className="text-center">Your Ticket Number:{" "}<span className="fw-bold">{qa?.ticket}</span></p>
                <div className="d-flex justify-content-center mt-4">
                    <Button
                        variant="primary"
                        className="m-1"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
