import { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import HTMLEditor from '../../UI/HTMLEditor'
import moment from 'moment';
import {handleAlertAndMailer} from '../../Products/Helper/functions';
import { getCurrentDate } from "../../../utils/timeUtils"

export default function ERTApproveRejectModal({
  show,
  handleClose,
  review,
  refetch,
  user,
  complete,
  selectedStatus
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies['csrftoken'];
  let authToken = cookies['auth_token'];
  const [updatedResponse, setUpdatedResponse] = useState({})

  const handleChange = (e) => {
    setUpdatedResponse({
      ...updatedResponse,
      [e.target.name]: e.target.value,
    });
  };

  const addUpdateToEar = (earUpdate) => {
    return new Promise(async (resolve, reject) => {
      try {
        let earUpdatedResponse = await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review.earid}/`,
          {
            response: earUpdate?.data?.upid,
            pid: review?.pid?.product_id,
          },
          {
            withCredentials: true,
            headers: {
              'X-CSRFToken': csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        resolve(earUpdatedResponse)
      }
      catch(error) {
        reject(error)
      }
    })
  }

  const handleUpdateAddResponse = (earResponse) => {
    let updatedEar = earResponse?.data
    return new Promise(async (resolve, reject) => {
      if(updatedEar?.response !== null) {
        try {
          let earUpdate = await axios
          .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/update/${updatedEar?.response}/`,
            updatedResponse, 
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          resolve(earUpdate)
        }
        catch(error) {
          reject(error)
        }
      } else {
        try {
          let earUpdate = await axios
          .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/update/`,
            {
              earid: updatedEar.earid,
              pid: review?.pid?.product_id,
              note1: updatedResponse?.note1,
              creator: user?.id,
              entrydate: getCurrentDate(),
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            })
            let earUpdatedResponse = await addUpdateToEar(earUpdate)
            resolve(earUpdate)
        }
        catch(error) {
          reject(error)
        }
      }
    })
  }

  //update ear status and note1
  const handleUpdateEar = async () => {
    try {
      let earResponse = await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review.earid}/`,
        {
          status: selectedStatus,
          gist: updatedResponse?.note1,
          just: updatedResponse?.note1,
          pid: review?.pid?.product_id,
          rejected: complete === false,
          approved: complete === true,
          enddate: getCurrentDate(),
        },
        {
          withCredentials: true,
          headers: {
            'X-CSRFToken': csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      let earUpdate = await handleUpdateAddResponse(earResponse)
      handleAlertAndMailer(
        csrfToken, 
        authToken, 
        {
            subject: `EAR Review ${earResponse.data?.earid} for VID ${earResponse.data?.pid} Status: ${earResponse.data?.status}`,
            recipients: {to: ["Lab"], cc: ["NIAP Management", "Validators"]},
            alert_type: "EAR",
            alert_text: `EAR Review ${earResponse.data?.earid} for VID ${earResponse.data?.pid} Status: ${earResponse.data?.status}`,
            alert_type_id: earResponse.data?.earid,
        }
      )
      refetch();
      handleClose();
    }
    catch(error) {
      console.log(error);
    }
  };

  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Form>
        <Modal.Header closeButton className='border-0'>
          <Modal.Title>
            <h3 className='text-bright-navy'>
              {complete ? "COMPLETE EAR / ERT TEAM" : "REJECT EAR"}
            </h3>
            <h3 className='text-bright-navy'>
              EAR ID REVIEW {review.earid} for VID {review?.pid?.v_id}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Row>
                <Col>
                  <p className='fw-bold mb-0'>Date of Receipt by the ERT:</p>
                  {review?.release_date != null ?
                    <p>{moment.utc(review?.release_date).format('MM/DD/YYYY')}</p>
                  :
                    <p>No Date Set</p>
                  }
                </Col>
                <Col>
                  <p className='fw-bold mb-0'>Completion Date:</p>
                  <p>{moment.utc(new Date()).format('MM/DD/YYYY')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='fw-bold mb-0'>Deadline</p>
                  {review?.due != null ?
                    <p>{moment.utc(review?.due).format('MM/DD/YYYY')}</p>
                  :
                    <p>No Date Set</p>
                  }
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <p className='fw-bold mb-0'>Official Response to the Lab:</p>
                  {!complete && <p>(Rejection Rationale)</p>}
                  <HTMLEditor 
                    name="note1" 
                    handleChange={handleChange} 
                    setIsValid={() => {}} 
                    isValid={true} 
                    defaultValue={review?.response?.note1} 
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='light'
            type='button'
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <>
            <Button
              variant={complete ? "success" : "danger"}
              type='button'
              onClick={() => handleUpdateEar()}
            >
              {complete ? "Complete" : "Reject"}
            </Button>
          </>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
