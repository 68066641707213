import { Modal, Container, Row, Col } from 'react-bootstrap';
import stepOneImage from '../../../images/evaluation-process/step-one.png';

export default function StepOneModal({show, setShowStepOneModal}) {

  const handleClose = () => {
    setShowStepOneModal(false);
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
    <Modal.Header className='border-0' closeButton></Modal.Header>
    <Modal.Body className='px-5'>
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              <div className='d-flex justify-content-center'>
                <img
                  src={stepOneImage}
                  alt='step one image'
                  className='w-auto'
                  height='120px'
                />
              </div>
              <div className='text-center'>
                <h3 className='text-uppercase fw-bold mt-3 mb-4 text-primary'>
                  Step 1
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <h5 className='fw-bold'>
              Vendor approaches <a href="/cctl">Common Criteria Testing Lab (CCTL)</a> with COTS IT
              Product
            </h5>
          </Row>
          <Row>
            <p>
              Draft Security Target and Applicable NIAP-Approved <a href="/protectionprofiles">Protection Profile</a>(s)
            </p>
          </Row>
          <Row className='mt-5'>
            <h5 className='fw-bold text-bright-navy'>
              What is a Security Target?
            </h5>
          </Row>
          <Row>
            <p>
              Specification of the security functions against which the IT
              product, i.e., the Target of Evaluation (TOE), will be evaluated
              as a description relating the product to the environment in which
              it will operate.
            </p>
          </Row>
          <Row className='mt-5'>
            <h5 className='fw-bold text-bright-navy'>
              What is a Protection Profile?
            </h5>
          </Row>
          <Row>
            <p>
              An implementation independent set of security requirements for a
              category of IT products that meet specific consumer needs.
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
    </Modal.Body>
    </Modal>
  );
}
