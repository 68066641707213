import { useEffect, useState } from "react";
import progressPointInfo from "./ProgressPointData";
import { useSelector } from "react-redux";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";
import "./ProgressPointModal.css";

export default function InitiateProgressModal({
  show,
  handleClose,
  product,
  progressPoint,
  isEdit,
  isValidator,
  refetch
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const [disableButton, setDisableButton] = useState(true)

  const handleInitiate = () => {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/${progressPoint?.prog_id}/`,
          {
            product: product.product_id,
            progress_point: progressPoint.progress_point,
            status: "In Progress",
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          refetch()
          handleClose('initiate')
          }
        )
        .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (
      product?.product_description?.length >= 50 &&
      product?.security_evaluation_summary?.length >= 50 &&
      product?.environmental_strengths?.length >= 50 &&
      product?.evaluation_configuration?.length >= 50
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
      
    }
  }, [product]);

  return (
    <>
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Form
        id="initiate-progress-point-6"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>
            <h3 className="text-bright-navy">
              {progressPoint?.progress_point === 6 &&
                isValidator &&
                permissions.role_type === "Validator" &&
                "VALIDATOR REVIEWS "}
               {progressPoint?.progress_point !== 6 ? progressPointInfo[progressPoint?.progress_point]?.title  :   ` ${progressPointInfo[progressPoint?.progress_point]?.title}`  }  
                <p>INITIATE for VID: {product.v_id}</p>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
                    <Row>
                        <Col sm={6}>
                            <p>Items to review on the Product Details Page:</p>
                            
                            <div className="text-bright-navy">
                                {product?.product_description?.length >= 50 && <p>Product Description</p> }
                    
                                {product?.security_evaluation_summary?.length >= 50 && <p>Security Evaluation Summary </p>}
                  
                                {product?.environmental_strengths?.length >= 50 && <p>Environmental Strengths</p>}
                    
                                {product?.evaluation_configuration?.length >= 50 && <p>Evaluation Configuration</p>}
                            </div>
                        
                        </Col>
                        <Col sm={6}>
                            <p>Items that need to be addressed on the Product Details Page:</p>
                              <div className="text-danger ">
                                {(product?.product_description === null || product?.product_description?.length < 50) && <p>Product Description</p>}
                    
                                {(product?.security_evaluation_summary === null || product?.security_evaluation_summary?.length < 50) && <p>Security Evaluation Summary </p>}
                  
                                {(product?.environmental_strengths === null || product?.environmental_strengths?.length < 50) && <p>Environmental Strengths</p>}
                    
                                {(product?.evaluation_configuration === null || product?.evaluation_configuration?.length < 50) && <p>Evaluation Configuration</p>}
                              </div> 
                        </Col>
                    </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
            {progressPoint?.progress_point === 6 &&
              isValidator &&
              permissions?.role_type === "Lab" &&
              !isEdit && (
                <Row className="d-flex justify-content-end">
                  <Col>
                    <Button
                      variant="outline-primary"
                      type="button"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      disabled={disableButton}
                      variant="primary"
                      onClick={handleInitiate}
                    >
                      Initiate
                    </Button>
                  </Col>
                </Row>
              )}  
        </Modal.Footer>
      </Form>
    </Modal>
    </>
  );
}
