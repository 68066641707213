import axios from 'axios';
import { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import { useCookies } from 'react-cookie';
import ReactSelect from '../../ReactSelect';


const CCTLAssociatesVendorModal = ({show, handleCloseModal, isSwitch, switchForms, setReloadVendors}) => {
    const [cookies] = useCookies()
    const authToken = cookies['auth_token']
    let csrfToken = cookies["csrftoken"];
    const [updatedInformation, setUpdatedInformation] = useState({org_type : 'Vendor'})
    const [addNew, setAddNew] = useState(false)
    const [vendors, setVendors] = useState([])
    const [states, setStates] = useState([])
    const [countries, setCountries] = useState([])
    const [labVendorInformation, setLabVendorInformation] = useState({})
    const [stateValid, setStateValid] = useState(true)
    const [countryValid, setCountryValid] = useState(true)
    const [errors, setErrors] = useState({})

    const handleExistingChange = (e) => {
        setLabVendorInformation({...labVendorInformation, [e.target.name]: e.target.value})
    }

    const handleChange = (e) => {
        if (e.target.name === 'primary_phone' || e.target.name === 'fax') {
            setErrors({...errors, [e.target.name]: !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(e.target.value)})
        }
        setUpdatedInformation({...updatedInformation, [e.target.name]: e.target.value})
    }

    const fetchOrgInfo = () => {
        if (show) {
            axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`,
            {withCredentials:true,
                headers: {
                    Authorization: `Token ${authToken}`
                }
            }).then(response => {
                setLabVendorInformation({...labVendorInformation, lab: response.data.org_id}) 
            }).catch(error => {
                console.log(error.response.data)
            })
        }
    }

    useEffect(() => {
        fetchOrgInfo()
    }, [show]);

    const loadVendors  = () => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/vendors_not_associated_to_org/`,
        {
            headers: {
                Authorization: `Token ${authToken}`
            },
            withCredentials: true
        }).then(response => {
            const responseVendors = response.data.map(vendor => { return {value: vendor.org_id, label: vendor.name}})
            setVendors([...responseVendors])
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    useEffect(() => {
        if (show) {
            loadVendors()
            setAddNew(false)
        }
    }, [show])

    const getStateInfo =  () => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}address/states/get_all_states/`,
            {
                headers: {
                    Authorization: `Token ${authToken}`
                },
                withCredentials: true
            }
        ).then(response => {
            setStates(response.data.map(state => { return {value: state.us_state_abbrv, label: state.us_state_name}}))
        }).catch(error => {
            console.log(error.response?.data)
        })
    }

    useEffect(() => {
        getStateInfo()
    },[]);




   const getCountryInfo = () => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}address/country/get_all_countries/`,
            {
                headers: {
                    Authorization: `Token ${authToken}`
                },
                withCredentials: true
            }
        ).then(response => {
            setCountries(response.data.map(country => { return {value: country.code2_iso, label: country.name}}))
        }).catch(error => {
            console.log(error.response?.data)
        })
    }

    useEffect(() => {
        getCountryInfo()
    }, []);

   


    const testReactSelects = () => {
        if(isSwitch === false){
            if(updatedInformation.primary_country && updatedInformation.primary_state){
                return true;
              } else {
                if(!updatedInformation.primary_country){
                  setCountryValid(false);
                }
                if(!updatedInformation.primary_state){
                  setStateValid(false);
                }
                return false
              }
        } else {
            return true
        }
      }

    const checkPhones = () => {
        if (updatedInformation?.primary_phone && !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(updatedInformation?.primary_phone)) {
          return false
        } 
        else if (updatedInformation?.fax && !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(updatedInformation?.fax)) {
            return false
        }
        else {
          return true
        }
    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault()
        testReactSelects()
        if (!form.checkValidity() || testReactSelects() === false || checkPhones() === false) {
            e.stopPropagation();
        } else { 
            if(addNew) {
                const org = {...updatedInformation, poc: `${updatedInformation.first_name} ${updatedInformation.last_name}`}
                axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/`,
                    org,
                    {
                        headers:{
                            Authorization: `Token ${authToken}`,
                            "X-CSRFToken": csrfToken,
                        },
                        withCredentials: true
                    }
                ).then(response => {
                    loadVendors()
                    setAddNew(false)
                    setUpdatedInformation({})
                    const data = {...labVendorInformation, vendor: response.data.org_id}
                    axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/lab-vendors/`,
                        data,
                        {
                            headers:{
                                Authorization: `Token ${authToken}`,
                                "X-CSRFToken": csrfToken,
                            },
                            withCredentials: true
                        }
                    ).then(response => {
                        if (isSwitch) {
                            switchForms()
                            setReloadVendors(response.data.id)
                        } else {
                            handleCloseModal()
                        }
                    })
                    .catch(error => {console.log(error.response?.data)}) 

                })
                .catch(error => {console.log(error.response?.data)}) 
            }
        }
    }

    const associateVendor = () => {
        axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/lab-vendors/`,
            {...labVendorInformation,
                active: true
            },
            {
                headers:{
                    Authorization: `Token ${authToken}`,
                    "X-CSRFToken": csrfToken,
                },
                withCredentials: true

            }
        ).then(response => {
            if (isSwitch) {
                switchForms()
                setReloadVendors(response.data.id)
            } else {
                handleCloseModal()
            }
        })
        .catch(error => {console.log(error.response?.data)}) 
    }
   
   
       
    return(
        <Modal
            size="lg"
            show={show}
            onHide={handleCloseModal}
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title> + ASSOCIATE NEW VENDOR </Modal.Title>
                </Modal.Header>
                <Modal.Body>
            {addNew === false &&
                <Form.Group controlId='existing_vendor'> 
                
                    <Form.Label>
                        Existing Vendor*
                    </Form.Label>
                    <ReactSelect 
                        options={vendors} 
                        newObj={labVendorInformation} 
                        handleChange={handleExistingChange} 
                        name="vendor" 
                        isValid={true} 
                        defaultValue="Select.."
                    />
                </Form.Group>
            }
                {addNew === false ?
                    <Button className='my-4' onClick={() => {setAddNew(true)}}>ADD NEW VENDOR</Button>
                    :
                    <Button className='my-4' onClick={() => {setAddNew(false)}}>CANCEL ADD NEW VENDOR</Button>
                }
                <div className={addNew ? "" : "d-none"}>
                    <h5>COMPANY INFORMATION</h5>
                    <Row>
                        <Col md={6} sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='comapny'>
                                    <Form.Label>Company:* </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.company} type="text" name="name" required onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='phone'>
                                        <Form.Label>Phone:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.phone} type="tel" name="primary_phone" required={isSwitch ? false : true} onChange={handleChange} isInvalid={errors.primary_phone} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='fax'>
                                        <Form.Label>Fax: </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.fax} type="tel" name="primary_fax" onChange={handleChange} isInvalid={errors.fax}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='address'>
                                    <Form.Label>Address:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.address} type="text" name="primary_address_1" required={isSwitch ? false : true} onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='city'>
                                        <Form.Label>City:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.city_state_zip} type="text" name="primary_city" required={isSwitch ? false : true} onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='state'>
                                        <Form.Label>State:* </Form.Label>
                                        <ReactSelect 
                                            options={states} 
                                            newObj={updatedInformation} 
                                            handleChange={handleChange} 
                                            name="primary_state" 
                                            isValid={stateValid} 
                                            defaultValue="Select.."
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='zip'>
                                        <Form.Label>Zip:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.city_state_zip} type="text" name="primary_zip" required={isSwitch ? false : true} onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='country'>
                                        <Form.Label>Country:* </Form.Label>
                                        <ReactSelect 
                                            options={countries} 
                                            newObj={updatedInformation} 
                                            handleChange={handleChange} 
                                            name="primary_country" 
                                            isValid={countryValid} 
                                            defaultValue="Select.."
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='website'>
                                        <Form.Label>Wesbite:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.website} type="text" name="website" required={isSwitch ? false : true} onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} sm={12}>
                            <h5>POINT OF CONTACT</h5>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='first_name'>
                                        <Form.Label>First Name:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.first_name} type="text" name="first_name" required={isSwitch ? false : true} onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='last_name'>
                                        <Form.Label>Last Name:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.last_name} type="text" name="last_name" required={isSwitch ? false : true} onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='email'>
                                        <Form.Label>Email Address:{isSwitch?"":"*"} </Form.Label>
                                        <Form.Control defaultValue={updatedInformation?.email} type="email" name="poc_email" required={isSwitch ? false : true} onChange={handleChange}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}> Cancel </Button>
                    {addNew ? 
                        <Button type="submit"> Submit </Button>
                        :
                        <Button onClick={associateVendor}> Submit </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default CCTLAssociatesVendorModal;