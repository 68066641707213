import { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import logo from '../../images/logo.png';
import nsaLogos from '../../images/nsaLogos.png';
import './Footer.css';
import ContactUsModal from '../ContactUs/ContactUsForm';
import { useLocation, Link } from 'react-router-dom';
import { useCookies } from "react-cookie";

export default function Footer() {
    const [hideFooter, setHideFooter] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const location = useLocation();
    const [cookies] = useCookies();
    let authToken = cookies['auth_token'];
  
    useEffect(() => {
      location.pathname.includes('/login') ? setHideFooter(true) : setHideFooter(false);
    }, [location])

    return ( !hideFooter && 
        <>
        <ContactUsModal show={show} handleClose={handleClose} loadCaptcha={true} />
        <footer className='text-center text-md-start bg-footer text-light'>
            <Container>
                <Row className='p-5 pb-3 gx-5 gb-5 m-0'>
                    <Col sm={12} md={4} className='mb-3'>
                        <Stack className='mb-2'>
                            <h6 className='fw-bold text-uppercase mb-3 link_header lh-base'>Products and Common Criteria</h6>
                            <Link to='/products' className='link'><h6>Products</h6></Link>
                            <Link to='/cctl' className='link'><h6 className='lh-base'>Common Criteria Testing Labs</h6></Link>
                            <Link to='/ccra' className='link'><h6 className='lh-base'>Common Criteria Recognition Agreements</h6></Link>
                            <a href="https://www.commoncriteriaportal.org" target="_blank" rel="noreferrer" className='link'><p className='m-0 lh-base'>Visit the Common Criteria Portal</p></a>
                        </Stack>
                    </Col>
                    <Col sm={12} md={4} className='mb-3'>
                        <Stack className='mb-2'>
                            <h6 className='fw-bold text-uppercase mb-3 link_header'>Protection Profile</h6>
                            <Link to='/technical-communities' className='link'><p>Technical Communities</p></Link>
                            <Link to='/protectionprofiles'  className='link'><p className='lh-base'>Search for Approved Protection Profiles</p></Link>
                        </Stack>
                    </Col>
                    <Col sm={12} md={4} className='mb-3'>
                        <Stack className='mb-4'>
                            <Link to='/niap-ccevs' className='link'><p className='mb-2'>About NIAP</p></Link>
                            <Link to='/policies' className='link'><p className='mb-2'>Resources</p></Link>
                            <Link to='/faqs' className='link'><p className='mb-2'>FAQ</p></Link>
                            <Link to='/announcements' className='link'><p className='mb-2'>Announcements</p></Link>
                            <Link to='/events' className='link'><p className='mb-2'>Events</p></Link>
                        </Stack>
                        <Col className='btn-group' role="group">
                            <a href={authToken ? '/logout' : '/login'}>
                                <Button className='btn-warning me-3 rounded-pill'>{authToken ? 'Logout' : 'Login'}</Button>
                            </a>
                            <Button className='btn-footer border border-warning rounded-pill p-0' onClick={handleShow}>Contact Us</Button>
                        </Col>
                    </Col>
                </Row>
                <Row className='p-5 gy-5'>
                    <Col>
                        <img src={nsaLogos} className='me-5' height="40px" alt="NSA Logos" />
                    </Col>
                    <Col>
                        <img src={logo} className='me-5' height="40px" alt="NIAP Logo" />
                    </Col>
                    <Col>
                        <Link to={'/sitemap'} aria-label="link site map" className='text-light me-5 p-0 my-0'>Site Map</Link>
                    </Col>
                    <Col className='d-flex align-items-center'>
                    <p className='ms-auto p-0 my-0'>&copy; 2021 National Information Assurance Partnership</p>  
                    </Col>
                </Row>
            </Container>
        </footer>
        </>
    )
}
