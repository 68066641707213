import { Container, Row, Col, Stack, Accordion, Breadcrumb } from 'react-bootstrap'
import './tds.css'
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import UnauthorizedView from "../UI/UnauthorizedView"
import Subheader from '../Subheader';
import { useInterval } from '../../hooks/useInterval';
import parse from 'html-react-parser';
import moment from 'moment';

export default function TechDecisionDetails() {

  const { id } = useParams();
  const [td, setTd] = useState({});
  const [view, setView] = useState(false)
  const navigate = useNavigate();

    const loadTechDecId = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/frontend_by_identifier/${id}/`)
        .then(response => {
            setTd(response.data);
            setView(true)
        }).catch(error => {
            console.log(error)
            setView(false)
        })
    }, [id])

  useEffect(() => {
    loadTechDecId()
  }, [loadTechDecId])

    useInterval(()=>{
        loadTechDecId()
    }, 60000) //1 min

    if (view === false) {
        return <UnauthorizedView />
    }

  return (
    <>
        <Subheader pageName={"Technical Decisions"} />
        <Container className='p-5' fluid>
        <Breadcrumb>
            <Breadcrumb.Item
                onClick={() => navigate(`/`)}>
                Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
                onClick={() => navigate(`/technical-decisions/`)}>
                Technical Decisions
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
                {td?.identifier}
            </Breadcrumb.Item>
        </Breadcrumb>
            <Row className='mb-4'>
                <Col>
                    <h6 className='fw-bold'>TECHNICAL DECISION</h6>
                    <h3 className='fw-bold'>{td?.title}</h3>
                </Col>
            </Row>
            <Row className='gx-2'>
                <Col sm={12} md={3} lg={2} className='bg-dark text-white mb-2'>
                    <Stack className='border-bottom pt-4 ps-4'>
                        <small className='pb-2'>Technical Decision</small>
                        <p>{td?.identifier}</p>
                    </Stack>
                    <Stack className='border-bottom pt-4 ps-4'>
                        <small className='pb-2'>Publication Date</small>
                        <p>{moment.utc(td?.publication_date).format('MM/DD/YYYY')}</p>
                    </Stack>
                    <Stack className='border-bottom pt-4 ps-4'>
                        <small className='pb-2'>Protection Profiles</small>
                        <ul>
                        {td?.protection_profile?.map((pp) => {
                            return <li key={pp.pp_id}>{pp.pp_name}</li>
                        })}
                        {td?.sunset_protection_profile?.map((pp) => {
                            return <li key={pp.pp_id}>{pp.pp_name}</li>
                        })}
                        </ul>
                    </Stack>
                    <Stack className='border-bottom pt-4 ps-4'>
                        <small className='pb-2'>References</small>
                        <p>{td?.related_to}</p>
                    </Stack>
                </Col>
                <Col sm={12} md={9} lg={10} className='px-4'>
                    <Accordion className='mb-4' flush>
                        <Accordion.Item>
                            <Accordion.Header><h6 className='text-primary fw-bold'>ISSUE DESCRIPTION</h6></Accordion.Header>
                            <Accordion.Body>{td?.description && parse(td?.description)}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='my-4' flush>
                        <Accordion.Item>
                            <Accordion.Header><h6 className='text-primary fw-bold'>RESOLUTION</h6></Accordion.Header>
                            <Accordion.Body>{td?.resolution && parse(td?.resolution)}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='my-4' flush>
                        <Accordion.Item>
                            <Accordion.Header><h6 className='text-primary fw-bold'>JUSTIFICATION</h6></Accordion.Header>
                            <Accordion.Body>{td?.justification && parse(td?.justification)}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    </>
  )
}
