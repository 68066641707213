import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Stack, Button, ButtonGroup } from 'react-bootstrap'
import { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import { useCookies } from "react-cookie";
import moment from 'moment';
import { useSelector } from "react-redux";
import fileDownload from "js-file-download";
import LabgramForm from "./LabgramForm";
import parse from "html-react-parser";
import { useInterval } from "../../../hooks/useInterval"

export default function LabgramDetails({setOverlayActive, setOverlayText}) {
    const [cookies] = useCookies()
    let csrfToken = cookies['csrftoken']
    let authToken = cookies['auth_token']
    const { id } = useParams();
    const navigate = useNavigate()

    const [show, setShowModal] = useState(false);
    const [labgram, setLabgram] = useState({});
    const user = useSelector(state => state.user.value);
    const [files, setFiles] = useState([]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
    }

    const loadLabgramsId = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/${id}`, {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
        })
        .then(response => {
            setLabgram(response.data);
        }).catch(error => {
            console.log(error)
        })
        pullFiles();
    }, [show, id])

    useEffect(() => {
        loadLabgramsId()
    }, [loadLabgramsId])

    useInterval(()=>{
        loadLabgramsId()
    }, 60000) //1 min

    const pullFiles = useCallback(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=labgram&file_type_id=${id}`,
        { withCredentials: true, headers: { 'Authorization':  `Token ${authToken}`}
        }).then((response) => setFiles(response.data?.filter(file => file.active))
        ).catch((error) => console.log(error))
    }, [id])

    useEffect(() => {
        pullFiles()
    }, [pullFiles])

    useInterval(()=>{
        pullFiles()
    }, 180000) //3 min

    const downloadFile = (fileName) => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_policy_letter/?file_name=${fileName}`,
        { withCredentials: true, headers: { Authorization: `Token ${authToken}`}, responseType: "blob" }
        ).then((response) => fileDownload(response.data, fileName))
        .catch((error) => console.log("Error: " + error));
    }

    const handleDelete = () => {
        let currentTime = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()));
        axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/${labgram?.labgram_id}/`, 
        { creator: labgram?.creator?.id, editor: user.id, archived: true, moddate: currentTime }, 
        { withCredentials: true, headers: { Authorization: `Token ${authToken}`, 'X-CSRFToken': csrfToken }
        }).then(() => navigate('/community/labgrams-manager/'))
        .catch((error) => console.log('Unable to archive labgram: ' + error)
        )
    }

    useEffect(() => {
        document.title = 'NIAP Community - Labgram Details';
    }, []);
  
    return (
        <Container className='p-5' fluid>
            <Row>
                <Col md={12} lg={9}>
                    <Row>
                        <Col sm={12}><h1>LABGRAM</h1></Col>
                        <Col sm={12}>
                            <h2 className='fw-bold text-primary'>Labgram #{labgram?.labgram_id} - {labgram?.subject}</h2>
                        </Col>
                        <Col sm={11} className="px-0">
                            <div className="d-flex justify-content-end">
                                <ButtonGroup>
                                    <Button variant="link" className="ms-auto fst-italic p-0 m-0" onClick={handleShowModal}>Edit</Button>
                                    <Button variant="link" className="fst-italic p-0 m-0" onClick={handleDelete}>Delete</Button>
                                </ButtonGroup>
                            </div>
                        </Col>
                        <Col sm={11} className="ms-3 p-2 border border-dark">
                            <h6 className="text-end fst-italic">Recorded by {labgram?.author}</h6>
                            <h6 className="text-end fst-italic">{moment.utc(labgram?.labgram_date).format('MM/DD/YYYY HH:mm')}</h6>
                            <h6>{labgram?.labgram && parse(labgram?.labgram)}</h6>
                        </Col>
                        <Col sm={12} className="border-dark border-top my-5 pt-3">
                            <h3 className="text-primary text-uppercase fw-bold">Links</h3>
                            <div className="ms-3 p-2 border border-dark">
                                {files.map(file => (
                                   <Button key={file?.file_id} variant="link" className="text-bright-navy text-decoration-underline"
                                        onClick={() => downloadFile(file?.file_name)}>
                                        {file?.file_label}
                                    </Button>
                                ))}
                            </div>
                            
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={3}>
                    <Stack className="border border-dark mb-3">
                        <h5 className="text-uppercase text-white bg-secondary p-2">Attribution Information</h5>
                        <small className="p-2">Last Modified</small>
                        <p className="px-2">{labgram?.moddate ? moment.utc(labgram?.moddate).format('MM/DD/YYYY HH:mm') : 
                        moment.utc(labgram?.entrydate).format('MM/DD/YYYY HH:mm')} by {labgram?.editor ? (` ${labgram?.editor?.first_name} ${labgram?.editor?.last_name}`) : 
                        (`${labgram?.creator?.first_name} ${labgram?.creator?.last_name}`)}</p>
                    </Stack>
                    {/* <div className="d-flex">
                        <Button variant="primary" className="ms-auto">Release This Item</Button>
                    </div> */}
                </Col>
            </Row>
            <LabgramForm show={show} handleCloseModal={handleCloseModal} labgram={labgram} currentUser={user} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
        </Container>
    )
}
