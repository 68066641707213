import { useState, useRef, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import { useSelector } from 'react-redux'
import { 
  Button, 
  ButtonGroup, 
  Container, 
  Row, 
  Col, 
  Popover, 
  OverlayTrigger, 
  ListGroup,
  Modal,
} from "react-bootstrap";
import ColorList from "./ColorList";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from 'react-router-dom';
import ContactTRRTModal from "../TRRTSPage/ContactTRRTModal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Filter from "./TRRTTableFilter";
import qs from "qs";
import parse from "html-react-parser"
import UnauthorizedView from "../../UI/UnauthorizedView";

export default function TechnicalQueriesTable({id}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [showColorKey, setShowColorKey] = useState(false);
  const [showTRRTQueryModal, setShowTRRTQueryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTQ, setDeleteTQ] = useState({});
  const [filterOpen, setFilterOpen] = useState(id ? false : true)
  //setting sidebarFilters to show 'active' statuses by default
  const [sidebarFilters, setSidebarFilters] = useState({status: "Proposed,TD Required,Resolution Proposed,In Progress,TD Proposed,Accepted"})
  const [refreshKey, setRefreshKey] = useState(0)
  const permissions = useSelector(state => state.role.value);
  const [groupedTds, setGroupedTds] = useState([])
  const tableRef = useRef()

  useEffect(() => {
    if (window.innerWidth < 768) {
      setFilterOpen(false);
    }
  }, []);
  
  const tqStatusOptions = {
    'Proposed': 'Proposed', 
    'In Progress': 'In Progress',
    'Rejected': 'Rejected',
    'TD Required': 'TD Required',
    'TD Proposed': 'TD Proposed',
    'Resolution Proposed': 'Resolution Proposed',
    'Resolved': 'Resolved',
    'Accepted': 'Accepted'
}

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange()
    }
  }, [sidebarFilters, id])

  const changeFilter = () => {
    setFilterOpen(!filterOpen)
  }

  const compareDates = (deadline) => {
    const newDeadline = new Date(deadline);
    const newCurrent = new Date();
    if (newDeadline < newCurrent) {
      return "red";
    } else if (newDeadline > newCurrent) {
      const diffTime = Math.abs(newCurrent - newDeadline);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays - 1 <= 2) {
        return "yellow";
      }
      return "green";
    }
  };

  const handleDelete = () => {
    axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${deleteTQ?.tq_id}/`,
      {
        active: false,
        recaptcha_token: "member_portal",
        modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
      },
      {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((res) => {
        setRefreshKey(refreshKey + 1);
        setDeleteTQ({})
        setShowDeleteModal(false)
      })
      .catch((err) => console.log("unable to get technical queries", err));
  }

  const fetchTQUrl = () => {
    if(permissions?.role_type === "NIAP"){
      return "technical-query/niap_tq_table_data/"
    } else if (permissions?.role_type === "Lab"){
      return "technical-query/lab_tq_table_data/"
    } else if (permissions?.role_type === "Validator"){
      return "technical-query/validator_tq_table_data/"
    } else if (permissions?.role_type === "SME"){
      return "technical-query/sme_tq_table_data/"
    }
  };

  const getGroupedTds = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_grouped_tds_by_tq/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((res) => {
        setGroupedTds(res.data);
      })
      .catch((err) => console.log("unable to get technical queries", err));
  }, []);

  useEffect(() => {
    getGroupedTds()
  }, [])

  const columns = [
    {
      field: "tq_id",
      title: "ID",
      render: (rowData) => (
        permissions?.role_type !== "Lab" ? (
          <Link to={`/community/technicalqueries/details/${rowData.tq_id}`} className='text-decoration-underline'>
            {rowData?.tq_id}
          </Link>
        ) : (
          <Link className="text-decoration-none" style={{ cursor: 'default' }}>
            {rowData?.tq_id}
          </Link>
        )
      )
    },    
    {
      title: "Color Key",
      headerStyle: {display: "none"},
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <>
          <ErrorIcon
            style={{
              color:
                compareDates(rowData?.deadline) === "red"
                  ? "var(--bs-red)"
                  : compareDates(rowData?.deadline) === "yellow"
                  ? "var(--bs-yellow)"
                  : "var(--bs-green)",
            }}
          />
        </>
      ),
    },
    
    {
      field: "deadline",
      title: "Deadline",
      type: "date",
      hidden: id,
      render: (rowData) => (
        <> 
        {
          rowData?.deadline ? moment.utc(rowData.deadline).format('MM/DD/YYYY') : 
          rowData?.requestedby ? moment.utc(rowData.requestedby).format('MM/DD/YYYY') : 
          ""
        } 
        </>
      ),
    },
    {
      field: "urgency",
      title: "Priority",
      hidden: id,
    },
    {
      field: "status",
      title: "Status",
      lookup: tqStatusOptions,
    },
    {
      field: "team.team",
      title: "Primary Team",
      hidden: id,
    },
    {
      field: "team.trrt_team",
      title: "Extended Teams",
      hidden: id,
      render: (rowData) => (
        <ListGroup>
          {rowData?.trrt_team?.map((team, idx) => (
            <ListGroup.Item disabled key={idx}>
              {team?.team}
            </ListGroup.Item>
          ))}
        </ListGroup>
      ),
    },
    {
      field: "full_requirement",
      title: "Requirement",
      hidden: id,
      render: (RowData) => (
        <p>{RowData.requirement}{RowData.family_component_element}</p>
      )
    },
    // {
    //   field: "response",
    //   title: "Response",
    //   hidden: id,
    //   render: (rowData) => (
    //     rowData?.status === "Resolved" &&
    //     <div style={{ maxHeight: 40, overflow: 'hidden', textOverflow: 'ellipsis' }}>
    //       {rowData?.response && parse(rowData?.response)}
    //     </div>
    //   )
    // },
    {
      field: "question",
      title: "Question",
      hidden: id,
      render: (rowData) => (
        <div style={{ maxHeight: 40, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {rowData?.question && parse(rowData?.question)}
        </div>
      )
    },
    {
      field: "td_id",
      title: "Associated TDs",
      filtering: false,
      sorting: false,
      hidden: id,
      render: (rowData) => (
        <ListGroup>
        { groupedTds && groupedTds[rowData?.tq_id]?.map((td, idx) => (
          <Link
            to={`/community/technical-decisions/details/${td}`}
            className='text-decoration-underline'
            key={idx}
          >
            <ListGroup.Item disabled>
              {td}
            </ListGroup.Item>
          </Link>
        ))}
        </ListGroup>
      )
    },
    {
      field: "title",
      title: "Title",
    },
    {
      field: "submitted_by",
      title: "Author",
      hidden: id,
      render: (rowData) => (
        rowData.submitted_by === null 
        ?
        <>
          {rowData.name}
        </>
        :
        <>
          {rowData.submitted_by?.first_name} {rowData.submitted_by?.last_name}
        </>
      )
    },
    { 
      field: "product_evaluation",
      title: 'Project Evaluations',
      filtering: false, 
      sorting: false,
      hidden: id,
      render: (rowData) => (
            <ListGroup>
                {rowData.product_evaluation?.map((product, idx) => (
                <Link 
                to={`/community/products/details/${product.product_id}`}
                className='text-decoration-underline'
                key={idx}
                >
                    <ListGroup.Item disabled>
                        VID{product.v_id}
                    </ListGroup.Item>
                    </Link>
                    ))
                }
            </ListGroup>
        
      ) 
    },
    {
      field: "date_sent",
      type: "date",
      title: "Date Sent to iTC",
      hidden: id,
      render: (rowData) => (
        <>
          {rowData?.date_sent && moment.utc(rowData?.date_sent).format('MM/DD/YYYY')}
        </>
      ),
    },
    {
      field: "submitted_on",
      type: "date",
      title: "Submitted",
      hidden: id,
      render: (rowData) => (
        <>
          {rowData?.submitted_on && moment.utc(rowData?.submitted_on).format('MM/DD/YYYY')}
        </>
      ),
    },
    {
      field: "modified_on",
      type: "date",
      title: "Modified",
      hidden: id,
      render: (rowData) => (
        <>
          {rowData?.modified_on && moment.utc(rowData?.modified_on).format('MM/DD/YYYY')}
        </>
      ),
    },
    {
      field: "modified_by",
      title: "Modified By",
      hidden: id,
      render: (rowData) =>
      <>
        {rowData?.modified_by?.first_name} {rowData?.modified_by?.last_name}
      </>
    },
    ...((permissions?.role_type !== "Lab")?
    [{
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      cellStyle: {
        position: "sticky",
        right: "0",
        backgroundColor: "var(--bs-white)",
      },

      headerStyle: {
        position: "sticky",
        right: "0",
        textAlign: "center",
      },
      render: (rowData) => {
        return (
          <>
           {
            (permissions?.role_permissions?.find(
              permission => permission.area === 'Technical Questions'
            )?.destroy && permissions?.role_type === 'NIAP') ?
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  key="bottom"
                  overlay={
                    <Popover>               
                      <Popover.Body>
                        <Button
                          variant="link"
                          className="m-1 p-0 btn-link"
                          href={`/community/technicalqueries/details/${rowData.tq_id}`} 
                        >
                          <RemoveRedEyeIcon color="action" /> View
                        </Button>
                      
                          <Button
                            variant="link"
                            className="m-1 p-0 btn-link"
                            onClick={(e) => {
                              setDeleteTQ(rowData)
                              setShowDeleteModal(true)
                              }}
                          >
                            <DeleteIcon color="action" /> Delete
                          </Button>
                      
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="link">
                    <MoreVertIcon className="float-end" />
                    <span className="visually-hidden">Actions</span>
                  </Button>
                </OverlayTrigger>
              :
              <Button
                variant="link"
                className="m-1 p-0 btn-link"
                href={`/community/technicalqueries/details/${rowData.tq_id}`} 
              >
              <RemoveRedEyeIcon color="action" /> View
              </Button>
          }
          </>
        );
      },
    }]:[])
  ];

  const options = {
    columnsButton: true,
    search: true,
    filtering: true,
    pageSize: 20
  };

  return (
    permissions?.role_permissions?.find(permission => permission.area === 'Technical Questions')?.read ?
    <>
    {showDeleteModal && (
      <Modal size='md' show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className='border-0'>
        <Modal.Title>Delete Technical Query</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-center'>Are You Sure You Want to Technical Query ID: {deleteTQ?.tq_id}?</h4>
          <div className='d-flex justify-content-center mt-4'>
            <Button
              variant='primary'
              className='m-1'
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant='danger'
              className='m-1'
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )}
    <ContactTRRTModal 
      show={showTRRTQueryModal}
      setShow={() => setShowTRRTQueryModal(false)}
      memberPortal={true}
      refetch={() => setRefreshKey(refreshKey + 1)}
    />
      <Container fluid>
        <Row className="py-4">
          <Col className="text-end">
            {!id &&
              <ButtonGroup>
                <Button
                  variant="primary"
                  className="me-3 rounded-0"
                  onClick={() => setShowColorKey(!showColorKey)}
                >
                  Color Key
                </Button>
                <Button
                  className="me-3 rounded-0"
                  variant='warning'
                  onClick={() => setShowTRRTQueryModal(true)}
                >
                  SUBMIT A TRRT QUERY
                </Button>
              </ButtonGroup>
            }
          </Col>
          {showColorKey && (
            <Row>
              <Col className="text-start">
                <ColorList />
              </Col>
            </Row>
          )}
        </Row>
        {!id &&
        <Row className="mt-3">
          <Col md={9}></Col>
          <Col sm={12} md={3} className="d-flex justify-content-end align-content-center mb-2">
              <Button variant="link rounded-pill" style={{backgroundColor: "#dedede", maxHeight: 40}} onClick={changeFilter}>
                {filterOpen && <>
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                  &nbsp;
                  Close Filter
                  &nbsp;
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                </>}
                {!filterOpen && <>
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                  &nbsp;
                  Open Filter
                  &nbsp;
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                </>}
              </Button>
          </Col>
        </Row>
        }
        <Row className="mb-3">
          <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
            <ResponsiveMaterialTable
              title={"Technical Queries"}
              key={refreshKey}
              tableRef={tableRef}
              columns={columns}
              options={options}
              actions={[
                {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () =>
                    tableRef.current &&
                    tableRef.current.dataManager.columns.forEach((item) => {
                        tableRef.current.onFilterChange(item.tableData.id, "");
                    }),
                }
              ]}
              data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                    let value = ""
                    if (Array.isArray(filter.value)) {
                        value = filter.value
                    } else if (["deadline", "submitted_on", "modified_on", "response_on", "date_sent"]?.includes(filter?.column?.field)) {
                        const dateObj = new Date(filter?.value);
                        const isoStr = dateObj.toISOString();
                        const formattedStr = isoStr.split('T')[0];
                        value = formattedStr
                    } else {
                        value = filter.value.replace(/['"]+/g, '')
                    }
                    if(filter.column.field === "status"){
                      if(value?.length > 0){
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                      } else {
                        return null
                      }
                    } else {
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    }
                }).filter(filter => filter !== null);

                let params = {
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                }

                if(id){
                  params["product_id"] = id
                } else {
                  params = {
                    ...sidebarFilters,
                    ...params
                  }
                }

                axios
                    .get(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}${fetchTQUrl()}?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                        {
                            withCredentials: true,
                            headers: {
                                Authorization: `Token ${authToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        resolve({
                            data: response.data.results,
                            page: page,
                            totalCount: response.data.count,
                        });
                    })
                    .catch((error) => {
                        reject(error);
                    });
              })
              }
            />
          </Col>
          {!id && filterOpen &&
            <Col xs={10} md={3}>
              <Filter filters={sidebarFilters} setFilters={setSidebarFilters} />
            </Col>
          }
        </Row>
      </Container>
    </>
    :
    <UnauthorizedView />
  );
}
