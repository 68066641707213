import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from "react-bootstrap";
import './HomepageHeader.css'

export default function HomepageHeader() {
  return (
    <Container fluid className='text-white'>
      <Row className='justify-content-start' id="homepage-header">
        <Col xs={12} md={6} lg={5} xl={4} className='p-4 m-5'>
          <Card className='rounded-0 card-1 main-card'>
            <Card.Body className='d-flex align-items-center align-flex text-white'>
              <Card.Title className='p-2'>
              <h1>CYBERSECURITY</h1>
              <h1>COLLABORATION</h1>
              <h1>CENTER</h1> 
              <h1 className='text-warning'>NIAP</h1>
              </Card.Title>
              <Card.Text className='p-2' as="h5">
                NIAP, through the Cybersecurity Collaboration Center, oversees a national program to evaluate Commercial Off-The-Shelf Information Technology products for conformance to the International Common Criteria.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
