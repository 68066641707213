import { useState } from 'react';
import { Tabs, Tab, Alert, Container, Row, Col } from 'react-bootstrap';
import UserRolesTable from './UserRolesTable';
import DeletedRolesTable from './DeletedRolesTable';

export default function UserRolesManager({roles}) {
  const [rolesSuccess, setRolesSuccess] = useState(false);
  const [deletedRoles, setDeletedRoles] = useState([]);
  const [restoreSuccess, setRestoreSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  return (
    <Container>
      <Row>
        {rolesSuccess && (
          <Alert
            variant='success'
            dismissible
            onClose={() => setRolesSuccess(false)}
          >
            User Role Created!
          </Alert>
        )}
        {restoreSuccess && (
          <Alert
            variant='success'
            dismissible
            onClose={() => setRestoreSuccess(false)}
          >
            User Role Restored!
          </Alert>
        )}
        {deleteSuccess && (
          <Alert
            variant='success'
            dismissible
            onClose={() => setDeleteSuccess(false)}
          >
            User Role Successfully Deleted!
          </Alert>
        )}
        <Col>
          <Tabs defaultActiveKey='active' id='user-roles-tabs' className='mb-3'>
            <Tab eventKey='active' title='Active Roles'>
              <UserRolesTable
                roles={roles}
                setRolesSuccess={setRolesSuccess}
                setDeleteSuccess={setDeleteSuccess}
                rolesSuccess={rolesSuccess}
                restoreSuccess={restoreSuccess}
                deleteSuccess={deleteSuccess}
              />
            </Tab>
            <Tab eventKey='deleted' title='Deleted Roles'>
              <DeletedRolesTable 
                deletedRoles={deletedRoles} 
                setDeletedRoles={setDeletedRoles}
                setRestoreSuccess={setRestoreSuccess}
                rolesSuccess={rolesSuccess}
                restoreSuccess={restoreSuccess}
                deleteSuccess={deleteSuccess}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
