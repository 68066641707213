import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import ReactSelect from "../../../ReactSelect";
import axios from "axios";
import { useCookies } from "react-cookie";

export default function ProductTypeFormSection({
    product,
    editedProduct,
    setEditedProduct,
    handleChange,
    reload,
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [allVids, setAllVids] = useState([]);
    const [productVids, setProductVids] = useState([]);
    const [vidsObj] = useState({});

    // calls get_all_products endpoint and grabs all existing vids from
    // those products to populate previous_vid selection drop down
    const fetchAllProducts = () => {
        axios
            .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products/`, {
                withCredentials: true,
                headers: { Authorization: `Token ${authToken}` },
            })
            .then((response) => setAllVids(response.data))
            .catch((error) => console.log("unable to get products", error));
    };

    useEffect(() => {
        fetchAllProducts();
    }, []);

    useEffect(() => {
        if(product){
            setProductVids(product?.previous_vid?.filter(vid => vid?.active === true)?.map(v => ({
                value: v.previous_product?.v_id,
                label: `VID
                    ${v.previous_product?.v_id}`,
            })))
        }
    }, [product, reload]);


    const vidOptions = allVids
        ?.filter(
            (product) =>
                !productVids?.some(
                    (productVid) => productVid.v_id === product.v_id
                )
        )
        ?.map((prod) => ({ value: prod.v_id, label: "VID" + prod.v_id }));

    return (
        <Row className="mb-3">
            <Form.Group className="my-3" controlId="is_resubmission">
                <Row>
                    <Col sm={6}>
                        <Form.Check
                            inline
                            label={
                                <span className="text-dark">
                                    New Product Submission*
                                </span>
                            }
                            name="is_resubmission"
                            defaultChecked={product?.is_resubmission === false}
                            value={false}
                            type="radio"
                            onChange={handleChange}
                            id={"is_resubmission2"}
                            required
                        />
                    </Col>
                    <Col sm={6}>
                        <Form.Check
                            inline
                            label={
                                <span className="text-dark">
                                    Product Re-Submission*
                                </span>
                            }
                            name="is_resubmission"
                            defaultChecked={product?.is_resubmission === true}
                            value={true}
                            type="radio"
                            onChange={handleChange}
                            id={"is_resubmission1"}
                            required
                        />
                    </Col>
                </Row>
            </Form.Group>
            {(product?.is_resubmission === true || editedProduct?.is_resubmission === true) && (
                <Row>
                    <Col sm={8}>
                        <Row>
                            <Form.Label>Indicate all previously terminated or withdrawn VIDs if this is a resubmitted product:*</Form.Label>
                            <Col>
                                <Form.Group className="mb-3" controlId="previous_vid">
                                    <ReactSelect
                                        isMulti={true}
                                        options={vidOptions}
                                        newObj={!editedProduct.previous_vid ? vidsObj : editedProduct}
                                        handleChange={handleChange}
                                        name="previous_vid"
                                        isValid={true}
                                        defaultValue={productVids}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Row>
    );
}
