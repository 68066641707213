import {useState, useEffect} from 'react'
import axios from 'axios';
import { Accordion, Container } from "react-bootstrap";
import LabgramsTable from "./LabgramsTable";
import Subheader from '../Subheader';
import { useInterval } from '../../hooks/useInterval';

export default function LabgramsPage () {
    const [activeLabs, setActiveLabs] = useState({})
    const [inactiveLabs, setInactiveLabs] = useState({})

    const loadLabgrams = () => {
        axios
            .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/get_frontend_labgrams/?archived=true`)
            .then((response) => {
                setInactiveLabs(response.data)
            }).catch(error => console.log(error))
        axios
            .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/get_frontend_labgrams/?archived=false`)
            .then((response) => {
                setActiveLabs(response.data)
            }).catch(error => console.log(error))
    }

    useEffect(() => {
        loadLabgrams()
    }, [])

    useInterval(()=>{
        loadLabgrams()
    }, 600000) //10 min

    useEffect(() => {
        document.title = 'NIAP - Labgrams';
    }, []);

    return (
        <>
        <Subheader pageName={"Labgrams"} />
        <Container className="mb-5 py-3">
            <div className="p-4">
                <h4 className="text-uppercase fw-bold pb-3">RESOURCES - <span className="text-primary text-uppercase">LABGRAMS</span></h4>
                <p>
                    LabGrams are a means of communication between the CCEVS and CCTLs (including applicant CCTLs). 
                    They are distributed electronically to subscribers of the ccevs-labs & ccevs-applicants mail 
                    distribution lists. LabGrams deliver guidance and CCEVS policy until such time that CCEVS 
                    publications are updated.
                </p>
            </div>
            <div className="p-4">
            <Accordion flush defaultActiveKey="0" alwaysOpen>
                <Accordion.Item eventKey="0">
                <Accordion.Header><h6 className='text-primary fw-bold'>{activeLabs.count} CURRENT LABGRAMS</h6></Accordion.Header>
                <Accordion.Body>
                <LabgramsTable status="active" />
                </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                <Accordion.Header><h6 className='text-primary fw-bold'>{inactiveLabs.count} SUPERSEDED OR OUTDATED LABGRAMS</h6></Accordion.Header>
                <Accordion.Body>
                <LabgramsTable status="inactive" />
                </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </div>
        </Container>
        </>
    )
}