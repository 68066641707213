import { useState } from "react";
import { Modal, Form, Button, Container, Row, Col } from "react-bootstrap";
import MembersForm from "../MembersForm";

export default function AddTeamMemberModal({
  show,
  setShow,
  reload,
  id,
  currentUser,
  levels,
  refetch
}) {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>+ ADD TEAM MEMBER</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MembersForm
          reload={reload}
          virtualTeamID={id}
          currentUser={currentUser}
          levels={levels}
          handleClose={handleClose}
          refetch={refetch}
        />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
