import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import parse from "html-react-parser";
import { Modal, Container, Row, Col, Form, Button } from "react-bootstrap";
import FileDownload from "js-file-download";
import { useSelector } from "react-redux";
import { handleAlertAndMailer } from "../../Helper/functions";
import { updateProductProgressPoint } from "../../../UI/Functions/progressFunctions";

export default function ReadOnlyProductForm({
  show,
  handleCloseModal,
  product,
  review,
  refetch,
  setOverlayActive,
  setOverlayText
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  let currentUser = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [protectionProfiles, setProtectionProfiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [ears, setEars] = useState([]);
  const [rejected, setRejected] = useState(false);
  const [rejectionRationale, setRejectionRationale] = useState("");
  const [teamDueDate, setTeamDueDate] = useState("");
  const [csfcComponents, setCsfcComponents] = useState([]);

  const loadAllProductsFiles = useCallback(() => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_projpp_by_product_id?product_id=${product?.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setProtectionProfiles(response.data);
        })
        .catch((error) => console.log("error getting protection profiles: ", error));

      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=product&file_type_id=${product?.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => setFiles(response.data))
        .catch((error) => console.log("error getting product files: ", error));
    }
  }, [product?.product_id]);

  const loadCSFCComponents = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}cs/proj/get_cscomponentproj_by_pid/?pid=${product.product_id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        setCsfcComponents(response.data);
      })
      .catch((error) => console.log(error));
  };

  const loadEarsAndFiles = () => {
    axios
    .get(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/ears_and_files_by_product_id/?product_id=${product?.product_id}`,
      {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      }
    )
    .then((response) => {
      setEars(response.data);
    })
    .catch((error) => console.log(error));
  };


  useEffect(() => {
    if (["Yes", "Maybe", "true"]?.includes(product?.csfc_eligible)) {
      loadCSFCComponents();
    }
  }, [product?.csfc_eligible]);

  useEffect(() => {
    setRejected(false);
    setRejectionRationale("");
    loadAllProductsFiles();
    loadEarsAndFiles();
  }, [loadAllProductsFiles]);

  const downloadFile = (file) => {
    setOverlayText("Downloading")
    setOverlayActive(true)
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        setOverlayActive(false)
        FileDownload(response.data, file.file_new_name ? file.file_new_name : file.file_name);
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const handleNIAPApprovalAlert = (product) => {
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        subject: `Validator Resource Request for VID${product?.v_id}`,
        recipients: { to: ["Validator Resource Team"], cc: ["NIAP Management"] },
        alert_text: `Validator Resource Request for VID${product?.v_id}`
      }
    )
  };

  // const handleNIAPRejectAlert = () => {
  //   handleAlertAndMailer(
  //     csrfToken, 
  //     authToken, 
  //     {
  //       alert_type_id: product?.product_id,
  //       alert_type: "Product",
  //       subject: `Check in Package for VID${product?.v_id} was rejected by NIAP.`,
  //       recipients: { to: ["Lab", "Vendor"], cc: ["NIAP Management"] },
  //       alert_text: `Check in Package for VID${product?.v_id} was rejected by NIAP.`
  //     }
  //   )
  // };

  const handleVendorApprovalAlert = (product) => {
    // we send the email to vendor and lab
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        subject: `Evaluation Project Proposed: VID ${product?.v_id}`,
        recipients: { to: ["Lab"], cc: ["Vendor"] },
        alert_text: `Evaluation Project Proposed – VID ${product?.v_id}`
      }
    )
    // we send the email to NIAP Management
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        subject: `Evaluation Project Proposed - VID ${product?.v_id}`,
        recipients: { to: ["NIAP Management"], cc: [] },
        alert_text: `Evaluation Project Proposed - VID ${product?.v_id}`
      }
    )
  };

  const handleVendorRejectAlert = (product) => {
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        subject: `Check in Package for VID${product?.v_id} rejected by Vendor.`,
        recipients: { to: ["Lab"], cc: ["NIAP Management", "Vendor"] },
        alert_text: `Check in Package for VID${product?.v_id} rejected by Vendor.`
      }
    )
  };

  const handleSign = () => {
    product["claims"] = protectionProfiles;
    product["files"] = files.filter((f) => f.active === true);
    product["ear_files"] = ears
    product["signed_by"] = `${currentUser.first_name} ${currentUser.last_name}`;
    product["status"] = "Proposed";
    setOverlayText("Redirecting")
    setOverlayActive(true);
    handleCloseModal(product);
    axios
      .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/sign_document/`, product, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        handleVendorApprovalAlert(product);
        setOverlayActive(false);
        window.location.href = response.data.redirect_url;
      })
      .catch((error) => {
        console.log("error", error.response.data);
      });
  };

  const removeSignedCheckinForm = () => {
    let signedCheckin = files?.find(
      (file) => file?.file_display_name === "Vendor Signed Check In Package"
    );
    if (signedCheckin?.file_id) {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${signedCheckin?.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          }
        )
        .then((response) => {
          console.log("file removed");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleApprove = (e, action) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      let data = {
        team_due_date: teamDueDate !== "" ? teamDueDate : product?.team_due_date !== null ? product?.team_due_date : null,
        pre_eval_status: action === "approved" ? "Ready for Assignment" : null,
      };
      // if NIAP rejected, we set the status back to Draft(goes back to vendor)
      // else if vendor rejects, we set status back to Internal Draft(goes back to Lab)
      if (action === "rejected") {
        if (permissions?.role_type === "NIAP") {
          data["status"] = "Draft";
          data["rejected_rationale"] = !review ? rejectionRationale : null;
          data["pre_eval_status"] = "Proposed";
          data["signature"] = null;
        } else {
          // for VENDOR
          data["status"] = "Internal Draft";
          data["vendor_rejected_rationale"] = review ? rejectionRationale : null;
          data["pre_eval_status"] = null;
        }
      } else {
        data["rejected_rationale"] = null;
      }

      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
          data,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then(async (response) => {
          if (action === "approved") {
            await updateProductProgressPoint(response.data.product_id, 1, "Completed", cookies);
            handleNIAPApprovalAlert(response.data);
          } else {
            removeSignedCheckinForm();
            if (permissions?.role_type !== "NIAP") {
              handleVendorRejectAlert(response.data);
            }
          }
          refetch();
          handleCloseModal(response.data, action, "Assignment");
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <Modal size="lg" show={show} onHide={() => handleCloseModal()}>
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Form onSubmit={(e) => handleApprove(e, "approved")}>
        <Modal.Body>
          <Row>
            <h5 className="fw-bold text-center">NIAP Check-In Form</h5>
            <h5 className="fw-bold text-center">
              Product Evaluations Claiming Exact Compliance with a NIAP Approved PP VID{" "}
              {product?.v_id}
            </h5>
            <Col className="mt-4">
              <Row className="m-3">
                {product?.is_resubmission === true ? (
                  <h5>Product Re-Submission</h5>
                ) : (
                  <h5>New Product Submission</h5>
                )}
                {product?.is_resubmission === true && (
                  <>
                    <p className="m-0 mt-4">Previous VID Numbers:</p>
                    <ul>
                      {product?.previous_vid.map((vid, idx) => (
                        <li key={idx}>
                          VID
                          {vid?.previous_product?.v_id}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </Row>
              <Row className="mb-2">
                <p className="fw-bold form-title">EVALUATION INFORMATION</p>
                <Col lg={6} sm={12}>
                  <p className="fw-bold m-0">Product Name:</p>
                  <p className="form-indent-readOnly">{product?.product_name}</p>
                </Col>
                <Col lg={6} sm={12}>
                  <p className="fw-bold m-0">Product URL:</p>
                  <p className="form-indent-readOnly">{product?.url}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6} sm={12}>
                  <p className="fw-bold m-0">Recommended Technology Type:</p>
                  <p className="form-indent-readOnly">{product?.tech_type}</p>
                </Col>
                <Col lg={6} sm={12}>
                  <p className="fw-bold m-0">Version:</p>
                  <p className="form-indent-readOnly">{product?.version}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <p className="fw-bold m-0">Protection Profile Claims:</p>
                <Container>
                  {protectionProfiles?.length === 0 ? (
                    <p className="form-indent-readOnly">None</p>
                  ) : (
                    <ul>
                      {protectionProfiles.map((pp, idx) => (
                        <li key={idx}>{pp?.pp__pp_name}</li>
                      ))}
                    </ul>
                  )}
                </Container>
              </Row>
              <Row className="mb-3">
                <Col sm={12}>
                  <p className="fw-bold m-0">Product Description:</p>
                  <div className="form-indent-readOnly">
                    {product?.product_description && parse(product?.product_description)}
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <p className="fw-bold form-title m-0">ATTACHED FILES:</p>
                  {files
                    ?.filter((file) => file?.active === true && file?.file_display_name !== "EAR")
                    .map((file, idx) => (
                      <Row key={idx} className="mx-2 mt-2">
                        <Button
                          variant="link"
                          className="text-bright-navy text-decoration-underline text-start"
                          onClick={() => downloadFile(file)}
                        >
                          {file?.file_display_name}
                        </Button>
                      </Row>
                    ))}
              </Row>
              <Row className="mb-3">
                <p className="fw-bold form-title m-0">ENTROPY ASSESSMENT REPORTS:</p>
                {ears?.map((ear, idx) => (
                  <Row key={idx} className="mx-2 mt-2">
                    {permissions?.role_type === "NIAP" ?
                      <p className="fw-bold">EAR ID: {ear?.earid}</p>
                      :
                      <p className="fw-bold">EAR:</p>
                    }
                    <p className="mb-1">Attached Files:</p>
                    {ear?.files?.map((file, idx) => (
                      <Button
                        key={idx}
                        variant="link"
                        className="text-bright-navy text-decoration-underline text-start"
                        onClick={() => downloadFile(file)}
                      >
                        {file?.file_display_name} - {file?.file_label}
                      </Button>
                    ))}
                  </Row>
                ))}
                {product?.ear_required === false && (
                  <Col>
                    <Row>
                      <p className="form-indent-readOnly">EAR Not Required</p>
                      <p className="fw-bold m-0">Justification for selecting no:</p>
                      <div className="form-indent-readOnly">
                        {product?.no_ear_justification && parse(product?.no_ear_justification)}
                      </div>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                <Row>
                  <Col lg={12}>
                    <p className="fw-bold form-title">PROJECTED COMPLETION DATES</p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <p>
                    Progress Point 3: ST Evaluation Completed:{" "}
                    <span className="fw-bold">
                      {moment.utc(product?.st_evaluation_date).format("MM/DD/YYYY")}
                    </span>
                  </p>
                  <p>
                    Progress Point 4: Test Ready:{" "}
                    <span className="fw-bold">
                      {moment.utc(product?.test_ready_date).format("MM/DD/YYYY")}
                    </span>
                  </p>
                  <p>
                    Progress Point 5: Testing Complete:{" "}
                    <span className="fw-bold">
                      {moment.utc(product?.testing_complete_date).format("MM/DD/YYYY")}
                    </span>
                  </p>
                  <p>
                    Progress Point 6: Evaluator Check-out:{" "}
                    <span className="fw-bold">
                      {moment.utc(product?.evaluator_checkout_date).format("MM/DD/YYYY")}
                    </span>
                  </p>
                </Row>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <p className="fw-bold form-title">NIAP CCEVS PRODUCT LISTING</p>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-3">
                {product?.publish_status === "Published" ? (
                  <p className="text-dark form-indent-readOnly">
                    <span className="fw-bold">LIST </span> on NIAP CCEVS website
                  </p>
                ) : (
                  <p className="text-dark form-indent-readOnly">
                    <span className="fw-bold">DO NOT LIST </span>
                    on NIAP CCEVS website
                  </p>
                )}
              </Row>
              <Row className="mb-3">
                <Col>
                  <p className="fw-bold form-title">VENDOR INFORMATION</p>
                  <Row className="form-indent-readOnly">
                    <p className="fw-bold m-0">
                      Vendor: <span className="fw-normal">{product?.vendor_id?.name}</span>
                    </p>
                  </Row>
                  <Row className="form-indent-readOnly">
                    <p className="fw-bold m-0">
                      Vendor POC: <span className="fw-normal">{product?.vendor_poc}</span>
                    </p>
                  </Row>
                  <Row className="form-indent-readOnly">
                    <p className="fw-bold m-0">
                      Is the vendor interested in CSfC?{" "}
                      <span className="fw-normal">
                        {["Yes", "Maybe", "true"]?.includes(product?.csfc_eligible) ? "Yes" : "No"}
                      </span>
                    </p>
                  </Row>
                  {["Yes", "Maybe", "true"]?.includes(product?.csfc_eligible) && (
                    <Row className="form-indent-readOnly">
                      {["Yes", "Maybe", "true"]?.includes(product?.csfc_eligible) && (
                        <>
                          <p className="fw-bold mb-0">CSfC Components:</p>
                          {csfcComponents?.map((component, index) => {
                            return (
                              <span key={index} className="mb-0 mx-2">
                                {component.component}
                              </span>
                            );
                          })}
                        </>
                      )}
                    </Row>
                  )}
                </Col>
                <Col>
                  <p className="fw-bold form-title">LAB INFORMATION</p>
                  <Row>
                    <p className="fw-bold m-0">
                      Assigned Lab:{" "}
                      <span className="fw-normal">{product?.assigned_lab?.name}</span>
                    </p>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={6}>
                  <p className="fw-bold form-title">CAVP Certificate</p>
                  <div className="mx-3">
                    {product?.nist_required === true ? (
                      <p className="text-dark">
                        CAVP Certificate <span className="fw-bold">Required</span>
                      </p>
                    ) : (
                      <p className="text-dark">
                        CAVP Certificate <span className="fw-bold">NOT Required</span>
                      </p>
                    )}
                  </div>
                  {product?.nist_required === true && (
                    <>
                      <Form.Label>Estimated CAVP Certificate completed date:</Form.Label>
                      <p className="form-indent-readOnly fw-bold">
                        {moment.utc(product?.nist_completion).format("MM/DD/YYYY")}
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            {permissions?.role_type === "NIAP" && product?.status === "Proposed" && (
              <Form.Group className="mb-3" controlId="team_due_date">
                <Form.Label className="fw-bold">Validation Team Due Date:*</Form.Label>
                <Form.Control
                  type="date"
                  name="team_due_date"
                  defaultValue={moment.utc(product?.team_due_date).format("YYYY-MM-DD")}
                  onChange={(e) => setTeamDueDate(e.target.value)}
                  required
                />
              </Form.Group>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Container className="d-flex justify-content-end">
            <Row>
              <Col>
                <Button variant="outline-primary" onClick={() => handleCloseModal()}>
                  Cancel
                </Button>
              </Col>
              {permissions?.role_type !== "Vendor" && 
              <Col>
                <Button variant="danger" onClick={() => setRejected(true)}>
                  Reject
                </Button>
              </Col>
              }
              {!review ? (
                <Col>
                  <Button style={{ width: "max-content" }} type="submit">
                    Approve For Assignment
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button variant="warning" onClick={handleSign} disabled={!product.product_id}>
                    Sign
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
          {rejected && (
            <Container className="mx-5 mr-5">
              <Row className="mt-4">
                <Col>
                  <Form.Group controlId="rejected_rationale">
                    <Form.Label as="h4">Rationale For Rejection</Form.Label>
                    <Form.Control
                      rows={3}
                      as="textarea"
                      onChange={(e) => setRejectionRationale(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Button
                  style={{ width: "max-content" }}
                  className="m-3"
                  type="button"
                  onClick={(e) => handleApprove(e, "rejected")}
                >
                  Send
                </Button>
              </Row>
            </Container>
          )}
        </Modal.Footer>
      </Form>
      <small className="fw-bold p-3" align="right">
        NIAP CCEVS Form F8002a, Version 1.0 (Nov 13)
      </small>
    </Modal>
  );
}
