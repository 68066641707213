import { Route, Routes } from "react-router-dom";
import SerialNumberTable from "./SerialNumberTable";
import { useSelector } from "react-redux";
import UnauthorizedView from '../../UI/UnauthorizedView';

export default function SerialNumberManager() {
  const permissions = useSelector(state => state.role.value);

  if (permissions?.role_type !== "NIAP") {
      return (
          <UnauthorizedView />
      )
  }
  
  return (
    <Routes>
        <Route path="/" element={<SerialNumberTable />} />
    </Routes>
  );
}