import axios from 'axios';
import { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import { useCookies } from 'react-cookie';


const CreateNewContactModal = ({showModal, closeModal, currentContact, tableRef}) => {
    const [cookies] = useCookies()
    const authToken = cookies['auth_token']
    let csrfToken = cookies["csrftoken"];
    const [updatedInformation, setUpdatedInformation] = useState({})
    const [errors, setErrors] = useState({})
    
    

   const handleChange = (e) => {
        if (e.target.name === 'phone_number') {
            setErrors({...errors, [e.target.name]: !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(e.target.value)})
        }
        setUpdatedInformation({...updatedInformation, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        if(currentContact?.contact_id){
        setUpdatedInformation({...updatedInformation, ...currentContact, org_id: currentContact?.org_id?.org_id})
        }
    }, [currentContact])

    const updateOrgId = () => {
      axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`,
        {withCredentials:true,
            headers: {
                Authorization: `Token ${authToken}`
            }

        }
      ).then(response => {
        setUpdatedInformation({...updatedInformation, org_id: response.data.org_id}) 
      }).catch(error => {
        console.log(error.response.data)
      }) 
    }

    useEffect(() => {
        updateOrgId()
    }, []);

    const checkPhones = () => {
        if (updatedInformation?.phone_number && !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(updatedInformation?.phone_number)) {
          return false
        }
        else {
          return true
        }
    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault()
        if (!form.checkValidity() || !checkPhones()) {
            e.stopPropagation();
        } else {
            if(currentContact?.contact_id){
                axios.put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}org/contacts/${currentContact?.contact_id}/`,
                    updatedInformation,
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`
                        }
                    }
                ).then(response => {
                      
                        tableRef.current.onQueryChange()
                        closeModal()

                }).catch(error => {
                    console.log(error.response.data)
               
                })
            }
            else {
                axios.post(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}org/contacts/`,
                    {...updatedInformation, active: true}, 
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`
                        }
                    }
                ).then(response => {
                    
                        tableRef.current.onQueryChange()
                        closeModal()
                }).catch(error => {
                    console.log(error.response.data)
            
                })
            }
         }
    
    }

   
    return (
        <Modal
        size="lg"
        show={showModal}
        onHide={closeModal}
    >
        <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title> + NEW CONTACT </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Personal Information</h5>
                <Row>
                    <Col md={6} sm={12}>
                        <Row>
                              <Col sm={12}>
                                <Form.Group controlId='first_name'>
                                <Form.Label>*First Name:</Form.Label>
                                    <Form.Control defaultValue={updatedInformation?.first_name} type="text" name="first_name" onChange={handleChange} required/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Group controlId='last_name'>
                                    <Form.Label>*Last Name:</Form.Label>
                                    <Form.Control defaultValue={updatedInformation?.last_name} type="text" name="last_name" onChange={handleChange} required/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Group controlId='email'>
                                    <Form.Label>*Email Address:</Form.Label>
                                    <Form.Control defaultValue={updatedInformation?.email} type="email" name="email" onChange={handleChange} required/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} sm={12}>
                        <Row>
                            <Col sm={12}>
                                <Form.Group controlId='position'>
                                    <Form.Label>*Position:</Form.Label>
                                    <Form.Control defaultValue={updatedInformation?.position} type="text" name="position" onChange={handleChange} required/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Group controlId='phone_number'>
                                    <Form.Label>Phone Number: </Form.Label>
                                    <Form.Control defaultValue={updatedInformation?.phone_number} type="tel" name="phone_number" onChange={handleChange} isInvalid={errors.phone_number} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={closeModal}>Cancel</Button>
                <Button type="submit">{currentContact?.contact_id ? "Update" : "Add"} Contact</Button>
            </Modal.Footer>
        </Form>
    </Modal>
    )

}


export default CreateNewContactModal;