import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal, Button, Form } from "react-bootstrap";
import { getCurrentDate } from "../../utils/timeUtils";
import { handleAlertAndMailer } from "../Products/Helper/functions";

export default function EARResubmitModal({
    show,
    handleClose,
    review,
    refetch,
    user,
    fetchNotes,
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];

    const handleResubmit = () => {
        const update = {
            pid: review.pid?.product_id,
            status: "Pending Review",
            rejected: false,
        };
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review.earid}/`,
                update,
                {
                    withCredentials: true,
                    headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then(() => sendMail())
            .then(() => handleSetNote())
            .then(() => {
                refetch();
                handleClose();
            })
            .catch((error) => console.log(error));
    };

    const sendMail = () => {
        handleAlertAndMailer(
            csrfToken, 
            authToken, 
            {
                subject: `EAR Review ${review.earid} for VID ${review.pid?.product_id} - Resubmitted`,
                recipients: {to: ["ERT Team"], cc: ["NIAP Management"]},
                alert_type: "EAR",
                alert_text: `EAR Review ${review.earid} for VID ${review.pid?.product_id} - Resubmitted`,
                alert_type_id: review.earid,
            }
        )
    }

    const handleSetNote = () => {
        const update = {
            note: `Resubmitted by ${user?.first_name} ${user?.last_name}`,
            note_type: "ear-review",
            note_type_id: review.earid,
            submitted_by: user?.id,
            submitted_on: getCurrentDate(),
        };
        axios
            .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/`, update, {
                withCredentials: true,
                headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                },
            })
            .then((response) => fetchNotes())
            .catch((error) => console.log(error));
    }

    return (
        <Modal
        size="lg"
        show={show}
        onHide={() => {
            handleClose();
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Resubmit EAR {review?.earid}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <h5>VID {review?.pid?.v_id}</h5>
                <h5>{review?.pid?.product_name}</h5>
                </Modal.Body>
                <Modal.Footer>
                <Button
                    variant="success"
                    type="button"
                    onClick={() => {
                        handleResubmit()
                    }}
                >
                    Yes
                </Button>
                <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
