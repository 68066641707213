import { useState, useEffect, useCallback } from "react"
import { useCookies } from "react-cookie";
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import axios from "axios"

const RemoveMultipleMembersOneListForm = ({show, members, listName, onHide, getMembers, setAlert}) => {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [ editedMember, setEditedMember ] = useState({members: [], listNames: []})

    const handleReset = () => {
        setEditedMember({members: [], listNames: [listName]})
    }

    const handleChange = (member) => {
        const members = editedMember.members
        members.push(member)
        setEditedMember({...editedMember, members: members})
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/members/delete/`, 
            {
                ...editedMember,
            },
            {
                withCredentials: true,
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'X-CSRFToken': csrfToken,
                }
            }).then(response => {
                getMembers()
                onHide()
                setAlert({variant: "success", message: "Members removed successfully"})
            }).catch((error) => {
                console.log(error)
                setAlert({variant: "danger", message: "Error removing Members. Please try again."})
            })
        }
    }

    useEffect(() => {
        setEditedMember({members: [], listNames: [listName]})
    }, [show])

    return (
        <Modal show={show} onHide={onHide}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Mailing List Members</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="members">
                        <Form.Label>{listName} Members</Form.Label>
                        <Row>
                            {members?.map((item, idx) => (
                                <Col sm="12" key={`member_${idx}`}>
                                    <Form.Check type="checkbox" name="member" onChange={() => handleChange(item)} label={item.primarySmtpAddress} />
                                </Col>
                            ))}
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide}>Cancel</Button>
                    <Button type="reset" onClick={handleReset} variant="warning">Reset</Button>
                    <Button type="submit" onClick={handleSubmit} variant="success">Remove</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default RemoveMultipleMembersOneListForm