import { useEffect, useState, useCallback } from "react"
import { Row, Col, Form, Stack } from "react-bootstrap"
import axios from "axios"
import { useCookies } from "react-cookie"
import { useSelector } from "react-redux"
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@mui/icons-material/Save";

const ProductDetailsSubheader = ({product, loadProject}) => {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const permissions = useSelector((state) => state.role.value);
    const [productDetailsMetrics, setProductDetailsMetrics] = useState({})
    const [updatedInfo, setUpdatedInfo] = useState({})
    const [isEditing, setIsEditing] = useState(false)

    const getFipsStatus = (fips) => {
        if (fips === 0) {
            return "No Dependency Exists"
        } else if (fips === 1) {
            return "Unsatisified Dependency Exists"
        } else if (fips === 2) {
            return "Dependency Satisified"
        } else {
            return "TBD"
        }
    }

    const loadMetrics = useCallback(() => {
        if(product?.product_id){
            axios.get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/product_details_metrics/?product_id=${product?.product_id}`, 
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                }).then((response) => {
                    setProductDetailsMetrics(response.data)
                }).catch((error) => {
                    console.log(error)
                })
        }
    }, [product?.product_id])

    useEffect(() => {
        loadMetrics()
    }, [loadMetrics])

    const handleChange = (event) => {
        setUpdatedInfo({...updatedInfo, [event.target.name]: event.target.value})
    }

    const handleSave = () => {
        axios.put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`, 
            updatedInfo,
            {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                },
            }).then((response) => {
                loadProject()
                setIsEditing(false)
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <Row>
            <Col>
                <Stack className="ps-2 h-100">
                    <h6>Number of Associated Technical Queries</h6>
                    <p>{productDetailsMetrics?.tq_count}</p>
                </Stack>
            </Col>
            <Col>
                <Stack className="border-start border-secondary ps-2 h-100">
                    <h6>Number of Associated Technical Decisions</h6>
                    <p>{productDetailsMetrics?.td_count}</p>
                </Stack>
            </Col>
            {/* <Col>
                <Stack className="border-start border-secondary ps-2 h-100">
                    <h6>Current EAR Status</h6>
                    <p>{productDetailsMetrics?.ear_status}</p>
                </Stack>
            </Col> */}
            <Col>
                <Stack className="border-start border-secondary ps-2 h-100">
                    <h6>CAVP FIPS Status</h6>
                    <Row>
                        <Col xs={10}>
                            {!isEditing && <p>{getFipsStatus(product?.fips)}</p>}
                            {isEditing && <Form.Select name="fips" onChange={handleChange} defaultValue={product?.fips}>
                                <option value={-1}>TBD</option>
                                <option value={0}>No Dependency Exists</option>
                                <option value={1}>Unsatisified Dependency Exists</option>
                                <option value={1}>Dependency Satisified</option>
                            </Form.Select>}
                        </Col>
                        <Col xs={2}>
                            {!isEditing && permissions.role_type === "NIAP" && (
                                <div className="d-flex justify-content-end">
                                <EditIcon
                                    color="action"
                                    className="clickable"
                                    style={{ fontSize: "2rem" }}
                                    onClick={() => setIsEditing(true)}
                                />
                                </div>
                            )}
                            {isEditing && (
                                <div className="d-flex justify-content-end">
                                <SaveIcon
                                    color="action"
                                    className="clickable"
                                    style={{ fontSize: "2rem" }}
                                    onClick={() => handleSave()}
                                />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Stack>
            </Col>
        </Row>
    )
}

export default ProductDetailsSubheader