import React, { useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { useCookies } from "react-cookie";

const Filter = ({filters, setFilters}) => {
    const [cookies] = useCookies();
    const [currentFilters, setCurrentFilters] = useState({...filters, status: filters?.status?.split(',')})
    const [formKey, setFormKey] = useState(Date.now());
    const statusOptions = [
        "Pending Review",         
        "Unassigned",
        "Assigned",
        "Review in Progress",
        "Pending Customer Response",
        "Ready to Be Posted",
        "Posted",
        "Rejected",
        "Other", 
        "Unknown", 
    ]
    
    const handleSubmit = (event) =>{
        event.preventDefault()
        setFilters({...currentFilters, 
            status: currentFilters?.status?.join(',')})
    }
    const handleChange = (event) => {
        if (event.target.name === "status") {
            let filter = currentFilters[event.target.name]
            if (!filter) {
                filter = [event.target.value]
            } 
            else {
                if (event.target.checked) {
                    filter.push(event.target.value)
                } else {
                    const idx = filter.indexOf(event.target.value)
                    filter.splice(idx, 1)
                }
            }
            setCurrentFilters({ ...currentFilters, [event.target.name]: filter })
        }
        else if (event.target.value.length === 0) {
            const newFilters = currentFilters
            delete newFilters[event.target.name]
            setCurrentFilters({...newFilters})

        }else {
            setCurrentFilters({ ...currentFilters, [event.target.name]: event.target.value })
        }
    
    
    }
    const handleClear = (event) => {
        setFilters({})
        setCurrentFilters({})
        setFormKey(Date.now())
    }
    
    return (
        <Form onSubmit={handleSubmit} id="filters" key={formKey}>
            <Form.Group className="d-inline-block" controlId="from-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="from_date" onChange={handleChange} />
            </Form.Group> 
            <p className="d-inline-block p-3">to</p>
            <Form.Group className="d-inline-block" controlId="to-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="to_date" onChange={handleChange} />
            </Form.Group>
                <Form.Group controlId="date-type" className="mb-3">
                <Form.Label>For The</Form.Label>
                <Form.Select name="date_type" onChange={handleChange}>
                    <option value="">Select a type for the time frame</option>
                    <option value="entrydate">Entry Date</option>
                    <option value="moddate">Modification Date</option>
                    <option value="entrydate">Date Of Reciept</option>
                    <option value="deadline">Requested Response Date</option>
                    <option value="enddate">Completion or Cancellation Date</option>
               </Form.Select>
            </Form.Group>
            <Form.Group controlId="ticket" className="mb-3">
                <Form.Label>Ticket</Form.Label>
                <Form.Control type="text" name="ticket" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="prod" className="mb-3">
                <Form.Label>Product</Form.Label>
                <Form.Control type="text" name="prod" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="agent" className="mb-3">
                <Form.Label>Posting Agent Last Name</Form.Label>
                <Form.Control type="text" name="agent" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="qc" className="mb-3">
                <Form.Label>Reviewer Last Name </Form.Label>
                <Form.Control type="text" name="qc" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="status" className="mb-3">
                <Form.Label>Status</Form.Label>
                <Row>
                    {
                        statusOptions.map((statusObj, index) => (
                            <Col lg={6} xs={12} key={`status${index}`} >
                                <Form.Check 
                                    type="checkbox" 
                                    name="status" 
                                    value={statusObj} 
                                    label={statusObj} 
                                    onChange={handleChange} 
                                    id={`status${index}`} 
                                    defaultChecked={currentFilters.status?.includes(statusObj)}   
                                />
                            </Col>
                        ))
                    }
                </Row>
            </Form.Group>
            <div className="mb-3">
                <Button type="submit" variant="primary" onClick={handleSubmit}>Apply</Button>
                &nbsp;
                <Button type="reset" variant="outline-dark" onClick={handleClear}>Clear</Button>
            </div>

        </Form>
    ) 

}

export default Filter