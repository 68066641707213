import { Row, Col, Form } from "react-bootstrap";
import ReactSelect from "../../../ReactSelect";
import HTMLEditor from "../../..//UI/HTMLEditor";
import PPFormSection from "./PPFormSection";

export default function EvaluationInfoFormSection({
    product,
    editedProduct,
    handleChange,
    setProdDescValid,
    prodDescValid,
    ppValid,
    reload,
    productPps
}) {
    const techTypeOptions = [
    "Application Software",
    "Anti-Virus",
    "Biometrics",
    "BIOS Update",
    "Certificate Authority",
    "DBMS",
    "Degaussing Equipment",
    "Email Client",
    "Encrypted Comm Devices",
    "Encrypted Storage",
    "Enterprise Security Management",
    "Firewall",
    "Guard",
    "Hardware Platform and Components",
    "Key Recovery",
    "Miscellaneous",
    "Mobility",
    "Mobile Code",
    "Multi Function Device",
    "Multiple Domain Solution",
    "Network Access Control",
    "Network Device",
    "Network Encryption",
    "Network Interface Card",
    "Network Management",
    "Network Separator",
    "Network Switch",
    "Operating System",
    "Peripheral Switch",
    "PKI/KMI",
    "Redaction Tool",
    "Remote Access",
    "Retransmission Device",
    "Router",
    "Secure Messsaging",
    "Sensitive Data Protection",
    "Sharing Switch",
    "SIP Server",
    "Smart Card",
    "System Access Control",
    "Tokens",
    "Traffic Monitoring",
    "USB Flashdrive",
    "Virtual Private Network",
    "Virtualization",
    "VoIP",
    "Web Browser",
    "Web Server",
    "Wireless LAN",
    "Wireless Monitoring",
    "Wireless PAN"
    ];

    return (
        <Row className="mb-3">
            <p className="fw-bold form-title">EVALUATION INFORMATION</p>
            <Col lg={6} sm={12}>
                <Form.Group className="mb-3" controlId="product_name">
                    <Form.Label>Product Name:*</Form.Label>
                    <Form.Control
                        className="form-indent-readOnly"
                        name="product_name"
                        defaultValue={product?.product_name}
                        placeholder="Product Name"
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
            </Col>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group className="mb-3" controlId="tech_type">
                        <Form.Label>Recommended Technology Type:*</Form.Label>
                        <Form.Select name="tech_type" onChange={handleChange} defaultValue={product?.tech_type?.split(',') ?? []} required multiple={true}>
                            <option value="">Please Select Tech Type</option>
                            {techTypeOptions?.map((filteredVal, i) => (
                                    <option key={i} value={filteredVal}>
                                        {filteredVal}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group className="mb-3" controlId="version">
                        <Form.Label>Version:*</Form.Label>
                        <Form.Control
                            className="form-indent-readOnly"
                            type="text"
                            name="version"
                            defaultValue={product?.version}
                            onChange={handleChange}
                            placeholder="Version"
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <PPFormSection
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
                ppValid={ppValid}
                productPps={productPps}
            />
            <Row className="mt-4">
                <small className="p-3">
                    Include Administrative Guide, Product URL, or other
                    information to prove details about the product to be
                    evaluated.
                </small>
                <Form.Group className="my-3" controlId="url">
                    <Form.Label>Product URL:*</Form.Label>
                    <Form.Control
                        className="form-indent-readOnly"
                        type="url"
                        name="url"
                        defaultValue={product?.url}
                        onChange={handleChange}
                        placeholder="https://example.com"
                        required
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col sm={12} className="mt-3 mb-4">
                    <Form.Group
                        className="mb-3"
                        controlId="product_description"
                    >
                        <Form.Label>Product Description:*</Form.Label>
                        <HTMLEditor
                            name="product_description"
                            handleChange={handleChange}
                            setIsValid={setProdDescValid}
                            isValid={prodDescValid}
                            defaultValue={product?.product_description}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Row>
    );
}
