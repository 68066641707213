import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { useCookies } from "react-cookie"
import axios from "axios"

const MoveObject = ({show, handleClose, reloadDocuments, object, currentPage}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    let csrfToken = cookies['csrftoken']
    const [newParent, setNewParent] = useState("")
    const [folders, setFolders] = useState([])

    const loadFolders = () => {
        axios.get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_folders/?page=${currentPage}`,
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
            }
        ).then(response => {
            setFolders(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        loadFolders()
    }, [])

    const handleChange = (event) => {
        setNewParent(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation()
        }
        axios.put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/move/`,
            {parent: newParent, file_id: object?.file_id},
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                  'X-CSRFToken': csrfToken, 
                },
            }
        ).then(response => {
            loadFolders()
            reloadDocuments()
            handleClose()
        }).catch(error => {
            console.log(error)
        })
    }

    const handleReset = () => {
        setNewParent("")
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Move {object?.file_type === "Folder" ? "Folder" : "Document"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="file_name">
                    <Form.Label>Move {object?.file_type === "Folder" ? "Folder" : "Document"} to another Folder</Form.Label>
                            <Form.Select name="parent" onChange={handleChange} defaultValue={object?.parent?.file_id} required>
                                <option value={null}>Select a Folder</option>
                                {folders?.map(folder => (
                                    <option key={folder.file_id} value={folder.file_id}>{folder.file_location}</option>
                                ))}
                            </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>Update</Button>
                    <Button variant="primary" onClick={handleReset}>Reset</Button>
                    <Button variant="primary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default MoveObject