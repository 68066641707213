import { useEffect, useState } from 'react';
import { Container, Button, Alert } from "react-bootstrap";
import Subheader from '../../Subheader';
import ContactTRRTModal from './ContactTRRTModal';

export default function TRRTSPage () {

    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState({message: '', type: ''});
    const handleShow = () => setShow(true);

    useEffect(() => {
        document.title = 'NIAP - Technical Rapid Response Team';
    }, []);

    return (
        <>
        <ContactTRRTModal show={show} setShow={setShow} loadCaptcha={true} setAlert={setAlert} />
        <Subheader pageName={"TRRT Process"} />
        <Container className="mb-5 py-3">
            <div>
                {alert?.message !== "" &&
                <Alert variant={alert.type} onClose={() => setAlert({message: '', type: ''})} dismissible>
                    {alert.message}
                </Alert>
                }
            </div>
            <div className="p-4 border-bottom border-dark">
                <h4 className="text-uppercase fw-bold pb-3">PROTECTION PROFILES - <span className="text-primary text-uppercase">TECHNICAL RAPID RESPONSE TEAM</span></h4>
                <p>The National Information Assurance Partnership (NIAP) established TRRTs to address questions and concerns related to evaluations under the Common Criteria Evaluation & Validation Scheme (CCEVS). TRRTs provide an avenue to obtain responses to technical issues raised throughout the course of an evaluation. As a result, TRRTs are created on a per-technology or per-Protection-Profile (PP) basis.</p>
                <p>Each TRRT is made up of experienced Common Criteria individuals from the validation community and NSA subject matter experts. The goal of the TRRT process is to provide an issue resolution statement that clarifies and/or interprets PP requirements and Assurance Activities within a reasonable timeframe. If an issue pertains to multiple evaluations and/or PPs, the TRRT will publicly disseminate the decision, called a Technical Decision (TD) on the NIAP website.</p> 
                <p>Although anyone (CCTLs, Vendors, Schemes, or NIAP) can submit a question to the TRRT, there should be a level of review prior to question submission. That is, all other avenues should be exhausted prior to a question being submitted to the TRRT. For example, if a vendor is already in contract with a lab and has been assigned a validator, the vendor should first discuss their question with their lab. If the question still cannot be answered, the lab should discuss their question with the assigned validator.</p>
                <div style={{marginTop: '30px'}}>
                <Button variant='warning fw-bold' onClick={handleShow}>SUBMIT A TRRT QUERY</Button>
                </div>
            </div>
            <div className="p-4">
                <h4 className="text-primary text-uppercase fw-bold pb-3">TRRT SUBMISSIONS</h4>
                <p>TRRTs are designed to enable the teams to respond to inquiries effectively and efficiently. Therefore, be sure to include all pertinent information so that NIAP does not need to spend additional time requesting additional information. For example, please include:</p>
                <ul>
                    <li>Whether or not related to a product in evaluation or pre-evaluation.</li>
                    <li>Evaluation progress point(s) and estimated evaluation completion date.</li>
                    <li>List the cPP(s), PP(s), EP(s), and Module(s) the product is being evaluated against.</li>
                    <li>Propose a resolution with the issue being submitted.</li>
                </ul>
            </div>
        </Container>
        </>
    )
}