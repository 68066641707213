import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useEffect, useState, useCallback} from 'react'
import axios from "axios";
import { useCookies } from 'react-cookie';
import Card from "react-bootstrap/Card";
import { Button, Container, Row, Col } from "react-bootstrap";
import personIcon from '../../../images/person-lines-fill.svg';
import UpdateContactInformationModal from './UpdateContactInformationModal';
import EditOrgProfile from '../EditOrgProfile';

import EditIcon from "@mui/icons-material/Edit";
import PhoneIcon from "@mui/icons-material/LocalPhone";

const VendorContactCardModule = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies()
  const user = useSelector(state => state.user.value)
  const authToken = cookies['auth_token'];
  const [myOrg, setMyOrg] = useState({})
  const [currentUser, setCurrentUser] = useState(user)
  const [showModal, setShowModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const permissions = useSelector(state => state.role.value);

  const getMyOrg = useCallback(() => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`, 
    {   
        withCredentials: true, 
        headers: {
            "Authorization": `Token ${authToken}`
        }
    }).then(response => {
        setMyOrg(response.data)
    })
    .catch(error => {
        console.log(error)
    }
    )
  }, [authToken])

  useEffect(() => {
      getMyOrg()
  }, [getMyOrg])

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleEmailMessage = (email) => {
    navigate("/community/email", { state: {  email: email } });
  }

  const formatPhoneNumber = (phonenumber) =>{
    const match = phonenumber.match(/^(\d{1,3})(\d{3})(\d{3})(\d{4})/)
    if (match){
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    }
    else {
      const matchSmallPhone = phonenumber.match(/^(\d{3})(\d{3})(\d{4})/)
      if (matchSmallPhone){
        return `(${matchSmallPhone[1]}) ${matchSmallPhone[2]}-${matchSmallPhone[3]}`;
      }
    }
    return phonenumber
  }
  return (
    <>
      <UpdateContactInformationModal showModal={showModal} closeModal={closeModal} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
      <EditOrgProfile showModal={showOrgModal} closeModal={() => setShowOrgModal(false)} org={myOrg} refresh={getMyOrg} />
      <Card className="mt-2 mb-2">
        <Card.Body className="p-1">
          <Container>
            <Row className='mb-2'>
              <Col sm={12} md={8} className="d-flex justify-content-left align-items-left" style={{height: "150px"}}>
                  {myOrg.logo ? 
                    <img 
                      src={myOrg.logo}
                      alt="Organization Logo" 
                      style={{maxHeight: "80%", maxWidth: "80%"}}
                    />
                  :
                    <div>No Logo Set</div>
                  }
              </Col>
              <Col className='text-end' sm={12} md={4}>
                <img alt='personlogo' src={personIcon}/>
              </Col>
                  
            </Row>
              <h2 className='text-bright-blue fw-bolder'>{myOrg?.name}</h2>

            <Row className=' mt-4'>                
              <Col className="ms-3">
                <Row>
                  <h4 className='m-0 fw-bold'>Address {" "} {(permissions?.role_permissions?.find(permission => permission.area === 'Company Profile')?.update) &&
                  <EditIcon
                      fontSize="small"
                      className="clickable top-0 start-100 translate-middle-y"
                      onClick={()=>setShowOrgModal(true)}
                  />
                }</h4>
                  <p className='m-0'>{myOrg?.primary_address_1}</p>
                  {myOrg?.primary_address_2 != 'null' && <p className='m-0'>{myOrg?.primary_address_2}</p>}
                  <p className='m-0'>{myOrg?.primary_city}, {myOrg?.primary_state}, {myOrg?.primary_zip}</p>
                  <p className='m-0'>{myOrg?.primary_country}</p>
                </Row>
                
              </Col>
              <Col className='ms-3'>
                <h4 className='m-0 fw-bold'>{user?.first_name} {user?.last_name} {" "}
                  <EditIcon
                  fontSize="small"
                  className="clickable top-0 start-100 translate-middle-y"
                  onClick={openModal}
                  /></h4>

                {user.phone_number &&
                
                <p className='m-0'><PhoneIcon fontSize="small"/> {formatPhoneNumber(user.phone_number)}</p>}
                {user.fax && <p className='m-0'>{user.fax} (fax)</p>}
                {user.email && <Button 
                  variant="link" 
                  onClick={() => {handleEmailMessage(user.email)}} 
                  className='p-0 text-bright-blue text-decoration-underline'
                >
                  {user.email}
                </Button>
                }
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}

export default VendorContactCardModule;