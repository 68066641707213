import { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Row, Col } from 'react-bootstrap';
import { useInterval } from "../../../hooks/useInterval";

const NIAPMetricsGroup = ({}) => {
    const [cookies] = useCookies();
    const authToken = cookies['auth_token'];
    const [productMetrics, setProductMetrics] = useState({});
  
    const fetchProductMetrics = () => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/product_metrics/`,
        { withCredentials: true, headers: { Authorization: `Token ${authToken}` }})
        .then(response => setProductMetrics(response.data))
        .catch(error => console.log('Unable to get current products: ' + error));
    }
    useEffect(() => {fetchProductMetrics()}, [])

    useInterval(()=>{
        fetchProductMetrics()
    }, 180000) //3 min


    return (
        <Row className='mt-3 mb-3'>
            <Col variant="light" className='text-center m-3 rounded-0 shadow'>
            <h2 className=" mt-2 text-gray-400">{productMetrics?.new_products}</h2>
                <p className="m-0 mb-2">New Projects</p>
            </Col>
            <Col variant="light" className='text-center m-3 rounded-0 shadow'>
            <h2 className=" mt-2 text-gray-400">{productMetrics?.new_trrts}</h2>
                <p className="m-0 mb-2">New TRRTs</p>
            </Col>
            <Col variant="light" className='text-center m-3 rounded-0 shadow'>
            <h2 className=" mt-2 text-gray-400">{productMetrics?.pending_ecrs}</h2>
                <p className="m-0 mb-2">Pending ECRs</p>
            </Col>
            <Col variant="light" className='text-center m-3 rounded-0 shadow'>
            <h2 className=" mt-2 text-gray-400">{productMetrics?.pending_tds}</h2>
                <p className="m-0 mb-2">Pending Technical Decisions</p>
            </Col>
            <Col variant="light" className='text-center m-3 rounded-0 shadow'>
                <h2 className=" mt-2 text-gray-400">{productMetrics?.in_final_products}</h2>
                <p className="m-0 mb-2">Products in Final Review</p>
            </Col>
            <Col variant="light" className='text-center m-3 rounded-0 shadow'>
                <h2 className="mt-2 text-gray-400">{productMetrics?.in_progress_products}</h2>
                <p className="m-0 mb-2">Products in Evaluation</p>
            </Col>
        </Row>
    )
}

export default NIAPMetricsGroup;