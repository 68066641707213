import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import ResponsiveMaterialTable from "../UI/MaterialTable/ResponsiveMaterialTable";
import AddVirtualTeamModal from "./Modals/AddVirtualTeamModal";
import { Button, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useInterval } from "../../hooks/useInterval";
import qs from "qs"
import UnauthorizedView from "../UI/UnauthorizedView";

export default function VirtualTeams() {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const currentUser = useSelector(state => state.user.value);
  const [teams, setTeams] = useState([]);
  const permissions = useSelector(state => state.role.value);
  const [show, setShow] = useState(false);
  const handleShow = () => {setShow(true); setEditMode(false);};
  const [refetch, setRefetch] = useState(false)
  const [editMode, setEditMode] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const tableRef = useRef()

  useEffect(() => {
    if (tableRef.current) {
        tableRef.current.onQueryChange()
    }
    
}, [refetch])

  const fetchVirtTeam = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((res) => {
        setTeams(res.data);
      })
      .catch((err) => console.log("unable to get virtual teams", err));
  };

  useEffect(() => {
    fetchVirtTeam();
  }, [])

  useInterval(()=>{
    fetchVirtTeam();
    tableRef.current.onQueryChange()
  }, 180000)

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleUpdateTeam = async (editedTeam) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/${editedTeam.vtid}/`,
        {
          ...editedTeam,
          moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          creator: currentUser.id,
          editor: currentUser.id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => console.log('Put request from updating team: ' + response))
      .catch((error) => console.log("Unable to Update Team: " + error));

    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((res) => {
        setTeams(res.data);
      })
      .catch((err) => console.log("unable to get virtual teams", err));
  };

  const activeOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const columns = [
    {
      title: "Team Name",
      field: "team",
      editable: "never",
      render: (rowData) => (
        <Link
          to={`/community/virtualteams/${rowData.vtid}`}
        >
          {rowData.team} ({rowData.abbrev})
        </Link>
      ),
    },
    {
      title: "Active Status",
      field: "active",
      lookup: { true: "Active", false: "Inactive" },
      render: (rowData) => (rowData.active ? <>Active</> : <>Inactive</>),
      editComponent: (props) => (
        <>
          <label htmlFor="select-component-lvls" className="visually-hidden">Select Component Levels</label>
          <Select
            id="select-component-lvls"
            options={activeOptions}
            defaultValue={activeOptions.find(
              (option) => option.value === props.rowData.active
            )}
            onChange={(e) => props.onChange(e.value)}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </>
      ),
    },
  ];

  const handleOpenEditModal = (rowData) => {
    setShow(true);
    setEditMode(true);
    setSelectedRowData(rowData);

  };

  const options = {
    sorting: true,
    filtering: true,
    search: false,
    columnsButton: true,
    actionsColumnIndex: -1,
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    pageSize: 20
  };

  return (
    permissions?.role_permissions?.find(permission => permission.area === 'Virtual Teams')?.read ?
    <Container fluid>
      <AddVirtualTeamModal
        show={show}
        handleClose={handleCloseModal}
        refetch={()=>{setRefetch(!refetch)}}
        editMode={editMode}
        teamData={selectedRowData}
      />
      <Col>
        <Row>
          <Col
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button variant="primary" className="m-3 w-sm-100" onClick={handleShow}>
              + Add New Team
            </Button>
          </Col>
        </Row>
      </Col>
      <ResponsiveMaterialTable
        title={"Virtual Teams"}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
            } = query;
  
            let newFilters = filters.map((filter) => {
                let value = ""
                if (Array.isArray(filter.value)) {
                    value = filter.value
                } else {
                    value = filter.value.replace(/['"]+/g, '')
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
            }
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                    });
                })
                .catch((error) => {
                    reject(error);
                });
            })
          }
        tableRef={tableRef}
        options={options}
        actions={[
          {
            icon: "edit", 
            tooltip: "Edit",
            onClick: (event, rowData) => {
              handleOpenEditModal(rowData);
            },
          },
        ]}
      />
    </Container>
    :
    <UnauthorizedView />
  );
}
