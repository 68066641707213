import { Modal, Form, Button, Container, Row, Col } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSelector } from "react-redux";

export default function ValidatorCheckoutRejectionModal({
  show,
  handleClose,
  project,
  currentPP,
  refetch,
  setAlert,
  reasoning,
  setRefetchNotes
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [checkoutPackageInfo, setCheckoutPackageInfo] = useState({});
  const user = useSelector((state) => state.user.value);
  const [ecrs, setEcrs] = useState([])

  const handleChange = (e) => {
    setCheckoutPackageInfo({
    ...checkoutPackageInfo,
    [e.target.name]: e.target.value,
    });
  };

  const fetchECRComments = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/comments_by_vid?vid=${project?.v_id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        setEcrs(
            response.data.filter((e) => e.state !== "Released")
        )
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken, project]);

  useEffect(() => {fetchECRComments()}, [fetchECRComments])

  const releaseEcr = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/release_listed_ecr/`,
        { ecrs: checkoutPackageInfo.selectedEcr },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let resubmissionCounter = currentPP.resubmission_counter;
    if (resubmissionCounter === null) {
        resubmissionCounter = 0;
    } else {
        resubmissionCounter += 1;
    }
    const note = "Product Rejected"
    const submittedBy = user.id;
    const noteType = "Product";
    const noteTypeId = project.product_id;
    createNote(note, submittedBy, noteType, noteTypeId);
    axios
        .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
            product: project.product_id,
            progress_point: 6,
            status: reasoning === 'Rejection' ? "Rejected" : 'In Progress',
            checkout_rejected_rationale: checkoutPackageInfo?.checkout_rejected_rationale,
            resubmission_counter: resubmissionCounter,
        },
        {
            withCredentials: true,
            headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            },
        }
        )
        .then((response) => {
          axios
          .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
          {
              product: project?.product_id,
              progress_point: 7,
              status: "Not Started",
              resubmission_counter: resubmissionCounter
          },
          {
              withCredentials: true,
              headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              },
          }
          )
          .then((response) => {
            refetch();
            handleClose();
            setAlert({
            variant: "success",
            message: "Progress Point Successfully Rejected",
            });
            releaseEcr();
          })
          .catch((error) => {
          console.log("error updating progress point", error);
          });
        })
        .catch((error) => {
        console.log("error updating progress point", error);
        });
  };

  const createNote = async (note, submittedBy, noteType, noteTypeId) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: note,
          submitted_by: submittedBy,
          note_type: noteType,
          note_type_id: noteTypeId,
          submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setRefetchNotes(response?.data?.note_id)
      })
      .catch((error) => alert("Unable to add note: " + error));
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit} id="validator-checkout-approval-form">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>
            <h3 className="text-bright-navy m-0">{reasoning === "Amended Package" ? "AMENDED PACKAGE NEEDED" : "CHECK OUT PACKAGE REJECTION"}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mt-5">
                <Form.Label as="h5" className="text-bright-navy text-uppercase">
                    RATIONALE FOR {reasoning}
                </Form.Label>
                <Form.Control
                    name="checkout_rejected_rationale"
                    rows={3}
                    as="textarea"
                    onChange={handleChange}
                />
            </Form.Group>
            {ecrs?.length > 0 &&
            <Container className="mt-4">
              <Form.Group
                  className="mb-4 text-start d-flex justify-content-start"
                  controlId="ecrs"
              >
                <Row>
                  <Col>
                    <Row>
                      <Form.Label as="h5" className="text-bright-navy text-uppercase">
                        ECR COMMENT RELEASE
                      </Form.Label>
                    </Row>
                    {ecrs.map(ecr => (
                      <Row key={ecr.ecrid} className="mx-2">
                        <Form.Check
                          type="checkbox"
                          label={ecr.gist}
                          name="selectedEcr"
                          value={ecr.ecrid}
                          className="fw-bold"
                          onChange={handleChange}
                          key={`${ecr.ecrid}selectedEcr`}
                        />
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Form.Group>
            </Container>
            }
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="rounded-pill"
            type="submit"
            disabled={!checkoutPackageInfo.checkout_rejected_rationale}
          >
            Save/Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
