import { useState, useEffect } from "react"
import {Form, Button, Container, Row, Col} from "react-bootstrap"
import axios from "axios";
import { useCookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close"

const Filter = ({filters, setFilters, update, currentPage}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const [currentFilters, setCurrentFilters] = useState(filters)
    const [displayFilters, setDisplayFilters] = useState([])
    const [users, setUsers] = useState([])
    const [folders, setFolders] = useState([])

    const loadFolders = () => {
        axios.get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_folders/?page=${currentPage}`,
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
            }
        ).then(response => {
            setFolders(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        loadFolders()
    }, [update])

    const loadUsers = ()=>{
        axios.get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_filter_users/`,
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
            }
        ).then(response => {
            setUsers(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        loadUsers()
    }, [])

    useEffect(() => {
        if (filters && Object.keys(filters).length === 0) {
            setCurrentFilters({})
            document.getElementById('filters').reset()
        }
    }, [filters])

    const handleChange = (event) => {
        setCurrentFilters({...currentFilters, [event.target.name]: event.target.value})
        if (event.target.name === "uploaded_by") {
            const user = users.find(user => user.id === Number(event.target.value))
            console.log(user)
            setDisplayFilters({...displayFilters, [event.target.name]: user.first_name + ' ' + user.last_name})
        } else if (event.target.name === "parent") {
            const parent = folders.find(folder => folder.file_id === Number(event.target.value))
            console.log(parent)
            setDisplayFilters({...displayFilters, [event.target.name]: parent.file_name})
        } else if (event.target.name === "file_name") {
            setDisplayFilters({...displayFilters, [event.target.name]: event.target.value})
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setFilters(currentFilters)
    }

    const handleClear = () => {
        setFilters({})
    }

    const removeFilter = (key) => {
        const newFilters = currentFilters
        delete newFilters[key]
        const newDisplayFilters = displayFilters
        delete newDisplayFilters[key]
        setFilters({...newFilters})
        setDisplayFilters({...newDisplayFilters})
    }

    return (
        <Container className="my-5 mx-1" style={{background: "white"}}>
        <Row>
        <Col>
        <Form onSubmit={handleSubmit} id="filters">
            <Form.Group className="d-inline-block" controlId="from-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="from_date" onChange={handleChange} />
            </Form.Group>
            <p className="d-inline-block p-3">to</p>
            <Form.Group className="d-inline-block" controlId="to-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="to_date" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="date-type" className="mb-3">
                <Form.Label>For The</Form.Label>
                <Form.Select name="date_type" onChange={handleChange}>
                    <option value="">Select a type for the time frame</option>
                    <option value="uploaded_on">Uploaded On</option>
                    <option value="removed_on">Removed On</option>
                    <option value="replaced_on">Replaced On</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="file-name" className="mb-3">
                <Form.Label>File Name</Form.Label>
                <Form.Control type="text" name="file_name" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="parent-folder" className="mb-3">
                <Form.Label>Folder Name</Form.Label>
                <Form.Select name="parent" onChange={handleChange}>
                    <option value="all">All</option>
                    {folders.map(folder => (
                        <option key={`folder-${folder.file_id}`} value={folder.file_id}>{folder.file_location}{folder.file_name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="uploaded-by" className="mb-3">
                <Form.Label>Uploaded By</Form.Label>
                <Form.Select name="uploaded_by" onChange={handleChange}>
                    <option value="">Select a user</option>
                    {users.map((user) => (
                        <option key={`user-${user.id}`} value={user.id}>{user.first_name} {user.last_name}</option>
                    ))}
                </Form.Select>
            </Form.Group> 
            <div className="py-2">
                {Object?.keys(displayFilters).map(key => (
                    <Button key={currentFilters[key]} onClick={() => removeFilter(key)} className="m-2">{displayFilters[key]} <CloseIcon /></Button>
                ))}
            </div>
            <Button type="submit" variant="primary" onClick={handleSubmit} className="mb-2">Apply</Button>
            &nbsp;
            <Button type="reset" variant="outline-dark" onClick={handleClear} className="mb-2">Clear</Button>
        </Form>
        </Col>
        </Row>
        </Container>
    )
}

export default Filter