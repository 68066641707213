import { useState, useEffect, useRef } from "react"
import axios from "axios"
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PersonAddIcon from '@mui/icons-material/PersonAddAlt1'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from 'react-bootstrap/Button'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom"
import MultipleMembersOneListForm from "./MultipleMembersOneListForm"
import RemoveMultipleMembersOneListForm from "./RemoveMultipleMembersOneListForm"
import EditMailContact from "./EditMailContact"
import { useInterval } from "../../../hooks/useInterval"
import { useNavigate } from "react-router-dom"

const Members = () => {
    const { id } = useParams()
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [mailingListMembers, setMailingListMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState({});
    const tableRef = useRef()
    const navigate = useNavigate()
    const [alert, setAlert] = useState({message: '', type: ''})

    const getMembers = () => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/members/${id}/`, 
            {withCredentials: true, headers: {'Authorization': `Token ${authToken}`}
        })
        .then(response => {
            setMailingListMembers(response.data)
        })
        .catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        getMembers()
    }, [])

    const handleShowModal = (event, member) => {
        setSelectedMember(member)
        setShowModal(true)
    }

    const handleHideModal = () => {
        setSelectedMember({})
        setShowModal(false)
    }

    const handleShowEditModal = (event, member) => {
        setSelectedMember(member)
        setShowEditModal(true)
    }

    const handleHideEditModal = () => {
        setSelectedMember({})
        setShowEditModal(false)
    }

    const handleShowRemoveMemberModal = (event, member) => {
        setSelectedMember(member)
        setShowRemoveMemberModal(true)
    }

    const handleHideRemoveMemberModal = () => {
        setSelectedMember({})
        setShowRemoveMemberModal(false)
    }

    const handleShowDeleteModal = (event, member) => {
        setSelectedMember(member)
        setShowDeleteModal(true)
    }

    const handleHideDeleteModal = () => {
        setSelectedMember({})
        setShowDeleteModal(false)
    }

    const handleDeleteMember = () => {
        axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/members/delete/`, 
        {
            listNames: [id],
            members: [selectedMember]
        },
        {
            withCredentials: true,
            headers: {
                'Authorization': `Token ${authToken}`,
                'X-CSRFToken': csrfToken,
            }
        }).then(response => {
            getMembers()
            handleHideDeleteModal()
        }).catch((error) => {console.log(error)})
    }

    useInterval(()=>{
        getMembers()
    }, 600000) //10 min

    //this function gets the name of the mailing list name from the url
    function getDecodedMemberName() {
        const path = window.location.pathname;
        const segments = path.split('/');
        const encodedName = segments.pop();
    
        return decodeURIComponent(encodedName);
    }

    const columns = [
        {title: "Name", field: "name"},
        {title: "Email", field: "primarySmtpAddress"},
        { title: "Actions", 
            filtering: false, 
            sorting: false, 
            rowStyle: {textAlign: "right"},
            render: (rowData) => {
            return (
                <OverlayTrigger trigger="click" rootClose placement='bottom' key='bottom'
                    overlay={
                        <Popover>
                            <Popover.Body>
                                {(rowData.recipientType !== "User" && rowData.recipientType !== "UserMailbox") && <Button variant='link' className='m-1 p-0 btn-link' onClick={(e) => handleShowEditModal(e, rowData)}><EditIcon color="action" /> Edit</Button>}
                                <Button variant='link' className='m-1 p-0 btn-link' onClick={(e) => handleShowDeleteModal(e, rowData)}><DeleteIcon color="action" /> Delete</Button> 
                            </Popover.Body>
                        </Popover>
                    }>
                    <Button variant='link'><MoreVertIcon className='float-end' /></Button>
                </OverlayTrigger>
            )
        }}
    ]

    const options = {
        columnsButton: true,
        headerStyle: {
            fontWeight: "bold",
            textAlign: "left"
        },
        pageSize: 20
    }

    return (
        <Container className="mt-4">
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/community/mailing-lists/')}>Mailing Lists</Breadcrumb.Item>
                <Breadcrumb.Item active>Manage Mailing Lists Members</Breadcrumb.Item>
            </Breadcrumb>
            {alert?.message &&
                <Row>
                    <Col>
                        <Alert variant={alert.type} onClose={() => setAlert({message: '', type: ''})} dismissible>
                            {alert.message}
                        </Alert>
                    </Col>
                </Row>
            }
            <ResponsiveMaterialTable
                title={`${getDecodedMemberName()} Members`}
                actions={[
                    {
                        icon: () => (
                            <PersonAddIcon />
                        ),
                        tooltip: "Add Members",
                        onClick: (e) => {
                            handleShowModal(e, {})
                        },
                        isFreeAction: true,
                    },
                    {
                        icon: () => (
                            <PersonRemoveIcon />
                        ),
                        tooltip: "Remove Members",
                        onClick: (e) => {
                            handleShowRemoveMemberModal(e, {})
                        },
                        isFreeAction: true,
                    },
                ]}
                columns={columns}
                tableRef={tableRef}
                data={mailingListMembers}
                options={options}
            />
            <EditMailContact show={showEditModal} member={selectedMember} onHide={handleHideEditModal} getMembers={getMembers} setAlert={setAlert}/>
            <MultipleMembersOneListForm show={showModal} listName={id} onHide={handleHideModal} getMembers={getMembers} setAlert={setAlert}/>
            <RemoveMultipleMembersOneListForm show={showRemoveMemberModal} listName={id} members={mailingListMembers} onHide={handleHideRemoveMemberModal} getMembers={getMembers} setAlert={setAlert}/>
            <Modal size='md' show={showDeleteModal} onHide={handleHideDeleteModal}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Delete Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='text-center'>Are You Sure You Want to Delete Member: {selectedMember.primarySmtpAddress}?</h4>
                    <div className='d-flex justify-content-center mt-4'>
                        <Button
                            variant='primary'
                            className='m-1'
                            onClick={handleHideDeleteModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='danger'
                            className='m-1'
                            onClick={handleDeleteMember}
                        >
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default Members