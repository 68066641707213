import { Card, Tab, Tabs, Row, Col, Alert } from "react-bootstrap";
import ValidatorProductsTable from "./ValidatorProductsTable";
import axios from "axios";
import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import EARReviewsTable from "./EARReviewsTable";
import StageTracker from "../StageTracker/StageTracker";
import TQTable from "../TRRTTableModule/TQTable";
import ValidatorResourceRequestTable from "./ValidatorResourceRequestTable";
import { useInterval } from "../../../hooks/useInterval";
import InactiveProductsTable from "./InactiveProductsTable";
import qs from 'qs'


export default function ValidatorProductsModule({setOverlayActive, setOverlayText}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const currentRole = useSelector((state) => state.role.value);
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [earReviews, setEARReviews] = useState([]);
  const [projpps, setProjpps] = useState([]);
  const [ecrs, setEcrs] = useState([]);
  const [progressPoints, setProgressPoints] = useState([]);
  const [trrts, setTRRTs] = useState([]);
  const [isValidatorResponseMember, setIsValidatorResponseMember] = useState(false);
  const [progressPointsData, setProgressPointsData] = useState({});
  const [inactiveProducts, setInactiveProducts] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [alert, setAlert] = useState({})
  const productsRef = useRef()
  const stageRef = useRef()
  const earRef = useRef()
  const inactiveRef = useRef()
  const availableRef = useRef()
  const trrtRef = useRef()


  const getInactiveProducts = useCallback(() => {
    axios
    .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_inactive_products/`, {
      withCredentials: true,
      headers: { Authorization: `Token ${authToken}` },
    })
    .then((response) => setInactiveProducts(response.data))
    .catch((error) => console.log("Unable to get Inactive Products: " + error));
  },[]);

  useEffect(() =>{
    getInactiveProducts() 
  },[])

  const validatorResponse = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_validator_response_team_member/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => setIsValidatorResponseMember(response.data))
      .catch((error) =>
        console.log("Unable to get current products: " + error)
      );
  };

  const fetchProducts = async() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/my_validations/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => setApprovedProducts(response.data))
      .catch((error) =>
        console.log("Unable to get current products: " + error)
      );
  }

  useEffect(() => {
    fetchProducts()
  }, []);

  const loadGrouped = async() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_grouped_pp/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        setProjpps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_grouped_progress_points/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => setProgressPoints(response.data))
      .catch((error) =>
        console.log("Unable to get progress points: " + error)
    );
  }

  useEffect(() => {
    loadGrouped()
  }, []);

  const getEcr = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/comments_for_count/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => setEcrs(response.data))
      .catch((error) => console.log("Unable to get ecrs: " + error));
  }

  const getEARReviews = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/?status=Pending Review`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => setEARReviews(response.data))
      .catch((error) => console.log(error));
  }, []);

  const fetchAvailableProducts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/available_claims/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => setAvailableProducts(response.data))
      .catch((error) =>
        console.log("Unable to get current products: " + error)
      );
  }, [])

  const fetchTRRTs = useCallback(() => {
      const params = {
        'status': "Proposed,TD Required,Resolution Proposed,In Progress,TD Proposed,Accepted",
        "active": true
      }
      axios
          .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/validator_tq_table_data/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
              {
                  withCredentials: true,
                  headers: {
                      Authorization: `Token ${authToken}`,
                  },
              }
          )
          .then((response) => setTRRTs(response.data))
          .catch((error) => console.log(error));
  }, []);

  const refetchTrrtTable = () => {
    if (trrtRef.current) {
      trrtRef.current.onQueryChange()
    }
  }

  const fetchAllProducts = useCallback(() => {
    axios
    .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products/`,
        {
            withCredentials: true,
            headers: {
                Authorization: `Token ${authToken}`,
            },
        }
    )
    .then((response) => setAllProducts(response.data))
    .catch((error) => console.log(error));
  }, [])

  const fetchProgressPointsData = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_grouped_progress_point_data/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => setProgressPointsData(response.data))
      .catch((error) =>
        console.log("Unable to get progress points data: " + error)
    );
  }, [])

  useEffect(() => {
    fetchProgressPointsData();
  }, [])


  useEffect(() => {
    fetchAllProducts()
  }, [])

  useEffect(() => {
    fetchAvailableProducts();
  }, [])

  useEffect(() => {
      fetchTRRTs();
  }, []);

  useEffect(() => {
    getEARReviews();
  }, []);

  useEffect(() => {
    validatorResponse();
  }, []);

  useEffect(() => {
    getEcr();
  }, []);

  useInterval(() => {
    fetchAvailableProducts();
    if (availableRef.current) {
      availableRef.current.onQueryChange()
    }
    fetchProducts()
    if (productsRef.current) {
      productsRef.current.onQueryChange()
    }
    getEARReviews();
    if (earRef.current){
      earRef.current.onQueryChange()
    }
    validatorResponse(); 
  }, 300000); //5 min

  useInterval(() => {
    getEcr();
    loadGrouped()
    fetchTRRTs();
    if (trrtRef.current) {
      trrtRef.current.onQueryChange()
    }
  }, 600000); //10 min

  return (
    <>
      {isValidatorResponseMember?.is_member &&
        <>      
          <Row>
            <Col lg={2} className="text-center m-3 rounded-0 shadow">
              <h2 className='text-gray-400 mt-2'>{availableProducts.count}</h2>
              <p className="mb-2">Available Projects</p>
            </Col>
          </Row>
          <Card className="mb-3">
            <Card.Body>
              {alert.type && <Alert 
              variant={alert.variant} 
              className="m-3" 
              onClose={()=>setAlert({})} 
              dismissible
              >
                {alert.type}
              </Alert>}
              <ValidatorResourceRequestTable
                tableRef={availableRef}
                projpps={projpps}
                currentRole={currentRole}
                refetch={fetchAvailableProducts}
                isValidatorResponseMember={isValidatorResponseMember}
                allProducts={allProducts}
                setOverlayActive={setOverlayActive} 
                setOverlayText={setOverlayText} 
                setAlertTable={setAlert}
              />
            </Card.Body>
          </Card>
        </>
      }
      <Card>
        <Card.Body>
          <Tabs defaultActiveKey="my project assignments" className="mb-3">
            <Tab
              eventKey="my project assignments"
              title={
                <div className="d-flex align-items-center">
                  My Active Project Assignments
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {approvedProducts.count}
                  </h4>
                </div>
              }
            >
              <ValidatorProductsTable
                tableRef={productsRef}
                currentProductsInEval={approvedProducts}
                projpps={projpps}
                ecrs={ecrs}
                trrts={trrts}
                currentRole={currentRole}
                progressPoints={progressPoints}
                progressPointsData={progressPointsData}
                isValidatorResponseMember={isValidatorResponseMember}
                setAlertTable={setAlert}
              />
            </Tab>
            <Tab
              eventKey="projects"
              title={
                <div className="d-flex align-items-center">
                  Project State Tracker
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {approvedProducts?.count}
                  </h4>
                </div>
              }
            >
              <StageTracker tableRef={stageRef} projects={approvedProducts} />
            </Tab>
            <Tab eventKey="technical queries" title={
                <div className="d-flex align-items-center">
                  Technical Queries
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {trrts.count}
                  </h4>
                </div>
              }>
              <TQTable refetch={refetchTrrtTable} tableRef={trrtRef} />
            </Tab>
            <Tab
              eventKey="ear reviews"
              title={
                <div className="d-flex align-items-center">
                  EAR Reviews
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {earReviews.count}
                  </h4>
                </div>
              }
            >
              <EARReviewsTable
                refetch={getEARReviews}
              />
            </Tab>
            <Tab
              eventKey="inactiveProjects"
              title={
                <div className="d-flex align-items-center">
                  Inactive Projects 
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {inactiveProducts?.count}
                  </h4>
                </div>
              }
            >
              <InactiveProductsTable tableRef={inactiveRef} inactiveProducts={inactiveProducts} />
            </Tab>
          </Tabs>
          
        </Card.Body>
      </Card>
    </>
  );
}
