import CCTLPagination from "./CCTLPagination";
import { Container, Row, Col } from "react-bootstrap";
import CCTLListItem from "../CCTLListItem";

export default function ProductListView({
    items,
    setOffset,
    offset,
    total,
    PAGE_SIZE,
}) {

    return (
        <Container className="p-4" fluid>
            <Row className="g-4 mb-5">
                {items
                    .map((card, idx) => (
                        <Col sm={12} key={idx}>
                            <CCTLListItem card={card} />
                        </Col>
                    ))}
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <CCTLPagination
                        setOffset={setOffset}
                        offset={offset}
                        total={total}
                        PAGE_SIZE={PAGE_SIZE}
                    />
                </Col>
            </Row>
        </Container>
    );
}
