import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Stack, Accordion } from 'react-bootstrap'
import PPForm from "./PPForm";
import SideInfo from "./SideInfo";
import AssociatedEvaluations from "./AssociatedEvaluations";
import Notes from "./Notes";
import { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useCookies } from "react-cookie";
import FileUpload from '../../Products/FileUpload';
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import { useInterval } from "../../../hooks/useInterval";

export default function PPDetails({setOverlayActive, setOverlayText}) {
    const [cookies] = useCookies()
    let csrfToken = cookies['csrftoken']
    let authToken = cookies['auth_token']
    const { id } = useParams();

    const [show, setShowModal] = useState(false);
    const [showFileModal, setShowFileModal] = useState(false);
    const [pp, setPp] = useState({});
    const [showAssociatedEvaluations, setShowAssociatedEvaluations] = useState(false);
    const [associatedEvaluations, setAssociatedEvaluations] = useState([]);
    const user = useSelector(state => state.user.value);
    const [files, setFiles] = useState([]);
    const [tds, setTds] = useState([]);
    const [archivedTds, setArchivedTds] = useState([]);
    const permissions = useSelector(state => state.role.value);
    const navigate = useNavigate()

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleShowEval = () => setShowAssociatedEvaluations(!showAssociatedEvaluations);

    const fetchProjectPP = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_project_pp/?pp=${id}`,
        {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})
        .then(response => {
            setAssociatedEvaluations(response.data)
        }).catch(error => {
            console.log(error)
        })
    }, [id])

    useEffect(() => {
        fetchProjectPP()
    }, [showAssociatedEvaluations, fetchProjectPP])

    useInterval(()=>{
        fetchProjectPP()
    }, 60000) //1 min

    const fetchPP = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/${id}`,
        {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})  
        .then(response => {
            setPp(response.data);
        }).catch(error => {
            console.log(error)
        })
    }, [id])

    useEffect(() => {
        fetchPP()
    }, [id])

    useInterval(()=>{
        fetchPP()
    }, 60000) //1 min

    const loadTechDec = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_all_archived_or_related_tds_by_id/?profile=${id}`,
        {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})  
        .then(response => {
            setTds(response.data.active)
            setArchivedTds(response.data.archived);
        }).catch(error => {
            console.log(error)
        })
    }, [id])

    useEffect(() => {
        loadTechDec()
    }, [loadTechDec])

    useInterval(()=>{
        loadTechDec()
    }, 180000) //3 min

    const pullFiles = () => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=protection-profile&file_type_id=${id}`,
        {
            withCredentials: true, 
            headers: {
                'Authorization':  `Token ${authToken}`,
            }
        }).then((response) => {
            setFiles(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        pullFiles()
    }, [])

    useInterval(()=>{
        pullFiles()
    }, 180000) //3 min

    const updateFiles = () => {
        setShowFileModal(false)
        pullFiles() 
    }

    useEffect(() => {
        document.title = 'NIAP Community - Protection Profiles Details';
    }, []);
    return (
        permissions?.role_permissions?.find(permission => permission.area === 'Protection Profile')?.read &&
        <Container className='p-5' fluid>
        <FileUpload show={showFileModal} updateFiles={updateFiles} type="protection-profile" id={id} />
        <Row>
            <Col md={12} lg={9}>
            <Row>
                <Col xs={12} md={4} className="d-flex mb-3">
                    <h3 className='fw-bold'>PROTECTION PROFILE DETAILS: </h3>
                </Col>
                {/* Wrap buttons in a single `Col` for md to larger screens */}
                <Col xs={12} md={8} className="d-md-flex justify-content-md-end">
                    {/* Each button takes full row on mobile, align to end on md */}
                    {permissions?.role_permissions?.find(permission => permission.area === 'Protection Profile')?.update && (
                    <div className="mb-3 mb-md-0 ms-md-2">
                        <Button variant='warning' className='w-100 rounded-pill' onClick={handleShowModal}>Edit Protection Profile</Button>
                    </div>
                    )}
                    {permissions?.role_permissions?.find(permission => permission.area === 'Protection Profile')?.update && (
                    <div className="ms-md-2">
                        <Button variant='warning' className='w-100 rounded-pill' onClick={() => setShowFileModal(true)}>Upload Files</Button>
                    </div>
                    )}
                </Col>
                <Col sm={6} className='mt-3'>
                    <h4>Short Name</h4>
                    <h4 className='fw-bold text-primary'>{pp?.pp_short_name}</h4>
                </Col>
                <Col sm={6} className='mt-3'>
                    <h4 className='fw-bold'>{pp?.pp_name}</h4>
                </Col>
                <Col sm={12} className='mt-3'>
                    <Row className='border-dark border-bottom border-top'>
                        <Col sm={2} className="border-sm-top-bottom border-md-left">
                            <Stack>
                                <h5 className="mt-2 mb-2">Status</h5>
                                <h5 className='text-secondary'>{pp?.status}</h5>
                            </Stack>
                        </Col>
                        <Col sm={2} className="border-sm-top-bottom border-md-left">
                            <Stack>
                                <h5 className="mt-2 mb-2">Archived</h5>
                                <h5 className='text-secondary'>{pp?.archived ? 'Yes' : 'No'}</h5>
                            </Stack>
                        </Col>
                        <Col sm={2} className="border-sm-top-bottom border-md-left">
                            <Stack>
                                <h5 className="mt-2 mb-2">CC Version</h5>
                                <h5 className='text-secondary'>{pp?.cc_version}</h5>
                            </Stack>
                        </Col>
                        <Col sm={2} className="border-sm-top-bottom border-md-left">
                            <Stack>
                                <h5 className="mt-2 mb-2">Tech Type</h5>
                                <h5 className='text-secondary'>{pp?.tech_type}</h5>
                            </Stack>
                        </Col>
                        <Col sm={2} className="border-sm-top-bottom border-md-left">
                            <Stack>
                                <h5 className="mt-2 mb-2">CSfC-Eligible</h5>
                                <h5 className='text-secondary'>{pp?.csfceligible ? "Yes" : "No"}</h5>
                            </Stack>
                        </Col>
                        <Col sm={2}>
                            <Stack>
                                <h5 className="mt-2 mb-2">DoD Annex Reference(s)</h5>
                                <h5 className='text-secondary'>{pp?.dod_compliant ? "Yes" : "No"}</h5>
                            </Stack>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className='mt-3'>
                    <Accordion className='mb-4 border-0' flush>
                        <Accordion.Item>
                        <Accordion.Header><h6 className='fw-bold text-dark'>PROTECTION PROFILE OVERVIEW</h6></Accordion.Header>
                        <Accordion.Body>{pp?.pp_overview && parse(pp?.pp_overview)}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='my-4' flush>
                        <Accordion.Item>
                            <Accordion.Header><h6 className='fw-bold text-dark'>SECURITY EVALUATION SUMMARY</h6></Accordion.Header>
                            <Accordion.Body>{pp?.security_evaluation_summary && parse(pp?.security_evaluation_summary)}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='my-4' flush>
                        <Accordion.Item>
                            <Accordion.Header><h6 className='fw-bold text-dark'>ENVIRONMENTAL STRENGTHS</h6></Accordion.Header>
                            <Accordion.Body>{pp?.environmental_strengths && parse(pp?.environmental_strengths)}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='my-4' flush>
                        <Accordion.Item>
                            <Accordion.Header><h6 className='fw-bold text-dark'>ASSURANCE MAINTENANCE</h6></Accordion.Header>
                            <Accordion.Body>{pp?.assurance_maintenance && parse(pp?.assurance_maintenance)}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='my-4' flush>
                    <Accordion.Item>
                        <Accordion.Header><h6 className='fw-bold text-dark'>RELATED TECHNICAL DECISIONS</h6></Accordion.Header>
                        <Accordion.Body>
                            {tds?.map(td => (
                                <>
                                <Link to={{pathname: `/technical-decisions/${td?.identifier}`}} key={td?.td_id}>
                                    
                                        <h6 className='mb-3 ms-3'><strong>{td?.identifier} - {td?.title}</strong>&nbsp;{td?.status==="Archived" && <small>(Archived)</small>}</h6>
                                        </Link>
                                        {td?.related_to && <p className='mb-3 ms-5'>Reference: {td?.related_to}</p>}
                                    </>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion><Accordion className='my-4' flush>
                    <Accordion.Item>
                        <Accordion.Header><h6 className='fw-bold text-dark'>ARCHIVED TECHNICAL DECISIONS</h6></Accordion.Header>
                        <Accordion.Body>
                            {archivedTds?.map(td => (
                                <>
                                <Link to={{pathname: `/technical-decisions/${td?.identifier}`}} key={td?.td_id}>
                                    
                                        <h6 className='mb-3 ms-3'><strong>{td?.identifier} - {td?.title}</strong>&nbsp;{td?.status==="Archived" && <small>(Archived)</small>}</h6>
                                        </Link>
                                        {td?.related_to && <p className='mb-3 ms-5'>Reference: {td?.related_to}</p>}
                                    </>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                    <Accordion className='my-4' flush>
                        <Accordion.Item>
                            <Accordion.Header><h6 className='fw-bold text-dark'>ASSOCIATED EVALUATIONS</h6></Accordion.Header>
                            <Accordion.Body>
                                {permissions?.role_type === "NIAP" && <Button variant='secondary rounded-pill mb-4' onClick={handleShowEval}>Associate a Product</Button>}
                                {associatedEvaluations?.map(projpp => (
                                    <Link to={`/community/products/details/${projpp?.product__product_id}`} key={projpp?.projpp_id} className="text-white">
                                        <p className='text-primary fw-bold ms-3 text-decoration-underline'>VID{projpp?.product__v_id} - {projpp?.product__product_name}</p>
                                    </Link>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Notes item={pp} note_type='protection_profile' user={user} id={id}  />
                </Col>
            </Row>
            </Col>
            <Col md={12} lg={3}>
            <SideInfo pp={pp} files={files} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
            </Col>
        </Row>
        <PPForm show={show} handleCloseModal={handleCloseModal} pp={pp} refetch={fetchPP}/>
        <AssociatedEvaluations show={showAssociatedEvaluations} handleShowEval={handleShowEval} 
            pp_id={pp.pp_id} user={user} associatedEvaluations={associatedEvaluations} setAssociatedEvaluations={setAssociatedEvaluations} />
        </Container>
    )
}
