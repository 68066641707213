import { useState, useEffect } from "react"
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import axios from "axios"

const ListForm = ({show, list, onHide, fetchMailingLists, setAlert}) => {
    const [cookies, setCookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [ editedList, setEditedList ] = useState(list)
    const [ niapList, setNiapList ] = useState("")

    const handleReset = () => {
        setEditedList(list)
    }

    const handleChange = (event) => {
        if (event.target.name === "listRelation") {
            const isCCP = editedList?.displayName?.includes("ccp_")
            if (event.target.value === "ccp_" && !isCCP) {
                const newDisplayName = event.target.value + (editedList.displayName ?? "\"Type Display Name Here\"")
                setEditedList({...editedList, [event.target.name]: event.target.value, displayName: newDisplayName})
            } else if (event.target.value === "" && isCCP) {
                const newDisplayName = editedList.displayName.replace("ccp_", event.target.value)
                setEditedList({...editedList, [event.target.name]: event.target.value, displayName: newDisplayName})
            } else {
                setEditedList({...editedList, [event.target.name]: event.target.value})
            }
        } else if (event.target.name === "name") {
            const newDisplayName = (editedList.listRelation ?? "") + event.target.value
            setEditedList({...editedList, [event.target.name]: event.target.value, displayName: newDisplayName})
        } else {
            setEditedList({...editedList, [event.target.name]: event.target.value})
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (list?.name) {
                axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/${list.name}/`, 
                {
                    name: editedList.name,
                    displayName: editedList.displayName,
                    primarySmtpAddress: editedList.primarySmtpAddress,
                    description: editedList.description
                },
                {
                    withCredentials: true,
                    headers: {
                        'Authorization': `Token ${authToken}`,
                        'X-CSRFToken': csrfToken,
                    }
                }).then(response => {
                    fetchMailingLists()
                    onHide()
                    setAlert({variant: "success", message: "Mailing list updated successfully."})
                }).catch((error) => {
                    console.log(error)
                    setAlert({variant: "danger", message: "Error updating mailing list. Please try again."})
                })
            } else {
                axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/`, 
                {
                    name: editedList.name,
                    displayName: editedList.displayName,
                    primarySmtpAddress: editedList.primarySmtpAddress,
                    description: editedList.description
                },
                {
                    withCredentials: true,
                    headers: {
                        'Authorization': `Token ${authToken}`,
                        'X-CSRFToken': csrfToken,
                    }
                }).then(response => {
                    fetchMailingLists()
                    onHide()
                    setAlert({variant: "success", message: "Mailing list created successfully."})
                }).catch((error) => {
                    console.log(error)
                    setAlert({variant: "danger", message: "Error creating mailing list. Please try again."})
                })
            }
        }
    }

    useEffect(() => {
        const isCCP = list?.displayName?.includes("ccp_")
        setEditedList((editedList) => {return {...list, listRelation: (isCCP ? "ccp_" : "")}})
    }, [list])

    return (
        <Modal size={'lg'} show={show} onHide={onHide}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{list?.id ? "Edit" : "Add"} Mailing List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="list-name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={editedList?.name} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="isCCP">
                        <Form.Label>List Relation</Form.Label> <br />
                        <Form.Check type="radio" label="Common Criteria Portal" onChange={handleChange} checked={editedList.listRelation === "ccp_"} value="ccp_" name="listRelation" inline />
                        <Form.Check type="radio" label="NIAP-CCEVS" onChange={handleChange} checked={editedList.listRelation === ""} value="" name="listRelation" inline />
                    </Form.Group>
                    <Form.Group controlId="list-display-name">
                        <Form.Label>Display Name</Form.Label>
                        <Form.Control type="text" name="displayName" defaultValue={editedList?.displayName} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="list-email-address">
                        <Form.Label>Primary Email Address</Form.Label>
                        <Form.Control type="text" name="primarySmtpAddress" defaultValue={editedList?.primarySmtpAddress} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="list-description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" name="description" defaultValue={editedList?.description} onChange={handleChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide}>Cancel</Button>
                    <Button type="reset" onClick={handleReset} variant="warning">Reset</Button>
                    <Button type="submit" onClick={handleSubmit} variant="success">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ListForm