import { Row, Col, Form } from "react-bootstrap";

export default function RejectionRationaleSection({product}) {

    return (
        <>
            {product?.rejected_rationale && (
                <Row className="mt-4">
                    <Col>
                        <Form.Group controlId="rejected_rationale">
                            <p className="fw-bold form-title">
                                RATIONALE FOR REJECTION FROM NIAP
                            </p>
                            <Form.Control
                                rows={3}
                                as="textarea"
                                disabled
                                defaultValue={product?.rejected_rationale}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
            {product?.vendor_rejected_rationale && (
                <Row className="mt-4">
                    <Col>
                        <Form.Group controlId="vendor_rejected_rationale">
                            <p className="fw-bold form-title">
                                RATIONALE FOR REJECTION FROM VENDOR
                            </p>
                            <Form.Control
                                rows={3}
                                as="textarea"
                                disabled
                                defaultValue={
                                    product?.vendor_rejected_rationale
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </>
    );
}
