import { Container, Accordion, Breadcrumb } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Subheader from '../../Subheader';
import AccordionBody from '../AccordionBody';
import { useInterval } from '../../../hooks/useInterval';
import qs from 'qs'
import { useNavigate } from 'react-router-dom';

export default function EventsPage() {
  const navigate = useNavigate();
  const [activeNews, setActiveNews] = useState([]);
  const [inactiveNews, setInactiveNews] = useState([]);
  const [activeOffset, setActiveOffset] = useState(0)
  const [expiredOffset, setExpiredOffset] = useState(0)
  const [activeCount, setActiveCount] = useState(0)
  const [expiredCount, setExpiredCount] = useState(0)

  useEffect(() => {
    document.title = 'NIAP - News';
  }, []);

  const getActiveNews = () => {
    const params = {
      limit: 5,
      offset: activeOffset,
      current: true,
    }
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_news_frontend/?${qs.stringify(params, {arrayFormat: "comma"})}`
      )
      .then((response) => {
        setActiveCount(response.data.count)
        setActiveNews(response.data.results)
      })
      .catch((error) => {
        console.log('error getting news: ', error);
      });
  };

  const getExpiredNews = () => {
    const params = {
      limit: 5,
      offset: expiredOffset,
      previous: true,
    }
    axios
    .get(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_news_frontend/?${qs.stringify(params, {arrayFormat: "comma"})}`
    )
    .then((response) => {
      setExpiredCount(response.data.count)
      setInactiveNews(response.data.results);
    })
    .catch((error) => {
      console.log('error getting news: ', error);
    });
  }

  useEffect(() => {
    getActiveNews();
    getExpiredNews()
  }, [])

  useEffect(() => {
    getActiveNews()
  }, [activeOffset])

  useEffect(() => {
    getExpiredNews()
  }, [expiredOffset])

  useInterval(() => {
    getActiveNews();
    getExpiredNews()
  }, 600000); //10 min


  return (
    <>
      <Subheader pageName={'Announcements'} />
      <Container className='my-4'>
      <Breadcrumb>
          <Breadcrumb.Item
              onClick={() => navigate(`/`)}>
              Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
              Announcements
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className='p-4'>
          <h4 className='text-uppercase fw-bold pb-3'>
            Resources -{' '}
            <span className='text-primary text-uppercase'>Announcements, News & Updates</span>
          </h4>
        </div>
        <div className='pt-4 px-4 pr-4'>
          <Accordion defaultActiveKey='1' alwaysOpen>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>
                <h5 className='text-primary fw-bold text-uppercase'>
                  Current Announcements
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                <AccordionBody announcements={activeNews} setOffset={setActiveOffset} count={activeCount} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className='p-4'>
          <Accordion>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>
                <h5 className='text-primary fw-bold text-uppercase'>
                  Previous Announcements
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                <AccordionBody announcements={inactiveNews} setOffset={setExpiredOffset} count={expiredCount} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    </>
  );
}