import { Container, Row, Col, Alert } from "react-bootstrap";
import AlertsModule from "../AlertsModule";
import VendorProductTabs from "../VendorModules/VendorProductTabs";
import VendorContacts from "../VendorModules/VendorContacts";
import "../VendorModules/vendorModules.css";
import VendorContactCardModule from "../VendorModules/VendorContactCardModule";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useCallback } from "react";

const VendorDashboard = ({ setOverlayActive, setOverlayText }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const [thirtyDayAlert, setThirtyDayAlert] = useState([])
  const [alertInfo, setAlertInfo] = useState({message: "", type: ""})

  const daysPassed = (inputDate) => {
    if (inputDate) {
      const startDate = moment(inputDate);
      const endDate = moment();
      const daysSinceStartDate = endDate.diff(startDate, "days");
      return daysSinceStartDate;
    } else {
      return 0;
    }
  }
  
    const createAlert = useCallback(() => {
        axios
        .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/vendor_certified_products_for_alert/`,
            {
            withCredentials: true,
            headers: {
                Authorization: `Token ${authToken}`,
            },
            }
        )  
        .then((response) => {
            response.data.forEach( (product) => {
            if (daysPassed(product.certification_date) >= 0 ) {
                /// Check If alert has already been created
                axios
                .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/get_alerts_by_source_and_type_and_type_id/?alert_source=Renewal Date&alert_type=Product&alert_type_id=${product.product_id}`,
                {
                withCredentials: true,
                headers: {
                    Authorization: `Token ${authToken}`,
                },
                }) 
                .then((response) => {
                  if (response.data.length === 0) {
                    axios 
                    .post( 
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/`,
                    {
                        alert_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                        alert_type: "Product",
                        alert_text: `VID ${product?.v_id} will expire 1 year from today's date ${moment().format('MM/DD/YYYY')}`,
                        alert_type_id: product?.product_id,
                        active: true,
                        alert_to: null,
                        alert_to_group: product?.vendor_id__org_id,
                        alert_source: "Renewal Date",
                        alert_to_vt: null,
                    },
                    {
                        withCredentials: true,
                        headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                        },
                    }
                    )
                    .then((response) => {
                    console.log("Alert created for Assurance Maintenance");
                    })
                    .catch((error) =>
                    console.log("Unable to create alert for Assurance Maintenance.", error)
                    );
                  }
                })    
                //If alert is not found       
                .catch((error) =>{
                })
            }
            });
        }).catch((error)=>{});
    }, []);

  useEffect(() => {
    createAlert();
  }, []);

  return (
    <Container fluid>
      {thirtyDayAlert?.length > 0 &&
        thirtyDayAlert?.map((alert, idx) => (
          <Row className='m-3 mb-3' key={idx}>
            <Alert className="mb-0" variant="warning" dismissible onClose={() => setThirtyDayAlert([])} >
              {alert}
            </Alert>
          </Row>
        ))
      }
      {alertInfo.message !== "" &&
        <Row className='m-3 mb-0'>
          <Alert className="mb-0" variant={alertInfo.type} dismissible onClose={() => setAlertInfo({message: "", type: ""})} >
            {alertInfo.message}
          </Alert>
        </Row>
      }
      <Row className="my-5">
        <Col lg={8} md={12} sm={12}>
          <Row>
            <Col sm={12}>
              <VendorProductTabs setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} setThirtyDayAlert={setThirtyDayAlert} setAlertInfo={setAlertInfo}/>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <VendorContacts />
            </Col>
          </Row>
          <Row>
            <Col className="alertsColumn">
              <AlertsModule />
            </Col>
          </Row>
        </Col>

        <Col lg={4} md={12} sm={12}>
          <Row>
            <Col>
              <VendorContactCardModule />
            </Col>
          </Row>        
        </Col>
      </Row>
    </Container>
  );
};

export default VendorDashboard;
