import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import ReactSelect from "../../../ReactSelect";
import HeaderEditOrSaveSections from "./HeaderEditOrSaveSections";
import TechDecisionAuditModal from "../TechDecisionAuditModal";

export default function TDDetailsHeader({ techDecision, refetch, currentUser, permissions }) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [editedContent, setEditedContent] = useState({});
  const [protectionProfiles, setProtectionProfiles] = useState([]);
  const [sunsetProtectionProfiles, setSunsetProtectionProfiles] = useState([]);
  const [editField, setEditField] = useState("");
  const [trrt, setTrrt] = useState([]);
  const [auditOpen, setAuditOpen] = useState(false);

  const openAudit = () => {
    setAuditOpen(true);
  };

  const closeAudit = () => {
    setAuditOpen(false);
  };

  const loadTechnicalQueries = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/get_all_technical_queries/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        const trrts = response.data?.map((trrt) => ({ value: trrt.tq_id, label: trrt.title }));
        setTrrt(trrts);
      })
      .catch((error) => console.log(error.response.data));
  }, []);

  useEffect(() => {
    loadTechnicalQueries();
  }, []);

  const loadProtectionProfile = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_niap_protection_profiles/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        const profiles = [];
        const sunsetProfiles = [];
        response.data.forEach((profile) => {
          if (profile.sunset_date && new Date(profile.sunset_date) < new Date()) {
            sunsetProfiles.push({ value: profile.pp_id, label: profile.pp_name });
          } else {
            profiles.push({ value: profile.pp_id, label: profile.pp_name });
          }
        });
        setProtectionProfiles(profiles);
        setSunsetProtectionProfiles(sunsetProfiles);
      })
      .catch((error) => console.log(error.response.data));
  }, []);

  useEffect(() => {
    loadProtectionProfile();
  }, []);

  const handleSave = () => {
    let data = {
      modified_by: currentUser.id,
      modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
    };
    let sunsetPPArr = [];
    if (editedContent?.sunset_protection_profile) {
      techDecision?.sunset_protection_profile?.forEach((pp) => sunsetPPArr.push(pp?.pp_id));
      sunsetPPArr = sunsetPPArr.concat(editedContent?.sunset_protection_profile);
      data["sunset_protection_profile"] = sunsetPPArr;
    }
    let ppArr = [];
    if (editedContent?.protection_profile) {
      techDecision?.protection_profile?.forEach((pp) => ppArr.push(pp?.pp_id));
      ppArr = ppArr.concat(editedContent?.protection_profile);
      data["protection_profile"] = ppArr;
    }
    let tqArr = [];
    if (editedContent?.tq_id) {
      techDecision?.tq_id?.forEach((pp) => tqArr.push(pp?.tq_id));
      tqArr = tqArr.concat(editedContent?.tq_id);
      data["tq_id"] = tqArr;
    }
    if (editedContent?.title) {
      data["title"] = editedContent?.title;
    }
    if (editedContent?.related_to) {
      data["related_to"] = editedContent?.related_to;
    }
    if (editedContent?.status) {
      data["status"] = editedContent?.status;
    }
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/${techDecision?.td_id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(() => {
        closeAudit();
        setEditField("");
        setEditedContent({});
        refetch();
      })
      .catch((error) => console.log("Unable to archive pp: " + error));
  };

  const handleChange = (e) => {
    if (e.target.name === "protection_profile_select") {
      const protection_profiles = editedContent.protection_profile ?? [];
      if (!protection_profiles.includes(e.target.value)) {
        protection_profiles.push(e.target.value);
      }
      setEditedContent({ ...editedContent, protection_profile: protection_profiles });
    } else if (e.target.name === "sunset_protection_profile_select") {
      const sunset_protection_profiles = editedContent.sunset_protection_profile ?? [];
      if (!sunset_protection_profiles.includes(e.target.value)) {
        sunset_protection_profiles.push(e.target.value);
      }
      setEditedContent({
        ...editedContent,
        sunset_protection_profile: sunset_protection_profiles,
      });
    } else if (e.target.name === "tq_id_select") {
      const tq_ids = editedContent.tq_id ?? [];
      if (!tq_ids.includes(e.target.value)) {
        tq_ids.push(e.target.value);
      }
      setEditedContent({ ...editedContent, tq_id: tq_ids });
    } else {
      setEditedContent({
        ...editedContent,
        [e.target.name]: e.target.value,
      });
    }
  };

  const remove = (group, idx) => {
    const tdGroup = techDecision[group];
    tdGroup.splice(idx, 1);
    let modGroup = [];
    if (group === "related_to") {
      modGroup = tdGroup.join(",");
    } else if (group === "tq_id") {
      modGroup = tdGroup.map((tq) => tq.tq_id);
    } else {
      modGroup = tdGroup.map((pp) => pp.pp_id);
    }
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/${techDecision?.td_id}/`,
        { [group]: modGroup },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(() => refetch())
      .catch((error) => console.log(error));
  };

  const editRemove = (group, id) => {
    const editedGroup = editedContent[group] ?? [];
    const index = editedGroup.indexOf(id);
    if (index !== -1) {
      editedGroup.splice(index, 1);
    }
    setEditedContent({ ...editedContent, [group]: editedGroup });
  };

  const handleEdit = (type) => {
    setEditField(type);
  };

  return (
    <>
      <TechDecisionAuditModal
        show={auditOpen}
        handleSave={handleSave}
        saveTextFields={{}}
        id={techDecision?.td_id}
        auditType={"detail"}
        handleClose={() => setAuditOpen(false)}
      />
      <Row className="mt-3 mb-4">
        <Col>
          <h3 className="text-primary text-uppercase">Technical Decision:</h3>
        </Col>
      </Row>
      <Row className="border-bottom border-dark me-1 pb-2">
        <Col sm={12} md={6} className="border-end border-dark">
          <h3 className="fw-bold">
            Details for <span style={{ color: "var(--bs-blue)" }}>{techDecision?.identifier}</span>
          </h3>
          <br />
          {editField !== "" && (
            <Button variant="warning" className="mb-3" onClick={() => setEditField("")}>
              Cancel Edit
            </Button>
          )}
        </Col>
        <Col sm={12} md={6}>
          <HeaderEditOrSaveSections
            permissions={permissions}
            title="Title"
            value={<h4>{techDecision?.title}</h4>}
            editField={editField}
            handleEdit={handleEdit}
            fieldName="title"
            isEditable={true}
            openAudit={openAudit}
            renderEditComponent={() => (
              <Form.Group className="mb-3" controlId="status">
                <Form.Control
                  type="text"
                  defaultValue={techDecision?.title}
                  placeholder="Enter Title"
                  onChange={(e) => setEditedContent({ title: e.target.value })}
                />
              </Form.Group>
            )}
            techDecision={techDecision}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-4">
        <Col xs={12} md={2} className="border-end">
          <HeaderEditOrSaveSections
            permissions={permissions}
            title="Status"
            value={<p className="text-secondary">{techDecision?.status}</p>}
            editField={editField}
            handleEdit={handleEdit}
            fieldName="status"
            isEditable={true}
            openAudit={openAudit}
            techDecision={techDecision}
            renderEditComponent={() => (
              <Form.Group className="mb-3" controlId="status">
                <Form.Select
                  name="status"
                  defaultValue={techDecision?.status}
                  onChange={handleChange}
                >
                  <option value="">Select...</option>
                  {[
                    "Pending",
                    "Withdrawn",
                    "TD Proposed",
                    "Resolved",
                    "Published",
                    "Archived",
                    "New",
                    "Valid",
                  ]?.map((s, idx) => (
                    <option key={idx} value={s}>
                      {s}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          />
        </Col>
        <Col xs={12} md={3} className="border-end">
          <HeaderEditOrSaveSections
            permissions={permissions}
            title="PP Claims"
            value={techDecision?.protection_profile?.map((pp, idx) => (
              <p className="text-secondary" key={idx}>
                {pp.pp_name}
              </p>
            ))}
            editField={editField}
            handleEdit={handleEdit}
            fieldName="protection_profile_select"
            isEditable={true}
            openAudit={openAudit}
            techDecision={techDecision}
            renderEditComponent={() => (
              <Container>
                <Row>
                  <Col>
                    <Form.Group controlId="protection_profile" className="my-2">
                      <ReactSelect
                        name="protection_profile_select"
                        handleChange={handleChange}
                        options={protectionProfiles}
                        newObj={editedContent}
                        isValid={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {techDecision?.protection_profile?.map((pp, idx) => (
                  <Row key={idx}>
                    <Col>
                      <p>{pp.pp_name}</p>
                    </Col>
                    <Col xs={2}>
                      <span
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => remove("protection_profile", pp, idx)}
                      >
                        X
                      </span>
                    </Col>
                  </Row>
                ))}
                {editedContent.protection_profile?.map((pp) => (
                  <Row key={pp}>
                    <Col>
                      <p>{protectionProfiles?.find((profile) => profile.value === pp)?.label}</p>
                    </Col>
                    <Col xs={2}>
                      <span
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => editRemove("protection_profile", pp)}
                      >
                        X
                      </span>
                    </Col>
                  </Row>
                ))}
              </Container>
            )}
          />
        </Col>
        <Col xs={12} md={2} className="border-end">
          <HeaderEditOrSaveSections
            permissions={permissions}
            title="References"
            value={<p className="text-secondary">{techDecision?.related_to}</p>}
            editField={editField}
            handleEdit={handleEdit}
            fieldName="related_to"
            isEditable={true}
            openAudit={openAudit}
            techDecision={techDecision}
            renderEditComponent={() => (
              <Form.Group controlId="related_to" className="my-2">
                <Form.Control
                  type="text"
                  name="related_to"
                  defaultValue={techDecision.related_to}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          />
        </Col>
        <Col xs={12} md={2} className="border-end">
          <HeaderEditOrSaveSections
            permissions={permissions}
            title="Associated TRRTs"
            value={techDecision?.tq_id?.map((tq, idx) => (
              <Link
                to={`/community/technicalqueries/details/${tq?.tq_id}/`}
                className="text-decoration-none"
                key={idx}
              >
                <p className="text-decoration-underline">{tq?.title}</p>
              </Link>
            ))}
            editField={editField}
            handleEdit={handleEdit}
            fieldName="tq_id_select"
            isEditable={true}
            openAudit={openAudit}
            techDecision={techDecision}
            renderEditComponent={() => (
              <Container>
                <Row>
                  <Col>
                    <Form.Group controlId="trrt" className="my-2">
                      <ReactSelect
                        name="tq_id_select"
                        handleChange={handleChange}
                        options={trrt}
                        newObj={editedContent}
                        isValid={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {techDecision?.tq_id?.map((tq, idx) => (
                  <Row>
                    <Col>
                      <p key={tq}>{tq?.title}</p>
                    </Col>
                    <Col xs={2}>
                      <span
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => remove("tq_id", idx)}
                      >
                        X
                      </span>
                    </Col>
                  </Row>
                ))}
                {editedContent.tq_id?.map((tq) => (
                  <Row>
                    <Col>
                      <p key={tq}>{trrt?.find((query) => query.value === tq)?.label}</p>
                    </Col>
                    <Col xs={2}>
                      <span
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => editRemove("tq_id", tq)}
                      >
                        X
                      </span>
                    </Col>
                  </Row>
                ))}
              </Container>
            )}
          />
        </Col>
        <Col xs={12} md={3}>
          <HeaderEditOrSaveSections
            permissions={permissions}
            title="Sunset Protection Profiles"
            value={techDecision?.sunset_protection_profile?.map((pp, idx) => (
              <p className="text-secondary" key={idx}>
                {pp.pp_name}
              </p>
            ))}
            editField={editField}
            handleEdit={handleEdit}
            fieldName="sunset_protection_profile_select"
            isEditable={true}
            openAudit={openAudit}
            techDecision={techDecision}
            renderEditComponent={() => (
              <Container>
                <Row>
                  <Col>
                    <Form.Group controlId="sunset_protection_profile" className="my-2">
                      <ReactSelect
                        name="sunset_protection_profile_select"
                        handleChange={handleChange}
                        options={sunsetProtectionProfiles}
                        newObj={editedContent}
                        isValid={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {techDecision?.sunset_protection_profile?.map((pp, idx) => (
                    <Row key={idx}>
                      <Col>
                        <p>{pp.pp_name}</p>
                      </Col>
                      <Col>
                        <span
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={() => remove("sunset_protection_profile", idx)}
                        >
                          X
                        </span>
                      </Col>
                    </Row>
                  ))}
                  {editedContent.sunset_protection_profile?.map((pp) => (
                    <Row key={pp}>
                      <Col>
                        <p>
                          {sunsetProtectionProfiles?.find((profile) => profile.value === pp)?.label}
                        </p>
                      </Col>
                      <Col>
                        <span
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={() => editRemove("sunset_protection_profile", pp)}
                        >
                          X
                        </span>
                      </Col>
                    </Row>
                  ))}
                </Row>
              </Container>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
