import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const ResetSuccess = () => {

    return (
        <Card className='login-card'>
            <Card.Body>
                <Card.Title as='h4'>Password Reset</Card.Title>
                <p className="m-4 text-secondary">Your password has been successfully reset. Click below to sign in.</p>
                <Button className='my-3' variant="warning" href="/login">Login</Button>
            </Card.Body>
        </Card>
    )
}

export default ResetSuccess