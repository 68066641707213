import { useState } from "react";
import HTMLEditor from '../UI/HTMLEditor'
import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal, Button, Form } from "react-bootstrap";

export default function EARResponseModal({
  show,
  review,
  user,
  handleClose,
  refetch,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [response, setResponse] = useState();
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)

  const handleChange = (e) => {
    setResponse({ ...response, [e.target.name]: e.target.value });
  };

  const sendResponse = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      const update = {
        earid: review?.earid,
        gist: response?.title,
        note1: response?.note1,
        pid: review?.pid?.product_id,
        creator: user?.id,
        entrydate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
      };
      axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/update/`, update, {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        })
        .then((response) => {
            axios
              .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review?.earid}/`,
                { response: response.data.upid, creator: user?.id, pid: review?.pid?.product_id, reqd: true },
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                }
              )
              .then((response) => {
                handleClose();
                refetch();
              })
              .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
  };

  const updateResponse = () => {
    axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/update/${review?.response?.upid}/`,
      {
        gist: response?.title,
        note1: response?.note1,
      },
      {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        handleClose();
        refetch();
      })
      .catch((error) => console.log(error));
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
      enforceFocus={enforceFocusModal}
    >
      <Form onSubmit={sendResponse}>
        <Modal.Header closeButton>
          <Modal.Title>
            + {
                review?.status === "Pending Review" ||  
                review?.status === "Draft" ?
                "DRAFT" : "OFFICIAL" 
              } RESPONSE TO
            LAB
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            VID {review?.pid?.v_id} - {review?.pid?.common_name}
          </h4>
          <Form.Group className="mb-3" controlId="note">
            <Form.Label className="fw-bold">Title of Response:*</Form.Label>
            <Form.Control
              name="title"
              onChange={handleChange}
              defaultValue={review?.response?.gist}
              placeholder="Title"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="note">
            <Form.Label className="fw-bold">Response*</Form.Label>
            <HTMLEditor 
              name="note1" 
              handleChange={handleChange} 
              setIsValid={() => {}} 
              isValid={true} 
              defaultValue={review?.response?.note1} 
              setEnforceFocusModal={setEnforceFocusModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            type="button"
            onClick={() => {
              handleClose();
            }}>
            Cancel
          </Button>
        {review?.response?.upid ?
          <Button variant="primary" onClick={(e) => updateResponse(e)}>
            Update
          </Button>
        :
          <Button variant="primary" type="submit">
            Save
          </Button>
        }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
