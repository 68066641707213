import { Accordion, Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useCallback, useEffect } from "react";
import FileDownload from "js-file-download";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@mui/icons-material/Save";
import FileUpload from "../../Products/FileUpload"
import { getCurrentDate } from "../../../utils/timeUtils";

export default function PCMSideInfo({ pcm, setOverlayActive, setOverlayText, fetchPCM }) {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"]
    const [files, setFiles] = useState([]);
    const [showUpload, setShowUpload] = useState(false);
    const [updatedDates, setUpdatedDates] = useState({})
    const [isEditing, setIsEditing] = useState(false)
    const navigate = useNavigate();
    const { id } = useParams();

    const toEmailForm = (toEmail) => {
        navigate("/community/email", { state: { email: toEmail } });
    };

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios
          .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
              },
              responseType: "blob",
            }
          )
          .then((response) => {
            setOverlayActive(false)
            FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name));
          })
          .catch((error) => {
            console.log(error);
          });
      };

    const fetchFiles = useCallback(() => {
        if (pcm?.ccid) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_pcm_files_by_ccid/?ccid=${pcm.ccid}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => setFiles(response.data?.filter(file => file?.active === true)))
                .catch((error) => setFiles([]));

        }
    }, [pcm?.ccid]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    const updateFiles = () => {
        setShowUpload(false);
        fetchFiles();
    };

    const handleChangeDates = (e) => {
        setUpdatedDates({...updatedDates, [e.target.name]: e.target.value})
    }

    const handleSaveDates = () => {
        axios
        .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/${pcm?.rid}/`,
            {
                ...updatedDates,
                moddate: getCurrentDate(),
            },
            {
                withCredentials: true,
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'X-CSRFToken': csrfToken,
                }
            }
        )
        .then((response) => {
            if (updatedDates?.am_date) {
                axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}concur/update_concurs_by_ccid/`,
                    {
                        ccid: pcm?.ccid,
                        lab: pcm?.assigned_lab ? pcm.assigned_lab.cctl_name : pcm?.custorg,
                        sunset: updatedDates.am_date
                    },
                    {
                        withCredentials: true,
                        headers: {
                            'Authorization': `Token ${authToken}`,
                            'X-CSRFToken': csrfToken,
                        }
                    }
                )
                .then((response) => {
                    /** console.log("Added product CC") */
                })
                .catch((error) => {
                    console.log('unable to update productcc', error)
                });
            }
            fetchPCM()
            setIsEditing(false)
        })
        .catch((error) => {
            console.log('unable to updating dates', error)
        });
    }

    return (
        <>
            <FileUpload
                show={showUpload}
                updateFiles={updateFiles}
                type="ccp"
                id={id}
                ccid={pcm?.ccid}
            />
            <Accordion flush className="border border-dark mb-3 hr" defaultActiveKey='1' alwaysOpen>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="bg-secondary">
                        <h6 className="sidebar-title">PCM Dates</h6>
                    </Accordion.Header>
                    <Accordion.Body className="bg-light p-1">
                        <Container>
                            <Row>
                                <Col sm={10}>
                                    <Stack>
                                        <div className="border-bottom">
                                            <p className="text-secondary mb-1 mt-2">
                                                Certified Date:
                                            </p>
                                            <p>
                                                {pcm?.certified !== null
                                                    ? moment.utc(
                                                        pcm?.certified
                                                    ).format("MM/DD/YYYY")
                                                    : "---"}
                                            </p> 
                                        </div>
                                        <div className="border-bottom">
                                            {!isEditing ? 
                                                <>
                                                    <p className="text-secondary mb-1 mt-2">
                                                        Release Date:
                                                    </p>
                                                    <p>
                                                        {pcm?.am_date !== null
                                                            ? moment.utc(
                                                                pcm?.am_date
                                                            ).format("MM/DD/YYYY")
                                                            : "---"}
                                                    </p>
                                                </> : 
                                                <Form.Group controlId="am_date" className="mb-2">
                                                    <Form.Label className="text-secondary mb-1 mt-2">Release Date:</Form.Label>
                                                    <Form.Control type="date" name="am_date" onChange={handleChangeDates} defaultValue={moment.utc(pcm?.am_date).format("YYYY-MM-DD")} />
                                                </Form.Group>
                                            }
                                        </div>
                                        <div className="border-bottom">
                                            {!isEditing ? 
                                                <>
                                                    <p className="text-secondary mb-1 mt-2">
                                                        Date of Receipt:
                                                    </p>
                                                    <p>
                                                        {pcm?.rcvd ? moment.utc(pcm.rcvd).format(
                                                            "MM/DD/YYYY"
                                                        ) : moment.utc(pcm?.entrydate).format(
                                                            "MM/DD/YYYY"
                                                        )}
                                                    </p>
                                                </> : 
                                                <Form.Group controlId="rcvd" className="mb-2">
                                                    <Form.Label className="text-secondary mb-1 mt-2">Date of Receipt:</Form.Label>
                                                    <Form.Control type="date" name="rcvd" onChange={handleChangeDates} defaultValue={pcm?.rcvd ? moment.utc(pcm?.rcvd).format("YYYY-MM-DD") : moment.utc(pcm?.entrydate).format('YYYY-MM-DD')} />
                                                </Form.Group>
                                            }
                                        </div>
                                        <div className="border-bottom">
                                            {!isEditing ? 
                                                <>
                                                    <p className="text-secondary mb-1 mt-2">
                                                        Requested Response Date:
                                                    </p>
                                                    <p>
                                                        {pcm?.deadline ? moment.utc(pcm.deadline).format(
                                                            "MM/DD/YYYY"
                                                        ) : "---"}
                                                    </p>
                                                </> : 
                                                <Form.Group controlId="deadline" className="mb-2">
                                                    <Form.Label className="text-secondary mb-1 mt-2">Requested Response Date:</Form.Label>
                                                    <Form.Control type="date" name="deadline" onChange={handleChangeDates} defaultValue={moment.utc(pcm?.deadline).format("YYYY-MM-DD")} />
                                                </Form.Group>
                                            }
                                        </div>
                                        <div className="border-bottom">
                                            {!isEditing ? 
                                                <>
                                                    <p className="text-secondary mb-1 mt-2">
                                                        Completion Date:
                                                    </p>
                                                    <p>
                                                        {pcm?.enddate !== null
                                                            ? moment.utc(
                                                                pcm?.enddate
                                                            ).format("MM/DD/YYYY")
                                                            : "---"}
                                                    </p>
                                                </> : 
                                                <Form.Group controlId="enddate" className="mb-2">
                                                    <Form.Label className="text-secondary mb-1 mt-2">Completion Date:</Form.Label>
                                                    <Form.Control type="date" name="enddate" onChange={handleChangeDates} defaultValue={moment.utc(pcm?.enddate).format("YYYY-MM-DD")} />
                                                </Form.Group>
                                            }
                                        </div>
                                        <div className="border-bottom">
                                            <p className="text-secondary mb-1 mt-2">
                                                Record Created:
                                            </p>
                                            <p>
                                                {moment.utc(pcm?.entrydate).format(
                                                    "MM/DD/YYYY"
                                                )}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-secondary mb-1 mt-2">
                                                Last Modified:
                                            </p>
                                                {pcm?.moddate !== null
                                                    ? 
                                                    <p> 
                                                    {moment.utc(pcm?.moddate).format("MM/DD/YYYY HH:mm")} by{" "}
                                                    {pcm?.editor?.first_name} {pcm?.editor?.last_name}    
                                                    </p>
                                                    : 
                                                    <p>---</p>
                                                }
                                        </div>
                                    </Stack>
                                </Col>
                                <Col sm={2}>
                                    {isEditing ? 
                                    <SaveIcon style={{pointer: "cursor", float: "right"}} onClick={handleSaveDates} /> : 
                                    <EditIcon style={{cursor: "pointer", float: "right"}} onClick={() => setIsEditing(true)} />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion flush className="border border-dark mb-3 hr" defaultActiveKey='1' alwaysOpen>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="bg-secondary">
                        <h6 className="sidebar-title">Customer Info</h6>
                    </Accordion.Header>
                    <Accordion.Body className="bg-light p-1">
                        <Container>
                            <Row>
                                <Col>
                                    <Stack>
                                        <div className="border-bottom">
                                            <p className="text-secondary mb-1 mt-2">
                                                Vendor:
                                            </p>
                                            <p>{pcm?.vendor_id?.name}</p>
                                        </div>
                                        <div className="border-bottom">
                                            <p className="text-secondary mb-1 mt-2">
                                                Lab:
                                            </p>
                                            <p>{pcm?.assigned_lab ? pcm.assigned_lab.cctl_name : pcm?.custorg}</p>
                                        </div>
                                        <div className="border-bottom">
                                            <p className="text-secondary mb-1 mt-2">
                                                Scheme:
                                            </p>
                                            <p>{pcm?.nsid?.cid?.name}</p>
                                        </div>
                                        <div className="border-bottom">
                                            <p className="text-secondary mb-1 mt-2">
                                                Customer:
                                            </p>
                                            <p>{pcm?.custuname}</p>
                                        </div>
                                        <div className="border-bottom">
                                            <p className="text-secondary mb-1 mt-2">
                                                Email:
                                            </p>
                                            <Button
                                                variant="link"
                                                className="p-0 text-bright-blue mb-3"
                                                style={{
                                                    fontSize: "16px",
                                                    wordBreak: "break-word",
                                                }}
                                                onClick={() =>
                                                    toEmailForm(pcm?.custemail)
                                                }
                                            >
                                                {pcm?.custemail}
                                            </Button>
                                        </div>
                                        <div>
                                            <p className="text-secondary mb-1 mt-2">
                                                Phone:
                                            </p>
                                            <p>{pcm?.phone1}</p>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion flush className="border border-dark mb-3">
            <Accordion.Item>
            <Accordion.Header className="bg-secondary">
                <h6 className="text-white">ATTACHED DOCUMENTS</h6>
            </Accordion.Header>
            <Accordion.Body className="text-center bg-light p-1">
                <Link to={`/community/pcm/details/${id}/pcm_files/${pcm.ccid}/`}>
                    <Button variant="primary">View All</Button>
                </Link>
                {files?.map((file, idx) => (
                <Stack  direction='horizontal' className='d-flex justify-content-left align-items-start' key={idx}>
                    {file.file_mime_type === 'application/pdf' && <FontAwesomeIcon icon={faFilePdf} color='red' size='xl' />}
                    {file.file_mime_type !== 'application/pdf' && <FontAwesomeIcon icon={faFileLines} color='gray' size='xl' />}
                    <div className="ms-2 text-break">
                        <Button style={{overflowWrap: "break-word"}} className="text-start m-0 p-0" variant="link" onClick={() => downloadFile(file)}>
                            {file.file_label ? file.file_label : file.file_new_name ? file.file_new_name : file.file_name}
                        </Button>
                        <p className="mb-0 ms-3">
                            {file.file_new_name ? file.file_new_name : file.file_name}
                        </p>
                        <p className="mb-0 ms-3">
                            Attached by {file?.uploaded_by?.first_name} {file?.uploaded_by?.last_name} on {file?.uploaded_on && moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
                        </p>
                    </div>
                </Stack>
                ))}
                <Button
                    variant="outline-primary rounded-pill mb-2"
                    onClick={() => setShowUpload(true)}
                >
                    Upload Files
                </Button>
            </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </>
    );
}
