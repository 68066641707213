import { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import moment from "moment";

const CreateQaConfirmationModal = ({ show, handleClose }) => {
    const [cookies] = useCookies();
    const csrfToken = cookies["csrftoken"];
    const authToken = cookies["auth_token"];
    const currentUser = useSelector((state) => state.user.value);

    const [alert, setAlert] = useState({ message: "", type: "" });

    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .post(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/`,
                {
                    //status auto being set on backend to Pending Review
                    deadline: moment().add(7, "days"),
                    creator: currentUser?.id ?? null,
                    recaptcha_token: "member_portal",
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                let redirectRid = response.data.rid;
                handleClose();
                if (redirectRid) {
                    window.location.href = `/community/question-answer/details/${redirectRid}`;
                }
            })
            .catch((error) => {
                console.log("Unable to submit qa request: " + error);
                setAlert({ message: "Error creating new QA", type: "danger" });
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="border-0">Create a New QA?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {alert.message && (
                    <Alert
                        variant={alert.type}
                        onClose={() => setAlert({ message: "", type: "" })}
                        dismissible
                    >
                        {alert.message}
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="warning" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="success" onClick={handleSubmit}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateQaConfirmationModal;
