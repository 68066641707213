import { Modal, Container, Row, Col } from 'react-bootstrap';
import industryCustomersImg from '../../../images/niap_ccevs/industry_customers.png';

export default function IndustryCustomers({show, setShowIndCustomersModal}) {

  const handleCloseModal = () => {
    setShowIndCustomersModal(false)
  }

  return (
    <Modal show={show} onHide={handleCloseModal} size='lg'>
    <Modal.Header className='border-0' closeButton></Modal.Header>
    <Modal.Body className='px-5'>
    <Container fluid>
      <Row>
        <Col>
          <Row>
              <Col>
                <div className='d-flex justify-content-center'>
                  <img
                    src={industryCustomersImg}
                    alt='industry customers image'
                    className='w-auto'
                    height='120px'
                  />
                </div>
                <div className='text-center'>
                  <h3 className='text-uppercase fw-bold mt-3 mb-4 text-primary'>
                    Industry Customers
                  </h3>
                </div>
              </Col>
            </Row>
          <Row>
            <h5 className='fw-bold text-primary'>
              Products
            </h5>
          </Row>
          <Row>
            <ul className='remove-dots mx-4'>
                <li>Product Compliant List</li>
                <li>Validated Products List</li>
                <li>Archived Product Compliance List</li>
                <li>Products in Evaluation</li>
            </ul>
          </Row>
          {/* <Row className='mt-5'>
            <h5 className='fw-bold text-primary'>Technical Communities</h5>
          </Row> */}
          <Row className='mt-5'>
            <h5 className='fw-bold text-primary'>Protection Profiles</h5>
          </Row>
          <Row>
            <ul className='remove-dots mx-4'>
                <li>NIAP Approved Protection Profiles</li>
                <li>Protection Profiles in Development</li>
                <li>Archived Protection Profiles</li>
                <li>Guidelines When No Protection Profile Exists</li>
            </ul>
          </Row>
          <Row className='mt-5'>
            <h5 className='fw-bold text-primary'>
              Common Criteria Testing Labs (CCTL)
            </h5>
          </Row>
          <Row>
            <ul className='remove-dots mx-4'>
                <li>Accredited CCTLs</li>
                <li>Candidate CCTLs</li>
            </ul>
          </Row>
          {/* <Row className='mt-5 mb-5'>
            <h5 className='fw-bold text-primary'>
              Common Criteria Users Forum (CCTL)
            </h5>
          </Row> */}
        </Col>
      </Row>
    </Container>
    </Modal.Body>
    </Modal>
  );
}