import { useState, useEffect, useRef } from "react";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import qs from "qs";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Button from "react-bootstrap/Button"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import FolderIcon from "@mui/icons-material/Folder"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines } from '@fortawesome/free-regular-svg-icons'
import FileDownload from 'js-file-download';
import moment from "moment";
import RenameObject from "../RenameObject";
import MoveObject from "../MoveObject";
import { useInterval } from "../../../hooks/useInterval";
import DeleteModal from "../../UI/DeleteModal";

const DocumentTreeTable = ({currentPage, updateFolder, currentFolder, update, sideFilters, setOverlayActive, setOverlayText}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const csrfToken = cookies["csrftoken"];
    const currentUser = useSelector(state => state.user.value)
    const [documents, setDocuments] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [currentObject, setCurrentObject] = useState({})
    const [openRenameModal, setOpenRenameModal] = useState(false)
    const [openMoveModal, setOpenMoveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentQuery, setCurrentQuery] = useState({})
    const tableRef = useRef()

    const handleOpenRenameModal = (object) => {
        setCurrentObject(object)
        setOpenRenameModal(true)
    }

    const handleCloseRenameModal = () => {
        setCurrentObject({})
        setOpenRenameModal(false)
    }

    const handleOpenMoveModal = (object) => {
        setCurrentObject(object)
        setOpenMoveModal(true)
    }

    const handleCloseMoveModal = () => {
        setCurrentObject({})
        setOpenMoveModal(false)
    }

    const handleOpenDeleteModal = (object) => {
        setCurrentObject(object)
        setShowDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        setCurrentObject({})
        setShowDeleteModal(false)
    }

    const deleteFile = () => {
        axios
        .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${currentObject?.file_id}/`,
        {
            active: JSON.stringify(false),
            removed_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
            removed_by: currentUser.id
        },
        {
            withCredentials: true,
            headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`
            },
        }
        )
        .then((response) => {
            reloadDocuments()
            handleCloseDeleteModal()
        })
        .catch((error) => {
        console.log(error);
        });
    
    }

    useEffect(() => {
        tableRef.current.onQueryChange({page: 0})
    }, [sideFilters, currentPage, update, currentFolder])

    useInterval(()=>{
        tableRef.current.onQueryChange()
    }, 300000) //5 min

    const reloadDocuments = () => {
        tableRef.current.onQueryChange()
    }

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
            {
                withCredentials: true, 
                headers: {
                    'Authorization':  `Token ${authToken}`,
                },
                responseType: 'blob'
            }).then((response) => {
                setOverlayActive(false)
                FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name))
            }).catch((error) => {
                console.log('Error')
            })
    }

    const handleRowClick = (rowData) => {
        if (rowData.isFolder === true) {
            const currentCrumbs = breadcrumbs
            currentCrumbs.push(currentFolder)
            setBreadcrumbs([...currentCrumbs])
            updateFolder(rowData) 
        }
        else {
            downloadFile(rowData)
        }
    }

    const handleBreadcrumbs = (crumb) => {
        const currentCrumbs = breadcrumbs
        const location = currentCrumbs.indexOf(crumb)
        const slice = currentCrumbs.slice(0, location)
        setBreadcrumbs([...slice])
        updateFolder(crumb)
    }

    const columns = [
        {title: "", field: "file_type", render: (rowData) => (rowData?.isFolder === true ? <FolderIcon /> : <FontAwesomeIcon icon={faFileLines} size="xl" />)},
        {title: "Path", field: "file_location"},
        {title: "File Name", field: "file_name", render: (rowData) => (rowData.file_new_name ? rowData.file_new_name : rowData.file_name)},
        {title: "Uploaded By", field: "uploaded_by", render: (rowData) => (rowData?.uploaded_by?.first_name + " " + rowData?.uploaded_by?.last_name)},
        {title: "Uploaded On", field: "uploaded_on", render: (rowData) => (rowData.uploaded_on ? moment.utc(rowData.uploaded_on).format("YYYY.MM.DD @hh:mm") : "")},
        {title: "Actions", disableClick: true, render: (rowData) => (<OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            key="bottom"
            overlay={
              <Popover>
                <Popover.Body>
                    <Button variant="link" onClick={() => handleOpenRenameModal(rowData)}>Rename</Button>
                    <Button variant="link" onClick={() => handleOpenMoveModal(rowData)}>Move</Button>
                    <Button variant="link" onClick={() => handleOpenDeleteModal(rowData)}>Delete</Button>
                </Popover.Body>
              </Popover>
            }
        >
            <Button variant="link">
                <MoreVertIcon className="float-end" />
            </Button>
        </OverlayTrigger>)}
    ]

    const options = {
        columnsButton: true,
        headerStyle: {
          fontWeight: "bold"
        },
        pageSize: 20,
    };

    return (
        <div>
            <Breadcrumb>
                {breadcrumbs.map(crumb => (
                    <Breadcrumb.Item key={crumb?.file_id} onClick={() => handleBreadcrumbs(crumb)}>{crumb?.file_name ? crumb?.file_name : currentPage}</Breadcrumb.Item>
                ))}
            </Breadcrumb>
            <ResponsiveMaterialTable
                title={currentPage + " Files"}
                columns={columns}
                tableRef={tableRef}
                data={(query) =>
                    new Promise((resolve, reject) => {
                      // Extract the necessary information from the query object
                      const {
                          page,
                          pageSize,
                          search,
                          filters,
                          orderBy,
                          orderDirection,
                      } = query;
          
                      let newFilters = filters.map((filter) => {
                          let value = ""
                          if (Array.isArray(filter.value)) {
                              value = filter.value
                          } else {
                              value = filter.value.replace(/['"]+/g, '')
                          }
                          return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                      });
                      const params = {
                          ...sideFilters,
                          offset: page * pageSize,
                          limit: pageSize,
                          search: search,
                          filters: newFilters,
                          orderBy: orderBy?.field,
                          orderDirection: orderDirection,
                          page: currentPage,
                          folder: "/"
                      }
                      if (currentFolder) {
                        params['folder'] = currentFolder.file_location
                      }
                      axios
                          .get(
                              `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_documents_by_page/?${qs.stringify(params, {arrayFormat: "comma"})}`,
                              {
                                  withCredentials: true,
                                  headers: {
                                      Authorization: `Token ${authToken}`,
                                  },
                              }
                          )
                          .then((response) => {
                              resolve({
                                  data: response.data.results,
                                  page: page,
                                  totalCount: response.data.count,
                              });
                          })
                          .catch((error) => {
                              reject(error);
                          });
                      })
                    }
                options={options}
                onRowClick={(e, rowData) => {handleRowClick(rowData)}}
            />
            <RenameObject show={openRenameModal} handleClose={handleCloseRenameModal} reloadDocuments={reloadDocuments} object={currentObject} />
            <MoveObject show={openMoveModal} handleClose={handleCloseMoveModal} reloadDocuments={reloadDocuments} object={currentObject} currentPage={currentPage} />
            <DeleteModal show={showDeleteModal} handleClose={handleCloseDeleteModal} type={"File"} deleteFunction={deleteFile} />
        </div>
    )
}

export default DocumentTreeTable