import {
  Row,
  Col,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import QADetailsHeaderInfo from "./QADetailsHeaderInfo";
import ReCAPTCHA from 'react-google-recaptcha';
import { useInterval } from "../../hooks/useInterval";
import moment from "moment";
  
  
export default function QAFollowUpResponsePage({testing}) { //only using testing prop for tests
  const [validated, setValidated] = useState(false);
  const [newContact, setNewContact] = useState({});
  const [invalidToken, setInvalidToken] = useState(false);
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("");
  const [ qa, setQa ] = useState({})
  const { id } = useParams()
  const { search } = useLocation()
  const [errors, setErrors] = useState({})
  const [qaCategories, setQaCategories] = useState([])

  const siteKey = '6Lf29aAjAAAAAIagG9XUSNUz_nXKnwxrK_fDcGf5';
  const recaptchaRef = useRef(null);

  const [cookies] = useCookies();
  let csrfToken = cookies['csrftoken']

  const query = new URLSearchParams(search)

  const showCaptcha = () => {
    try {
      window.grecaptcha.render('form-recaptcha', {
        sitekey: siteKey,
        ref: recaptchaRef,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const checkPhones = () => {
  //   if (newContact?.phone1 && newContact?.phone1 !== "" && !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(newContact.phone1)) {
  //     return false
  //   }
  //   else {
  //     return true
  //   }
  // }

  const fetchQACategories = useCallback(() => {
    axios
    .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/cat/get_all_active_cats/`, {
        withCredentials: true,
    })
    .then((response) => {
        const categories = response.data.map(cat => {return {value: cat.cid, label: cat.cat}})
        setQaCategories(categories);
    })
    .catch((error) => console.log(error));
    }, [])

  useEffect(() => {
    fetchQACategories();
  }, [fetchQACategories]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } 
    else if (!testing && window.grecaptcha.getResponse() === '') {
      event.preventDefault();
      event.stopPropagation();
      setInvalidToken(true);
    } else {
      event.preventDefault();
      const token = event.target['g-recaptcha-response']?.value;
      axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/create_req_on_frontend/`, 
      {
        ...newContact,
        status: "Pending Review",
        creator: qa?.creator?.id ?? null, 
        deadline: moment().add(7, 'days'),
        recaptcha_token: token,
        original_qa: qa?.original_qa?.rid, //capturing original qa
        in_response_to: id 
        //on the backend on the update serializer, sets original QA to in_progress if passed here
      },
      {
          withCredentials: true, 
          headers: {
              'X-CSRFToken': csrfToken,
          }
      }).then(() => {
          fetchQA();
          setInvalidToken(false);
          setValidated(false);
          setSubmitted(true)
          recaptchaRef.current.reset();
      }).catch((error) => {
        console.log('Unable to submit contact request: ' + error);
        setError("Error Submitting Contact Request. Please Try Again.");
      })
    }
    setValidated(true);
  };

  const fetchQA = useCallback(() => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/response_data/?id=${id}&send_to=${query.get('send_to')}`, {
      withCredentials: true,
    })
    .then((response) => {
        setNewContact({
          custuname: response.data.custuname,
          custemail: response.data.custemail,
          custorg: response.data.custorg,
          phone1: response.data.phone1,
          cid: response.data.cid.cid,
        })
        setQa(response.data)
    })
    .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchQA();
  }, []);

  useInterval(()=>{
    fetchQA()
  }, 180000) //3 min

  const handleChange = (e) => {
    setNewContact({ ...newContact, [e.target.name]: e.target.value });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValidated(false);
    recaptchaRef.current.reset();
    setInvalidToken(false)
    setNewContact({
        ...newContact,
        custuname:  '',
        custemail:  '',
        custorg:  '',
        phone1:  '',
        cid: '',
        // other_submission_category: '',
        req: ''
      });
  };

  return (
    <>
      {!testing && showCaptcha()}
      <Row className="my-5 justify-content-center">
        <Col sm={11}>
          <QADetailsHeaderInfo
              showButton={false}
              qa={qa}
              refetch={() => fetchQA()}
          />
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            onReset={handleReset}
            id='submit-contact-us-form'
          >
            {submitted && <Alert variant="success" className="mt-2" dismissible>Your follow up question has been submitted. Our agents will get back to your shortly. Thank you</Alert>}
            {error !== "" && <Alert variant="danger" className="mt-2" dismissible>{error}</Alert>}
            <Row sm={3} className="justify-content-center my-4">
              <Col  className="justify-content-center">
                <Form.Group className='mb-3' controlId='name'>
                  <Form.Label>Name:*</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    name='custuname'
                    value={newContact?.custuname || ""}
                    onChange={handleChange}
                    
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please provide a Name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3' controlId='email'>
                  <Form.Label>Email Address:*</Form.Label>
                  <Form.Control
                    type='email'
                    name='custemail'
                    value={newContact?.custemail || ""}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please provide a valid Email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3' controlId='organization'>
                  <Form.Label>Organization:*</Form.Label>
                  <Form.Control
                    type='text'
                    name='custorg'
                    value={newContact?.custorg || ""}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please provide an Organization.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3' controlId='phone'>
                  <Form.Label>Telephone Number:</Form.Label>
                  <Form.Control
                    type='tel'
                    name='phone1'
                    onChange={handleChange}
                    value={newContact?.phone1 || ""}
                  />
                  <Form.Text className='text-muted'>
                    Specify country code, DSN, comm., etc., as applicable.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3' controlId='category-list'>
                  <Form.Label>Category List:*</Form.Label>
                  <Form.Select name="cid" value={newContact?.cid || ""} onChange={handleChange} required>
                    <option value='' disabled hidden>Select a category</option>
                    {qaCategories.map((cat) => (
                      <option key={cat.value} value={cat.value}>{cat.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className='mb-5' controlId='req'>
                  <Form.Label>Remarks, Comments, Questions:*</Form.Label>
                  <Form.Control
                    required
                    as='textarea'
                    rows={5}
                    name='req'
                    onChange={handleChange}
                    value={newContact?.req || ""}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please provide a Remark, Comment, and/or Question.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3 text-end' controlId='token'>
                <div className='d-flex justify-content-end'>
                  <ReCAPTCHA 
                    id='form-recaptcha'
                    sitekey={siteKey}
                    ref={recaptchaRef}
                  />
                  </div>
                  {invalidToken ? 
                    <p className='text-danger'>Please Confirm.</p>
                  :
                  <Form.Text className='text-muted '>
                    Required to submit any information via this form.
                  </Form.Text>
                  }
                </Form.Group>
              </Col>
            </Row>
            <Row className=' justify-content-end'>
              <Col className="me-4" sm={1} >
                  <Button variant='outline-primary' type='reset' disabled={submitted}>
                  Reset
                  </Button>
              </Col>
              <Col  sm={3} >
                  <Button variant='primary' type='submit' disabled={submitted}>
                  Submit
                  </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>  
    </>
    );
  }