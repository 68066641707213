import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';
import QRCode from 'react-qr-code'
import { useSearchParams, useNavigate } from 'react-router-dom';

const MFADisplayQR = ({setStep, qrCode}) => {

    return (
        <Card className='login-card'>
            <Card.Body>
                <Card.Title as="h4">
                    Scan this Code with your Authentication Application
                </Card.Title>
                <QRCode value={qrCode} />
                <Button className='my-4' variant="warning" onClick={() => setStep('confirm')}>Next</Button>
            </Card.Body>
        </Card>
    )
}

export default MFADisplayQR