import { useEffect, useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";

export default function PCMModalFileSection({
    pcm,
    files,
    setFiles,
    isMaintenance
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [fileInfo, setFileInfo] = useState({})
    const [editedFileLabel, setEditedFileLabel] = useState('')
    const [fileErr, setFileErr] = useState(false)

    const handleChange = (e) => {
        if (e.target.name === 'file_display_name' || e.target.name === 'file_label') {
            setEditedFileLabel(e.target.value);
            setFileInfo({...fileInfo, [e.target.name]: e.target.value, file_label: e.target.value})
        } else {
            setFileInfo({...fileInfo, [e.target.name]: e.target.value})
        }
        if(e.target.name === "file"){
            if (e.target.files[0].type ==='application/pdf'){
                const newFiles = files ? files : []
                newFiles.push({
                  file_display_name: fileInfo.file_display_name, 
                  file_name: e.target.files[0].name, 
                  file_label: fileInfo.file_label,
                  file: e.target.files[0],
                  active: true,
                  private: true,
                })
                setFiles([...newFiles])
                setFileErr(false)
            }
            else {
                setFileErr(true)
            }
        }
    }

    const fetchFiles = useCallback(() => {
        axios
            .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=cc&file_type_id=${pcm?.rid}`
            )
            .then((response) => setFiles(response.data?.filter(file => file?.active === true)))
            .catch((error) => setFiles([]));
    }, []);

    useEffect(() => {
        if(pcm?.rid){
            fetchFiles();
        }
    }, [pcm]);

    const removeFile = async (file, idx) => {
        if (file.file_id) {
            await axios
                .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
                    {
                        active: false,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => fetchFiles())
                .catch((error) => console.log("Unable to update file", error));
        } else {
            const newFiles = files.slice();
            newFiles.splice(idx, 1);
            setFiles(newFiles);
        }
    };

    return (
        <Row className="mb-3">
            <p className="m-0 fw-bold mt-4">ATTACH FILES</p>
            <Form.Text className="fst-italic text-dark m-3">
            {isMaintenance ?
                "**Attach updated Administrative Guidance (AGD) if applicable.**"
                :
                `
                    **Attach supporting documents that are not posted on the Common 
                    Criteria Portal to include the Administrative Guide (AGD), Assurance 
                    Activity Report (AAR) required for inclusion to the NIAP PCL**
                `
            }
            </Form.Text>
            <Col xl={6} sm={12}>
                <Form.Group
                    className="mb-4 text-start"
                    controlId="file_display_name"
                >
                    <Form.Label className="small text-secondary">
                        Type
                    </Form.Label>
                    <Form.Select
                        name="file_display_name"
                        value={fileInfo?.file_display_name ?? ""}
                        onChange={handleChange}
                    >
                        <option value=""></option>
                        <option value="Administrative Guide (AGD)">
                        Administrative Guide (AGD)
                        </option>
                        <option value="Assurance Activity Report (AAR)">
                        Assurance Activity Report (AAR)
                        </option>
                        {/* <option value="Security Targets">
                        Security Targets
                        </option>
                        <option value="Certificate">Certificate</option> */}
                        <option value="Other Documents">Other Documents</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xl={6} sm={12}>
                <Form.Group controlId="file_label">
                    <Form.Label className="small text-secondary">
                        File Label
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="file_label"
                        onChange={handleChange}
                        value={editedFileLabel}
                        noValidate
                    />
                </Form.Group>
            </Col>
            <Col xl={6} sm={12} className="mb-3">
                <Form.Group controlId="file">
                    <Form.Label className="small text-secondary">
                        Attach Files
                    </Form.Label>
                    <Form.Control
                        type="file"
                        name="file"
                        accept=".pdf"
                        onChange={handleChange}
                        disabled={
                            !fileInfo.file_display_name ||
                            !fileInfo.file_label
                        }
                    />
                </Form.Group>
            </Col>
            {fileErr && 
                <Alert variant="danger">Please upload PDF files.</Alert>
            }
            {files
                ?.filter((file) => file.active === true)
                .map((file, idx) => (
                    <Row className="m-3 border-bottom text-center" key={idx}>
                        <Col sm={4}>{file?.file_display_name}</Col>
                        <Col sm={3}>{file?.file_label}</Col>
                        <Col sm={3}>
                            {file?.file_name}
                        </Col>
                        <Col sm={2}>
                            <Button
                                variant="outline-primary"
                                className="attachment-remove"
                                onClick={() => removeFile(file, "product", idx)}
                            >
                                X
                            </Button>
                        </Col>
                    </Row>
                ))}
        </Row>
    );
}
