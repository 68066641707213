import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import glossary from "./Glossary.json"

const Glossary = () => {

    const columns = [
        {
            title: "Term/Acronym",
            field: "term",
        },
        {
            title: "Definition",
            field: "definition"
        }
    ]

    const options = {
        showTitle: true,
        headerStyle: {
            fontWeight: "bold",
            borderBottom: "none",
            paddingBottom: "0",
        },
        filtering: false,
        pageSize: 20
    };

    return (
        <Container fluid>
            <Row className="mt-5 mb-3">
                <Col sm={12}>
                    <h3 className="text-bright-navy">Training Center</h3>
                </Col>
            </Row>
            <Row className="mt-5 mb-3">
                <Col sm={12}>
                    <ResponsiveMaterialTable
                        title={"Glossary"}
                        columns={columns}
                        options={options}
                        data={glossary}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Glossary