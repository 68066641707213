import ProductCard from "../ProductCard";
import MaintenanceCard from "../../AssuranceMaintenancePage/MaintenanceCard";
import ProductPagination from "./ProductPagination";
import { Container, Row, Col } from "react-bootstrap";

export default function ProductGridView({
    items,
    setOffset,
    offset,
    total,
    PAGE_SIZE,
    maintenances,
    projpps,
    ccPps
}) {
    return (
        <Container className="p-4" fluid>
            <Row className="g-4 mb-5">
                {items
                    ?.map((card, idx) => (                        
                        <Col sm={6} key={idx}>
                            {!maintenances[card?.product_id] ?
                                <ProductCard 
                                    card={card} 
                                    projpps={projpps}
                                    ccPps={ccPps} 
                                />
                                :
                                <MaintenanceCard
                                    card={card}
                                    maintenances={maintenances[card?.product_id]}
                                    projpps={projpps}
                                    ccPps={ccPps}
                                />
                            }
                        </Col>
                    ))}
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <ProductPagination
                        setOffset={setOffset}
                        offset={offset}
                        total={total}
                        PAGE_SIZE={PAGE_SIZE}
                    />
                </Col>
            </Row>
        </Container>
    );
}
