import MoreVertIcon from "@material-ui/icons/MoreVert";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import {
    Alert,
    Button,
    Popover,
    OverlayTrigger,
    Row,
    Col,
    Container,
    Stack,
} from "react-bootstrap";
import { useEffect, useState, useRef, useCallback } from "react";
import NAIPChangeAgentModal from "../Dashboard/NIAPTabsModule/NIAPChangeAgentModal";
import NAIPReassignAgentModal from "../Dashboard/NIAPTabsModule/NIAPReassignAgentModal";
import CreateQaConfirmationModal from "./CreateQaConfirmationModal";
import ContactUsForm from "../ContactUs/ContactUsForm";
import { useInterval } from "../../hooks/useInterval";
import qs from "qs";
import parse from "html-react-parser";
import Filter from "./QaTableFilter"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ResponsiveMaterialTable from "../UI/MaterialTable/ResponsiveMaterialTable";
import moment from "moment";


export default function QATable({refetchCount, manager}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [showModal, setShowModal] = useState(false);
    const [showReassignModal, setShowReassignModal] = useState(false);
    const [currentAgentValue, setCurrentAgentValue] = useState({});
    const [currentQA, setCurrentQA] = useState({});
    const [showQAModal, setShowQAModal] = useState(false);
    const [showCreateQAModal, setShowCreateQAModal] = useState(false);
    const [alert, setAlert] = useState({message: "", type: ""});
    const tableRef = useRef();

    const [filterOpen, setFilterOpen] = useState(manager ? true : false)
    const [sidebarFilters, setSidebarFilters] = useState({status: "Pending Review,In Progress,Assigned"})

    useEffect(() => {
        if (window.innerWidth < 768) {
          setFilterOpen(false);
        }
    }, []);

    const getOnDutyAgent = () => {
        axios
            .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_on_duty_agent/`,
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                setCurrentAgentValue(response.data);
            });
    };

    const openReassignModal = (rowData) => {
        setShowReassignModal(true);
        setCurrentQA(rowData);
    };

    const handleSoftDelete = (rowData) => {
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/${rowData.rid}/`,
                {
                    status: "Cancelled", 
                    enddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                    recaptcha_token: "member_portal"
    
                },
                {
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                fetchQAs();
                setAlert({message: `QA Successfully Marked as Spam!`, type: "success"})
            })
            .catch((error) => {
                console.log(error.response.data);
                setAlert({message: "Error Marking QA as Spam.", type: "danger"})
            });
    };

    useEffect(() => {
        if (tableRef.current) {
          tableRef.current.onQueryChange()
        }
      }, [sidebarFilters])
    
    const changeFilter = () => {
        setFilterOpen(!filterOpen)
    }

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
        getOnDutyAgent();
    }, []);

    useInterval(() => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
        getOnDutyAgent();
    }, 600000); //10 min

    const fetchQAs = useCallback(() => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
            if(refetchCount){
                refetchCount();
            }
        }
    }, []);

    useEffect(() => {
        fetchQAs();
    }, []);

    const handleShowModal = (e, rowData) => {
        setShowModal(true);
    };

    const handleCloseModal = async (e) => {
        setShowModal(false);
        setShowReassignModal(false);
        setShowQAModal(false);
        setCurrentQA({});
        fetchQAs();
    };

    const options = {
        headerStyle: {
            fontWeight: "bold",
            borderBottom: "none",
            paddingBottom: "0",
        },
        filtering: true,
        columnsButton: true,
        pageSize: 20
    };

    const columns = [
        {
            title: "Ticket No.",
            field: "ticket",
            render: (rowData) => (
                <Link
                    to={`/community/question-answer/details/${rowData.rid}`}
                    className="text-decoration-underline"
                >
                    {moment.utc(rowData?.entrydate).format('YYYY')}-{rowData?.ticket}
                </Link>
            )
        },
        {
            title: "Agent",
            render: (rowData) => <div>{rowData.agent?.last_name}</div>,
            field: "agent.last_name",
            customSort: (a, b) =>
                a.agent?.last_name.localeCompare(b.agent?.last_name),
        },
        { 
            title: "Question", 
            field: "req", 
            render: rowData => {
                const maxChars = 100;
                const question = rowData?.req
                if(!question) return "";
                let displayText = question.length > maxChars ? question.substring(0, maxChars) + "..." : question;
                displayText = parse(displayText);
                return displayText;
            }
        },
        { title: "Status", field: "stid.status" },
        {
            title: "Deadline",
            field: "deadline",
            type: "date",
            render: (rowData) => {
                return rowData.deadline ? 
                moment.utc(rowData.deadline).format('MM/DD/YYYY') : 
                rowData?.requestedby ? 
                moment.utc(rowData.requestedby).format('MM/DD/YYYY') : "";
            },
        },
        {
            title: "Date Completed",
            field: "enddate",
            type: "date",
            hidden: !manager,
            render: (rowData) => {
                return rowData?.enddate ? moment.utc(rowData.enddate).format('MM/DD/YYYY') : "";
            },
        },
        { title: "Customer", field: "custuname" },
        {
            title: "Actions",
            headerStyle: {display: "none"},
            field: "actions",
            filtering: false,
            sorting: false,
            render: (rowData) => (
                <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    key="bottom"
                    overlay={
                        <Popover>
                            <Popover.Body>
                                <Stack>
                                <Link
                                    to={`/community/question-answer/details/${rowData.rid}`}
                                    className="text-decoration-none"
                                >
                                    <Button
                                        variant="link"
                                        className="m-1 p-0 btn-link"
                                    >
                                        Edit
                                    </Button>
                                </Link>
                                <Button
                                    onClick={() => handleSoftDelete(rowData)}
                                    variant="link"
                                    className="m-1 p-0 btn-link"
                                >
                                    Mark as Spam
                                </Button>
                                <Button
                                    onClick={() => {
                                        openReassignModal(rowData);
                                    }}
                                    variant="link"
                                    className="m-1 p-0 btn-link"
                                >
                                    Reassign Q&A
                                </Button>
                                </Stack>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Button variant="link">
                        <MoreVertIcon className="float-end" />
                        <span className="visually-hidden">Action</span>
                    </Button>
                </OverlayTrigger>
            ),
        }
    ];

    return (
        <>
            {alert.message !== "" &&
                <Alert 
                    variant={alert.type} 
                    className="m-3" 
                    onClose={() => setAlert({message: "", type: ""})} 
                    dismissible
                >
                    {alert.message}
                </Alert>
            }
            <ContactUsForm 
                handleClose={handleCloseModal} 
                show={showQAModal} 
            />
            <NAIPChangeAgentModal
                show={showModal}
                handleCloseModal={handleCloseModal}
                refetch={getOnDutyAgent}
            />
            <NAIPReassignAgentModal
                show={showReassignModal}
                handleCloseModal={handleCloseModal}
                refetch={fetchQAs}
                id={currentQA.rid}
            />
            <CreateQaConfirmationModal
                show={showCreateQAModal}
                handleClose={() => setShowCreateQAModal(false)}
            />
            <Container fluid>
                <Row className="justify-content-end">
                    {/* Individual button containers */}
                    <Col xs={12} md="auto">
                        <Button
                            variant="warning"
                            className="my-1 w-100 w-md-auto rounded" // Adjust width for md screens
                            onClick={() =>
                                tableRef.current.onQueryChange({
                                    filters: [],
                                    search: "",
                                })
                            }
                        >
                            Reset Filters
                        </Button>
                    </Col>
                    {/* <Col xs={12} md="auto">
                        <Button
                            variant={filtering ? "primary" : "outline-primary"}
                            className="my-1 w-100 w-md-auto rounded" // Adjust width for md screens
                            onClick={handleFiltering}
                        >
                            Filter
                        </Button>
                    </Col> */}
                    <Col xs={12} md="auto">
                        <Button 
                            variant='primary' 
                            className='my-1 w-100 w-md-auto rounded' // Adjust width for md screens
                            onClick={()=> setShowCreateQAModal(true)}
                        >
                            + ADD QA
                        </Button>
                    </Col>
                    <Col xs={12} md="auto" className="d-flex align-items-center">
                        <Button
                            variant="primary"
                            className="rounded me-2" // Adjust margin as needed
                            onClick={() => handleShowModal()}
                        >
                            Change
                        </Button>
                        <p
                            style={{ fontWeight: "bold" }}
                            className="mb-0 text-center text-md-left" // Adjust text alignment
                        >
                            On Duty: {currentAgentValue?.last_name}
                        </p>
                    </Col>
                </Row>

            {manager &&
            <Row className="mt-3">
                <Col md={9}></Col>
                <Col sm={12} md={3} className="d-flex justify-content-end align-content-center mb-2">
                    <Button variant="link rounded-pill" style={{backgroundColor: "#dedede", maxHeight: 40}} onClick={changeFilter}>
                        {filterOpen && <>
                        <KeyboardArrowRightIcon />
                        <KeyboardArrowRightIcon />
                        &nbsp;
                        Close Filter
                        &nbsp;
                        <KeyboardArrowRightIcon />
                        <KeyboardArrowRightIcon />
                        </>}
                        {!filterOpen && <>
                        <KeyboardArrowLeftIcon />
                        <KeyboardArrowLeftIcon />
                        &nbsp;
                        Open Filter
                        &nbsp;
                        <KeyboardArrowLeftIcon />
                        <KeyboardArrowLeftIcon />
                        </>}
                    </Button>
                </Col>
            </Row>
            }
                <Row className="mb-3">
                    <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
                    <ResponsiveMaterialTable
                        id={"qa-table"}
                        title={
                            "Question & Answer"
                        }
                        tableRef={tableRef}
                        actions={[
                        {
                            icon: "refresh",
                            tooltip: "Refresh Data",
                            isFreeAction: true,
                            onClick: () =>
                            tableRef.current &&
                            tableRef.current.dataManager.columns.forEach((item) => {
                                tableRef.current.onFilterChange(item.tableData.id, "");
                            }),
                        }
                        ]}
                        columns={columns}
                        options={options}
                        data={(query) =>
                            new Promise((resolve, reject) => {
                                // Extract the necessary information from the query object
                                const {
                                    page,
                                    pageSize,
                                    search,
                                    filters,
                                    orderBy,
                                    orderDirection,
                                } = query;

                                let newFilters = filters?.map((filter) => {
                                    let value = "";
                                    if (Array.isArray(filter.value)) {
                                        value = filter.value;
                                    } else if (
                                        ["completed", "deadline", "enddate"]?.includes(
                                            filter?.column?.field
                                        )
                                    ) {
                                        const dateObj = new Date(filter?.value);
                                        const isoStr = dateObj.toISOString();
                                        const formattedStr = isoStr.split("T")[0];
                                        value = formattedStr;
                                    } else {
                                        value = filter.value.replace(/['"]+/g, "");
                                    }
                                    return `"${filter.column.field.replace(
                                        /\./g,
                                        "__"
                                    )}":"${value}"`;
                                });

                                const params = {
                                    ...sidebarFilters,
                                    offset: page * pageSize,
                                    limit: pageSize,
                                    search: search,
                                    filters: newFilters,
                                    orderBy: orderBy?.field,
                                    orderDirection: orderDirection,
                                    deleted: false,
                                    filter_responses: true
                                };

                                if (!manager) {
                                    params["status"] = ["Pending Review","Assigned","In Progress", "Planned"];
                                }

                                axios
                                    .get(
                                        `${
                                            process.env.REACT_APP_DJANGO_ENDPOINT
                                        }question-answer/req/?${qs.stringify(params, {
                                            arrayFormat: "comma",
                                        })}`,
                                        {
                                            withCredentials: true,
                                            headers: {
                                                Authorization: `Token ${authToken}`,
                                            },
                                        }
                                    )
                                    .then((response) => {
                                        resolve({
                                            data: response.data.results,
                                            page: page,
                                            totalCount: response.data.count,
                                        });
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                        }
                    />
                    </Col>
                {manager && filterOpen &&
                    <Col xs={10} md={3}>
                        <Filter filters={sidebarFilters} setFilters={setSidebarFilters} />
                    </Col>
                }
                </Row>
            </Container>
        </>
    );
}
