import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Accordion, Alert, Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import moment from "moment";
import "moment-timezone";
import parse from "html-react-parser";
import { handleAlertAndMailer } from '../Products/Helper/functions'
import ECRNoteForm from "./ECRNoteForm";

export default function ECRNotes({ notes, fetchNotes, fetchComment, comment, user }) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const [showNotes, setShowNotes] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [inResponseTo, setInResponseTo] = useState();
  const [totalNotes, setTotalNotes] = useState(0);
  const [nestedNotes, setNestedNotes] = useState([]);
  const [responseType, setResponseType] = useState("");
  const [expandAll, setExpandAll] = useState(false);
  const [toDeleteNote, setToDeleteNote] = useState();
  const [alert, setAlert] = useState({});

  // const handleSendAlertAndMail = () => {
  //   handleAlertAndMailer(
  //     csrfToken, 
  //     authToken, 
  //     {
  //       alert_type_id: comment?.pid?.product_id,
  //       alert_type: "Product",
  //       alert_source: "ECR",
  //       subject: `ECR Comment ${comment?.ecrid} has been responded to by ${comment?.assigned_lab?.name}.`,
  //       recipients: {to: ["Validators"], cc: []},
  //       alert_text: `ECR Comment ${comment?.ecrid} has been responded to by ${comment?.assigned_lab?.name}`
  //     }
  //   )
  // };

  const openNoteModal = (replyTo = null, note = null) => {
    if (note?.in_response_to) {
      setInResponseTo(note?.in_response_to.upid);
    }
    else {
      setInResponseTo(replyTo);
    }
    setCurrentNote(note);
    setShowNotes(true);
  };

  const openDeleteModal = (note) => {
    setToDeleteNote(note);
    setShowDeleteModal(true);
  };

  const [selectedNotes, setSelectedNotes] = useState([])

  useEffect(() => {
    setAlert({})
  }, [comment?.ecrid])

  useEffect(() => {
    if (permissions?.role_type === "Lab") {
      setResponseType("Validator");
    } else {
      setResponseType("Lab");
    }
  }, [permissions]);

  useEffect(() => {
    const unfilteredNotes = notes;
    setTotalNotes(unfilteredNotes.length);
    const filteredNotes = [];
    const responses = {};
    unfilteredNotes.forEach((note) => {
      if (!note.in_response_to) {
        filteredNotes.push(note);
      } else {
        if (!responses[note.in_response_to.upid]) {
          responses[note.in_response_to.upid] = [];
        }
        responses[note.in_response_to.upid].push(note);
      }
    });
    for (let key in responses) {
      const children = responses[key];
      children.forEach((note, idx) => {
        if (responses[note.upid]) {
          note["responses"] = responses[note.upid];
          responses[key][idx] = note;
        }
      });
    }
    filteredNotes.forEach((note) => {
      if (responses[note.upid]) {
        note["responses"] = responses[note.upid];
      }
    });
    setNestedNotes(filteredNotes);
  }, [notes]);

  const noteStatus = (note) => {
    if (note?.response_type === "ECR Cancelled") {
      return note?.response_type;
    } else if (note?.released) {
      return `Released to ${note?.response_type}`;
    } else {
      return `Not Released to ${note?.response_type}`;
    }
  };
  const visibilityText = (note) => {
    if (note.private) {
      return "Private";
    } else if (note.visible_to_labs && note.visible_to_validators) {
      return "Visible to Lab and Validators";
    } else if (note.visible_to_labs) {
      return "Visible to Labs";
    } else if (note.visible_to_validators) {
      return "Visible to Validators";
    } else {
      return "N/A";
    }
  };

  const childNotes = (note) => {
    return (
      <>
        <div className="text-end">
          <button
            className="btn btn-link d-inline-block"
            style={{ minWidth: 10 }}
            onClick={() => openNoteModal(note.upid)}
          >
            Reply
          </button>
          {note?.creator?.id === user?.id && (
            <>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => {
                  openNoteModal(null, note);
                }}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 ms-3">
          <p className="text-end">
            Note by: {note.creator?.first_name} {note.creator?.last_name} <br />
            {note.entrydate && moment(note.entrydate).format("YYYY.MM.DD [at] HH:mm")}
            <br />
            {visibilityText(note) === "N/A" ? (
              <span>Note status: {noteStatus(note)}</span>
            ) : (
              <span>Visibility: {visibilityText(note)} </span>
            )}
          </p>
          <h6>{note.gist}</h6>
          <div>{note.note1 && parse(note.note1)}</div>
          {note.responses && (
            <Accordion
            defaultActiveKey={note.upid}
            activeKey={selectedNotes}
            onSelect={() => {
              const uniqueNotes = new Set(selectedNotes);
              if (uniqueNotes.has(note.upid)) {
                uniqueNotes.delete(note.upid);
              } else {
                uniqueNotes.add(note.upid);
              }
              setSelectedNotes([...uniqueNotes]);
            }}
            >
              <Accordion.Item
                eventKey={
                    selectedNotes.includes(note.upid)
                    ? note.upid
                    : null
                }
              >
                <Accordion.Header>{note.responses.length} Responses</Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.upid}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  const displayNotes = () => {
    return nestedNotes.map((note) => (
      <div key={note.upid}>
        <div className="text-end">
          <button
            className="btn btn-link d-inline-block"
            style={{ minWidth: 10 }}
            onClick={() => openNoteModal(note.upid)}
          >
            Reply
          </button>
          {note?.creator?.id === user?.id && (
            <>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal(null, note)}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 m-4">
          <p className="text-end">
            Note by: {note.creator?.first_name} {note.creator?.last_name} <br />
            {note.entrydate && moment(note.entrydate).format("YYYY.MM.DD [at] HH:mm")}
            <br />
            {visibilityText(note) === "N/A" ? (
              <span>Note status: {noteStatus(note)}</span>
            ) : (
              <span>Visibility: {visibilityText(note)} </span>
            )}
          </p>
          <h6>{note.gist}</h6>
          <div>{note.note1 && parse(note.note1)}</div>
          {note.responses && (
           <Accordion
           defaultActiveKey={note.upid}
           activeKey={selectedNotes}
           onSelect={() => {
             const uniqueNotes = new Set(selectedNotes);
             if (uniqueNotes.has(note.upid)) {
               uniqueNotes.delete(note.upid);
             } else {
               uniqueNotes.add(note.upid);
             }
             setSelectedNotes([...uniqueNotes]);
           }}
           >
             <Accordion.Item
               eventKey={
                   selectedNotes.includes(note.upid)
                   ? note.upid
                   : null
               }
             >
                <Accordion.Header>{note.responses.length} Responses</Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.upid}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
    ));
  };

  const deleteNote = async (upid) => {
    await axios
      .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/update/${upid}/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(() => {
        fetchNotes();
        setShowDeleteModal(false)
      })
      .catch((error) => console.log(error));
  };

  const releaseResponses = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/update/release_responses/`,
        {
          response_type: responseType,
          product: comment?.pid?.product_id,
          maintenance: comment?.maintenance_id?.maintenance_id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(() => {
        fetchNotes();
        // if (responseType === "Validator") {
        //   handleSendAlertAndMail();
        // }
      })
      .catch((error) => console.log(error));
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${comment?.ecrid}/`,
        {
          status: `Response to ${responseType}`,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(() => {
        fetchComment();
        setAlert({
          message: `Responses Released! ECR Status set as Response to ${responseType}`,
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          message: "Error releasing responses. Please try again.",
          variant: "success",
        });
      });
  };

  return (
    <Accordion className="my-4" flush>
      {alert?.message && (
        <Alert className="mt-3" variant={alert?.variant} onClose={() => setAlert({})} dismissible>
          {alert?.message}
        </Alert>
      )}
      <Accordion.Item>
        <Accordion.Header>
          <h6 className="fw-bold text-dark">NOTES</h6>
          <span className="border my-2 mx-4 p-2">Total: {totalNotes}</span>
        </Accordion.Header>
        {(permissions?.role_type === "Lab" || permissions?.is_senior || permissions?.is_lead) &&
          permissions?.role_type !== "NIAP" && (
            <Row>
              <Col className="d-flex justify-content-end">
                <Button variant="warning rounded-pill m-1" onClick={() => openNoteModal()}>
                  {permissions?.role_type === "Lab" ? "Respond to Validator" : "Response to Lab"}
                </Button>
                <Button variant="warning rounded-pill m-1" onClick={() => releaseResponses()}>
                  Release to {responseType}
                </Button>
              </Col>
            </Row>
          )}
        <Accordion.Body>
          {
            <div className="text-end">
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal()}
              >
                Add
              </button>
            </div>
          }
          {notes?.length > 0 && (!expandAll ?
          (
            <div>
              <button
                className="btn btn-primary d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => {
                    setExpandAll(true);
                    setSelectedNotes(notes.map((note) => note.upid));
                }}
              >
                Expand All
              </button>
            </div>
          ) :
          (
            <div>
              <button
                className="btn btn-primary d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => {
                    setExpandAll(false);
                    setSelectedNotes([]);
                }}
              >
                Collapse All
              </button>
            </div>
          ))}
          {notes?.length > 0 && displayNotes()}
          <ECRNoteForm
            show={showNotes}
            handleClose={() => {
              setCurrentNote({});
              setShowNotes(false);
              fetchComment();
            }}
            user={user}
            currentNote={currentNote}
            comment={comment}
            fetchNotes={fetchNotes}
            inResponseTo={inResponseTo}
            responseType={responseType}
            setAlert={setAlert}
          />
          {showDeleteModal && (
            <Modal size="md" show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
              <Modal.Header closeButton className="border-0">
                <Modal.Title>Delete Note</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4 className="text-center">
                  Are You Sure You Want to Delete this By: <br />
                  {toDeleteNote.creator?.first_name} {toDeleteNote.creator?.last_name} on: <br />
                  {toDeleteNote.entrydate && moment.utc(toDeleteNote.entrydate).format("YYYY.MM.DD [at] HH:mm")}
                </h4>
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    variant="primary"
                    className="m-1"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    className="m-1"
                    onClick={() => deleteNote(toDeleteNote.upid)}
                  >
                    Delete
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
