import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import CheckIcon from "@mui/icons-material/Check"

const Filter = ({statuses, filters, setFilters}) => {
    const handleClick = (statusId) => {
        const newFilters = filters.statusId
        const exists = newFilters.indexOf(statusId)
        if (exists > -1) {
            newFilters.splice(exists, 1)
        } else {
            newFilters.push(statusId)
        }
        setFilters({statusId: newFilters})
    }

    const isSelected = (statusId) => {
        if (filters.statusId?.indexOf(statusId) > -1) {
            return <CheckIcon />
        }
    }

    return (
        <Row className='py-2'>
            {statuses.map(status => (
                <Col key={status?.id}>
                    <Card 
                        style={{backgroundColor: status.color, cursor: "pointer"}} 
                        className="px-3 pt-2"
                        onClick={() => handleClick(status?.id)}
                    >
                        <Card.Title as="h6">{isSelected(status?.id)}{status?.text}</Card.Title>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}

export default Filter