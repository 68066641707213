import { Accordion, Row, Container, Col, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import moment from 'moment';


export default function CertificateReviewAccordion({nistReview}) {
    const navigate = useNavigate();

    const navigateToPage = () => {
        navigate(`nist/${nistReview.crid}`)
    }

  return (
    <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
                <h6 className="sidebar-title">NIST Certificate Review</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
            {!nistReview?.crid ? 
                <Container>
                    <Row>
                       <p>No Nist Review</p>
                    </Row>
                </Container>
                :
                <Container>
                    <Row>
                        <Col>
                            <Row>
                                <p className="text-secondary">
                                   Submitted:
                                </p>
                            </Row>
                            <Row className="border-bottom">
                                <p>
                                    <span className='me-2'>
                                        {nistReview?.rcvd &&
                                            moment.utc(nistReview.rcvd).format("YYYY.MM.DD")
                                        }
                                    </span>
                                    by
                                    <br></br>
                                   { `${nistReview?.creator?.first_name}  ${nistReview?.creator?.last_name}`}
                                </p>
                            </Row>
                            <Row>
                                <p className="text-secondary">
                                    Status: <span className='text-black'>{nistReview?.stid?.status}</span>
                                </p>
                            </Row>
                            <Row>
                                <p className="text-secondary">
                                    Completed: <span className='text-black'>{["Rejected - Revision Required", "Approved", "Rejected, revisions needed"]?.includes(nistReview?.stid?.status) ? nistReview?.enddate && moment.utc(nistReview?.enddate).format("YYYY.MM.DD") : "TBD"}</span>
                                </p>
                            </Row>
                            <Row>
                                <Button variant="link" className="text-bright-blue" onClick={navigateToPage}>View Details</Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            }
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
  )
}