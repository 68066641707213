import { Row, Col, Button } from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

export default function NiapClearPostingVerifier({
  product,
  readyToPostChecks,
  setReadyToPostChecks,
  refreshPage,
  refetch,
  clearRecommendationNote,
  progressPoint,
  setRefetchNotes
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const user = useSelector((state) => state.user.value);

  const handleClearValidator = () => {
    let array = readyToPostChecks;
    const updatedArray = array.map((obj) => {
      const updatedObj = { ...obj };
      for (let key in updatedObj) {
        updatedObj[key] = false;
      }
      return updatedObj;
    });
    setReadyToPostChecks(updatedArray);

    let resubmissionCounter = progressPoint?.resubmission_counter;
    if (resubmissionCounter === null) {
      resubmissionCounter = 0;
    } else {
      resubmissionCounter += 1;
    }

    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          product: product?.product_id,
          progress_point: 7,
          status: "Ready for Release",
          resubmission_counter: resubmissionCounter
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "Update Already Exists") {
          refreshPage();
        } else {
          const note = "Product Rejected";
          const submittedBy = user.id;
          const noteType = "Product";
          const noteTypeId = product.product_id;
          createNote(note, submittedBy, noteType, noteTypeId);
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
              {
                status: "In Progress",
                validator_posting_verifier: null,
                validator_posting_date: null,
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              }
            )
            .then((response) => {
              refetch();
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        clearRecommendationNote();
      });
  };
  const createNote = async (note, submittedBy, noteType, noteTypeId) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: note,
          submitted_by: submittedBy,
          note_type: noteType,
          note_type_id: noteTypeId,
          submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setRefetchNotes(response?.data?.note_id)
      })
      .catch((error) => alert("Unable to add note: " + error));
  };
  return (
    <Row className="border-bottom border-top border-dark pt-3 pb-3">
      <Col lg={2} className="m-auto">
        <p className="m-0">Verified By: </p>
      </Col>
      <Col lg={1} className="m-auto">
        <CheckCircleIcon style={{ color: "var(--bs-green)" }} />
      </Col>
      <Col className="m-auto">
        <p className="m-0">
          {product?.validator_posting_verifier?.first_name}{" "}
          {product?.validator_posting_verifier?.last_name},{" "}
          {product?.validator_posting_verifier?.user_role?.role_name} on{" "}
          {moment.utc(product?.validator_posting_date).format("MM/DD/YYYY")}
        </p>
      </Col>
      <Col className="m-auto">
        <Button
          variant="warning"
          className="rounded-pill"
          onClick={() => {
            handleClearValidator();
          }}
        >
          Clear Posting Recommendation
        </Button>
      </Col>
    </Row>
  );
}
