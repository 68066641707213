import { Link } from 'react-router-dom';
import './ValidatorProductsTable.css';

const TrrtsLinkView = ( { tq_id, tq_title } ) => {
  return (
    <Link
      to={`/community/technicalqueries/details/${tq_id}`}
      data-testid='technical_queries'
      className='tq_Link'
    >
      <span className='me-2 fw-semibold'>
        tq.{tq_id}
      </span>
      {tq_title}
    </Link>
  );
};

export default TrrtsLinkView;
