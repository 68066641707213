import PPListItem from "../PPListItem";
import PPPagination from "./PPPagination";
import { Container, Row, Col } from "react-bootstrap";

export default function PPListView({
    items,
    setOffset,
    offset,
    total,
    PAGE_SIZE,
    filters,
    search,
    projpps
}) {
    return (
        <Container className="p-4" fluid>
            <Row className="g-4 mb-5">
                {items
                    .map((card, idx) => (
                        <Col sm={12} key={idx}>
                            <PPListItem listItem={card} />
                        </Col>
                    ))}
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <PPPagination
                        setOffset={setOffset}
                        offset={offset}
                        total={total}
                        PAGE_SIZE={PAGE_SIZE}
                    />
                </Col>
            </Row>
        </Container>
    );
}
