import {
    Row,
    Col,
    Stack,
    Button,
    Form,
} from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit"
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import { handleAlertAndMailer } from "../Products/Helper/functions"

export default function QADetailsHeaderInfo({showButton, qa, qaStatuses, refetch, setAlert}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const { id } = useParams();
    const [ selectCategory, setSelectCategory ] = useState(false);
    const [ selectStatus, setSelectStatus ] = useState(false);
    const [ selectAgent, setSelectAgent ] = useState(false);
    const [ selectDeadline, setSelectDeadline ] = useState(false)
    const [ qaCategories, setQaCategores] = useState([])
    const [agents, setAgents] = useState([]);

    const handleSendAlertAndMail = () => {
        handleAlertAndMailer(
          csrfToken, 
          authToken, 
          {
            alert_type_id: id,
            alert_type: "QA",
            subject: `Assigned as Agent for QA Ticket ${qa?.ticket}`,
            recipients: {to: ["Agent"], cc: []},
            alert_text: `Assigned as Agent for QA Ticket ${qa?.ticket}`
          }
        )
    }

    const handleChange = (event) => {
        var qaChange = { 
            [event.target.name]: event.target.value 
        }
        if(event.target.name === "status") {
            if(event.target.value === "Completed"){
                qaChange = {...qaChange, enddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) }
            }
        }

        qaChange = {...qaChange, moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())), recaptcha_token: "member_portal"}

        axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/${id}/`, qaChange,
                {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`
                }
                }
            )
            .then((response) => {
                if(event.target?.name === "agent"){
                    handleSendAlertAndMail()
                }
                setSelectStatus(false)
                setSelectCategory(false)
                setSelectAgent(false)
                setSelectDeadline(false)
                refetch();
                setAlert({message: "QA Successfully Updated!", type: "success"})
            })
            .catch((error) => {
                console.error("Error deleting resource:", error);
                setAlert({message: "Error updating QA.", type: "danger"})
            });
    }

    const fetchAgents = useCallback(() => {
        axios
          .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/users_by_role/?role=NIAP`, {
            headers: {
              Authorization: `Token ${authToken}`,
            },
            withCredentials: true,
          })
          .then((response) => {
            setAgents(response.data?.map(agent => 
                {
                    return {value: agent.id, label: agent.last_name}
                }
            ));
          })
          .catch((error) => {
            console.log(error);
          });
    }, [])


    const fetchQACategories = useCallback(() => {
        const headers = {}
        if (authToken) {
            headers['Authorization'] = `Token ${authToken}`
        }
        axios
            .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/cat/get_all_active_cats/`, {
                withCredentials: true,
                headers: headers,
            })
            .then((response) => {
                const categories = response.data.map(cat => {return {value: cat.cid, label: cat.cat}})
                setQaCategores(categories);
            })
            .catch((error) => console.log(error));
    }, [])
    
    useEffect(() => {
        fetchQACategories()
    }, []);

    useEffect(() => {
        fetchAgents()
    }, [])

    const handleSoftDelete = () => {

    axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/${id}/`,
            {
                status: "Cancelled", 
                enddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                recaptcha_token: "member_portal"

            },
            {
            withCredentials: true,
            headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`
            }
            }
        )
        .then((response) => {
            refetch();
            setAlert({message: "QA Successfully Marked as Spam!", type: "success"})
        })
        .catch((error) => {
            console.error("Error deleting resource:", error);
            setAlert({message: "Error Marking QA as Spam!", type: "danger"})
        });
    }

    const handlePublish = () => {
        //on the backend, we are checking if a question-answer with the same rid exists
        //and updating the publish field to true if it does
        axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/`,
            {
                category: qa?.cid__cat,
                qa_req: qa?.rid,
                created: qa?.entrydate,
                deadline: qa?.deadline,
                account_id: qa?.agent__id,
                submitter_name: qa?.custuname,
                submitter_email: qa?.custemail,
                submitter_organization: qa?.custorg,
                submitter_phone: qa?.phone1,
                question: qa?.req,
                answer: qa?.answer,
                status: "Completed",
                publish: false, //should be published manually from the faq manager
                recaptcha_token: "member_portal"
            },
            {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`
                }
            }
        )
        .then((response) => {
            refetch();
            setAlert({message: "QA Completed!", type: "success"})
        })
        .catch((error) => {
            console.error("Error creating QA:", error);
            setAlert({message: "Error completing QA.", type: "danger"})
        });
    }
      

    return (
        <Row>
            <Row className="d-flex flex-wrap justify-content-end">
                <Col xs={12} md={7} className="mb-2 mb-md-0">
                    <h3 className="fw-bold text-primary">
                    QUESTION AND ANSWER:
                    </h3>
                </Col>
                {qa?.stid__status !== "Cancelled" && (
                    <Col xs={12} md={2} className="mb-2 mb-md-0">
                    {showButton && (
                        <div> 
                        {qa?.stid__status === 'Completed' ? (
                            <Button variant='success' className="w-sm-100" disabled>Added to FAQ</Button>
                        ) : (
                            <Button variant='success' className="w-sm-100" onClick={handlePublish}>Add to FAQ</Button>
                        )} 
                        </div>
                    )}
                    </Col>
                )}
                <Col xs={12} md={3}>
                    {showButton && (
                    <div> 
                        {qa?.stid__status === "Cancelled" ? (
                        <Button variant='danger' className="w-sm-100" disabled>MARKED AS SPAM</Button>
                        ) : (
                        <Button variant='danger' className="w-sm-100" onClick={handleSoftDelete}>Mark as Spam</Button>
                        )} 
                    </div>
                    )}
                </Col>
            </Row>
            <Row>
            <Col sm={5} className="mt-3"> 
                <h3 className="fw-bold">{qa?.ticket}</h3>
            </Col>
            
            <Col sm={5} className="mt-3">
            <Stack className="border-start border-secondary ps-2 my-2">
                <h3 className="me-3 fw-bold">Associated Topic:</h3>
                <h4 className="fw-bold">{qa?.cid__cat ?? qa?.cid?.cat}</h4>
            </Stack>    
           
            </Col>
            </Row>
                {
                    !window.location.href.includes('send_to=') && !window.location.href.includes('@') &&
                    
                    (<>
                        <Col className="border-dark border-bottom border-top">
                        <Stack className="border-start border-secondary ps-2 my-2">
                            <h5>Status & Progress: {showButton && <span style={{float: 'right'}}><EditIcon
                                fontSize="small"
                                className="clickable"
                                data-testid="edit-status-button"
                                onClick={() => setSelectStatus(!selectStatus)}
                            /></span>}</h5>
                            {selectStatus ?
                                <Form.Group controlId="react-select-req-input" className="my-2">
                                    <Select
                                        defaultValue={qaStatuses?.find(status => status.value === qa?.stid__stid)}
                                        options={qaStatuses}
                                        onChange={(e) =>
                                            handleChange({
                                                target: {
                                                    name: "status",
                                                    value: e.label,
                                                },
                                            })
                                        }
                                    />
                                </Form.Group> 
                                :
                                <h5 className="text-secondary">{qa?.stid__status ?? qa?.stid?.status}</h5>
                            }
                        </Stack>
                        </Col>
                        <Col className="border-dark border-bottom border-top">
                            <Stack className="border-start border-secondary ps-2 my-2">
                                <h5>Agent: 
                                    {showButton && 
                                        <span style={{float: 'right'}}>
                                            <EditIcon
                                                fontSize="small"
                                                className="clickable"
                                                onClick={() => setSelectAgent(!selectAgent)}
                                                data-testid="edit-agent-button"
                                            />
                                        </span>
                                    }
                                </h5>
                                {selectAgent ? 
                                    <Form.Group controlId="react-select-req-input" className="my-2">
                                        <Select
                                            defaultValue={agents?.find(agent => agent.value === qa?.agent__id)}
                                            options={agents}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: {
                                                        name: "agent",
                                                        value: e.value,
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    :
                                    <h5 className="text-secondary">{qa?.agent__last_name ?? qa?.agent?.last_name}</h5>
                                }
                            </Stack>
                        </Col>
                        <Col className="border-dark border-bottom border-top">
                            <Stack className="border-start border-secondary ps-2 my-2">
                                <h5>Deadline: {showButton && <span style={{float: 'right'}}><EditIcon
                                    fontSize="small"
                                    className="clickable"
                                    onClick={() => setSelectDeadline(!selectDeadline)}
                                /></span>}</h5>
                                {selectDeadline ? 
                                    <Form.Group controlId="react-select-req-input" className="my-2">
                                        <Form.Control 
                                            type="date"
                                            name="deadline" 
                                            onChange={handleChange} 
                                            value={moment.utc(qa?.deadline).format('YYYY-MM-DD')} 
                                            required
                                        />
                                    </Form.Group>
                                    :
                                    <h5 className="text-secondary">{qa?.deadline ? moment.utc(qa.deadline).format('YYYY-MM-DD') : "Not Set"}</h5>
                                }
                            </Stack>
                        </Col>
                        <Col className="border-dark border-bottom border-top">
                            <Stack className="border-start border-secondary ps-2 my-2">
                                <h5>Category: {showButton && <span style={{float: 'right'}}><EditIcon
                                    fontSize="small"
                                    className="clickable"
                                    onClick={() => setSelectCategory(!selectCategory)}
                                /></span>}</h5>
                                {selectCategory ? 
                                    <Form.Group controlId="react-select-req-input" className="my-2">
                                        <Select
                                            defaultValue={qaCategories?.find(category => category.value === qa?.cid__cid)}
                                            options={qaCategories}
                                            onChange={(e) =>
                                            handleChange({
                                                target: {
                                                    name: "cid",
                                                    value: e.value,
                                                },
                                            })
                                        }
                                        />
                                    </Form.Group>
                                    :
                                    <h5 className="text-secondary">{qa?.cid__cat ?? qa?.cid?.cat}</h5>
                                }
                            </Stack>
                        </Col>
                    </>)}
         
        </Row>
    );
}
