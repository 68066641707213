import { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import NewsCarousel from './NewsCarousel/NewsCarousel';
import EventsCarousel from './EventsCarousel/EventsCarousel'
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import HomepageHeader from "./HomepageHeader";
import StatusContainer from './StatusContainer'
import Cards from "./Cards"
import './Homepage.css'
import axios from 'axios';

export default function Homepage({setOverlayActive, setOverlayText}) {

  const runOnce = useRef()

  useEffect(() => {
    document.title = 'NIAP - Homepage';
  }, []);

  useEffect(() => {
    if (runOnce.current !== "Has Run") {
      axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/fetch_from_ccp`)
      .then(response => {console.log(response)})
      .catch(error => {console.log(error)})
      runOnce.current = "Has Run"
    }
  }, [])

  const responsiveCarousel = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  }
  
  return (
    <>
      <div className='subheader'>
        <HomepageHeader />
      </div>
      <div className='homepage-status-container'>
        <StatusContainer />
      </div>
      <div className='homepage-cards'>
        <Cards />
      </div>
      <Container fluid className='bg-gray py-5'>
        <Row className='ms-5 pb-2 justify-content-between'>
          <Col xs={12} sm={12} md={5} className='d-flex'>
            <h5 className='me-5'><strong>ANNOUNCEMENTS, NEWS & UPDATES</strong></h5>
            <Link to="/announcements" className='link'><p><u>View All News</u></p></Link>
          </Col>
          {/* <Col xs={12} sm={12} md={5} lg={4} xl={3} className='d-flex'>
            <label htmlFor="updates" className="visually-hidden">SIgn up for updates</label>
            <input id="updates" type="email" className='form-control border border-dark rounded-0 me-3' placeholder="Sign up for updates" />
            <Button type="submit" className='btn-warning rounded-pill p-0 me-3'>Submit</Button>
          </Col> */}
        </Row>
        <NewsCarousel responsiveCarousel={responsiveCarousel} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
        <Stack direction="horizontal" className='mt-4 ms-5'>
        <h5 className='me-5'><strong>EVENTS</strong></h5>
          <Link to="/events" className='link'><p><u>View All Events</u></p></Link>
        </Stack>
        <EventsCarousel responsiveCarousel={responsiveCarousel} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
      </Container>
    </>
  )
}
