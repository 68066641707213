import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import AddIcon from "@mui/icons-material/Add"
import { useCookies } from "react-cookie"
import axios from "axios"

const CreateFolder = ({show, handleClose, currentFolder, setUpdate, currentPage, setAlert}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    let csrfToken = cookies['csrftoken']
    const [folders, setFolders] = useState([])
    const [newFolder, setNewFolder] = useState({})

    const loadFolders = () => {
        axios.get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_folders/?page=${currentPage}`,
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
            }
        ).then(response => {
            setFolders(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        loadFolders()
    }, [])

    useEffect(() => {
        setNewFolder({...newFolder, parent: currentFolder?.file_id})
    }, [currentFolder])

    const handleChange = (event) => {
        setNewFolder({...newFolder, [event.target.name]: event.target.value})
    }

    const handleReset = () => {
        setNewFolder({})
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation()
        }
        axios.post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/`,
            {...newFolder, file_type: currentPage, isFolder: true},
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                  'X-CSRFToken': csrfToken, 
                },
            }
        ).then(response => {
            loadFolders()
            setUpdate(response.data.file_id)
            handleClose()
            setAlert({message: "Folder created successfully!", variant: "success"})
        }).catch(error => {
            console.log(error)
            setAlert({message: "Folder creation failed. Please try again.", variant: "danger"})
        })
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title><AddIcon />&nbsp;Create New Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="parent_folder">
                        <Form.Label>Parent Folder</Form.Label>
                        <Form.Select name="parent" onChange={handleChange} defaultValue={currentFolder?.file_id}>
                            <option value={null}>Select a Parent</option>
                            {folders.map(folder => (
                                <option key={folder.file_id} value={folder.file_id}>{folder.file_location}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="file_name">
                        <Form.Label>Folder Name</Form.Label>
                        <Form.Control type="text" name="file_name" onChange={handleChange} required />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>Create</Button>
                    <Button variant="primary" onClick={handleReset}>Reset</Button>
                    <Button variant="primary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default CreateFolder