import { Card, Button, Stack, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../Products.css'
import moment from 'moment'

export default function ProductCard({ card, projpps, ccPps }) {

    const getStatusColor = () => {
        if(card?.certification_status === "Certified" && card?.status !== "Archived" && !card?.is_archived){
            return 'bg-green'
        } else if(card?.is_archived || card?.status === "Archived"){
            return 'bg-archived'
        } else {
            return 'bg-yellow'
        }
    }

    const getCardName = () => {
        if(card?.certification_status === "Certified" && card?.status !== "Archived" && !card?.is_archived){
            return "Certified"
        } else if(card?.is_archived || card?.status === "Archived"){
            return "Archived"
        } else {
            return card?.status
        }
    }

    return (
        <Card className="shadow bg-product product-card">
            <p className={'text-center text-uppercase ' + getStatusColor()}>
                {getCardName()}
            </p>
            <Card.Body>
                <Card.Text className='d-flex px-3'>
                    <small>{card?.vendor_id_name}</small>
                </Card.Text>
                <h4 className='px-3 pb-3' data-testid="productCardName"><strong>{card?.product_name}</strong></h4>
                <div className='d-flex px-3 my-3'>
                    {/* {(card?.is_archived || card?.status === "Archived")&& <Stack className='card-info'>
                        <p className='mb-0'>Archived Date</p>
                        <strong>{card?.sunset_date ? moment.utc(card?.sunset_date).format('MM/DD/YYYY'): "Pending"}</strong>
                    </Stack>} */}
                    {card?.certification_date && 
                        <Stack className='card-info'>
                            <p className='mb-0'>Certification Date</p>
                            <strong>{moment.utc(card?.certification_date).format('MM/DD/YYYY')}</strong>
                        </Stack>
                    }
                    {card.status !== "Archived" && !card.is_archived && card?.status !== "In Progress" &&
                        <Stack className='card-info'>
                            <p className='mb-0'>Assurance Maintenance Date</p>
                            <strong>{moment.utc(card?.am_sort).format('MM/DD/YYYY')}</strong>
                        </Stack>
                    }
                    {card?.status === "In Progress" && 
                        <Stack className='card-info'>
                            <p className='mb-0'>Kickoff Date</p>
                            <strong>{moment.utc(card?.kicked_off_date).format('MM/DD/YYYY')}</strong>
                        </Stack>
                    }
                    <Stack className='card-info'>
                        <p className='mb-0'>Conformance Claims:</p>
                        <ListGroup>
                            { card?.from_cc_portal ? ccPps && ccPps[card?.product_id]?.map((projpp, idx) => (
                                <OverlayTrigger
                                key={idx}
                                placement="left"
                                overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
                                >
                                    <div key={idx}>
                                        <Link to={`/protectionprofiles/${projpp?.pp_id}`}>
                                            <strong className='py-0 my-0 overflow-ellipsis'>{projpp?.pp_short_name ? projpp?.pp_short_name : projpp?.pp_name}</strong>
                                        </Link>
                                    </div>
                                </OverlayTrigger>
                            ))
                            : projpps && projpps[card?.product_id]?.map((projpp, idx) => (
                                <OverlayTrigger
                                key={idx}
                                placement="left"
                                overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
                                >
                                    <div key={idx}>
                                        <Link to={`/protectionprofiles/${projpp?.id}`}>
                                            <strong className='py-0 my-0 overflow-ellipsis'>{projpp?.pp_short_name ? projpp?.pp_short_name : projpp?.pp_name}</strong>
                                        </Link>
                                    </div>
                                </OverlayTrigger>
                            ))}
                        </ListGroup>
                    </Stack>
                </div>
                <div className='d-flex px-3 my-3'>
                    <Stack className='card-info'>
                        <p className='mb-0'>CCTL</p>
                        <strong>{card?.assigned_lab_name}</strong>
                    </Stack>
                    {!card?.v_id ?
                        <div></div>
                        :
                        <Stack className='card-info'>
                            <p className='mb-0'>VID</p>
                            <strong>{card?.v_id}</strong>
                        </Stack>
                    }
                </div>
                <Card.Text className='d-flex flex-wrap px-3 my-3 align-items-center'>
                    {card?.submitting_country_id_code &&
                        <OverlayTrigger
                            placement='bottom'
                            overlay={<Tooltip id="button-tooltip-2">{card?.submitting_country_id_name}</Tooltip>}
                        >
                            {({ ref, ...triggerHandler }) => (
                                <img ref={ref} {...triggerHandler} src={require(`../../../images/flags2/${card?.submitting_country_id_code?.toLowerCase()}.png`)} className='me-5' height='50px' alt='Country Flag' />
                            )}
                        </OverlayTrigger>
                    }
                    {card?.status !== "Archived" && !card?.is_archived && card?.certification_status === 'Certified' &&
                        <Link to={
                            !("from_cc_portal" in card) ? 
                            `/products/${card?.product_id}` :
                            `/products/international-product/${card?.product_id}`
                        }>
                            <Button variant='light' className='border-dark ms-auto btn-hover'>Full Details</Button>
                        </Link>
                    }
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
