import { Accordion, Container, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useState } from "react";
import axios from "axios";
import { DocumentInfoModal } from "./DocumentInfoModal";
import { DocumentEditModal } from "./DocumentEditModal";
import { PreviewDocModal } from "./PreviewDocModal";

export default function GenerateDocAccordion({ product, validationTeam, setAlert }) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [documentHtml, setDocumentHtml] = useState("");
  const [document, setDocument] = useState({}); //this is the document to be generated
  const [displayIframe, setDisplayIframe] = useState(false); //this is the preview
  const [showInfoModal, setShowInfoModal] = useState(false); //this is for draft or final certificate
  const [showEditModal, setShowEditModal] = useState(false);

  const documents = [
    { type: "Draft or Final Certificate" },
    { type: "Congratulations Letter", pscode: "CL1", abbrev: "Congrats Ltr" },
    { type: "Termination Warning Letter", pscode: "TWL", abbrev: "Warning Ltr" },
    { type: "Termination Letter 1 - Package Incomplete", pscode: "TL", abbrev: "Term Ltr 1" },
    { type: "Termination Letter 2 - Package Not Submitted", pscode: "TL2", abbrev: "Term Ltr 2" },
    { type: "Withdrawal Letter", pscode: "WDL", abbrev: "Withdrawal Ltr" },
    { type: "IAR Concurrence Letter", pscode: "UAL", abbrev: "IAR Concur Ltr" },
    { type: "IAR Denial Letter", pscode: "URL", abbrev: "IAR Denial Ltr" },
  ];

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleClosePreviewModal = () => {
    setDisplayIframe(false);
  };

  const fetchDocument = async (documentName, preview, certificateReviewOption) => {
    if(product.kicked_off_date === null && documentName?.includes("Termination")) {
      setAlert({ message: "This evaluation has not even begun.", variant: "danger" });
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/generate_document/?document_name=${documentName}&product_id=${product?.product_id}&cert_review_option=${certificateReviewOption}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
          responseType: "blob",
        }
      )
      .then((response) => {
        URL.revokeObjectURL(setDocumentHtml);
        let newDoc = "";
        if (response.data.size > 0) {
          newDoc = URL.createObjectURL(response.data);
        }
        if (preview) {
          //if preview, we want to hide the downloadable toolbar in the iframe
          setDocumentHtml(newDoc + "#toolbar=0");
        } else {
          setDocumentHtml(newDoc);
        }
        setDisplayIframe(true);
        if (showInfoModal) {
          setShowInfoModal(false);
        }
      })
      .catch((error) => {
        setDisplayIframe(false);
        setAlert({ message: "An unexpected error occurred.", variant: "danger" });
      });
  };

  return (
    <Accordion flush className="border border-dark mb-3">
      <PreviewDocModal
        documentHtml={documentHtml}
        setDocumentHtml={setDocumentHtml}
        show={displayIframe}
        handleClose={handleClosePreviewModal}
        setShowEditModal={setShowEditModal}
        document={document}
        product={product}
        setShowInfoModal={setShowInfoModal}
      />
      <DocumentInfoModal
        product={product}
        documentName={document.type}
        validationTeam={validationTeam}
        fetchDocument={fetchDocument}
        show={showInfoModal}
        handleClose={handleCloseInfoModal}
      />
      <DocumentEditModal
        product={product}
        documentName={document.type}
        validationTeam={validationTeam}
        setDocumentHtml={setDocumentHtml}
        setDisplayIframe={setDisplayIframe}
        show={showEditModal}
        handleClose={handleCloseEditModal}
      />
      <Accordion.Item eventKey="1">
        <Accordion.Header className="bg-secondary">
          <h6 className="sidebar-title">Generate a Document</h6>
        </Accordion.Header>
        <Accordion.Body className="bg-light p-1">
          <Container>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <Button
                    variant="link"
                    onClick={() => {
                      setDocument(doc);
                      if (doc?.type === "Draft or Final Certificate") {
                        setShowInfoModal(true);
                      } else {
                        fetchDocument(doc.type, true);
                      }
                    }}
                    style={{ textAlign: "start" }}
                  >
                    {doc.type}
                  </Button>
                </li>
              ))}
            </ul>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
