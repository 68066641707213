import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useCookies } from "react-cookie";
import VendorVerifyPostingModal from "../VendorVerifyPostingModal";
import VendorCheckOutModal from "../VendorCheckOutModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import PostingSubheaderDetails from "../ProductDetailsSubheaderDetails/PostingSubheaderDetails";

export default function PostingHeader({
    product,
    refetch,
    projpps,
    validationReport,
    vendorSignedCheckout,
    pcl, 
    securityTarget, 
    certificate, 
    assuranceActivityReport,
    setOverlayActive,
    setOverlayText,
    setShowCertificate,
}) {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const user = useSelector((state) => state.user.value);
    const [requestWithdrawal, setRequestWithdrawal] = useState(false);
    const [showVerifyPostingModal, setShowVerifyPostingModal] = useState(false);
    const [showModal, setShowModal] = useState(false)

    const handleShowVerifyPostingModal = () => {
        setShowVerifyPostingModal(true);
    };

    const handleCloseVerifyPostingModal = () => {
        setShowVerifyPostingModal(false);
    };

    const handleCreateAlerts = async (groups) => {
        //sending alert to labs, vendors, and NIAP
        for(let group in groups){
            let data = {
                alert_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                alert_type: "Product",
                alert_text: `VID ${product?.v_id} ${
                product?.product_name
                } has been requested for withdrawal.`,
                alert_type_id: product?.product_id,
                active: true,
                alert_to: null,
                alert_source: "Withdrawal Request",
                alert_to_vt: null,
                alert_to_group: null
            }
            if(groups[group] === 'NIAP'){
                data['alert_to_role'] = 'NIAP'
            } else {
                data['alert_to_group'] = groups[group]
            }
            await axios
            .post( 
            `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/`,
            data,
            {
                withCredentials: true,
                headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                },
            }
            )
            .then((response) => {
            console.log("Alert created for Withdrawal Request");
            })
            .catch((error) =>
            console.log("Unable to create alert for Withdrawal Request.", error)
            );
        }
    };

    const handleWithdrawalRequest = () => {
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
                {
                    request_withdrawal: true,
                    request_withdrawal_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                    request_withdrawal_user: user?.id
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                //sending alert to labs, vendors, and NIAP
                handleCreateAlerts([product?.vendor_id?.org_id, product?.assigned_lab?.org_id, 'NIAP'])
                refetch()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const vendorButtons = () => {
        return (
            <>
                <Col md={12} lg={3}>
                {vendorSignedCheckout && Object.keys(vendorSignedCheckout).length > 0 ?
                    <Row>
                        <Col lg={1}>
                            <CheckCircleIcon
                                style={{ color: "var(--bs-green)" }}
                            />
                        </Col>
                        <Col lg={10}>
                            <p className="text-center m-0 small-font">Verified By{" "}
                            {vendorSignedCheckout?.uploaded_by?.first_name}{" "}
                            {vendorSignedCheckout?.uploaded_by?.last_name}{" "}
                            {moment(vendorSignedCheckout?.uploaded_on).format(
                                "MM/DD/YYYY"
                            )}
                            </p>
                        </Col>
                    </Row>
                :
                    <Button 
                        variant="warning" 
                        className="rounded-pill" 
                        onClick={() => setShowModal(true)}
                    >
                        Sign Release Form
                    </Button>
                }
                </Col>
                {product?.pcl_posting_date === null ? (
                    <Col md={12} lg={2}>
                        <Button
                            variant="secondary"
                            className="rounded-pill"
                            onClick={handleShowVerifyPostingModal}
                        >
                            Verify
                        </Button>
                    </Col>
                ) : (
                    <Col md={12} lg={3}>
                        <Row>
                            <Col lg={1}>
                                <CheckCircleIcon
                                    style={{ color: "var(--bs-green)" }}
                                />
                            </Col>
                            <Col lg={10}>
                                <p className="text-center m-0 small-font">
                                    {product?.changes_pcl_posting === null
                                        ? "Verified"
                                        : "Changes Requested"}{" "}
                                    By{" "}
                                    {product?.pcl_posting_verifier?.first_name}{" "}
                                    {product?.pcl_posting_verifier?.last_name}{" "}
                                    {moment(product?.pcl_posting_date).format(
                                        "MM/DD/YYYY"
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        );
    };

    return (
        <Container>
            <VendorVerifyPostingModal
                show={showVerifyPostingModal}
                handleClose={handleCloseVerifyPostingModal}
                product={product}
                refetch={refetch}
            />
            <VendorCheckOutModal 
                show={showModal} 
                handleClose={() => setShowModal(false)} 
                product={product} 
                pcl={pcl} 
                security_target={securityTarget} 
                validation_report={validationReport} 
                certificate={certificate?.certificate} 
                assurance_activity_report={assuranceActivityReport}
                setOverlayActive={setOverlayActive} 
                setOverlayText={setOverlayText} 
            />
            <Row>
                <Col className="px-0" md={12} lg={6}>
                    <h3 className="fw-bold text-primary">
                        EVALUATION PROJECT DETAILS:{" "}
                    </h3>
                    {/* {product?.post_to_iccc && <p className="text-success">This product certificate will be presented at the ICCC</p>} */}
                </Col>
                {vendorButtons()}
            </Row>
            <Row>
                <Col className="px-0">
                    <Row>
                        <h5 className="fw-bold">
                            {product?.vendor_id?.name}
                        </h5>
                    </Row>
                    <Row>
                        <h4 className="fw-bold">
                            {product?.product_name}
                        </h4>
                    </Row>
                </Col>
            </Row>
            <Row className="border-top border-bottom border-dark">
                <Col lg={2} className="mt-3 ">
                    <p>PP Identifiers: </p>
                </Col>
                <Col className="mt-3 ">
                    <ul>
                        {projpps?.map((projpp, idx) => (
                            <li key={idx}>
                                <p>{projpp?.pp?.pp_name}</p>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
            <PostingSubheaderDetails
                product={product}
                validationReport={validationReport}
                projpps={projpps}
                setShowCertificate={setShowCertificate}
            />
        </Container>
    );
}
