import {useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ActionsModal from './ActionsModal'

import PreEvalImage from "./png_1.png"

const PreEvaluation = () => {
    const [action, setAction] = useState(0)
    const [showModal, setShowModal] = useState(false)

    const setActionNumber = (number) => {
        setAction(number)
        setShowModal(true)
    }

    const keyUpSetActionNumber = (event, number) => {
        if (event.key === "Enter" && action !== number) {
            setAction(number)
            setShowModal(true)
        } else {
            setAction(0)
        }
    }

    return (
        <Container fluid>
            <ActionsModal show={showModal} onHide={() => setShowModal(false)} actionNumber={action} />
            <Row className="mt-5 mb-3">
                <Col sm={12}>
                    <h3 className="text-bright-navy">Training Center</h3>
                </Col>
            </Row>
            <Row className="mt-5 mb-3">
                <Col sm={12} className='text-center'>
                    <img src={PreEvalImage} id="ConvertedImage" alt="Pre-Eval Phase" name="RasterImage" border="0" useMap="#visImageMap" />
                    <map name="visImageMap">
                        <area shape="polygon" tabIndex="1" alt="IN DRAFT" origtitle="IN DRAFT" title="IN DRAFT"   coords="244,391,239,398,231,393,229,402,220,401,221,410,212,412,217,420,210,425,217,431,212,439,221,441,220,450,229,449,231,457,239,453,244,460,250,453,258,457,260,449,269,450,268,441,276,439,272,431,279,425,272,420,276,412,268,410,269,401,260,402,258,393,250,398,244,391"   onClick={() => setActionNumber(52)} onKeyUp={(event) => keyUpSetActionNumber(event, 52)} />
                        <area shape="polygon" tabIndex="1" alt="16 Entropy Consultant Review" origtitle="16 Entropy Consultant Review" title="16 Entropy Consultant Review"   coords="498,126,595,126,595,76,498,76,498,126"   onClick={() => setActionNumber(16)} onKeyUp={(event) => keyUpSetActionNumber(event, 16)} />
                        <area shape="polygon" tabIndex="1" alt="18 Resolve Comments" origtitle="18 Resolve Comments" title="18 Resolve Comments"   coords="528,449,595,449,595,390,528,390,528,449"   onClick={() => setActionNumber(18)} onKeyUp={(event) => keyUpSetActionNumber(event, 18)} />
                        <area shape="polygon" tabIndex="1" alt="PRE-EVALUATION" origtitle="PRE-EVALUATION" title="PRE-EVALUATION"   coords="397,101,403,96,396,90,401,82,392,80,393,71,384,72,382,64,374,68,368,61,363,68,355,64,353,72,344,71,345,80,336,82,341,90,334,96,340,101,340,103,336,109,345,111,344,120,353,119,355,128,363,123,368,130,374,123,382,128,384,119,393,120,392,111,401,109,397,103,397,101"   onClick={() => setActionNumber(54)} onKeyUp={(event) => keyUpSetActionNumber(event, 54)} />
                        <area shape="polygon" tabIndex="1" alt="PROPOSED" origtitle="PROPOSED" title="PROPOSED"   coords="101,158,95,164,88,160,86,168,77,168,78,176,70,178,74,186,67,191,74,197,70,204,78,206,77,215,86,214,88,222,95,218,101,225,106,218,114,222,116,214,124,215,124,206,132,204,128,197,134,191,128,186,132,178,124,176,124,168,116,168,114,160,106,164,101,158"   onClick={() => setActionNumber(53)} onKeyUp={(event) => keyUpSetActionNumber(event, 53)} />
                        <area shape="polygon" tabIndex="1" alt="3 Evaluation Proposal" origtitle="3 Evaluation Proposal" title="3 Evaluation Proposal"   coords="126,232,134,236,142,238,150,239,159,238,167,236,174,232,182,228,190,225,199,224,207,225,215,228,223,232,223,179,126,179,126,232"   onClick={() => setActionNumber(3)} onKeyUp={(event) => keyUpSetActionNumber(event, 3)} />
                        <area shape="polygon" tabIndex="1" alt="10 Monitor NIST Evaluation" origtitle="10 Monitor NIST Evaluation" title="10 Monitor NIST Evaluation"   coords="187,691,235,691,241,690,247,688,252,683,256,678,258,672,259,666,258,659,256,653,252,648,247,644,241,641,235,640,187,640,180,641,174,644,169,648,166,653,163,659,162,666,163,672,166,678,169,683,174,688,180,690,187,691"   onClick={() => setActionNumber(10)} onKeyUp={(event) => keyUpSetActionNumber(event, 10)} />
                        <area shape="polygon" tabIndex="1" alt="20 Document Check-in Meeting Minutes" origtitle="20 Document Check-in Meeting Minutes" title="20 Document Check-in Meeting Minutes"   coords="903,342,999,342,999,288,903,288,903,342"   onClick={() => setActionNumber(20)} onKeyUp={(event) => keyUpSetActionNumber(event, 20)} />
                        <area shape="polygon" tabIndex="1" alt="17 EAR acceptable?" origtitle="17 EAR acceptable?" title="17 EAR acceptable?"   coords="739,296,751,287,703,250,655,287,667,296,667,300,672,300,703,323,734,300,739,300,739,296"   onClick={() => setActionNumber(17)} onKeyUp={(event) => keyUpSetActionNumber(event, 17)} />
                        <area shape="polygon" tabIndex="1" alt="15 Entropy Validation Board Reviews EAR" origtitle="15 Entropy Validation Board Reviews EAR" title="15 Entropy Validation Board Reviews EAR"   coords="498,230,595,230,595,170,498,170,498,230"   onClick={() => setActionNumber(15)} onKeyUp={(event) => keyUpSetActionNumber(event, 15)} />
                        <area shape="polygon" tabIndex="1" alt="2" origtitle="2" title="2"   coords="876,218,894,202,876,186,858,202,876,218"   onClick={() => setActionNumber(68)} onKeyUp={(event) => keyUpSetActionNumber(event, 68)} />
                        <area shape="polygon" tabIndex="1" alt="1" origtitle="1" title="1"   coords="373,190,391,174,373,158,355,174,373,190"   onClick={() => setActionNumber(56)} onKeyUp={(event) => keyUpSetActionNumber(event, 56)} />
                        <area shape="polygon" tabIndex="1" alt="5 Evaluation Record" origtitle="5 Evaluation Record" title="5 Evaluation Record"   coords="258,139,266,142,274,144,282,145,291,144,299,142,306,139,314,135,322,133,331,133,339,133,347,135,355,139,355,96,258,96,258,139"   onClick={() => setActionNumber(5)} onKeyUp={(event) => keyUpSetActionNumber(event, 5)} />
                        <area shape="polygon" tabIndex="1" alt="9 Entropy Assessment Report (EAR)" origtitle="9 Entropy Assessment Report (EAR)" title="9 Entropy Assessment Report (EAR)"   coords="460,671,466,674,473,677,480,677,487,677,494,674,500,671,506,667,513,665,520,664,527,665,534,667,540,671,540,605,460,605,460,671"   onClick={() => setActionNumber(9)} onKeyUp={(event) => keyUpSetActionNumber(event, 9)} />
                        <area shape="polygon" tabIndex="1" alt="4 Set Up Evaluation" origtitle="4 Set Up Evaluation" title="4 Set Up Evaluation"   coords="126,146,223,146,223,96,126,96,126,146"   onClick={() => setActionNumber(4)} onKeyUp={(event) => keyUpSetActionNumber(event, 4)} />
                        <area shape="polygon" tabIndex="1" alt="8 Develop Entropy Assessment Report" origtitle="8 Develop Entropy Assessment Report" title="8 Develop Entropy Assessment Report"   coords="314,669,411,669,411,613,314,613,314,669"   onClick={() => setActionNumber(8)} onKeyUp={(event) => keyUpSetActionNumber(event, 8)} />
                        <area shape="polygon" tabIndex="1" alt="7 Security Target (ST)" origtitle="7 Security Target (ST)" title="7 Security Target (ST)"   coords="370,586,381,590,393,591,404,591,416,590,427,586,439,583,450,582,462,582,474,583,485,586,485,551,370,551,370,586"   onClick={() => setActionNumber(7)} onKeyUp={(event) => keyUpSetActionNumber(event, 7)} />
                        <area shape="polygon" tabIndex="1" alt="6 Develop Security Target" origtitle="6 Develop Security Target" title="6 Develop Security Target"   coords="223,595,344,595,344,548,223,548,223,595"   onClick={() => setActionNumber(6)} onKeyUp={(event) => keyUpSetActionNumber(event, 6)} />
                        <area shape="polygon" tabIndex="1" alt="22 Begin Evaluation" origtitle="22 Begin Evaluation" title="22 Begin Evaluation"   coords="1009,464,1009,423,1008,417,1006,413,1002,408,996,405,990,403,984,402,977,403,971,405,966,408,962,413,959,417,958,423,958,464,959,469,962,474,966,478,971,482,977,484,984,484,990,484,996,482,1002,478,1006,474,1008,469,1009,464"   onClick={() => setActionNumber(22)} onKeyUp={(event) => keyUpSetActionNumber(event, 22)} />
                        <area shape="polygon" tabIndex="1" alt="13 Review Check-in Package" origtitle="13 Review Check-in Package" title="13 Review Check-in Package"   coords="388,326,468,326,468,247,388,247,388,326"   onClick={() => setActionNumber(13)} onKeyUp={(event) => keyUpSetActionNumber(event, 13)} />
                        <area shape="polygon" tabIndex="1" alt="2 Propose an Evaluation" origtitle="2 Propose an Evaluation" title="2 Propose an Evaluation"   coords="126,458,223,458,223,401,126,401,126,458"   onClick={() => setActionNumber(2)} onKeyUp={(event) => keyUpSetActionNumber(event, 2)} />
                        <area shape="polygon" tabIndex="1" alt="19 Check-in Meeting" origtitle="19 Check-in Meeting" title="19 Check-in Meeting"   coords="808,584,875,584,875,203,808,203,808,584"   onClick={() => setActionNumber(19)} onKeyUp={(event) => keyUpSetActionNumber(event, 19)} />
                        <area shape="polygon" tabIndex="1" alt="11 Prepare Check-in Package" origtitle="11 Prepare Check-in Package" title="11 Prepare Check-in Package"   coords="379,508,476,508,476,436,379,436,379,508"   onClick={() => setActionNumber(11)} onKeyUp={(event) => keyUpSetActionNumber(event, 11)} />
                        <area shape="polygon" tabIndex="1" alt="1 Start Evaluation Preparation" origtitle="1 Start Evaluation Preparation" title="1 Start Evaluation Preparation"   coords="153,595,153,547,152,541,149,535,145,530,140,526,134,524,127,523,121,524,115,526,109,530,105,535,103,541,102,547,102,595,103,602,105,608,109,613,115,616,121,619,127,620,134,619,140,616,145,613,149,608,152,602,153,595"   onClick={() => setActionNumber(1)} onKeyUp={(event) => keyUpSetActionNumber(event, 1)} />
                        <area shape="polygon" tabIndex="1" alt="14 Package acceptable?" origtitle="14 Package acceptable?" title="14 Package acceptable?"   coords="588,303,610,287,561,250,513,287,535,303,535,306,539,306,561,323,584,306,588,306,588,303"   onClick={() => setActionNumber(14)} onKeyUp={(event) => keyUpSetActionNumber(event, 14)} />
                        <area shape="polygon" tabIndex="1" alt="12 Check-in Package" origtitle="12 Check-in Package" title="12 Check-in Package"   coords="289,224,295,227,302,230,309,230,316,230,323,227,329,224,335,220,342,218,349,217,356,218,363,220,369,224,369,170,289,170,289,224"   onClick={() => setActionNumber(12)} onKeyUp={(event) => keyUpSetActionNumber(event, 12)} />
                        <area shape="polygon" tabIndex="1" alt="21 List product as “In Evaluation”" origtitle="21 List product as “In Evaluation”" title="21 List product as “In Evaluation”"   coords="903,131,999,131,999,84,903,84,903,131"   onClick={() => setActionNumber(21)} onKeyUp={(event) => keyUpSetActionNumber(event, 21)} />
                    </map>
                </Col>
            </Row>
        </Container>
    )

}

export default PreEvaluation