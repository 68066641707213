import { Modal, Form, Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import axios from "axios";

export default function VendorVerifyPostingModal({
    show,
    handleClose,
    product,
    refetch,
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [modalInfo, setModalInfo] = useState([]);
    const user = useSelector((state) => state.user.value);

    const handleChange = (e) => {
        setModalInfo({ ...modalInfo, [e.target.name]: e.target.value });
    };

    //send to assigned lab if changes needed
    const handleChangesAlert = () => {
        axios
            .post(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/`,
                {
                    alert_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                    alert_type: "Product",
                    alert_text: "Changes Requested for PCL Posting by Vendor",
                    alert_type_id: product?.product_id,
                    active: true,
                    alert_to: null,
                    alert_to_group: product?.assigned_lab?.org_id,
                    alert_to_vt: null,
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                handleClose();
                refetch();
            })
            .catch((error) =>
                console.log("Unable to create alert for PCL Posting", error)
            );
    };

    //send to validators and vendor if no changes needed
    const handleVerifyAlert = () => {
        axios
            .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/?team=Validation&product=${product?.product_id}`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                response.data.forEach((validator) => {
                    axios
                        .post(
                            `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/`,
                            {
                                alert_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                                alert_type: "Product",
                                alert_text:
                                    "PCL Posting by Verified by Vendor.",
                                alert_type_id: product?.product_id,
                                active: true,
                                alert_to: validator?.user_id?.id,
                                alert_to_group: null,
                                alert_to_vt: null,
                            },
                            {
                                withCredentials: true,
                                headers: {
                                    "X-CSRFToken": csrfToken,
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            console.log("alert sent to validator");
                        })
                        .catch((error) =>
                            console.log(
                                "Unable to create alert for PCL Posting",
                                error
                            )
                        );
                });
                axios
                    .post(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/`,
                        {
                            alert_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                            alert_type: "Product",
                            alert_text: "PCL Posting by Verified.",
                            alert_type_id: product?.product_id,
                            active: true,
                            alert_to: null,
                            alert_to_group: product?.vendor_id?.org_id,
                            alert_to_vt: null,
                        },
                        {
                            withCredentials: true,
                            headers: {
                                "X-CSRFToken": csrfToken,
                                Authorization: `Token ${authToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        handleClose();
                        refetch();
                    })
                    .catch((error) =>
                        console.log(
                            "Unable to create alert for PCL Posting",
                            error
                        )
                    );
            })
            .catch((error) => {
                console.log("error sending alerts");
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
                {
                    changes_pcl_posting: modalInfo.changes_rationale
                        ? modalInfo.changes_rationale
                        : null,
                    pcl_posting_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                    pcl_posting_verifier: user.id,
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                if (modalInfo.changes_rationale) {
                    console.log("in if");
                    handleChangesAlert();
                } else {
                    console.log("in else");
                    handleVerifyAlert();
                }
            })
            .catch((error) => console.log(error));
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header
                    closeButton
                    onClose={handleClose}
                    className="border-0"
                >
                    <Modal.Title color="primary">
                        VERIFY FOR PCL POSTING
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="my-3" controlId="changes">
                        <Container>
                            <Row>
                                <Col sm={4}>
                                    <Form.Check
                                        inline
                                        label="Changes Needed"
                                        name="changes"
                                        value={true}
                                        type="radio"
                                        onChange={handleChange}
                                        id="changes1"
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Form.Check
                                        inline
                                        label="No Changes Needed"
                                        name="changes"
                                        value={false}
                                        type="radio"
                                        onChange={handleChange}
                                        id="changes2"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Form.Group>
                    {modalInfo.changes === "true" && (
                        <Form.Group className="mt-5 mx-4">
                            <Form.Label as="h6" className="text-bright-navy">
                                CHANGES NEEDED
                            </Form.Label>
                            <Form.Control
                                rows={3}
                                as="textarea"
                                name="changes_rationale"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    )}
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={
                            modalInfo.length === 0 ||
                            (modalInfo.changes === "true" &&
                                !modalInfo.changes_rationale)
                        }
                    >
                        Send
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
