import { Alert, Container } from "react-bootstrap";
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import Filters from "./Filters";
import OrganizationTable from "./OrganizationTable";
import OrganizationForm from "./OrganizationForm";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useInterval } from "../../hooks/useInterval";

export default function OrganizationManagerView(){
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies['csrftoken'];
  const [show, setShow] = useState(false);
  const role = useSelector(state => state.role.value);
  const [currentOrg, setCurrentOrg] = useState({});
  const [isArchivedView, setIsArchivedView] = useState(false);
  const [alert, setAlert] = useState({message: '', type: ''});
  const tableRef = useRef();

  useInterval(() => {
      tableRef.current.onQueryChange();
  }, 300000); //5 min

  const fetchOrgs = useCallback(() => {
    tableRef.current.onQueryChange();
  }, []);

  useEffect(() => {
    fetchOrgs()
  }, [isArchivedView])

  const handleArchive = async (e, rowData, archived) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/${rowData.org_id}/`,
        {
          archived: archived
        },
        {
          withCredentials: true,
          headers: { 
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken 
          },
        }
      )
      .then((response) => {
        tableRef.current.onQueryChange();
        setAlert({message: `Organization successfully ${archived ? 'Archived' : 'Activated'}!`, type: 'success'})
      })
      .catch((error) => {
        console.log("Unable to update organization: " + error)
        setAlert({message: 'Error archiving organization.', type: 'danger'})});
  };

  const handleShowModal = (e, rowData) => {
    setCurrentOrg({ ...rowData });
    setShow(true);
  };

  return (
    <Container fluid>
      {alert?.message !== "" &&
        <Alert variant={alert.type} onClose={() => setAlert({message: '', type: ''})} dismissible>
          {alert.message}
        </Alert>
      }
      <Filters
        isArchivedView={isArchivedView}
        setIsArchivedView={setIsArchivedView}
        handleShowModal={handleShowModal}
        role={role}
      />
      <OrganizationTable
        handleShowModal={handleShowModal}
        handleArchive={handleArchive}
        role={role}
        isArchivedView={isArchivedView}
        tableRef={tableRef}
      />
      <OrganizationForm
        show={show}
        org={currentOrg}
        handleClose={() => {
          setShow(false);
          setCurrentOrg({});
          fetchOrgs();
        }}
        setAlert={setAlert}
      />
    </Container>
  );
};