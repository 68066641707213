import { useEffect, useState, useRef } from 'react';
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import ReactSelect from '../ReactSelect';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCookies } from "react-cookie";
import axios from 'axios';

export default function LetterOfIntentForm({ show, setShowModal, loadCaptcha, setOverlayActive, setOverlayText, setAlert}) {
    const [cookies] = useCookies()
    let csrfToken = cookies['csrftoken']
    const [letter, setLetter] = useState({});
    const [states, setStates] = useState([]);
    const [validated, setValidated] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);
    const siteKey = '6Lf29aAjAAAAAIagG9XUSNUz_nXKnwxrK_fDcGf5';
    const recaptchaRef = useRef(null)
    
    const [stateValid, setStateValid] = useState(true);

    const handleChange = (e) => {
      setLetter({...letter, [e.target.name]: e.target.value});
    }

    const testSelects = () => {
      if('state' in letter){
        return true;
      } else {
        setStateValid(false)
        return false; 
      }
    }

    const handleSubmit = async (e) => {
      const form = e.currentTarget;
      testSelects()
      if (form.checkValidity() === false || testSelects() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else if (window.grecaptcha.getResponse() === '') {
        e.preventDefault();
        e.stopPropagation();
        setInvalidToken(true);
      } else {
        e.preventDefault();
        const token = e.target['g-recaptcha-response'].value;
        setOverlayText("Redirecting")
        setOverlayActive(true);
        setShowModal(false)
        await axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}cctl/loi/sign_document/`, {...letter, recaptcha_token: token}, 
          {
            withCredentials: true, 
            headers: {
              'X-CSRFToken': csrfToken,
            }
          })
          .then((response) => {
            setOverlayActive(false);
            localStorage.setItem('loi', response.data.loi_id); //we are storing the loi_id in local storage so we can retrieve it later for sending the signed copy to the user
            window.location.href = response.data.redirect_url;
          })
          .catch((error) => {
            console.log('Unable to submit loi: ' + error);
            setAlert({type: 'danger', message: 'Unable to submit Letter of Intent. Please try again.'})
          });

      }
      setValidated(true);
    };

    const handleClose = () => setShowModal(false);

    const showCaptcha = () => {
      // Regenerate recaptcha on opening modal
      try {
        window.grecaptcha.render('form-recaptcha', {
          sitekey: siteKey, ref: recaptchaRef
        });
      } catch (error) {
        console.log(error)
      }
    }

  const loadStates = () =>{
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}address/states/get_all_states`)
      .then((response) => {
        const states = response.data.map(data => {
          return {value: data.us_state_id, label: data.us_state_name}
        })
        setStates(states)
      })
      .catch((error) => console.log(error))
  }
    useEffect(() => {
      loadStates()
    }, [])

    useEffect(() => {
      setLetter({});
      setValidated(false);
    }, [show])

    return (
      <Modal size="lg" show={show} onEntered={loadCaptcha ? showCaptcha : ''} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>CCTL: <span className='text-primary'>LETTER OF INTENT</span></Modal.Title>
            </Modal.Header>
          <Form onSubmit={handleSubmit} validated={validated} noValidate>
            <Modal.Body>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId='name'>
                    <Form.Label>Name:*</Form.Label>
                    <Form.Control type="text" name="name" onChange={handleChange} placeholder="Name" autoFocus required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId='title'>
                    <Form.Label>Title:*</Form.Label>
                    <Form.Control type="text" name="title" onChange={handleChange} placeholder="Title" required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId='company_name'>
                    <Form.Label>Company Name/Organization:*</Form.Label>
                    <Form.Control type="text" name="company_name" onChange={handleChange} placeholder="Name" required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId='telephone_number'>
                    <Form.Label>Telephone Number:*</Form.Label>
                    <Form.Control type="text" name="telephone_number" onChange={handleChange} placeholder="111-111-1111" required />
                    <Form.Text className="text-muted">Specify country code, DSN, comm., etc., as applicable</Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId='email_address'>
                    <Form.Label>E-mail Address:*</Form.Label>
                    <Form.Control type="email" name="email_address" onChange={handleChange} placeholder="Email" required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId='token'>
                    <ReCAPTCHA
                      id='form-recaptcha'
                      sitekey={siteKey}
                      ref={recaptchaRef}
                      onExpired={showCaptcha}
                    />
                    {invalidToken && (
                      <p className='text-danger'>Please Confirm.</p>
                    )}
                    <Form.Text className="text-muted">Required to submit any information via this form.</Form.Text>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId='address'>
                    <Form.Label>Address:*</Form.Label>
                    <Form.Control type="text" name="address" onChange={handleChange} placeholder="Address" required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId='city'>
                    <Form.Label>City:*</Form.Label>
                    <Form.Control type="text" name="city" onChange={handleChange} placeholder="City" required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="react-select-3-input">
                    <Form.Label>State*</Form.Label>
                    <ReactSelect options={states} newObj={letter} handleChange={handleChange} name="state" isValid={stateValid} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId='additional_notes'>
                    <Form.Label className='text-secondary fw-bold'>Remarks, Comments, Questions:</Form.Label>
                    <Form.Control as="textarea" rows={6} name="additional_notes" onChange={handleChange}  />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <p className='text-secondary fw-bold'>Notice: Submitting this form will redirect you to DocuSign.</p>
              </Row>
              <Button variant="warning" onClick={handleClose}>Cancel</Button>
              <Button variant="warning" type='submit'>Submit</Button>
            </Modal.Footer>
          </Form>
      </Modal>
    )
}

