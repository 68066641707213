import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";

export default function EcrCommentsCount({ ecrCounts }) {
  const permissions = useSelector((state) => state.role.value);

  return (
    <Col xs={12} sm={9} className="mt-xs-2">
      <div className="d-flex flex-wrap justify-content-start">
        <span className="border p-2 me-2 mb-2">Resolved: {ecrCounts?.resolved}</span>
        {permissions?.role_type !== "Lab" && (
          <>
            {ecrCounts?.Response_to_validator > 0 && (
              <span className="border p-2 me-2 mb-2">
                Response to Validator: {ecrCounts.Response_to_validator}
              </span>
            )}
            {ecrCounts?.Response_to_lab > 0 && (
              <span className="border p-2 me-2 mb-2">
                Response to Lab: {ecrCounts.Response_to_lab}
              </span>
            )}
            {ecrCounts?.draft > 0 && (
              <span className="border p-2 me-2 mb-2">Draft: {ecrCounts.draft}</span>
            )}
            {ecrCounts?.cancelled > 0 && (
              <span className="border p-2 me-2 mb-2">Cancelled: {ecrCounts.cancelled}</span>
            )}
            {ecrCounts?.proposed > 0 && (
              <span className="border p-2 me-2 mb-2">Proposed: {ecrCounts.proposed}</span>
            )}
            {ecrCounts?.released > 0 && (
              <span className="border p-2 me-2 mb-2">Released: {ecrCounts.released}</span>
            )}
            {ecrCounts?.On_Hold > 0 && (
              <span className="border p-2 me-2 mb-2">On Hold: {ecrCounts.On_Hold}</span>
            )}
            {ecrCounts?.concealed > 0 && (
              <span className="border p-2 me-2 mb-2">Concealed: {ecrCounts.concealed}</span>
            )}
            {ecrCounts?.Pending_Final_Review > 0 && (
              <span className="border p-2 me-2 mb-2">
                Pending Final Review: {ecrCounts.Pending_Final_Review}
              </span>
            )}
            {ecrCounts?.reviewed > 0 && (
              <span className="border p-2 me-2 mb-2">Reviewed: {ecrCounts.reviewed}</span>
            )}
          </>
        )}
        {/* <span className='border p-2 me-2 mb-2'>Total: {ecrCounts?.total}</span> */}
      </div>
    </Col>
  );
}
