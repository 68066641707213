import { Col, Row, Card, Stack } from 'react-bootstrap'
import '../Products/Products.css'

export default function CCTLCard({ card }) {

    const statusColors = {
        'Candidate': 'bg-orange',
        'Certified': 'bg-green'
    }

    return (
        <Card className="shadow bg-product product-card">
            <p className={'text-center text-uppercase ' + statusColors[card?.status_id?.status_name]}>
                {card?.status_id?.status_name === 'Active' ? "Approved" : card?.status_id?.status_name}</p>
            <Card.Body>
                <a href={card?.cctl_url}><Card.Title className='d-flex fw-bold'>{card?.cctl_name}</Card.Title></a>
                <Row>
                    <Col sm={6}>
                    {card?.address_1 &&
                        <>{card?.address_1}</>
                    }
                    {card?.address_2 &&
                        <>{" " + card?.address_2}</>
                    }
                    {card?.city &&
                        <>{" " + card?.city}</>
                    }
                    {card?.state_id &&
                        <>{", " + card?.state_id.us_state_abbrv}</>
                    }
                    {card?.zip &&
                        <>{" " + card?.zip}</>
                    }
                    <p className='fw-bold mt-3 mb-1'>Lab Contact</p>
                    {card?.poc &&
                        <p className='mb-1'>{card?.poc}</p>
                    }
                    {card?.work_phone && 
                        <p className='mb-1'>{card?.work_phone} (Phone)</p>
                    }
                    {card?.poc_email &&
                        <p className='text-decoration-underline my-0' style={{ color: "var(--bs-blue)" }}>{card?.poc_email}</p>
                    }
                    </Col>
                    <Col sm={6}>
                        <Stack>
                            <p className='fw-bold mb-0'>NVLAP Lab Code</p>
                            <p>{card?.nvlap_lab_code}</p>
                            <p className='fw-bold mb-0'>Test Methods</p>
                            <p>{card?.lab_test_methods}</p>
                        </Stack>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
