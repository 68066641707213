import { useState } from "react";
import { useCookies } from "react-cookie";
import { Accordion, Modal, Button } from "react-bootstrap";
import AddPCMUpdateModal from "./AddPCMUpdateModal";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";


export default function PCMUpdatesSection({ pcmUpdates, pcm, currentUser, refetch }) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [showPCMUpdateModal, setShowPCMUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPcmUpdate, setSelectedPcmUpdate] = useState({});

    const handleDeleteUpdate = () => {
        axios
        .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/update/${selectedPcmUpdate?.upid}/`,
            {
                active: false,
                editor: currentUser?.id,
                moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
            },
            {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                },
            }
        )
        .then((response) => {
            setShowDeleteModal(false)
            refetch()
        })
        .catch((error) => console.log("error sending email: " + error));
    };

    return (
        <>
            <AddPCMUpdateModal
                show={showPCMUpdateModal}
                handleClose={() => {
                    refetch()
                    setSelectedPcmUpdate({})
                    setShowPCMUpdateModal(false)
                }}
                pcm={pcm}
                user={currentUser}
                editPcmUpdate={
                    Object.keys(selectedPcmUpdate)?.length > 0
                        ? selectedPcmUpdate
                        : null
                }
            />
            <Accordion className="my-4" flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h6 className="fw-bold text-primary mt-3">
                            ITEM UPDATES
                        </h6>
                        <span className="border my-2 mx-4 p-2">
                            Total: {pcmUpdates.length}
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="text-end">
                            <button
                                className="btn btn-link d-inline-block"
                                style={{ minWidth: 10 }}
                                onClick={() => setShowPCMUpdateModal(true)}
                            >
                                Add Update
                            </button>
                        </div>
                        {showDeleteModal && (
                            <Modal
                                size="md"
                                show={showDeleteModal}
                                onHide={() => setShowDeleteModal(false)}
                            >
                                <Modal.Header closeButton className="border-0">
                                    <Modal.Title>Delete PCM Update</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4 className="text-center">
                                        Are You Sure You Want to Delete this Update By:{" "}
                                        <br />
                                        {
                                            selectedPcmUpdate?.creator__first_name
                                        }{" "}
                                        {selectedPcmUpdate?.creator__last_name}{" "}
                                        on: <br />
                                        {selectedPcmUpdate.entrydate &&
                                            moment(
                                                selectedPcmUpdate.entrydate
                                            ).format("YYYY.MM.DD [at] HHmm")}
                                    </h4>
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button
                                            variant="primary"
                                            className="m-1"
                                            onClick={() => {
                                                setSelectedPcmUpdate({});
                                                setShowDeleteModal(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="m-1"
                                            onClick={handleDeleteUpdate}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        )}
                        {pcmUpdates.map((update, idx) => (
                            <div key={`update-${idx}`}>
                                <div className="text-end">
                                    {update?.creator__id ===
                                        currentUser?.id && (
                                        <>
                                            <button
                                                className="btn btn-link d-inline-block"
                                                style={{ minWidth: 10 }}
                                                onClick={() => {
                                                    setSelectedPcmUpdate(
                                                        update
                                                    );
                                                    setShowPCMUpdateModal(true);
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="btn btn-link d-inline-block"
                                                style={{ minWidth: 10 }}
                                                onClick={() => {
                                                    setSelectedPcmUpdate(
                                                        update
                                                    );
                                                    setShowDeleteModal(true);
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </div>
                                <div className="border border-secondary p-4 m-4">
                                    {update?.shared &&
                                        <p className="d-flex justify-content-end m-0 fw-bold">Shared with Customer</p>
                                    }
                                    {update?.moddate ?
                                        <p className="text-end">
                                            Update Modified by: {update?.editor__first_name}{" "}
                                            {update?.editor__last_name} <br />
                                            {update?.moddate &&
                                                moment.utc(update.moddate).format(
                                                    "YYYY.MM.DD [at] HH:mm"
                                                )}
                                        </p>
                                    :
                                        <p className="text-end">
                                            Update Created by: {update?.creator__first_name}{" "}
                                            {update?.creator__last_name} <br />
                                            {update?.entrydate &&
                                                moment.utc(update.entrydate).format(
                                                    "YYYY.MM.DD [at] HH:mm"
                                                )}
                                        </p>
                                    }
                                    <div>
                                        {update.note1 && parse(update.note1)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}
