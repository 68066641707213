import { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import "./StatusContainer.css"

export default function StatusContainer() {
    const [projectsLength, setProjectsLength] = useState('')
    const [cctlLength, setCctlLength] = useState('')
    const [ppLength, setPPLength] = useState('')
    const [eventsLength, setEventsLength] = useState('')
    const [newsLength, setNewsLength] = useState('')
    const [ccraLength, setCcraLength] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/counts/?certification_status=Certified&publish_status=Published&not_archived=true`)
        .then(response => {
            setProjectsLength(response.data.count);
        }).catch(error => {
            console.log('unable to get projects', error)
        })
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}cctl/directory/counts/?status=Certified`)
        .then(response => {
            setCctlLength(response.data.count);
        }).catch(error => {
          console.log('unable to get cctls', error)
        })
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/counts/?status=Publishing&not_sunset=True`)
        .then(response => {
            setPPLength(response.data.count);
        }).catch(error => {
          console.log('unable to get protection profiles', error)
        })
      }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/scheme/get_frontend_producers_consumers`
        ).then(response => {
            setCcraLength(response.data.consumers?.length + response.data.producers?.length)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const addZero = (length) => {
        if(length < 10 && length !== 0){
            let lengthStr = length.toString() 
            lengthStr = '0' + lengthStr;
            return lengthStr;
        }
        return length;
    }

    return (
        <Container fluid className="text-white card-group mt-2 justify-content-center">
            <Row className='text-center p-5 w-100' xs={1}>
                <Col className='pt-1 border-transparent warning-hover' md={3}>
                <a id="projects_link" className='text-white remove-link' href='/products'>
                    <div className="text-warning-hover">
                        <h2>{addZero(projectsLength)}</h2>
                        <p className="text-white">Products on PCL</p>
                    </div>
                </a>
                </Col>
                <Col className='pt-1 border-transparent warning-hover' md={3}>
                    <a id="pp_link" className='text-white remove-link' href='/protectionprofiles'>
                        <div className="text-warning-hover">
                            <h2>{addZero(ppLength)}</h2>
                            <p className="text-white">Approved Protection Profiles</p>
                        </div>
                    </a>
                </Col>
                <Col className='pt-1 border-transparent warning-hover' md={3}>
                    <a id="cctl_link" className='text-white remove-link' href='/cctl'>
                        <div className="text-warning-hover">
                            <h2>{addZero(cctlLength)}</h2>
                            <p className="text-white">Common Criteria Testing Laboratories</p>
                        </div>
                    </a>
                </Col>
                <Col className='pt-1 border-transparent warning-hover' md={3}>
                    <a id="ccra_link" className='text-white remove-link' href='/ccra'>
                        <div className="text-warning-hover">
                            <h2>{addZero(ccraLength)}</h2>
                            <p className="text-white">Nations making up the Common Criteria Recognition Agreement</p>
                        </div>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}