import { Card, Button, Stack, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import './cctl.css'

export default function CCTLListItem({ card }) {

    const statusColors = {
        'Candidate': 'bg-orange',
        'Certified': 'bg-green'
    }

    return (
        <Card className="shadow bg-product">
            <Row>
                <Col xs={2} sm={2} md={1}>
                    <div className={'list-card-bar ' + statusColors[card?.status_id?.status_name]}></div>
                </Col>
                <Col xs={6} sm={7} md={8} lg={9}>
                    <Card.Body>
                        <a href={card?.cctl_url}><Card.Title className='pb-3 fw-bold'>{card?.cctl_name}</Card.Title></a>
                        <Row>
                            <Col sm={6}>
                                {card?.address_1 &&
                                    <>{card?.address_1}</>
                                }
                                {card?.address_2 &&
                                    <>{" " + card?.address_2}</>
                                }
                                {card?.city &&
                                    <>{" " + card?.city}</>
                                }
                                {card?.state_id &&
                                    <>{", " + card?.state_id.us_state_abbrv}</>
                                }
                                {card?.zip &&
                                    <>{" " + card?.zip}</>
                                }
                                <p className='fw-bold mt-3 mb-1'>Lab Contact</p>
                                {card?.poc &&
                                    <p className='mb-1'>{card?.poc}</p>
                                }
                                {card?.work_phone && 
                                    <p className='mb-1'>{card?.work_phone} (Phone)</p>
                                }
                                {card?.poc_email &&
                                    <p className='text-decoration-underline my-0' style={{ color: "var(--bs-blue)" }}>{card?.poc_email}</p>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Col>
                <Col xs={4} sm={3} md={3} lg={2} className='ms-auto pe-5'>
                    <Stack>
                        <h6 className='text-sm-end text-xs-start my-3'>
                            <FontAwesomeIcon icon={faCircle} className='me-1' 
                            color={card?.status_id?.status_name === "Certified" ? "var(--bs-green)" : "var(--bs-yellow)"} size='xs' />
                            {card?.status_id?.status_name === 'Active' ? "Approved" : card?.status_id?.status_name}
                        </h6>
                        <p className='fw-bold mb-0'>NVLAP Lab Code</p>
                        <p>{card?.nvlap_lab_code}</p>
                        <p className='fw-bold mb-0'>Test Methods</p>
                        <p>{card?.lab_test_methods}</p>
                    </Stack>
                </Col>
            </Row>
        </Card>
    )
}