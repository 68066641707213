import { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import steeringImg from '../../images/tech-communities/steering-comm.png';
import technicalImg from '../../images/tech-communities/tech-comm.png';
import participantsImg from '../../images/tech-communities/participants.png';
import TechnicalCommBody from './ModalBodies/TechnicalCommBody';
import SteeringCommBody from './ModalBodies/SteeringCommBody';
import ParticipantsBody from './ModalBodies/ParticipantsBody';

export default function TechCommModals() {
    const [showTcModal, setShowTcModal] = useState(false);
    const [showSteeringModal, setShowSteeringModal] = useState(false);
    const [showParticipantsModal, setShowParticipantsModal] = useState(false);
    const [showLine, setShowLine] = useState(true);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const handleShowTcModal = () => setShowTcModal(!showTcModal);
    const handleShowSteeringModal = () => setShowSteeringModal(!showSteeringModal)
    const handleShowParticipantsModal = () => setShowParticipantsModal(!showParticipantsModal)

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        if (windowSize.innerWidth <= 992) {
            setShowLine(false);
        } else {
            setShowLine(true);
        }
    }, [windowSize]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
    window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <Row className="my-3">
        <Col sm={12} md={5} className="text-center">
            <img src={technicalImg} className='border-bottom border-warning border-5' height='150px' alt='Technical Community Logo' />
            <h5 className="text-uppercase fw-bold text-primary">Technical Community</h5>
            <Button variant='warning' onClick={handleShowTcModal}>Learn More</Button>
            <TechnicalCommBody showModal={showTcModal} handleShowModal={handleShowTcModal} />
        </Col>
        {showLine && 
            <Col lg={2}><div className="horizontal-line"></div></Col>
        }
        <Col sm={12} md={5} className="text-center">
            <img src={steeringImg} className='border-bottom border-warning border-5' height='150px' alt='Steering Committee Logo' />
            <h5 className="text-uppercase fw-bold text-primary">Steering Committee</h5>
            <Button variant='warning' onClick={handleShowSteeringModal}>Learn More</Button>
            <SteeringCommBody showModal={showSteeringModal} handleShowModal={handleShowSteeringModal} />
        </Col>
        {/* {showLine && 
            <Col lg={2} xl={3}><div className="horizontal-line"></div></Col>
        } */}
        {/* <Col xs={12} sm={12} md={4} lg={2} className="text-center">
            <img src={participantsImg} className='border-bottom border-warning border-5' height='150px' alt='Participants Logo' />
            <h5 className="text-uppercase fw-bold text-primary">Participants</h5>
            <Button variant='none' onClick={handleShowParticipantsModal}>Learn More</Button>
            <ParticipantsBody showModal={showParticipantsModal} handleShowModal={handleShowParticipantsModal} />
        </Col> */}
    </Row>
    );
}
